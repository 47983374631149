/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Stack,
  Box,
  Tooltip,
  Rating,
} from '@mui/material';

import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { Worker } from '@react-pdf-viewer/core';
import { saveAs } from 'file-saver';

// IMPORT : composant pour afficher l'image depuis S3
import FournisseurImages from './fournisseurImages';

import { BASE_URL } from '../../../utils/api';

/* --- Styles personnalisés modernes --- */
const modernCardStyle = {
  borderRadius: 4,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',
  },
};

const modernDialogTitleStyle = {
  backgroundColor: '#1976d2',
  color: '#fff',
  p: 2,
  fontSize: '1.5rem',
  fontWeight: 'bold',
};

const modernButtonStyle = {
  borderRadius: '20px',
  px: 3,
  py: 1.5,
  textTransform: 'none',
};

const modernInputStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
};

/* --- Composant d'upload du logo --- */
function FournisseurLogoUploadField({
  formData,
  setFormData,
  userToken,
  fieldName = 'logo',
  label = 'Logo du fournisseur',
}) {
  const [localPreviewUrl, setLocalPreviewUrl] = useState('');
  const fileKey = formData[fieldName] || '';
  const fileName = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = fileName ? fileName.split('.').pop().toLowerCase() : '';
  const [isHovered, setIsHovered] = useState(false);
  const [viewUrl, setViewUrl] = useState('');

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (evt) => setLocalPreviewUrl(evt.target.result);
      reader.readAsDataURL(file);
    }
    else {
      setLocalPreviewUrl('');
    }
    const maxFileSize = 2 * 1024 * 1024;
    if (file.size > maxFileSize) {
      Swal.fire('Attention', 'Fichier trop volumineux (max 2Mo).', 'warning');
      return;
    }
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      Swal.fire('Erreur', 'Type de fichier invalide (PNG, JPEG ou PDF).', 'error');
      return;
    }
    try {
      const resp = await axios.post(
        `${BASE_URL}fournisseurs/presigned`,
        { name: file.name, type: file.type },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      const { url, fields } = resp.data;
      const uploadData = new FormData();
      Object.entries(fields).forEach(([k, v]) => uploadData.append(k, v));
      uploadData.append('file', file);
      await axios.post(url, uploadData, { headers: { 'Content-Type': file.type } });
      const uploadedFileKey = fields.key;
      setFormData((prev) => ({ ...prev, [fieldName]: uploadedFileKey }));
    }
    catch (error) {
      console.error('Erreur upload fichier:', error);
      Swal.fire('Erreur', 'Erreur lors de l’upload du fichier.', 'error');
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const resp = await axios.post(
        `${BASE_URL}fournisseurs/get-signed-url`,
        { key },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      setViewUrl(resp.data.url);
    }
    catch (err) {
      console.error('Erreur get-signed-url (view):', err);
      Swal.fire('Erreur', 'Impossible de visualiser ce document.', 'error');
    }
  };

  useEffect(() => {
    if (viewUrl) {
      window.open(viewUrl, '_blank');
      setViewUrl('');
    }
  }, [viewUrl]);

  const downloadFile = async (signedUrl, filename) => {
    try {
      const fileResp = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([fileResp.data]);
      saveAs(blob, filename || 'download');
    }
    catch (error) {
      console.error('Erreur téléchargement:', error);
      Swal.fire('Erreur', 'Impossible de télécharger ce document.', 'error');
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const resp = await axios.post(
        `${BASE_URL}fournisseurs/get-signed-url`,
        { key },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      downloadFile(resp.data.url, key.split('/').pop());
    }
    catch (err) {
      console.error('Erreur get-signed-url (download):', err);
      Swal.fire('Erreur', 'Impossible de télécharger ce document.', 'error');
    }
  };

  const handleCardClick = () => {
    if (fileKey) fetchSignedUrlForViewing(fileKey);
  };

  const stopPropagation = (e) => e.stopPropagation();
  const openFileSelector = () => {
    document.getElementById(`fournisseur-${fieldName}-input`)?.click();
  };

  return (
    <Card
      variant="outlined"
      sx={{
        ...modernCardStyle,
        mb: 2,
        cursor: fileKey ? 'pointer' : 'default',
        backgroundColor: isHovered ? '#f0f0f0' : '#fff',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCardClick}
    >
      <input
        id={`fournisseur-${fieldName}-input`}
        type="file"
        style={{ display: 'none' }}
        accept="image/*,application/pdf"
        onChange={handleFileChange}
      />
      <CardActionArea>
        <CardMedia
          sx={{
            height: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(135deg, #ece9e6, #ffffff)',
          }}
        >
          {localPreviewUrl ? (
            <img
              src={localPreviewUrl}
              alt="Aperçu local"
              style={{
                width: '100%', height: '100%', objectFit: 'cover', display: 'block'
              }}
            />
          ) : fileKey ? (
            fileExtension === 'pdf' ? (
              <PictureAsPdfIcon sx={{ fontSize: 80, color: '#1976d2' }} />
            ) : (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <FournisseurImages
                  imageKey={fileKey}
                  userToken={userToken}
                  style={{
                    width: '100%', height: '100%', objectFit: 'cover', display: 'block'
                  }}
                />
              </Worker>
            )
          ) : (
            <Typography variant="body2" color="text.secondary">
              Aucun document
            </Typography>
          )}
        </CardMedia>
      </CardActionArea>
      <CardContent sx={{ textAlign: 'center', pt: 1 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          {label}
        </Typography>
        <Box onClick={stopPropagation} display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Ajouter / Remplacer">
            <IconButton color="primary" onClick={openFileSelector}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          {fileKey && (
            <Tooltip title="Télécharger">
              <IconButton color="secondary" onClick={() => fetchSignedUrlForDownloading(fileKey)}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

/* --- Composant principal Fournisseurs --- */
function Fournisseurs({ userToken }) {
  const [fournisseurs, setFournisseurs] = useState([]);
  const [searchNom, setSearchNom] = useState('');
  const [searchCategorie, setSearchCategorie] = useState('');
  const [categories, setCategories] = useState([]);

  // Gestion des critères de notation
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [selectedFournisseur, setSelectedFournisseur] = useState(null);
  const [formData, setFormData] = useState({
    logo: '',
    nom: '',
    adresse: '',
    telephone: '',
    mail: '',
    site_web: '',
    categorie: '',
    // Critères individuels
    rating_economique: 0,
    rating_delais: 0,
    rating_qualite: 0,
    rating_environnement: null, // optionnel
    // Note globale (sera calculée)
    rating: 0,
  });

  // État pour la note globale affichée dans le formulaire
  const [globalRating, setGlobalRating] = useState(0);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const fetchFournisseurs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}fournisseurs`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setFournisseurs(response.data.fournisseurs);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des fournisseurs:', error);
    }
  };

  useEffect(() => {
    fetchFournisseurs();
  }, []);

  // Calcul des catégories à partir des fournisseurs
  useEffect(() => {
    const uniqueCats = [...new Set(fournisseurs.map((f) => f.categorie).filter(Boolean))];
    setCategories(uniqueCats);
  }, [fournisseurs]);

  // Tri alphabétique par nom
  const sortedFournisseurs = [...fournisseurs].sort((a, b) => a.nom.localeCompare(b.nom));

  const filteredFournisseurs = sortedFournisseurs.filter((f) => (
    f.nom.toLowerCase().includes(searchNom.toLowerCase())
    && f.categorie.toLowerCase().includes(searchCategorie.toLowerCase())
  ));

  // Fonction pour calculer le rating global à partir des critères individuels
  const computeGlobalRating = (data) => {
    const {
      rating_economique, rating_delais, rating_qualite, rating_environnement
    } = data;
    let sum = Number(rating_economique) + Number(rating_delais) + Number(rating_qualite);
    let count = 3;
    if (rating_environnement !== null && rating_environnement !== 0) {
      sum += Number(rating_environnement);
      count++;
    }
    return sum / count;
  };

  const handleOpenFormDialog = (mode, fournisseur = null) => {
    setFormMode(mode);
    setSelectedFournisseur(fournisseur);
    if (fournisseur) {
      setFormData({ ...fournisseur });
      // Mettre à jour le globalRating à partir des critères stockés dans le fournisseur
      const computed = computeGlobalRating({
        rating_economique: fournisseur.rating_economique,
        rating_delais: fournisseur.rating_delais,
        rating_qualite: fournisseur.rating_qualite,
        rating_environnement: fournisseur.rating_environnement
      });
      setGlobalRating(computed);
    }
    else {
      setFormData({
        logo: '',
        nom: '',
        adresse: '',
        telephone: '',
        mail: '',
        site_web: '',
        categorie: '',
        rating_economique: 0,
        rating_delais: 0,
        rating_qualite: 0,
        rating_environnement: null,
        rating: 0,
      });
      setGlobalRating(0);
    }
    setOpenFormDialog(true);
  };

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
    setSelectedFournisseur(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Fonctions de gestion des changements des critères
  const handleRatingEconomiqueChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, rating_economique: newValue }));
  };

  const handleRatingDelaisChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, rating_delais: newValue }));
  };

  const handleRatingQualiteChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, rating_qualite: newValue }));
  };

  const handleRatingEnvironnementChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, rating_environnement: newValue }));
  };

  // Recalcule le global rating à chaque changement des critères
  useEffect(() => {
    const computed = computeGlobalRating({
      rating_economique: formData.rating_economique,
      rating_delais: formData.rating_delais,
      rating_qualite: formData.rating_qualite,
      rating_environnement: formData.rating_environnement,
    });
    setGlobalRating(computed);
  }, [
    formData.rating_economique,
    formData.rating_delais,
    formData.rating_qualite,
    formData.rating_environnement,
  ]);

  const handleFormSubmit = async () => {
    // On prépare les données en insérant le globalRating calculé
    const newFormData = { ...formData, rating: globalRating };

    try {
      if (formMode === 'create') {
        await axios.post(
          `${BASE_URL}fournisseurs`,
          newFormData,
          { headers: { Authorization: `Bearer ${userToken}` } }
        );
        Swal.fire('Succès', 'Fournisseur créé avec succès', 'success');
      }
      else {
        await axios.put(
          `${BASE_URL}fournisseurs/${selectedFournisseur.id}`,
          newFormData,
          { headers: { Authorization: `Bearer ${userToken}` } }
        );
        Swal.fire('Succès', 'Fournisseur mis à jour avec succès', 'success');
      }
      fetchFournisseurs();
      handleCloseFormDialog();
    }
    catch (error) {
      console.error('Erreur lors de l’enregistrement du fournisseur:', error);
      Swal.fire('Erreur', 'Erreur lors de l’enregistrement', 'error');
    }
  };

  const handleDeleteFournisseur = async (id) => {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}fournisseurs/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
          });
          Swal.fire('Supprimé !', 'Le fournisseur a été supprimé.', 'success');
          fetchFournisseurs();
        }
        catch (error) {
          console.error('Erreur lors de la suppression du fournisseur:', error);
          Swal.fire('Erreur !', 'Erreur lors de la suppression', 'error');
        }
      }
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Bouton "Ajouter un Fournisseur" */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          sx={modernButtonStyle}
          onClick={() => handleOpenFormDialog('create')}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Ajouter un Fournisseur
        </Button>
      </Box>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
        Annuaire des fournisseurs
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mb: 3
      }}
      >
        <TextField
          variant="outlined"
          placeholder="Rechercher par nom"
          value={searchNom}
          onChange={(e) => setSearchNom(e.target.value)}
          sx={{ ...modernInputStyle, mb: { xs: 2, md: 0 }, width: { xs: '100%', md: '48%' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="outlined" sx={{ ...modernInputStyle, width: { xs: '100%', md: '48%' } }}>
          <InputLabel id="categorie-label">Catégorie</InputLabel>
          <Select
            labelId="categorie-label"
            label="Catégorie"
            value={searchCategorie}
            onChange={(e) => setSearchCategorie(e.target.value)}
          >
            <MenuItem value="">
              <em>Toutes les catégories</em>
            </MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={4}>
        {filteredFournisseurs.map((fournisseur) => (
          <Grid item key={fournisseur.id} xs={12} sm={6} md={4}>
            <Card sx={{
              ...modernCardStyle, height: '100%', display: 'flex', flexDirection: 'column'
            }}
            >
              {fournisseur.logo && (
                <CardMedia sx={{ position: 'relative', height: 300, overflow: 'hidden' }}>
                  {fournisseur.logo.startsWith('http') ? (
                    <img
                      src={fournisseur.logo}
                      alt={`${fournisseur.nom} logo`}
                      style={{
                        width: '100%', height: '100%', objectFit: 'cover', display: 'block'
                      }}
                    />
                  ) : (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <FournisseurImages
                        imageKey={fournisseur.logo}
                        userToken={userToken}
                        style={{
                          width: '100%', height: '100%', objectFit: 'cover', display: 'block'
                        }}
                      />
                    </Worker>
                  )}
                </CardMedia>
              )}
              <CardContent sx={{ flexGrow: 1, p: 2 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {fournisseur.nom}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  {fournisseur.adresse}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  Téléphone: {fournisseur.telephone}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  Email:{' '}
                  <Link href={`mailto:${fournisseur.mail}`} underline="hover">
                    {fournisseur.mail}
                  </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  Site Web:{' '}
                  <Link href={fournisseur.site_web} target="_blank" rel="noopener" underline="hover">
                    {fournisseur.site_web}
                  </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Catégorie: {fournisseur.categorie}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Rating name="read-only" value={Number(fournisseur.rating) || 0} readOnly precision={0.5} />
                  <Typography variant="caption" sx={{ ml: 1 }}>
                    {fournisseur.rating ? `${fournisseur.rating}/5` : 'Non noté'}
                  </Typography>
                </Box>
              </CardContent>
              <Stack direction="row" spacing={1} sx={{ p: 2, pt: 0, justifyContent: 'center' }}>
                <Tooltip title="Modifier le fournisseur">
                  <IconButton color="primary" onClick={() => handleOpenFormDialog('edit', fournisseur)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le fournisseur">
                  <IconButton color="error" onClick={() => handleDeleteFournisseur(fournisseur.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openFormDialog} onClose={handleCloseFormDialog} maxWidth="sm" fullWidth>
        <DialogTitle sx={modernDialogTitleStyle}>
          {formMode === 'create' ? 'Ajouter un Fournisseur' : 'Modifier le Fournisseur'}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 3 }}>
          <FournisseurLogoUploadField
            formData={formData}
            setFormData={setFormData}
            userToken={userToken}
            fieldName="logo"
            label="Logo du fournisseur"
          />
          <TextField
            margin="dense"
            label="Nom"
            name="nom"
            fullWidth
            value={formData.nom}
            onChange={handleFormChange}
            required
            sx={modernInputStyle}
          />
          <TextField
            margin="dense"
            label="Adresse"
            name="adresse"
            fullWidth
            value={formData.adresse}
            onChange={handleFormChange}
            sx={modernInputStyle}
          />
          <TextField
            margin="dense"
            label="Téléphone"
            name="telephone"
            fullWidth
            value={formData.telephone}
            onChange={handleFormChange}
            sx={modernInputStyle}
          />
          <TextField
            margin="dense"
            label="Email"
            name="mail"
            fullWidth
            value={formData.mail}
            onChange={handleFormChange}
            sx={modernInputStyle}
          />
          <TextField
            margin="dense"
            label="Site Web"
            name="site_web"
            fullWidth
            value={formData.site_web}
            onChange={handleFormChange}
            sx={modernInputStyle}
          />
          <TextField
            margin="dense"
            label="Catégorie"
            name="categorie"
            fullWidth
            value={formData.categorie}
            onChange={handleFormChange}
            sx={modernInputStyle}
          />
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Notation par critères
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Economique</Typography>
                <Rating
                  name="rating_economique"
                  value={Number(formData.rating_economique)}
                  precision={0.5}
                  onChange={handleRatingEconomiqueChange}
                  size="large"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Délais de prestation / livraison</Typography>
                <Rating
                  name="rating_delais"
                  value={Number(formData.rating_delais)}
                  precision={0.5}
                  onChange={handleRatingDelaisChange}
                  size="large"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Qualité des services</Typography>
                <Rating
                  name="rating_qualite"
                  value={Number(formData.rating_qualite)}
                  precision={0.5}
                  onChange={handleRatingQualiteChange}
                  size="large"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Respect de l'environnement <em>(optionnel)</em>
                </Typography>
                <Rating
                  name="rating_environnement"
                  value={formData.rating_environnement !== null ? Number(formData.rating_environnement) : 0}
                  precision={0.5}
                  onChange={handleRatingEnvironnementChange}
                  size="large"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">
                Note Globale calculée :
              </Typography>
              <Rating name="globalRating" value={globalRating} readOnly precision={0.1} />
              <Typography variant="caption">
                {globalRating.toFixed(2)}/5
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleCloseFormDialog} sx={modernButtonStyle} variant="outlined">
            Annuler
          </Button>
          <Button onClick={handleFormSubmit} sx={modernButtonStyle} variant="contained" color="primary">
            {formMode === 'create' ? 'Ajouter' : 'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default React.memo(Fournisseurs);
