/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './updateTransportModalStyles.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Autocomplete } from '@mui/material';
import moment from 'moment';
import { BASE_URL } from '../../../utils/api';
import fetchAddresses from '../../../utils/fetchAddresses';
import DistanceCalculator from '../../hooks/distanceCalculator';
import calculateDevis from '../../hooks/calculateDevis';

function UpdateTransportModal({
  onUpdateTransport, currentTransport, userToken
}) {
  const [returnChecked, setReturnChecked] = useState(currentTransport.round_trip);
  const [vehicles, setVehicles] = useState([]);
  const [crews, setCrews] = useState([]);
  const [staff, setStaff] = useState([]);

  // ---------------- NOUVEAU : état pour les sociétés et pour Osiris ----------------
  const [companies, setCompanies] = useState([]);
  const [incidentSocieteConcernee, setIncidentSocieteConcernee] = useState('');
  const [incidentReponse, setIncidentReponse] = useState('');
  // ---------------------------------------------------------------------------------

  const [showIncidentModal, setShowIncidentModal] = useState(false);

  // incidentType inclut désormais "Osiris"
  const [incidentType, setIncidentType] = useState('');

  const [incidentDescription, setIncidentDescription] = useState('');
  const [incidentFacility, setIncidentFacility] = useState('departure');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [returnAddressSuggestions, setReturnAddressSuggestions] = useState([]);
  const [distance, setDistance] = useState('');
  const formatPhoneNumber = (number) => number.replace(/(\d{2})(?=\d)/g, '$1 ');
  const [formData, setFormData] = useState({
    id: '',
    date: '',
    appointment_hour: '',
    departure_hour: '',
    arrival_hour: '',
    tarification_type: '',
    name: '',
    last_name: '',
    phone_number: '',
    departure_address: '',
    departure_label: '',
    arrival_address: '',
    arrival_label: '',
    price: '',
    comment: '',
    reason: '',
    round_trip: currentTransport.round_trip || false,
    driver1: '',
    driver2: '',
    vehicule: '',
    status: '',
    uh: '',
    service: '',
    samu_number: '',
    uh_arrival: '',
    service_arrival: '',
    incident: '',
    incident_type: '',
    tarif_applicable: '',
    distance: '',
    aeroport: false,
    premature: false,
    tpmr: false,
    urgent: false,
    tarif_taxi: null,
    waiting_time: '00:00',
    number_of_people: 1,
    invoice_number: '',
    return_date: null,
    return_departure_hour: null,
    // Ici on pourrait stocker la société Osiris et la réponse
    // mais pour éviter toute confusion avec `societe`,
    // on crée explicitement des champs d'incident distincts.
    incident_osiris_societe: '',
    incident_osiris_reponse: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const formatDate = (date) => date.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const [returnFormData, setReturnFormData] = useState({
    return_date: formatDate(new Date()),
    return_hour: null,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [etablissementsData, setEtablissementsData] = useState([]);
  const [tarifs, setTarifs] = useState([]);
  const [typesDeTarif, setTypesDeTarif] = useState([]);
  const [displayDate, setDisplayDate] = useState(format(new Date(currentTransport.date), 'yyyy-MM-dd'));
  const [selectedType, setSelectedType] = useState('');
  const [estimatedPrice, setEstimatedPrice] = useState('');
  const [, setInputDepartureLabel] = useState('');
  const [inputArrivalLabel, setInputArrivalLabel] = useState('');
  const [selectedDepartureEtablissementId, setSelectedDepartureEtablissementId] = useState(null);
  const [selectedArrivalEtablissementId, setSelectedArrivalEtablissementId] = useState(null);

  const keyToLabelMapping = {
    basekma: 'A',
    basekmb: 'B',
    basekmc: 'C',
    basekmd: 'D'
  };

  const handleTarifTaxiChange = (event) => {
    const newTarifTaxi = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      tarif_taxi: newTarifTaxi,
    }));
  };

  const handleOpenIncidentModal = () => {
    setShowIncidentModal(true);
  };

  const handleCloseIncidentModal = () => {
    setShowIncidentModal(false);
  };

  // ---------------- NOUVEAU : récupérer la liste des sociétés ----------------
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}companies`, { headers });
        setCompanies(response.data.companies);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des sociétés:', error);
      }
    };

    fetchCompanies();
  }, []);
  // ----------------------------------------------------------------------------

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}vehicules`, { headers });
        const fetchedVehicles = response.data.vehicule;
        setVehicles(fetchedVehicles);

        // Update formData with the vehicle once vehicles are fetched
        setFormData((prevFormData) => ({
          ...prevFormData,
          vehicule: fetchedVehicles.find((v) => v.code === currentTransport.vehicule)?.code || '',
        }));
      }
      catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };

    fetchVehicles();
    axios
      .get(`${BASE_URL}crews`, { headers })
      .then((res) => {
        setCrews(res.data.crews);
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(`${BASE_URL}staff`, { headers })
      .then((res) => {
        setStaff(res.data.staff);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchTarifs = async () => {
    try {
      const res = await axios.get(`${BASE_URL}tarifs`, { headers });
      setTarifs(res.data.tarifs);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchTypeDeTarif = async () => {
    try {
      const res = await axios.get(`${BASE_URL}types-de-tarif`, { headers });
      setTypesDeTarif(res.data.typesDeTarif);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTarifs();
    fetchTypeDeTarif();
  }, []);

  const handleTransportTypeChange = (e) => {
    const { value } = e.target;
    setSelectedType(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      tarification_type: value,
    }));
  };

  const handleTarifApplicable = (event) => {
    const newTarifApplicable = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      tarif_applicable: newTarifApplicable,
    }));
  };

  const handleWaitingTimeChange = (event) => {
    const newWaitingTime = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      waiting_time: newWaitingTime,
    }));
  };

  const handleNumberOfPeopleChange = (event) => {
    const newNumberOfPeople = parseInt(event.target.value, 10);
    setFormData((prevFormData) => ({
      ...prevFormData,
      number_of_people: newNumberOfPeople,
    }));
  };

  useEffect(() => {
    setFormData({
      id: currentTransport.id,
      date: currentTransport.date,
      appointment_hour: currentTransport.appointment_hour,
      departure_hour: currentTransport.departure_hour,
      arrival_hour: currentTransport.arrival_hour,
      tarification_type: currentTransport.tarification_type,
      name: currentTransport.name,
      last_name: currentTransport.last_name,
      phone_number: formatPhoneNumber(currentTransport.phone_number),
      departure_address: currentTransport.departure_address,
      price: currentTransport.price,
      departure_label: currentTransport.departure_label,
      arrival_address: currentTransport.arrival_address,
      arrival_label: currentTransport.arrival_label,
      samu_number: currentTransport.samu_number,
      comment: currentTransport.comment,
      reason: currentTransport.reason,
      driver1: currentTransport.driver1,
      driver2: currentTransport.driver2,
      vehicule: vehicles.find((v) => v.code === currentTransport.vehicule)?.code || '',
      status: currentTransport.status,
      uh: currentTransport.uh,
      service: currentTransport.service,
      uh_arrival: currentTransport.uh_arrival,
      service_arrival: currentTransport.service_arrival,
      societe: currentTransport.societe,
      incident: currentTransport.incident,
      incident_type: currentTransport.incident_type,
      tarif_applicable: currentTransport.tarif_applicable,
      distance: currentTransport.distance,
      aeroport: currentTransport.aeroport,
      premature: currentTransport.premature,
      tpmr: currentTransport.tpmr,
      urgent: currentTransport.urgent,
      tarif_taxi: currentTransport.tarif_taxi,
      waiting_time: currentTransport.waiting_time,
      number_of_people: currentTransport.number_of_people,
      invoice_number: currentTransport.invoice_number,
      round_trip: currentTransport.round_trip,
      return_date: currentTransport.return_date,
      return_departure_hour: currentTransport.return_departure_hour,
      incident_osiris_societe: '', // Valeur par défaut
      incident_osiris_reponse: '' // Valeur par défaut
    });
    if (currentTransport.linked_transport) {
      setReturnFormData({
        return_date: currentTransport.linked_transport.date,
        return_appointment_hour: currentTransport.linked_transport.appointment_hour,
        return_departure_hour: currentTransport.linked_transport.departure_hour,
        return_arrival_hour: currentTransport.linked_transport.arrival_hour,
      });
    }
  }, [currentTransport, vehicles]);

  useEffect(() => {
    const initSelectedType = () => {
      const foundType = typesDeTarif.find((type) => type.nom === currentTransport.tarification_type);
      if (foundType) {
        setSelectedType(foundType.nom);
      }
    };
    if (typesDeTarif.length > 0 && currentTransport) {
      initSelectedType();
    }
  }, [typesDeTarif, currentTransport]);

  const selectedTypeId = typesDeTarif.find((type) => type.nom === selectedType)?.id;

  const filteredCategories = tarifs
    .filter((tarif) => tarif.typedetarifid === selectedTypeId)
    .map((tarif) => tarif.categorie)
    .filter((value, index, self) => self.indexOf(value) === index);

  const categoryToTarifNameMapping = filteredCategories.reduce((acc, category) => {
    const tarifName = tarifs.find((tarif) => tarif.categorie === category)?.nom;
    if (tarifName) {
      acc[category] = tarifName;
    }
    return acc;
  }, {});

  // ------------------- AJOUT : gestion de l'incident Osiris -------------------
  const handleAddIncident = () => {
    let prefix = '';
    if (incidentFacility === 'departure') prefix = '[DEPART] ';
    else if (incidentFacility === 'arrival') prefix = '[ARRIV] ';
    else if (incidentFacility === 'both') prefix = '[BOTH] ';

    // Construction du texte d'incident
    const incidentTexte = `${prefix}${incidentDescription}`;

    // Met à jour formData : type, description, et si Osiris, on ajoute aussi la société concernée + la réponse
    setFormData((prevFormData) => ({
      ...prevFormData,
      incident_type: incidentType,
      incident: incidentTexte,
      // Si c'est Osiris, on stocke aussi dans deux champs supplémentaires
      incident_osiris_societe: incidentType === 'Osiris' ? incidentSocieteConcernee : '',
      incident_osiris_reponse: incidentType === 'Osiris' ? incidentReponse : ''
    }));

    handleCloseIncidentModal();
  };
  // ----------------------------------------------------------------------------

  useEffect(() => {
    axios.get(`${BASE_URL}services`, { headers })
      .then((response) => {
        setServicesData(response.data.services);
      })
      .catch((error) => {
        console.error(error);
      });

    axios.get(`${BASE_URL}etablissements`, { headers })
      .then((response) => {
        setEtablissementsData(response.data.etablissements);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleChange = (e) => {
    let value = e.target.type === 'checkbox' ? !formData[e.target.name] : e.target.value;
    if (e.target.name === 'phone_number') {
      value = formatPhoneNumber(value);
    }

    if (e.target.name === 'date') {
      setDisplayDate(value);
      value = format(new Date(value), 'yyyy-MM-dd');
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
    }));

    if (e.target.name === 'round_trip') {
      setReturnChecked(!returnChecked);
    }
  };

  const handleRadioChange = (event) => {
    setFormData({
      ...formData,
      status: event.target.value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.date) {
      newErrors.date = 'La date est requise';
    }
    if (!formData.appointment_hour) {
      newErrors.appointment_hour = 'L\'heure de rendez-vous est requise';
    }
    if (!formData.departure_hour) {
      newErrors.departure_hour = 'L\'heure de départ est requise';
    }
    if (!formData.tarification_type) {
      newErrors.tarification_type = 'Le type est requis';
    }
    if (!formData.last_name) {
      newErrors.last_name = 'Le prénom est requis';
    }
    if (!formData.phone_number) {
      newErrors.phone_number = 'Le numéro de téléphone est requis';
    }
    if (!formData.departure_address) {
      newErrors.departure_address = 'L\'adresse de départ est requise';
    }
    if (!formData.arrival_address) {
      newErrors.arrival_address = 'L\'adresse d\'arrivée est requise';
    }
    if (!formData.reason) {
      newErrors.reason = 'La raison est requise';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleUhChange = (event, value) => {
    if (!value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh: '',
        service: '',
        departure_address: '',
        departure_label: '',
        phone_number: '',
      }));
      setInputDepartureLabel('');
      return;
    }

    const service = servicesData.find((s) => s.uh === value);
    if (service) {
      const etablissement = etablissementsData.find(
        (e) => e.id === service.etablissement_id
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh: value,
        service: service.name,
        departure_address: `${etablissement.address} ${etablissement.zipcode} ${etablissement.city || ''}`,
        departure_label: etablissement.name,
        phone_number: service.phone,
      }));
      setInputDepartureLabel(etablissement.name);
    }
  };

  const handleUhArrivalChange = (event, value) => {
    if (!value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh_arrival: '',
        service_arrival: '',
        arrival_address: '',
        arrival_label: '',
      }));
      setInputArrivalLabel('');
      return;
    }

    const service_arrival = servicesData.find((s) => s.uh === value);
    if (service_arrival) {
      const etablissementArrival = etablissementsData.find(
        (e) => e.id === service_arrival.etablissement_id
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh_arrival: value,
        service_arrival: service_arrival.name,
        arrival_address: `${etablissementArrival.address} ${etablissementArrival.zipcode} ${etablissementArrival.city || ''}`,
        arrival_label: etablissementArrival.name,
      }));
      setInputArrivalLabel(etablissementArrival.name);
    }
  };

  useEffect(() => {
    if (tarifs.length > 0 && typesDeTarif.length > 0 && currentTransport) {
      const tarifApplicable = tarifs.find((tarif) => tarif.categorie === formData.tarif_applicable);
      const transportDay = new Date(currentTransport.date).getDay();

      if (tarifApplicable) {
        const transportDetails = {
          selectedTransportType: formData.tarification_type,
          distance: formData.distance || distance,
          waitingTime: formData.waiting_time,
          departureTime: formData.departure_hour,
          arrivalTime: formData.arrival_hour,
          transportDate: transportDay,
          cancelled: formData.status === 'Annuler facturé',
          aeroport: formData.aeroport,
          premature: formData.premature,
          tpmr: formData.tpmr,
          urgent: formData.urgent,
          tarifTaxi: formData.tarif_taxi,
          numberOfPeople: formData.number_of_people,
        };

        const { estimate } = calculateDevis(tarifApplicable, transportDetails);

        if (typeof estimate === 'number' && !Number.isNaN(estimate)) {
          setEstimatedPrice(estimate.toFixed(2));
        }
        else {
          setEstimatedPrice('0');
        }
      }
    }
  }, [tarifs, distance, formData, currentTransport, formData.waiting_time, formData.number_of_people]);

  useEffect(() => {
    setEstimatedPrice(formData.price || '0');
  }, [formData.price]);

  const currentCrews = crews.filter((crew) => format(new Date(crew.date), 'yyyy-MM-dd') === displayDate);

  const getVehicleSociete = (vehicleCode) => {
    if (!vehicleCode) return null;
    const selectedVehicle = vehicles.find((v) => v.code === vehicleCode);
    return selectedVehicle ? selectedVehicle.societe : null;
  };

  const getInvoiceIdIfExists = async (invoiceNumber) => {
    try {
      const response = await axios.get(`${BASE_URL}invoices`, { headers });
      const invoices = response.data.factures || [];
      const existingInvoice = invoices.find((invoice) => invoice.numero_facture === invoiceNumber);
      return existingInvoice ? existingInvoice.id : null;
    }
    catch (error) {
      console.error('Erreur lors de la vérification de l\'existence de la facture:', error);
      toast.error('Erreur lors de la vérification de l\'existence de la facture');
      return null;
    }
  };

  const getInvoiceByNumber = async (invoiceNumber) => {
    try {
      const response = await axios.get(`${BASE_URL}invoices`, { headers });
      const invoices = response.data.factures || [];
      const invoice = invoices.find((inv) => inv.numero_facture === invoiceNumber);
      return invoice ? invoice.id : null;
    }
    catch (error) {
      console.error('Erreur lors de la récupération de la facture par numéro:', error);
      return null;
    }
  };

  const createOrUpdateInvoice = async () => {
    const invoiceNumber = `FAC-${formData.id}`;
    const vehicleSociete = getVehicleSociete(formData.vehicule);

    const invoiceData = {
      numero_facture: invoiceNumber,
      date_facture: new Date(),
      type_transport: formData.tarification_type,
      date_transport: formData.date,
      heure_depart: formData.departure_hour,
      heure_arrivee: formData.arrival_hour,
      vehicule: formData.vehicule,
      chauffeur1: formData.driver1,
      chauffeur2: formData.driver2,
      adresse_depart: `${formData.departure_address} ${formData.zipcode} ${formData.city}`,
      adresse_arrivee: formData.arrival_address,
      distance: formData.distance,
      nombre_personnes: formData.number_of_people,
      attente: formData.waiting_time,
      retour: formData.round_trip,
      societe_nom: vehicleSociete,
      motif: formData.reason,
      tarif_nom: formData.tarif_applicable,
      tarif_forfait: formData.tarif_applicable,
      tarif_taxi: formData.tarif_taxi,
      tarif_total: formData.price,
      assure_nom: formData.last_name,
      assure_prenom: formData.name,
    };

    try {
      const existingInvoiceId = await getInvoiceIdIfExists(invoiceNumber);
      if (existingInvoiceId) {
        await axios.put(`${BASE_URL}invoices/${existingInvoiceId}`, invoiceData, { headers });
        return { id: existingInvoiceId, numero_facture: invoiceNumber };
      }
      await axios.post(`${BASE_URL}invoices`, invoiceData, { headers });
      const createdInvoiceId = await getInvoiceByNumber(invoiceNumber);
      return { id: createdInvoiceId, numero_facture: invoiceNumber };
    }
    catch (error) {
      console.error('Erreur lors de la création ou mise à jour de la facture:', error);
      return null;
    }
  };

  const updateInvoiceWithReturn = async (invoiceId) => {
    const vehicleSociete = getVehicleSociete(formData.vehicule);

    const updateData = {
      date_retour: formData.date,
      heure_depart_retour: formData.departure_hour,
      heure_arrivee_retour: formData.arrival_hour,
      vehicule_retour: formData.vehicule,
      chauffeur1_retour: formData.driver1,
      chauffeur2_retour: formData.driver2,
      adresse_depart_retour: formData.departure_address,
      adresse_arrivee_retour: formData.arrival_address,
      distance_retour: formData.distance,
      nombre_personnes_retour: formData.number_of_people,
      attente_retour: formData.waiting_time,
      tarif_nom_retour: formData.tarif_applicable,
      tarif_forfait_retour: formData.tarif_applicable,
      tarif_taxi_retour: formData.tarif_taxi,
      tarif_total_retour: formData.price,
      societe_retour: vehicleSociete,
    };

    try {
      await axios.put(`${BASE_URL}invoices/${invoiceId}/return`, updateData, { headers });
      toast.success('Facture mise à jour avec succès');
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour de la facture:', error);
      toast.error('Erreur lors de la mise à jour de la facture');
    }
  };

  function calculateRemainingTransports(startDate, endDate, daysString) {
    const daysMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 0,
    };

    if (!daysString) {
      return 0;
    }

    const currentDate = moment();
    const end = moment(endDate);
    const start = moment(startDate);

    if (currentDate.isAfter(end)) {
      return 0;
    }

    const selectedDays = daysString.split(',').map((day) => daysMap[day]);
    let remainingTransports = 0;

    for (let m = moment(start); m.isBefore(end); m.add(1, 'days')) {
      if (selectedDays.includes(m.day()) && m.isSameOrAfter(currentDate)) {
        remainingTransports += 1;
      }
    }

    return remainingTransports;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const remainingTransports = calculateRemainingTransports(currentTransport.series_start_date, currentTransport.series_end_date, currentTransport.series_days);

    if (remainingTransports && remainingTransports === 1) {
      if (!confirm("Attention, c'est le dernier transport de la série. Voulez-vous continuer ?")) {
        return;
      }
    }

    const selectedVehicle = vehicles.find((v) => v.code === (formData.vehicule || ''));
    const vehicleSociete = selectedVehicle ? getVehicleSociete(formData.vehicule) : null;

    const newData = {
      date: formData.date,
      appointmentHour: formData.appointment_hour,
      departureHour: formData.departure_hour,
      arrivalHour: formData.arrival_hour,
      tarificationType: formData.tarification_type,
      name: formData.name,
      lastName: formData.last_name,
      phoneNumber: formatPhoneNumber(formData.phone_number),
      departureAddress: formData.departure_address,
      departureLabel: formData.departure_label,
      arrivalAddress: formData.arrival_address,
      arrivalLabel: formData.arrival_label,
      price: estimatedPrice || formData.price,
      comment: formData.comment,
      samuNumber: formData.samu_number,
      reason: formData.reason,
      round_trip: formData.round_trip,
      status: formData.status,
      driver1: formData.driver1,
      driver2: formData.driver2,
      vehicule: selectedVehicle ? selectedVehicle.code : '',
      societe: vehicleSociete,
      service: formData.service,
      service_arrival: formData.service_arrival,
      uh: formData.uh,
      uh_arrival: formData.uh_arrival,
      return_departure_hour: returnFormData.return_departure_hour,
      return_date: returnFormData.return_date,

      // On récupère l'incident, le type + si besoin, les infos Osiris
      incident: formData.incident,
      incident_type: formData.incident_type,
      // SI VOUS AVEZ des colonnes prévues côté API/BDD pour stocker la société et la réponse d’Osiris, ajoutez-ici :
      incident_osiris_societe: formData.incident_osiris_societe,
      incident_osiris_reponse: formData.incident_osiris_reponse,

      tarif_applicable: formData.tarif_applicable,
      distance: formData.distance || distance,
      aeroport: formData.aeroport,
      premature: formData.premature,
      tpmr: formData.tpmr,
      urgent: formData.urgent,
      tarif_taxi: formData.tarif_taxi,
      waiting_time: formData.waiting_time,
      number_of_people: formData.number_of_people,
    };

    if (!validateForm()) {
      console.log('Form validation failed');
      return;
    }

    setIsLoading(true);

    try {
      const currentTransportResponse = await axios.get(`${BASE_URL}transports/${currentTransport.id}`, { headers });
      const currentTransportData = currentTransportResponse.data;

      const updatedTransportData = { ...currentTransportData, ...newData };

      await axios.put(`${BASE_URL}transports/${currentTransport.id}`, updatedTransportData, { headers });
      onUpdateTransport(updatedTransportData);

      let invoiceCreatedOrUpdated = false;
      let invoiceId = null;
      let invoiceNumber = null;

      if (
        (currentTransport.status !== 'Terminé' && newData.status === 'Terminé')
        || (currentTransport.status !== 'Annuler facturé' && newData.status === 'Annuler facturé')
      ) {
        if (currentTransport.linked_transport_id) {
          const linkedTransportResponse = await axios.get(`${BASE_URL}transports/${currentTransport.linked_transport_id}`, { headers });
          const linkedTransport = linkedTransportResponse.data;

          const currentTransportSociete = getVehicleSociete(formData.vehicule);
          const linkedTransportSociete = getVehicleSociete(linkedTransport.transport.vehicule);

          if (currentTransportSociete && linkedTransportSociete && currentTransportSociete !== linkedTransportSociete) {
            const { id: newInvoiceId, numero_facture: newInvoiceNumber } = await createOrUpdateInvoice(formData);
            if (newInvoiceId && newInvoiceNumber) {
              await axios.put(`${BASE_URL}transports/${currentTransport.linked_transport_id}/invoice-number`, { invoice_number: newInvoiceNumber }, { headers });
              invoiceCreatedOrUpdated = true;
              invoiceId = newInvoiceId;
              invoiceNumber = newInvoiceNumber;
            }
          }
          else if (linkedTransport.invoice_number) {
            const invoiceIdFromLinkedTransport = await getInvoiceIdIfExists(linkedTransport.invoice_number);
            if (invoiceIdFromLinkedTransport) {
              await updateInvoiceWithReturn(invoiceIdFromLinkedTransport, formData);
              invoiceCreatedOrUpdated = true;
              invoiceId = invoiceIdFromLinkedTransport;
              invoiceNumber = linkedTransport.invoice_number;
            }
          }
          else if (currentTransport.invoice_number) {
            const invoiceIdFromCurrentTransport = await getInvoiceIdIfExists(currentTransport.invoice_number);
            if (invoiceIdFromCurrentTransport) {
              await updateInvoiceWithReturn(invoiceIdFromCurrentTransport, formData);
              invoiceCreatedOrUpdated = true;
              invoiceId = invoiceIdFromCurrentTransport;
              invoiceNumber = currentTransport.invoice_number;
            }
          }
          else {
            const { id: newInvoiceId, numero_facture: newInvoiceNumber } = await createOrUpdateInvoice(formData);
            if (newInvoiceId && newInvoiceNumber) {
              await axios.put(`${BASE_URL}transports/${currentTransport.id}/invoice-number`, { invoice_number: newInvoiceNumber }, { headers });
              await axios.put(`${BASE_URL}transports/${currentTransport.linked_transport_id}/invoice-number`, { invoice_number: newInvoiceNumber }, { headers });
              invoiceCreatedOrUpdated = true;
              invoiceId = newInvoiceId;
              invoiceNumber = newInvoiceNumber;
            }
            else {
              console.log('Failed to create or update invoice for the return trip.');
            }
          }
        }
        else {
          const { id: newInvoiceId, numero_facture: newInvoiceNumber } = await createOrUpdateInvoice(formData);
          if (newInvoiceId && newInvoiceNumber) {
            await axios.put(`${BASE_URL}transports/${currentTransport.id}/invoice-number`, { invoice_number: newInvoiceNumber }, { headers });
            invoiceCreatedOrUpdated = true;
            invoiceId = newInvoiceId;
            invoiceNumber = newInvoiceNumber;
          }
          else {
            console.log('Failed to create or update invoice.');
          }
        }
      }

      if (invoiceCreatedOrUpdated && invoiceId) {
        await axios.put(`${BASE_URL}transports/${currentTransport.id}/invoice-number`, { invoice_id: invoiceId }, { headers });
        await axios.put(`${BASE_URL}transports/${currentTransport.id}/invoice-number`, { invoice_number: invoiceNumber }, { headers });
      }
    }
    catch (error) {
      console.error('Error in handleSubmit:', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleAddressChange = async (query) => {
    if (query) {
      const results = await fetchAddresses(query);
      if (results && results.features) {
        setAddressSuggestions(results.features);
      }
    }
    else {
      setAddressSuggestions([]);
    }
  };

  const handleReturnAddressChange = async (query) => {
    if (query) {
      const results = await fetchAddresses(query);
      if (results && results.features) {
        setReturnAddressSuggestions(results.features);
      }
    }
    else {
      setReturnAddressSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      departure_address: suggestion.properties.label,
    }));
    setAddressSuggestions([]);
  };

  const handleReturnSuggestionClick = (suggestion) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      arrival_address: suggestion.properties.label,
    }));
    setReturnAddressSuggestions([]);
  };

  const handleReturnDataChange = (e) => {
    const { name, value } = e.target;
    setReturnFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Réinitialise la distance à null lorsque les adresses changent
    setFormData((prevFormData) => ({
      ...prevFormData,
      distance: null,
    }));
  }, [formData.departure_address, formData.arrival_address, setFormData]);

  return (
    <Box component="form" noValidate autoComplete="off" className="updateTransportModal">
      <Grid container spacing={2}>
        <h2 className="updateModalTitle">Modifié un transport</h2>
        <Button
          onClick={handleOpenIncidentModal}
          variant="contained"
          sx={{
            margin: 'auto', backgroundColor: '#505154', width: '20%'
          }}
        >
          Incident
        </Button>
      </Grid>

      {/* ---------------- MODAL INCIDENT ---------------- */}
      <Dialog open={showIncidentModal} onClose={handleCloseIncidentModal}>
        <DialogTitle sx={{ color: '#b47f31', textAlign: 'center' }}>Ajouter un incident</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <InputLabel id="incident-type-label">Type d'incident</InputLabel>
            <Select
              labelId="incident-type-label"
              id="incident-type"
              value={incidentType}
              onChange={(e) => setIncidentType(e.target.value)}
              label="Type d'incident"
            >
              <MenuItem value="Annuler sur place">Annuler sur place</MenuItem>
              <MenuItem value="Patient non prêt">Patient non prêt</MenuItem>
              <MenuItem value="Patient absent">Patient absent</MenuItem>
              <MenuItem value="Documents non établis">Documents non établis</MenuItem>
              <MenuItem value="Erreur de date ou d'horraire">Erreur de date ou d'horraire</MenuItem>
              <MenuItem value="Attente imposée">Attente imposée</MenuItem>
              <MenuItem value="Erreur mode de transport">Erreur mode de transport</MenuItem>
              <MenuItem value="Mauvaise destination">Mauvaise destination</MenuItem>
              <MenuItem value="Refus du patient">Refus du patient</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>

              {/* --------- NOUVELLE OPTION OSIRIS --------- */}
              <MenuItem value="Osiris">Osiris</MenuItem>
            </Select>
          </FormControl>

          {/* Si "Osiris" est sélectionné, on affiche 2 champs supplémentaires */}
          {incidentType === 'Osiris' && (
            <FormControl
              sx={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}
            >
              <InputLabel id="societe-concernee-label">Société concernée</InputLabel>
              <Select
                labelId="societe-concernee-label"
                id="societe-concernee"
                value={incidentSocieteConcernee}
                label="Société concernée"
                onChange={(e) => setIncidentSocieteConcernee(e.target.value)}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.code}>
                    {company.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl sx={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <InputLabel id="incident-facility-label">Établissement concerné</InputLabel>
            <Select
              labelId="incident-facility-label"
              id="incident-facility"
              value={incidentFacility}
              onChange={(e) => setIncidentFacility(e.target.value)}
              label="Établissement concerné"
            >
              <MenuItem value="departure">Établissement de départ</MenuItem>
              <MenuItem value="arrival">Établissement d'arrivée</MenuItem>
              <MenuItem value="both">Les deux</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            id="incident-description"
            label="Description de l'incident"
            variant="outlined"
            value={incidentDescription}
            onChange={(e) => setIncidentDescription(e.target.value)}
          />

          {incidentType === 'Osiris' && (
          <TextField
            fullWidth
            sx={{ marginBottom: '10px' }}
            id="incident-reponse"
            label="Réponse"
            variant="outlined"
            value={incidentReponse}
            onChange={(e) => setIncidentReponse(e.target.value)}
          />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleAddIncident} sx={{ color: '#b47f31' }}>Ajouter</Button>
        </DialogActions>
      </Dialog>
      {/* ------------------------------------------------ */}

      <div className="firstLine">
        <TextField
          type="date"
          sx={{ width: '30%' }}
          name="date"
          label="Date"
          InputLabelProps={{
            shrink: true,
          }}
          id="date"
          value={displayDate}
          onChange={handleChange}
          error={errors.date}
          helperText={errors.date}
        />
        <TextField
          type="time"
          sx={{ width: '30%' }}
          name="appointment_hour"
          label="Heure de rendez-vous"
          InputLabelProps={{
            shrink: true,
          }}
          id="appointment_hour"
          value={formData.appointment_hour}
          onChange={handleChange}
          error={errors.appointment_hour}
          helperText={errors.appointment_hour}
        />
        <TextField
          type="time"
          sx={{ width: '30%' }}
          name="departure_hour"
          label="Heure de départ"
          InputLabelProps={{
            shrink: true,
          }}
          id="departure_hour"
          value={formData.departure_hour}
          onChange={handleChange}
        />
      </div>
      <Grid container spacing={2} className="secondLine">
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}
        >
          <TextField
            type="time"
            fullWidth
            sx={{ width: '30%' }}
            name="arrival_hour"
            label="Heure d'arrivée"
            InputLabelProps={{
              shrink: true,
            }}
            id="arrival_hour"
            value={formData.arrival_hour}
            onChange={handleChange}
          />
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel sx={{ width: '100%' }} id="type-label">Mode de transport</InputLabel>
              <Select
                labelId="type-label"
                label="Mode de transport"
                id="type-select"
                name="tarification_type"
                value={formData.tarification_type}
                onChange={handleTransportTypeChange}
              >
                {typesDeTarif.map((type) => (
                  <MenuItem key={type.id} value={type.nom}>
                    {type.nom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="category-label">Tarification</InputLabel>
              <Select
                labelId="category-label"
                id="tarif_applicable"
                name="tarif_applicable"
                value={formData.tarif_applicable}
                label="Tarification"
                onChange={handleTarifApplicable}
              >
                {Object.entries(categoryToTarifNameMapping).map(([category, tarifName]) => (
                  <MenuItem key={category} value={category}>
                    {tarifName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formData.tarification_type === 'Taxi' && (
            <Grid item xs={4}>
              {formData.tarification_type === 'Taxi'
                && tarifs.find((tarif) => tarif.categorie === formData.tarif_applicable) && (
                <FormControl fullWidth>
                  <InputLabel id="tarif_taxi-label">Tarif kilométrique</InputLabel>
                  <Select
                    labelId="tarif_taxi-label"
                    id="taxi_taxi-select"
                    name="tarif_taxi"
                    value={formData.tarif_taxi}
                    onChange={handleTarifTaxiChange}
                    label="Tarif kilométrique"
                  >
                    {Object.entries(keyToLabelMapping).map(([key, label]) => {
                      const tarif = tarifs.find((t) => t.categorie === formData.tarif_applicable);
                      if (tarif && tarif[key]) {
                        return (
                          <MenuItem key={key} value={tarif[key]}>
                            {label} - {tarif[key]} €/km
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <div className="secondLine">
        <TextField
          size="small"
          sx={{ width: '30%' }}
          required
          id="last_name"
          label="Nom"
          variant="outlined"
          type="text"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          error={errors.last_name}
          helperText={errors.last_name}
        />
        <TextField
          size="small"
          sx={{ width: '30%' }}
          id="name"
          label="Prénom"
          variant="outlined"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={errors.name}
          helperText={errors.name}
        />
        <TextField
          required
          size="small"
          sx={{ width: '30%' }}
          id="phone_number"
          label="Numéro de téléphone"
          variant="outlined"
          type="text"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          error={errors.phone_number}
          helperText={errors.phone_number}
        />
      </div>
      <div className="transport-details">
        <div className="departure">
          <Autocomplete
            sx={{ width: '70%', marginBottom: '10px' }}
            id="uh"
            options={servicesData.map((service) => service.uh)}
            value={formData.uh}
            onChange={handleUhChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="UH"
                error={!!errors.uh}
                helperText={errors.uh}
              />
            )}
          />
          <TextField
            size="small"
            sx={{ width: '80%' }}
            id="service"
            label="Service"
            variant="outlined"
            type="text"
            name="service"
            value={formData.service}
            onChange={handleChange}
            error={errors.service}
            helperText={errors.service}
          />
          <span className="span_adress">
            <TextField
              size="small"
              required
              sx={{ width: '80%' }}
              id="departure_address"
              label="Adresse de départ"
              variant="outlined"
              type="text"
              name="departure_address"
              value={formData.departure_address}
              onChange={(e) => {
                handleChange(e);
                handleAddressChange(e.target.value);
              }}
              error={!!errors.departure_address}
              helperText={errors.departure_address}
            />
            {addressSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {addressSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="suggestions-list-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.properties.label}
                  </li>
                ))}
              </ul>
            )}
          </span>
          <Autocomplete
            sx={{ width: '90%', marginBottom: '10px' }}
            id="departure_label"
            options={
              servicesData
                .filter((service) => (selectedDepartureEtablissementId
                  ? service.etablissement_id === selectedDepartureEtablissementId
                  : true))
                .map((service) => service.uh)
            }
            value={formData.departure_label || ''}
            onChange={(event, newValue) => {
              if (newValue) {
                const selectedEtablissement = etablissementsData.find(
                  (etab) => etab.name === newValue
                );
                if (selectedEtablissement) {
                  setFormData((prev) => ({
                    ...prev,
                    departure_label: selectedEtablissement.name,
                    departure_address: `${selectedEtablissement.address} ${selectedEtablissement.zipcode} ${selectedEtablissement.city || ''}`,
                  }));
                  setSelectedDepartureEtablissementId(selectedEtablissement.id);
                }
                else {
                  setSelectedDepartureEtablissementId(null);
                  setFormData((prev) => ({
                    ...prev,
                    departure_label: newValue,
                    departure_address: '',
                  }));
                }
              }
              else {
                setSelectedDepartureEtablissementId(null);
                setFormData((prev) => ({
                  ...prev,
                  departure_label: '',
                  departure_address: '',
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Etablissement de départ"
                variant="outlined"
              />
            )}
          />
        </div>
        <div className="arrival">
          <Autocomplete
            sx={{ width: '70%', marginBottom: '10px' }}
            id="uh_arrival"
            options={servicesData.map((service) => service.uh)}
            value={formData.uh_arrival}
            onChange={handleUhArrivalChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="UH d'arrivée"
                error={!!errors.uh_arrival}
                helperText={errors.uh_arrival}
              />
            )}
          />
          <TextField
            size="small"
            sx={{ width: '80%', fontSize: '5px' }}
            id="service_arrival"
            label="Service d'arrivée"
            variant="outlined"
            type="text"
            name="service_arrival"
            value={formData.service_arrival}
            onChange={handleChange}
            error={errors.service_arrival}
            helperText={errors.service_arrival}
          />
          <span className="span_adress">
            <TextField
              size="small"
              sx={{ width: '80%' }}
              required
              id="arrival_address"
              label="Adresse d'arrivée"
              variant="outlined"
              type="text"
              name="arrival_address"
              value={formData.arrival_address}
              onChange={(e) => {
                handleChange(e);
                handleReturnAddressChange(e.target.value);
              }}
              error={!!errors.return_arrival_address}
              helperText={errors.return_arrival_address}
            />
            {returnAddressSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {returnAddressSuggestions.map((suggestion, index) => (
                  <li key={index} className="suggestions-list-item" onClick={() => handleReturnSuggestionClick(suggestion)}>
                    {suggestion.properties.label}
                  </li>
                ))}
              </ul>
            )}
          </span>
          <Autocomplete
            sx={{ width: '90%', marginBottom: '10px' }}
            id="arrival_label"
            options={
              servicesData
                .filter((service) => (selectedArrivalEtablissementId
                  ? service.etablissement_id === selectedArrivalEtablissementId
                  : true))
                .map((service) => service.uh)
            }
            value={formData.arrival_label || ''}
            inputValue={inputArrivalLabel}
            freeSolo
            onInputChange={(event, newInputValue) => {
              setInputArrivalLabel(newInputValue);
              setFormData((prevFormData) => ({
                ...prevFormData,
                arrival_label: newInputValue,
              }));
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                const selectedEtablissement = etablissementsData.find(
                  (etab) => etab.name === newValue
                );
                if (selectedEtablissement) {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    arrival_label: selectedEtablissement.name,
                    arrival_address: `${selectedEtablissement.address} ${selectedEtablissement.zipcode} ${selectedEtablissement.city || ''}`,
                  }));
                  setSelectedArrivalEtablissementId(selectedEtablissement.id);
                  setInputArrivalLabel(selectedEtablissement.name);
                }
              }
              else {
                setSelectedArrivalEtablissementId(null);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  arrival_label: '',
                  arrival_address: '',
                }));
                setInputArrivalLabel('');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Etablissement d'arrivée"
                variant="outlined"
                error={!!errors.arrival_label}
                helperText={errors.arrival_label}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </div>
      </div>
      {(!formData.distance && formData.departure_address && formData.arrival_address) && (
        <DistanceCalculator
          pickupAddress={formData.departure_address}
          destinationAddress={formData.arrival_address}
          onDistanceCalculated={(calculatedDistance) => {
            setDistance(calculatedDistance);
            setFormData((prevFormData) => ({
              ...prevFormData,
              distance: calculatedDistance
            }));
          }}
        />
      )}

      <Grid item xs={12}>
        <TextField
          sx={{ margin: '10px 0' }}
          size="small"
          label="Distance calculée (en Km)"
          value={formData.distance || ''}
          type="text"
          InputProps={{ readOnly: true }}
          disabled
        />
      </Grid>
      <TextField
        sx={{ marginBottom: '10px' }}
        size="small"
        id="price"
        label="Prix estimé"
        variant="outlined"
        type="text"
        name="price"
        value={isNaN(parseFloat(estimatedPrice)) ? '0' : estimatedPrice}
        onChange={handleChange}
        disabled
      />

      <div className="fourthLine">
        <FormControl sx={{ width: '30%', padding: '10px' }}>
          <InputLabel id="reason">Motif</InputLabel>
          <Select
            name="reason"
            id="reason"
            value={formData.reason}
            onChange={handleChange}
            error={errors.reason}
            helperText={errors.reason}
          >
            <MenuItem value="CONSULTATION">Consultation</MenuItem>
            <MenuItem value="TRANSFERT">Transfert</MenuItem>
            <MenuItem value="URGENCE">Urgence</MenuItem>
            <MenuItem value="DIALYSE">Dialyse</MenuItem>
            <MenuItem value="RADIO">Radio</MenuItem>
            <MenuItem value="IRM">IRM</MenuItem>
            <MenuItem value="MEDECINE NUCLEAIRE">Médecine nucléaire</MenuItem>
            <MenuItem value="CHIMIO">Chimio</MenuItem>
            <MenuItem value="RADIOTHERAPIE">Radiotherapie</MenuItem>
            <MenuItem value="REEDUCATION">Rééducation</MenuItem>
            <MenuItem value="PSYCHIATRIE">Psychiatrie</MenuItem>
            <MenuItem value="DENTISTE">Dentiste</MenuItem>
            <MenuItem value="OPHTALMOLOGIE">Ophtalmologie</MenuItem>
            <MenuItem value="KINE">Kiné</MenuItem>
            <MenuItem value="AUTRE">Autre</MenuItem>
          </Select>
        </FormControl>
        {formData.tarification_type === 'Samu' && (
          <FormControl sx={{ width: '30%', padding: '10px' }}>
            <TextField
              size="small"
              sx={{ width: '50%' }}
              id="samu_number"
              label="Numéro de SAMU"
              variant="outlined"
              type="text"
              name="samu_number"
              value={formData.samu_number}
              onChange={handleChange}
              error={errors.samu_number}
              helperText={errors.samu_number}
            />
          </FormControl>
        )}
        <FormControl component="fieldset">
          <FormControlLabel
            control={(
              <Checkbox
                name="round_trip"
                checked={formData.round_trip || currentTransport.linked_transport_id !== null}
                onChange={handleChange}
                disabled={currentTransport.linked_transport_id !== null}
              />
          )}
            label="Retour"
            color="primary"
          />
          {formData.round_trip && (
            <>
              <TextField
                label="Date de Retour"
                type="date"
                name="return_date"
                value={formData.return_date}
                onChange={handleReturnDataChange}
                variant="outlined"
                fullWidth
                sx={{ marginBottom: '10px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Heure de Retour"
                type="time"
                name="return_departure_hour"
                value={formData.return_departure_hour}
                onChange={handleReturnDataChange}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}
        </FormControl>
        <FormControl sx={{ width: '15%', padding: '10px' }}>
          <TextField
            size="small"
            label="Temps d'attente"
            type="time"
            name="waiting_time"
            value={formData.waiting_time}
            onChange={handleWaitingTimeChange}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl sx={{ width: '15%', padding: '10px' }}>
          {(selectedType === 'Vsl' || selectedType === 'Taxi') && (
            <TextField
              size="small"
              id="number_of_people"
              label="Nombre de personnes"
              variant="outlined"
              type="number"
              name="number_of_people"
              value={formData.number_of_people}
              onChange={handleNumberOfPeopleChange}
            />
          )}
          {(selectedType === 'Vsl' || selectedType === 'Taxi') && (
            <>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="tpmr"
                    checked={formData.tpmr}
                    onChange={handleChange}
                  />
                )}
                label="TPMR"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    name="aeroport"
                    checked={formData.aeroport}
                    onChange={handleChange}
                  />
                )}
                label="Aéroport"
              />
            </>
          )}
          {(selectedType === 'Ambulance' || selectedType === 'Paramédical') && (
            <>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="premature"
                    checked={formData.premature}
                    onChange={handleChange}
                  />
                )}
                label="Prématuré"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    name="urgent"
                    checked={formData.urgent}
                    onChange={handleChange}
                  />
                )}
                label="Urgent"
              />
            </>
          )}
        </FormControl>
      </div>
      <div className="fifthLine">
        <TextField
          fullWidth
          multiline
          id="comment"
          placeholder="Commentaires"
          variant="outlined"
          name="comment"
          value={formData.comment}
          onChange={handleChange}
        />
      </div>
      <div className="seventhLine">
        <Autocomplete
          id="crew"
          options={currentCrews}
          sx={{ width: '30%' }}
          value={formData.vehicule
            ? currentCrews.find((crew) => crew.vehicule_id === vehicles.find((v) => v.code === formData.vehicule)?.id)
            : null}
          getOptionLabel={(option) => {
            const vehicle = vehicles.find((vehicule) => vehicule.id === option.vehicule_id);
            return `${vehicle ? vehicle.code : ''} - ${vehicle ? vehicle.immat : ''} - ${option.crew_type}`;
          }}
          onChange={(event, newValue) => {
            if (!newValue) {
              setFormData((prevFormData) => ({
                ...prevFormData,
                vehicule: '',
                driver1: '',
                driver2: '',
              }));
              return;
            }

            const vehicle = vehicles.find((vehicule) => vehicule.id === newValue.vehicule_id);
            const driver1 = staff.find((staffs) => staffs.id === newValue.staff_id_1);
            const driver2 = staff.find((staffs) => staffs.id === newValue.staff_id_2);

            if (formData.tarification_type === 'PARA' && newValue.crew_type !== 'PARA') {
              toast.error("Type d'équipage non adéquat avec ce transport");
              return;
            }

            if ((formData.tarification_type === 'AMB' || formData.tarification_type === 'SAMU') && newValue.crew_type === 'VSL') {
              toast.error("Le type de véhicule VSL n'est pas compatible avec le type de transport AMB ou SAMU");
              return;
            }
            if (formData.tarification_type === 'PSY' && newValue.crew_type !== 'PSY') {
              toast.error("Type d'équipage non adéquat avec ce transport");
              return;
            }
            if (formData.tarification_type === 'TAXI' && newValue.crew_type !== 'TAXI') {
              toast.error("Type d'équipage non adéquat avec ce transport");
              return;
            }
            if (formData.tarification_type === 'BARIA' && newValue.crew_type !== 'BARIA') {
              toast.error("Type d'équipage non adéquat avec ce transport");
              return;
            }

            setFormData((prevFormData) => ({
              ...prevFormData,
              vehicule: vehicle ? vehicle.code : '',
              driver1: driver1 ? `${driver1.name} ${driver1.last_name}` : '',
              driver2: driver2 ? `${driver2.name} ${driver2.last_name}` : '',
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Véhicule" variant="outlined" />
          )}
        />

        <TextField
          size="small"
          sx={{ width: '30%' }}
          id="driver1"
          label="Chauffeur 1"
          variant="outlined"
          type="text"
          name="driver1"
          value={formData.driver1}
          onChange={handleChange}
        />

        {!(selectedType === 'Vsl' || selectedType === 'Taxi') && (
          <TextField
            size="small"
            sx={{ width: '30%' }}
            id="driver2"
            label="Chauffeur 2"
            variant="outlined"
            type="text"
            name="driver2"
            value={formData.driver2}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="sixLine">
        <FormLabel id="radio-buttons-group-label" />
        <RadioGroup
          row
          aria-label="status"
          name="status"
          value={formData.status}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="Non affecté"
            control={<Radio sx={{ color: '#b47f31' }} />}
            label="Non-affecté"
          />
          <FormControlLabel
            value="Envoyé"
            control={<Radio sx={{ color: '#b47f31' }} disabled={!formData.driver1 && !formData.driver2} />}
            label="Envoyé"
          />
          <FormControlLabel
            value="En cours"
            control={<Radio sx={{ color: '#b47f31' }} disabled={!formData.driver1 && !formData.driver2} />}
            label="En cours"
          />
          <FormControlLabel
            value="Terminé"
            control={<Radio sx={{ color: '#b47f31' }} disabled={!formData.driver1 && !formData.driver2} />}
            label="Terminé"
          />
          <FormControlLabel
            value="Annulé"
            control={<Radio sx={{ color: '#b47f31' }} />}
            label="Annulé"
          />
          <FormControlLabel
            value="Annuler facturé"
            control={<Radio sx={{ color: '#b47f31' }} />}
            label="Annuler facturé"
          />
        </RadioGroup>
      </div>
      <div className="sixLine">
        <Button
          sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
          variant="contained"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? 'Loading...' : 'Mettre à jour'}
        </Button>
      </div>
    </Box>
  );
}

export default React.memo(UpdateTransportModal);
