/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, InputLabel, MenuItem, Select, Button, FormControl,
  IconButton, Tooltip
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { Worker } from '@react-pdf-viewer/core';
import ArticleImage from '../articleImage/articleImage';
import usePdfToImageConverter from '../../../../hooks/usePdfToImageConverter';
import { BASE_URL } from '../../../../../utils/api';

// ---------------------------------------------------------------------
// Sous-composant pour gérer l'upload et l'affichage d'un fichier
// ---------------------------------------------------------------------
function ArticleUploadField({
  fileKey, // la clé S3 (ex: formData.picture)
  selectedImageUrl, // l'aperçu local (si l'utilisateur vient de choisir un fichier)
  onFileChange, // callback pour déclencher l'input file
  onDownloadFile, // callback pour télécharger
  onViewFile, // callback pour ouvrir dans un nouvel onglet
}) {
  const [isHovered, setIsHovered] = useState(false);

  // Si on a déjà un fichier stocké (S3) ou un PDF → le nom
  const filename = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = filename.split('.').pop().toLowerCase();

  // On rend le bloc "cliquable" seulement si on a déjà un doc S3
  const cursorStyle = fileKey ? 'pointer' : 'default';

  // Empêche le clic de remonter lorsque l'on clique sur les icônes
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <Box
      mb={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{
        border: '1px solid #ccc',
        borderRadius: 8,
        padding: 10,
        cursor: cursorStyle,
        backgroundColor: isHovered ? '#f0f0f0' : 'transparent',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (fileKey) {
          onViewFile(fileKey); // Ouvrir dans un nouvel onglet
        }
      }}
    >
      {/* Affichage PDF ou image */}
      {fileKey ? (
        fileExtension === 'pdf' ? (
          <PictureAsPdfIcon style={{ fontSize: 100 }} />
        ) : (
          // C'est une image => on affiche un aperçu
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <ArticleImage
              imageKey={fileKey}
              selectedImageUrl={selectedImageUrl}
              width={200}
              height={200}
            />
          </Worker>
        )
      ) : (
        // Si aucun doc dans S3, on peut afficher l'aperçu local (si user vient juste de choisir le fichier)
        selectedImageUrl ? (
          <img
            src={selectedImageUrl}
            alt="preview"
            style={{ maxWidth: 200, maxHeight: 200, objectFit: 'cover' }}
          />
        ) : (
          // Sinon, on peut mettre une icône ou un texte "Aucun document"
          <div style={{ fontSize: 14, color: '#999' }}>Aucun document</div>
        )
      )}

      {/* Boutons d'action (uploader / télécharger), on empêche la propagation du clic */}
      <Box mt={1} onClick={stopPropagation} display="flex" justifyContent="center">
        {/* Bouton pour remplacer le fichier */}
        <Tooltip title="Ajouter / Remplacer">
          <IconButton onClick={onFileChange}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        {fileKey && (
          <Tooltip title="Télécharger">
            <IconButton onClick={() => onDownloadFile(fileKey)}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

// ---------------------------------------------------------------------
// Composant principal AddArticle
// ---------------------------------------------------------------------
function AddArticle({ onArticleAdded, userToken }) {
  const convertPdfToImage = usePdfToImageConverter();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('default');

  // Aperçu local de l'image (ex: quand user choisit un fichier depuis son poste)
  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  const [formData, setFormData] = useState({
    picture: null, // Clé du fichier sur S3
    category_name: null,
    name: '',
    quantity: 0,
    min_quantity: 0,
    qr_code: '',
    conditionnement: '',
    prix_unite: 0,
    prix_total: 0,
    date_limite: null,
    fournisseur: '',
    url_fournisseur: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // Charger la liste des catégories
  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await axios.get(`${BASE_URL}categories`, { headers });
        const categoriesFromResponse = response.data.categories;
        if (Array.isArray(categoriesFromResponse)) {
          setCategories([
            { category_name: 'Sélectionnez une catégorie', category_id: 'default' },
            ...categoriesFromResponse
          ]);
        }
        else {
          console.error('Categories from response is not an array:', categoriesFromResponse);
        }
      }
      catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    fetchCategories();
  }, []);

  // Mise à jour formData (quantité, prix, etc.)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    // Recalcul du prix total
    if (name === 'quantity' || name === 'prix_unite') {
      const quantity = name === 'quantity' ? parseFloat(value) : parseFloat(formData.quantity);
      const prixUnite = name === 'prix_unite' ? parseFloat(value) : parseFloat(formData.prix_unite);
      const prixTotal = quantity * prixUnite;
      if (!Number.isNaN(prixTotal)) {
        updatedFormData.prix_total = prixTotal;
      }
    }
    setFormData(updatedFormData);
  };

  // ---------------------------------------------------------------------
  // 1) Upload File => présigned => S3
  // ---------------------------------------------------------------------
  const handleFileSelectorClick = () => {
    // Ouvrir la fenêtre de sélection
    document.getElementById('article-file-input')?.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Pour l’aperçu local (image)
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImageUrl(event.target.result);
    };
    reader.readAsDataURL(file);

    // Vérif taille
    const maxFileSize = 2 * 1024 * 1024; // 2MB
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    // Vérif type
    // (NB: "application/pdf" pour PDF, "image/png" etc. Sur votre code d’origine, vous aviez un peu
    //  un type "image/pdf", qui n’est pas standard. On corrige ici)
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF.');
      return;
    }

    try {
      // 1) Si c'est un PDF, on convertit en image pour le stocker en JPEG (selon votre souhait)
      let fileToUpload = file;
      if (file.type === 'application/pdf') {
        const imageBlob = await convertPdfToImage(file);
        fileToUpload = new File(
          [imageBlob],
          `${file.name.split('.').slice(0, -1).join('.')}.jpg`,
          { type: 'image/jpeg' }
        );
      }

      // 2) On récupère l’URL présignée
      const response = await axios.post(
        `${BASE_URL}articles/presigned`,
        { name: fileToUpload.name, type: fileToUpload.type },
        { headers }
      );
      const { url, fields } = response.data;

      // 3) On envoie le fichier sur S3
      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', fileToUpload);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': fileToUpload.type
        }
      });

      // 4) On stocke la key S3 dans formData
      const uploadedFileUrl = `${fields.key}`;
      setFormData((prev) => ({ ...prev, picture: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // ---------------------------------------------------------------------
  // 2) Visualiser (ouvrir dans un nouvel onglet) => Signed URL
  // ---------------------------------------------------------------------
  const [viewFileUrl, setViewFileUrl] = useState('');

  const handleViewFile = async (s3Key) => {
    try {
      const resp = await axios.post(`${BASE_URL}articles/get-signed-url`, { key: s3Key }, { headers });
      setViewFileUrl(resp.data.url);
    }
    catch (err) {
      console.error('Error fetching signed URL for viewing:', err);
    }
  };

  useEffect(() => {
    // Ouverture automatique dans un nouvel onglet
    if (viewFileUrl) {
      window.open(viewFileUrl, '_blank');
      setViewFileUrl(''); // reset
    }
  }, [viewFileUrl]);

  // ---------------------------------------------------------------------
  // 3) Télécharger => Signed URL => Blob => Download
  // ---------------------------------------------------------------------
  const handleDownloadFile = async (s3Key) => {
    try {
      const resp = await axios.post(`${BASE_URL}articles/get-signed-url`, { key: s3Key }, { headers });
      const downloadUrl = resp.data.url;

      // Télécharger via un Blob
      const fileResponse = await axios.get(downloadUrl, { responseType: 'blob' });
      const contentType = fileResponse.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([fileResponse.data], { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = s3Key.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (err) {
      console.error('Error fetching signed URL for downloading:', err);
    }
  };

  // ---------------------------------------------------------------------
  // 4) Gérer la catégorie
  // ---------------------------------------------------------------------
  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);

    if (selectedValue === 'new') {
      setShowNewCategoryInput(true);
      setNewCategoryName('');
    }
    else {
      setShowNewCategoryInput(false);
      setFormData({ ...formData, category_name: selectedValue });
    }
  };

  // ---------------------------------------------------------------------
  // 5) Soumission du formulaire
  // ---------------------------------------------------------------------
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const articleData = {
        ...formData,
        category_name: showNewCategoryInput ? newCategoryName : formData.category_name,
      };
      // Création de l’article
      await axios.post(`${BASE_URL}articles`, articleData, { headers });
      // On notifie le parent
      onArticleAdded();
    }
    catch (error) {
      console.error('Error creating article:', error);
    }
  };

  // Génération d’un QR code
  const handleGenerateQRCodeId = () => {
    const uniqueId = uuidv4();
    setFormData({ ...formData, qr_code: uniqueId });
  };

  return (
    <form noValidate autoComplete="off">
      {/* Input file caché (pour l'upload) */}
      <input
        id="article-file-input"
        type="file"
        accept="image/*,application/pdf"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Bloc d’affichage / upload / download */}
      <ArticleUploadField
        fileKey={formData.picture}
        selectedImageUrl={selectedImageUrl}
        onFileChange={handleFileSelectorClick}
        onDownloadFile={handleDownloadFile}
        onViewFile={handleViewFile}
      />

      <Box mb={2}>
        {/* Choix de la catégorie */}
        <FormControl
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ padding: '0.5rem 0' }}
        >
          <InputLabel
            fullWidth
            id="category_name_label"
          >
            Catégorie
          </InputLabel>
          <Select
            labelId="category_name_label"
            id="category_name"
            value={selectedCategory}
            onChange={handleCategoryChange}
            fullWidth
          >
            {categories.map((category) => (
              <MenuItem key={category.category_id} value={category.category_name}>
                {category.category_name}
              </MenuItem>
            ))}
            <MenuItem value="new">Ajouter une nouvelle catégorie</MenuItem>
          </Select>
        </FormControl>

        {/* Saisie d’une nouvelle catégorie si besoin */}
        {showNewCategoryInput && (
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
            id="new_category_name"
            label="Nouvelle catégorie"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        )}
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="name"
          name="name"
          label="Nom"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="quantity"
          name="quantity"
          label="Quantité"
          value={formData.quantity}
          onChange={handleInputChange}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="min_quantity"
          name="min_quantity"
          label="Quantité minimum"
          value={formData.min_quantity}
          onChange={handleInputChange}
        />

        {/* Génération d’un QR Code */}
        <Button onClick={handleGenerateQRCodeId} sx={{ marginY: '0.5rem' }}>
          Générer QR Code
        </Button>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="qr_code"
          name="qr_code"
          label="QR Code"
          value={formData.qr_code}
          onChange={handleInputChange}
          disabled
          required
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="conditionnement"
          name="conditionnement"
          label="Conditionnement"
          value={formData.conditionnement}
          onChange={handleInputChange}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="prix_unite"
          name="prix_unite"
          label="Prix Unité"
          value={formData.prix_unite}
          onChange={handleInputChange}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="prix_total"
          name="prix_total"
          label="Prix Total"
          value={formData.prix_total}
          onChange={handleInputChange}
          disabled
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          type="date"
          id="date_limite"
          name="date_limite"
          label="Date Limite"
          value={formData.date_limite || ''}
          onChange={handleInputChange}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="fournisseur"
          name="fournisseur"
          label="Fournisseur"
          value={formData.fournisseur}
          onChange={handleInputChange}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="url_fournisseur"
          name="url_fournisseur"
          label="URL Fournisseur"
          value={formData.url_fournisseur}
          onChange={handleInputChange}
        />

        {/* Bouton d’envoi du formulaire */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Button
            type="submit"
            sx={{ backgroundColor: '#505154', width: '40%' }}
            variant="contained"
            onClick={handleFormSubmit}
          >
            Envoyer
          </Button>
        </div>
      </Box>
    </form>
  );
}

export default React.memo(AddArticle);
