/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, InputLabel, MenuItem, Select, Button, FormControl,
  IconButton, Tooltip
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { Worker } from '@react-pdf-viewer/core';
import ArticleImage from '../articleImage/articleImage';
import usePdfToImageConverter from '../../../../hooks/usePdfToImageConverter';
import { BASE_URL } from '../../../../../utils/api';

/**
 * Sous-composant qui gère l’affichage de l’image/PDF et les boutons
 */
function ArticleUploadField({
  fileKey, // la clé S3 (ex: formData.picture)
  onClickSelectFile, // callback pour ouvrir l’input file caché
  onClickDownloadFile, // callback pour télécharger
  onClickViewFile, // callback pour visualiser dans un onglet
  localPreview, // aperçu local (facultatif si vous convertissez un PDF, etc.)
  width = 200,
  height = 200,
}) {
  const [isHovered, setIsHovered] = useState(false);

  // Nom et extension du fichier
  const filename = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = filename.split('.').pop().toLowerCase();

  // Bloc cliquable seulement si un fichier S3 existe déjà
  const cursorStyle = fileKey ? 'pointer' : 'default';

  // Éviter de propager le clic lorsque l’on clique sur les icônes
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <Box
      mb={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        border: '1px solid #ccc',
        borderRadius: 8,
        padding: 10,
        cursor: cursorStyle,
        backgroundColor: isHovered ? '#f0f0f0' : 'transparent',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (fileKey) {
          onClickViewFile(fileKey); // ouvre le doc si on clique sur le bloc
        }
      }}
    >
      {/* Affichage PDF ou image */}
      {fileKey ? (
        fileExtension === 'pdf' ? (
          <PictureAsPdfIcon style={{ fontSize: 80 }} />
        ) : (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <ArticleImage
              key={fileKey}
              imageKey={fileKey}
              selectedImageUrl={localPreview}
              width={width}
              height={height}
            />
          </Worker>
        )
      ) : (
        // Pas de fileKey => Soit un aperçu local (si on vient juste d’uploader)
        // Soit un texte "Aucun document"
        localPreview ? (
          <img
            src={localPreview}
            alt="preview"
            style={{ maxWidth: width, maxHeight: height, objectFit: 'cover' }}
          />
        ) : (
          <div style={{ fontSize: 14, color: '#999' }}>
            Aucun document
          </div>
        )
      )}

      {/* Icônes de remplacement / téléchargement */}
      <Box mt={1} onClick={stopPropagation}>
        <Tooltip title="Ajouter / Remplacer">
          <IconButton onClick={onClickSelectFile}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        {fileKey && (
          <Tooltip title="Télécharger">
            <IconButton onClick={() => onClickDownloadFile(fileKey)}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

/**
 * Composant principal : formulaire de mise à jour d'un article
 */
function UpdateArticle({ currentArticle, onArticleUpdated, userToken }) {
  const convertPdfToImage = usePdfToImageConverter();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(currentArticle.category_name);
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  // On stocke le formData dans un state local
  const [formData, setFormData] = useState({
    picture: currentArticle.picture,
    category_name: currentArticle.category_id,
    name: currentArticle.name,
    quantity: currentArticle.quantity,
    min_quantity: currentArticle.min_quantity,
    qr_code: currentArticle.qr_code,
    conditionnement: currentArticle.conditionnement,
    prix_unite: currentArticle.prix_unite,
    prix_total: currentArticle.prix_total,
    date_limite: currentArticle.date_limite,
    fournisseur: currentArticle.fournisseur,
    url_fournisseur: currentArticle.url_fournisseur,
  });

  // Aperçu local si l’utilisateur choisit un nouveau fichier
  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  // Pour la visualisation (ouvrir dans un nouvel onglet)
  const [viewFileUrl, setViewFileUrl] = useState('');

  const headers = { Authorization: `Bearer ${userToken}` };

  useEffect(() => {
    // Mettre à jour le formData si currentArticle change
    setFormData({
      ...currentArticle,
      date_limite: currentArticle.date_limite
        ? currentArticle.date_limite.split('T')[0]
        : ''
    });
    setSelectedCategory(currentArticle.category_name);
  }, [currentArticle]);

  useEffect(() => {
    // Charger la liste des catégories
    async function fetchCategories() {
      try {
        const response = await axios.get(`${BASE_URL}categories`, { headers });
        setCategories(response.data.categories);
      }
      catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    fetchCategories();
  }, []);

  // =====================
  // 1) Changement Catégorie
  // =====================
  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);

    if (selectedValue === 'new') {
      setShowNewCategoryInput(true);
      setNewCategoryName('');
    }
    else {
      setShowNewCategoryInput(false);
      setFormData({ ...formData, category_name: selectedValue });
    }
  };

  // =====================
  // 2) Modif des champs
  // =====================
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    // Recalcul du prix total
    if (name === 'quantity' || name === 'prix_unite') {
      const quantity = name === 'quantity' ? parseFloat(value) : parseFloat(formData.quantity);
      const prixUnite = name === 'prix_unite' ? parseFloat(value) : parseFloat(formData.prix_unite);
      const prixTotal = quantity * prixUnite;
      if (!Number.isNaN(prixTotal)) {
        updatedFormData.prix_total = prixTotal;
      }
    }

    // Conversion date_limite en ISO pour la BDD (si vous le souhaitez)
    if (name === 'date_limite') {
      updatedFormData.date_limite = value ? new Date(value).toISOString() : '';
    }

    setFormData(updatedFormData);
  };

  // =====================
  // 3) Upload du fichier
  // =====================
  const handleFileSelectorClick = () => {
    // Ouvrir la fenêtre de sélection
    document.getElementById('update-article-file-input')?.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Aperçu local
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImageUrl(event.target.result);
    };
    reader.readAsDataURL(file);

    // Vérif taille
    const maxFileSize = 2 * 1024 * 1024; // 2MB
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    // Vérif type
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF.');
      return;
    }

    try {
      // Si PDF, on convertit en image
      let fileToUpload = file;
      if (file.type === 'application/pdf') {
        const imageBlob = await convertPdfToImage(file);
        fileToUpload = new File(
          [imageBlob],
          `${file.name.split('.').slice(0, -1).join('.')}.jpg`,
          { type: 'image/jpeg' }
        );
      }

      // Récupère l’URL présignée
      const resp = await axios.post(
        `${BASE_URL}articles/presigned`,
        { name: fileToUpload.name, type: fileToUpload.type },
        { headers }
      );
      const { url, fields } = resp.data;

      // Envoi sur S3
      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, val]) => {
        uploadData.append(key, val);
      });
      uploadData.append('file', fileToUpload);

      await axios.post(url, uploadData, {
        headers: { 'Content-Type': fileToUpload.type }
      });

      // Stocker la key S3
      const uploadedFileUrl = `${fields.key}`;
      setFormData((prev) => ({ ...prev, picture: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // =====================
  // 4) Visualiser / Télécharger
  // =====================
  const handleViewFile = async (s3Key) => {
    try {
      const resp = await axios.post(
        `${BASE_URL}articles/get-signed-url`,
        { key: s3Key },
        { headers }
      );
      setViewFileUrl(resp.data.url);
    }
    catch (err) {
      console.error('Error fetching signed URL for viewing:', err);
    }
  };

  // Ouverture automatique
  useEffect(() => {
    if (viewFileUrl) {
      window.open(viewFileUrl, '_blank');
      setViewFileUrl('');
    }
  }, [viewFileUrl]);

  const handleDownloadFile = async (s3Key) => {
    try {
      const resp = await axios.post(
        `${BASE_URL}articles/get-signed-url`,
        { key: s3Key },
        { headers }
      );
      const downloadUrl = resp.data.url;

      const fileResponse = await axios.get(downloadUrl, { responseType: 'blob' });
      const contentType = fileResponse.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([fileResponse.data], { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = s3Key.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (err) {
      console.error('Error downloading file:', err);
    }
  };

  // =====================
  // 5) Soumission formulaire
  // =====================
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        ...formData,
        category_name: showNewCategoryInput ? newCategoryName : formData.category_name,
        date_limite: formData.date_limite || null, // Mettre la date limite à null si vide
      };
      await axios.put(
        `${BASE_URL}articles/${currentArticle.article_id}`,
        updatedData,
        { headers }
      );
      onArticleUpdated(updatedData);
    }
    catch (error) {
      console.error('Error updating article:', error);
    }
  };

  // Nettoyer la date
  const handleClearDate = () => {
    setFormData((prev) => ({ ...prev, date_limite: '' }));
  };

  return (
    <form noValidate autoComplete="off">
      {/* Input file caché */}
      <input
        id="update-article-file-input"
        type="file"
        accept="image/*,application/pdf"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Bloc d’affichage / upload / download */}
      <ArticleUploadField
        fileKey={formData.picture}
        localPreview={selectedImageUrl}
        onClickSelectFile={handleFileSelectorClick}
        onClickDownloadFile={handleDownloadFile}
        onClickViewFile={handleViewFile}
      />

      <Box mb={2}>
        <FormControl fullWidth>
          <InputLabel id="category_name_label">Catégorie</InputLabel>
          <Select
            labelId="category_name_label"
            id="category_name"
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Catégorie"
            fullWidth
          >
            {categories.map((cat) => (
              <MenuItem key={cat.category_id} value={cat.category_name}>
                {cat.category_name}
              </MenuItem>
            ))}
            <MenuItem value="new">Ajouter une nouvelle catégorie</MenuItem>
          </Select>

          {showNewCategoryInput && (
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: '0.5rem' }}
              fullWidth
              id="new_category_name"
              name="new_category_name"
              label="Nouvelle catégorie"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
          )}
        </FormControl>
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="name"
          name="name"
          label="Nom"
          value={formData.name}
          onChange={handleInputChange}
        />
      </Box>

      {/* QUANTITÉ */}
      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="quantity"
          name="quantity"
          label="Quantité"
          value={formData.quantity}
          onChange={handleInputChange}
          disabled // Dans votre code, c'est désactivé
        />
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="min_quantity"
          name="min_quantity"
          label="Quantité minimale"
          value={formData.min_quantity}
          onChange={handleInputChange}
        />
      </Box>

      {/* QR Code (si besoin) */}
      <Box mb={2} sx={{ display: 'none' }}>
        <TextField
          InputLabelProps={{ shrink: true }}
          fullWidth
          id="qr_code"
          name="qr_code"
          label="Code QR"
          value={formData.qr_code}
          onChange={handleInputChange}
        />
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="conditionnement"
          name="conditionnement"
          label="Conditionnement"
          value={formData.conditionnement}
          onChange={handleInputChange}
        />
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="prix_unite"
          name="prix_unite"
          label="Prix unitaire"
          value={formData.prix_unite}
          onChange={handleInputChange}
        />
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="prix_total"
          name="prix_total"
          label="Prix Total"
          value={formData.prix_total}
          onChange={handleInputChange}
          disabled
        />
      </Box>

      {/* Date limite + bouton clear */}
      <Box mb={2} display="flex" alignItems="center">
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          type="date"
          id="date_limite"
          name="date_limite"
          label="Date limite"
          value={formData.date_limite ? formData.date_limite.split('T')[0] : ''}
          onChange={handleInputChange}
        />
        <IconButton onClick={handleClearDate} aria-label="clear date" sx={{ marginLeft: 1 }}>
          <ClearIcon />
        </IconButton>
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="fournisseur"
          name="fournisseur"
          label="Fournisseur"
          value={formData.fournisseur}
          onChange={handleInputChange}
        />
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="url_fournisseur"
          name="url_fournisseur"
          label="URL fournisseur"
          value={formData.url_fournisseur}
          onChange={handleInputChange}
        />

        <div className="sixLine" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Button
            type="submit"
            sx={{ backgroundColor: '#505154', width: '40%' }}
            variant="contained"
            onClick={handleFormSubmit}
          >
            Mettre à jour
          </Button>
        </div>
      </Box>
    </form>
  );
}

export default React.memo(UpdateArticle);
