/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
// Import additional libraries if needed
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import './vehiculeManagementStyles.scss';
import moment from 'moment';
import * as XLSX from 'xlsx';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Tooltip from '@mui/material/Tooltip';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AddVehiculeModal from './addVehiculeModal/addVehiculeModal';
import UpdateVehiculeModal from './updateVehiculeModal/updateVehiculeModal';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import { BASE_URL } from '../../../utils/api';
import PrintQRCodes from './printQRCodes';

function vehiculeManagement({
  theme, userRole, userToken, userEmail
}) {
  usePageTitle('Gestion des véhicules');

  // ------------------------------
  // STATES
  // ------------------------------
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [vehiculeList, setVehiculeList] = useState([]);
  const [showExitedVehicule, setShowExitedSVehicule] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVehicule, setCurrentVehicule] = useState(null);
  const [filterSociete, setFilterSociete] = useState('');
  const [alertShown, setAlertShown] = useState(false);

  const modalVehiculeRef = useRef();
  const UpdateVehiculeModalRef = useRef();

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // ------------------------------
  // (A) GESTION DU TRI
  // ------------------------------
  // On stocke la clé (colonne) et la direction ('asc' ou 'desc').
  // isDate permet de savoir si on compare des dates ou du texte/numérique.
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc', isDate: false });

  // Fonction au clic sur l'en-tête
  const handleSort = (key, isDate = false) => {
    if (sortConfig.key === key) {
      // Inverse le sens
      setSortConfig((prev) => ({
        ...prev,
        direction: prev.direction === 'asc' ? 'desc' : 'asc',
      }));
    }
    else {
      // Nouvelle colonne => tri asc par défaut
      setSortConfig({ key, direction: 'asc', isDate });
    }
  };

  // Fonction pour appliquer le tri
  const sortVehicules = (list) => {
    const { key, direction, isDate } = sortConfig;
    if (!key) return list; // Pas de colonne à trier => on renvoie tel quel

    return [...list].sort((a, b) => {
      // 1) Tri par date
      if (isDate) {
        const dateA = a[key] ? moment(a[key]) : null;
        const dateB = b[key] ? moment(b[key]) : null;
        // Gérer les cas de date invalide
        if (!dateA || !dateA.isValid()) return 1;
        if (!dateB || !dateB.isValid()) return -1;

        if (dateA.isBefore(dateB)) return direction === 'asc' ? -1 : 1;
        if (dateA.isAfter(dateB)) return direction === 'asc' ? 1 : -1;
        return 0;
      }
      // 2) Tri par valeur numérique (ex: km) ou chaîne
      if (key === 'km') {
        const valA = parseInt(a[key], 10) || 0;
        const valB = parseInt(b[key], 10) || 0;
        return direction === 'asc' ? valA - valB : valB - valA;
      }
      // 3) Tri par chaîne (code, marque, etc.)
      const valA = a[key] || '';
      const valB = b[key] || '';
      return direction === 'asc'
        ? valA.toString().localeCompare(valB.toString())
        : valB.toString().localeCompare(valA.toString());
    });
  };

  // ------------------------------
  // UTILS
  // ------------------------------
  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const handleUnauthorizedAccess = () => {
    if (!isAuthorizedRole(userRole)) {
      Swal.fire({
        title: 'Accès refusé',
        text: 'Vous n\'avez pas les droits pour modifier les informations des véhicules.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  // ------------------------------
  // EXPORT EXCEL
  // ------------------------------
  const exportToExcel = () => {
    Swal.fire({
      title: 'Exportation en Excel',
      text: 'Voulez-vous exporter la liste des véhicules en Excel ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        const vehiculesForExcel = vehiculeList.map((vehicule) => ({
          Société: vehicule.societe,
          Code: vehicule.code,
          Immatriculation: vehicule.immat,
          Marque: vehicule.marque,
          Type: vehicule.type,
          Catégorie: vehicule.categorie,
          "Date d'entrée": vehicule.entry_date ? new Date(vehicule.entry_date).toLocaleDateString() : '',
          'Date de sortie': vehicule.exit_date ? new Date(vehicule.exit_date).toLocaleDateString() : '',
          'Date du contrôle technique': vehicule.technical_control_date ? new Date(vehicule.technical_control_date).toLocaleDateString() : '',
          'Date du contrat de location': vehicule.location_date ? new Date(vehicule.location_date).toLocaleDateString() : '',
          'Dimension des pneus': vehicule.pneu_dim,
          'Pneu de secours': vehicule.pneu_secour ? 'Oui' : 'Non',
          'Seconde clé': vehicule.second_key ? 'Oui' : 'Non',
          Kilométrage: vehicule.km,
          'Numéro de téléphone': vehicule.phone_number,
          Observation: vehicule.observation ? vehicule.observation : ''
        }));

        const ws = XLSX.utils.json_to_sheet(vehiculesForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Liste des Véhicules');

        // Exporter le fichier
        XLSX.writeFile(wb, 'export_vehicules.xlsx');
      }
    });
  };

  // ------------------------------
  // CLIC EN-DEHORS (FERMETURE DES MODALS)
  // ------------------------------
  const handleClick = (e) => {
    if (modalVehiculeRef.current && !modalVehiculeRef.current.contains(e.target)) {
      setShowModal(false);
    }
    if (UpdateVehiculeModalRef.current && !UpdateVehiculeModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
  };

  // ------------------------------
  // FERMETURE MODAL AVEC CONFIRMATION
  // ------------------------------
  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        setModalState(false);
      }
    });
  };

  // ------------------------------
  // REQUÊTES API
  // ------------------------------
  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchAllVehicule = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules`, { headers });
      const sortedVehicules = response.data.vehicule.sort((a, b) => {
        // Tri initial par 'code'
        const isANumeric = /^[0-9]+$/.test(a.code);
        const isBNumeric = /^[0-9]+$/.test(b.code);

        if (isANumeric && isBNumeric) {
          return parseInt(a.code, 10) - parseInt(b.code, 10);
        }
        if (isANumeric) return -1;
        if (isBNumeric) return 1;
        return a.code.localeCompare(b.code);
      });
      setVehiculeList(sortedVehicules);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  // ------------------------------
  // MOUNT
  // ------------------------------
  useEffect(() => {
    fetchAllVehicule();
    fetchCompanies();
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  // ------------------------------
  // AJOUT / MODIFICATION
  // ------------------------------
  const handleAddButtonClick = () => {
    if (isAuthorizedRole(userRole)) {
      setShowModal(true);
    }
    else {
      handleUnauthorizedAccess();
    }
  };

  const handleClickModal = (vehicule) => {
    if (isAuthorizedRole(userRole)) {
      setCurrentVehicule(vehicule);
      setShowUpdateModal(true);
    }
    else {
      handleUnauthorizedAccess();
    }
  };

  const handleAddVehicule = (vehicule) => {
    setVehiculeList([...vehiculeList, vehicule]);
    setShowModal(false);
    fetchAllVehicule();
  };

  const handleUpdateVehicule = async (updatedVehicule) => {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier ce vehicule ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, mettre à jour',
      cancelButtonText: 'Non',
    });
    if (confirmation.value) {
      const index = vehiculeList.findIndex((v) => v.id === currentVehicule.id);
      const newList = [
        ...vehiculeList.slice(0, index),
        updatedVehicule,
        ...vehiculeList.slice(index + 1),
      ];
      setVehiculeList(newList);
      setShowUpdateModal(false);
      Swal.fire({
        title: 'Véhicule modifié !',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      fetchAllVehicule();
    }
  };

  // ------------------------------
  // FILTRES
  // ------------------------------
  const handleFilterSocieteChange = (societe) => {
    setFilterSociete(societe);
  };

  // ------------------------------
  // TACHES (ALERTES, CONTRÔLE TECHNIQUE, CONTRE VISITE, ETC.)
  // ------------------------------
  const checkIfTaskExists = async (vehiculeCode, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;
      return tasks.some((task) => task.field_name === `${type} - ${vehiculeCode}`);
    }
    catch (error) {
      console.error('Error checking tasks:', error);
      return false;
    }
  };

  const createTask = async (vehicule, type, expirationDate) => {
    let fieldName;
    let comment;

    if (type === 'Échéance') {
      fieldName = `Échéance - ${vehicule.code}`;
      comment = `L'échéance du véhicule ${vehicule.code} est prévue pour le ${moment(expirationDate).format('DD-MM-YYYY')}.`;
    }
    else if (type === 'Contre visite') {
      fieldName = `Contre visite - ${vehicule.code}`;
      comment = `Contre visite à réaliser avant le ${moment(expirationDate).format('DD-MM-YYYY')}.`;
    }
    else {
      fieldName = `${type} - ${vehicule.code}`;
      comment = `${type} du véhicule ${vehicule.code} expire dans moins d'un mois.`;
    }

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      vehicule_code: vehicule.code,
      role_name: 'Administrateur',
      comment,
    };

    const taskExists = await checkIfTaskExists(vehicule.code, type);
    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  // Échéance location (4 ans)
  const checkEcheance = async () => {
    let alertMessage = '';

    for (const vehicule of vehiculeList) {
      if (vehicule.exit_date) continue; // On ignore les véhicules sortis

      if (vehicule.location_date) {
        const echeanceDate = moment(vehicule.location_date).add(4, 'years');
        const daysUntilEcheance = echeanceDate.diff(moment(), 'days');

        if (daysUntilEcheance < 0) {
          alertMessage += `L'échéance du véhicule ${vehicule.code} est expirée.\n`;
        }
        else if (daysUntilEcheance <= 30) {
          alertMessage += `L'échéance du véhicule ${vehicule.code} approche (moins de 30 jours).\n`;
          await createTask(vehicule, 'Échéance', echeanceDate.toDate());
        }
      }
    }

    if (alertMessage) {
      Swal.fire({
        title: 'Alerte Échéance',
        text: alertMessage,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  // Contrôle technique et contre-visite
  const checkControlDates = async () => {
    const alertMessages = [];

    for (const vehicule of vehiculeList) {
      if (vehicule.exit_date) continue; // On ignore les véhicules sortis

      // Contrôle technique (1 an)
      if (vehicule.technical_control_date) {
        const controlDate = moment(vehicule.technical_control_date).add(1, 'years');
        const daysUntilExpiration = controlDate.diff(moment(), 'days');

        if (daysUntilExpiration < 0) {
          alertMessages.push(`Le contrôle technique du véhicule ${vehicule.code} est expiré.`);
        }
        else if (daysUntilExpiration <= 30) {
          alertMessages.push(`Le contrôle technique du véhicule ${vehicule.code} expire dans moins d'un mois.`);
          await createTask(vehicule, 'Contrôle technique');
        }
      }

      // Contre-visite : date + 2 mois
      if (vehicule.counter_visit_date) {
        const counterVisitExpiration = moment(vehicule.counter_visit_date).add(2, 'months');
        await createTask(vehicule, 'Contre visite', counterVisitExpiration.toDate());

        const daysUntilCounterVisitExpiration = counterVisitExpiration.diff(moment(), 'days');
        if (daysUntilCounterVisitExpiration <= 0) {
          alertMessages.push(`La contre-visite du véhicule ${vehicule.code} est expirée.`);
        }
        else if (daysUntilCounterVisitExpiration <= 30) {
          alertMessages.push(`La contre-visite du véhicule ${vehicule.code} expire dans moins d'un mois.`);
        }
      }
    }

    if (alertMessages.length > 0) {
      Swal.fire({
        title: 'Alertes contrôle technique / contre-visite',
        html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  // Lancement des checks une seule fois
  useEffect(() => {
    if (vehiculeList.length > 0 && !alertShown) {
      checkEcheance();
      checkControlDates();
      setAlertShown(true);
    }
  }, [vehiculeList, alertShown]);

  // ------------------------------
  // STYLES ET RENDU
  // ------------------------------
  const calculateEcheanceColor = (locationDate) => {
    if (!locationDate) return 'inherit';
    const echeanceDate = moment(locationDate).add(4, 'years');
    const daysUntilEcheance = echeanceDate.diff(moment(), 'days');

    if (daysUntilEcheance < 0) return 'red';
    if (daysUntilEcheance <= 30) return 'red';
    if (daysUntilEcheance <= 90) return 'orange';
    return 'inherit';
  };

  const calculateControlColor = (technicalControlDate) => {
    const currentDate = moment();
    const expirationDate = moment(technicalControlDate).add(1, 'years');
    const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

    if (daysUntilExpiration < 0) return 'red';
    if (daysUntilExpiration <= 30) return 'red';
    if (daysUntilExpiration <= 90) return 'orange';
    return 'inherit';
  };

  const formatNumberWithUnit = (number, unit) => {
    if (!number) return '';
    return `${Number(number).toLocaleString('fr-FR')}${unit}`;
  };

  const customStyles = {
    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '90%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll',
      overflowX: 'hidden',
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  // On applique le tri avant de filtrer et d’afficher
  const sortedVehicules = sortVehicules(vehiculeList);
  const filteredVehicules = sortedVehicules.filter((vehicule) => {
    const matchSociete = !filterSociete || vehicule.societe === filterSociete;
    const matchSortie = showExitedVehicule ? vehicule.exit_date : !vehicule.exit_date;
    return matchSociete && matchSortie;
  });

  return (
    <div className="vehiculeManagement">
      <div className="regulation__header__button">
        {isAuthorizedRole(userRole) && (
          <button
            type="button"
            className="AddTransport"
            onClick={handleAddButtonClick}
          >
            Ajouter un vehicule
          </button>
        )}
      </div>

      <button className="hide-on-mobile" type="button" onClick={exportToExcel}>
        Exporter en Excel
      </button>

      {/* Impression QR Code */}
      <div className="print-qr-container">
        <PrintQRCodes vehiculeList={vehiculeList} />
        <QrCodeIcon
          onClick={() => document.querySelector('.btn-print-qr').click()}
          style={{ cursor: 'pointer', fontSize: 40 }}
        />
      </div>

      {/* Filtres */}
      <div className="search-bar hide-on-mobile">
        <button type="button" onClick={() => setShowExitedSVehicule(!showExitedVehicule)}>
          {showExitedVehicule ? 'Cacher les véhicules sortis' : 'Afficher les véhicules sortis'}
        </button>
        <select onChange={(e) => handleFilterSocieteChange(e.target.value)}>
          <option value="">Toutes les sociétés</option>
          {companies.map((company) => (
            <option key={company.id} value={company.code}>
              {company.code}
            </option>
          ))}
        </select>
      </div>

      {/* TABLEAU */}
      <table className="table">
        <thead>
          <tr>
            <th className="table__header"> </th>

            {/* Tri sur clic : code (texte/numérique) */}
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('code', false)}
            >
              Code
            </th>

            <th className="table__header">Immat</th>

            {/* Tri sur clic : marque (texte) */}
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('marque', false)}
            >
              Modèle
            </th>

            <th className="table__header">Type</th>

            {/* Tri sur clic : km (numérique) */}
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('km', false)}
            >
              Kilométrage
            </th>

            {/* Tri sur clic : technical_control_date (date) */}
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('technical_control_date', true)}
            >
              Date Contrôle technique
            </th>

            {/* Tri sur clic : location_date (date) */}
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('location_date', true)}
            >
              Date contrat de location
            </th>

            <th className="table__header hide-on-mobile">Observation</th>
          </tr>
        </thead>
        <tbody className="transportBody">
          {filteredVehicules.map((vehicule) => (
            <tr
              key={vehicule.id}
              className="table-row listing"
              onClick={() => {
                if (!isMobile()) handleClickModal(vehicule);
              }}
            >
              <td className="table__cell">
                {vehicule.counter_visit_date && (
                  <Tooltip
                    title={`Contre visite à réaliser avant le ${moment(vehicule.counter_visit_date)
                      .add(2, 'months')
                      .local()
                      .format('DD-MM-YYYY')}`}
                  >
                    <ReportProblemIcon
                      style={{
                        color: 'orange',
                        cursor: 'pointer',
                        marginLeft: '8px',
                        alignSelf: 'center',
                      }}
                    />
                  </Tooltip>
                )}
              </td>
              <td className="table__cell">{vehicule.code}</td>
              <td className="table__cell">{vehicule.immat}</td>
              <td className="table__cell">{vehicule.marque}</td>
              <td className="table__cell">{vehicule.type}</td>
              <td className="table__cell">
                {formatNumberWithUnit(vehicule.km, ' km')}
              </td>
              <td
                className="table__cell"
                style={{ color: calculateControlColor(vehicule.technical_control_date) }}
              >
                {vehicule.technical_control_date
                  ? moment(vehicule.technical_control_date).local().format('DD-MM-YYYY')
                  : ''}
              </td>
              <td
                className="table__cell"
                style={{ color: calculateEcheanceColor(vehicule.location_date) }}
              >
                {vehicule.location_date
                  ? moment(vehicule.location_date).local().format('DD-MM-YYYY')
                  : 'Pas de date'}
              </td>
              <td className="table__cell hide-on-mobile">{vehicule.observation}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* MODAL AJOUT */}
      {showModal && (
        <Modal
          isOpen={showModal}
          style={customStyles}
          ariaHideApp={false}
          onRequestClose={() => confirmCloseModal(setShowModal)}
          ref={(el) => {
            modalVehiculeRef.current = el && el.contentRef;
          }}
        >
          <AddVehiculeModal userToken={userToken} onAddVehicule={handleAddVehicule} />
        </Modal>
      )}

      {/* MODAL UPDATE */}
      <Modal
        isOpen={showUpdateModal}
        onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
        style={customStyles}
        ariaHideApp={false}
        ref={(el) => {
          UpdateVehiculeModalRef.current = el && el.contentRef;
        }}
      >
        <UpdateVehiculeModal
          onUpdateVehicule={handleUpdateVehicule}
          currentVehicule={currentVehicule}
          userToken={userToken}
          userEmail={userEmail}
        />
      </Modal>
    </div>
  );
}

export default React.memo(vehiculeManagement);
