/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button,
  MenuItem, Select, FormControl, InputLabel, Box, Grid, Typography, Accordion,
  AccordionSummary, AccordionDetails, Tooltip, TablePagination
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';

import { Worker } from '@react-pdf-viewer/core';
import ArticleImage from '../stockManagement/Articles/articleImage/articleImage';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import { BASE_URL } from '../../../utils/api';
import MedicalStockForm from './medicalStockForm';

// ---------------------------------------------------------------------
// Sous-composant d’upload “PDF/Image cliquable + icônes d’upload/download”
// ---------------------------------------------------------------------
function MedicalUploadField({
  uploadField, // { id, label, fieldName }
  formData, // l’objet contenant l’URL S3 (ex: formData.photo, formData.img_revision)
  handleFileChange, // callback pour uploader
  userToken,
  fetchSignedUrlForViewing, // ouvre PDF/image dans un nouvel onglet
  fetchSignedUrlForDownloading // télécharge le fichier existant
}) {
  const [isHovered, setIsHovered] = useState(false);

  // La clé S3
  const fileKey = formData[uploadField.fieldName] || '';
  const filename = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = filename.split('.').pop().toLowerCase();

  const cursorStyle = fileKey ? 'pointer' : 'default';

  // Empêcher le clic sur les icônes de remonter
  const stopPropagation = (e) => e.stopPropagation();

  // Ouvrir l’input file caché
  const triggerFileInput = () => {
    document.getElementById(uploadField.id)?.click();
  };

  return (
    <Box
      mb={2}
      style={{
        border: '1px solid #ccc',
        borderRadius: 8,
        padding: 10,
        textAlign: 'center',
        cursor: cursorStyle,
        backgroundColor: isHovered ? '#f7f7f7' : 'transparent'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (fileKey) {
          fetchSignedUrlForViewing(fileKey);
        }
      }}
    >
      <p style={{ fontWeight: 'bold', marginBottom: 8 }}>{uploadField.label}</p>

      {/* Input file caché */}
      <input
        id={uploadField.id}
        type="file"
        style={{ display: 'none' }}
        accept="image/*,application/pdf"
        onChange={(e) => handleFileChange(e, uploadField.fieldName)}
      />

      {fileKey ? (
        fileExtension === 'pdf' ? (
          <PictureAsPdfIcon style={{ fontSize: 60 }} />
        ) : (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <ArticleImage imageKey={fileKey} width={120} height={120} />
          </Worker>
        )
      ) : (
        <div style={{ fontSize: 14, color: '#999' }}>Aucun document</div>
      )}

      {/* Icônes “Ajouter/Remplacer” + “Télécharger” */}
      <Box mt={1} onClick={stopPropagation}>
        <Tooltip title="Ajouter / Remplacer">
          <IconButton onClick={triggerFileInput}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        {fileKey && (
          <Tooltip title="Télécharger">
            <IconButton onClick={() => fetchSignedUrlForDownloading(fileKey)}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

// ---------------------------------------------------------------------
// Composant principal
// ---------------------------------------------------------------------
function MedicalStockManagement({ userToken, userRole, userEmail }) {
  usePageTitle('DRIVESOFT | GESTION DU MATERIEL MEDICAL');
  const [isLoading, setIsLoading] = useState(true);

  // Données
  const [medicalItems, setMedicalItems] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [categories, setCategories] = useState([
    'Néonatologie', 'Brancardage', 'Bariatrique', 'Réanimation'
  ]);

  // Modal / Form
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Filtrage / pagination
  const [filterCompany, setFilterCompany] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  // Visualisation
  const [viewImageUrl, setViewImageUrl] = useState('');

  // Factures
  const [factureDialogOpen, setFactureDialogOpen] = useState(false);
  const [factures, setFactures] = useState([]);
  const [currentMedicalItem, setCurrentMedicalItem] = useState(null);

  // FormData local pour l’article médical
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    photo: '', // image principale
    serial_number: '',
    revision_date: null,
    purchase_date: null,
    purchase_price: 0,
    value: 0,
    company: '',
    category: '',
    ownership: '',
    img_revision: '', // doc de révision
  });

  const headers = { Authorization: `Bearer ${userToken}` };

  // Rôles autorisés
  const isAuthorizedRole = () => {
    const authorizedRoles = [
      'Administrateur', 'Direction', 'Responsable exploitation', 'Gestionnaire de stock'
    ];
    return authorizedRoles.includes(userRole);
  };

  // ---------------------------------------------------------------------
  // 1) Récup items
  // ---------------------------------------------------------------------
  const fetchMedicalItems = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}medical-items`, { headers });
      setMedicalItems(response.data.medicalItems);
      setIsLoading(false);
    }
    catch (error) {
      console.error('Error fetching medical items:', error);
    }
  };

  useEffect(() => {
    fetchMedicalItems();
  }, []);

  // ---------------------------------------------------------------------
  // 2) Sociétés + Véhicules
  // ---------------------------------------------------------------------
  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchVehicules = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules`, { headers });
      const sortedVehicles = response.data.vehicule.sort((a, b) => a.code.localeCompare(b.code));
      setVehicules(sortedVehicles);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des véhicules', error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchVehicules();
  }, []);

  // ---------------------------------------------------------------------
  // 3) Modal Form
  // ---------------------------------------------------------------------
  // Format date => input
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleOpen = (item) => {
    if (item) {
      // Modifier
      setIsEditing(true);
      setFormData({
        ...item,
        photo: item.photo || '',
        img_revision: item.img_revision || '',
        revision_date: item.revision_date ? formatDateForInput(item.revision_date) : null,
        purchase_date: formatDateForInput(item.purchase_date),
      });
    }
    else {
      // Créer
      setIsEditing(false);
      setFormData({
        id: null,
        name: '',
        photo: '',
        serial_number: '',
        revision_date: null,
        purchase_date: '',
        purchase_price: '',
        value: '',
        company: '',
        category: '',
        ownership: '',
        img_revision: ''
      });
    }
    setOpen(true);
  };

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false);
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
      confirmCloseModal();
    }
  };

  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  // Calcul amortissement
  const calculateDepreciatedValue = (purchasePrice, purchaseDate) => {
    if (!purchasePrice || !purchaseDate) return purchasePrice;
    const purchaseYear = new Date(purchaseDate).getFullYear();
    const currentYear = new Date().getFullYear();
    const yearsSincePurchase = currentYear - purchaseYear;
    const depreciationFactor = 0.9 ** yearsSincePurchase;
    return (purchasePrice * depreciationFactor).toFixed(2);
  };

  // Envoi du form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedValue = calculateDepreciatedValue(formData.purchase_price, formData.purchase_date);

    // révision_date => null si vide
    const revisionDate = formData.revision_date && formData.revision_date.trim() !== ''
      ? formData.revision_date
      : null;

    const updatedFormData = {
      ...formData,
      value: updatedValue,
      revision_date: revisionDate,
    };

    try {
      if (updatedFormData.id) {
        // UPDATE
        await axios.put(`${BASE_URL}medical-items/${updatedFormData.id}`, updatedFormData, { headers });
        Swal.fire({
          icon: 'success',
          title: 'Mis à jour!',
          text: 'L\'article médical a été mis à jour avec succès.'
        });
      }
      else {
        // CREATE
        await axios.post(`${BASE_URL}medical-items`, updatedFormData, { headers });
        Swal.fire({
          icon: 'success',
          title: 'Ajouté!',
          text: 'L\'article médical a été ajouté avec succès.'
        });
      }
      fetchMedicalItems();
      setOpen(false);
    }
    catch (error) {
      console.error('Error creating/updating medical item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur s\'est produite lors de la sauvegarde de l\'article médical.'
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas annuler cela!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!'
      });

      if (result.isConfirmed) {
        await axios.delete(`${BASE_URL}medical-items/${id}`, { headers });
        fetchMedicalItems();
        Swal.fire(
          'Supprimé!',
          'L\'article médical a été supprimé.',
          'success'
        );
      }
    }
    catch (error) {
      console.error('Error deleting medical item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur s\'est produite lors de la suppression de l\'article médical.'
      });
    }
  };

  // ---------------------------------------------------------------------
  // 4) PDF/Images (visualiser/télécharger)
  // ---------------------------------------------------------------------
  const downloadFile = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      saveAs(blob, filename || 'download');
    }
    catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      Swal.fire({ icon: 'error', title: 'Erreur', text: 'Impossible de télécharger.' });
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(
        `${BASE_URL}medical-items/get-signed-url`,
        { key },
        { headers }
      );
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Erreur récup URL signée:', error);
      Swal.fire({ icon: 'error', title: 'Erreur', text: 'Impossible de visualiser ce doc.' });
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(
        `${BASE_URL}medical-items/get-signed-url`,
        { key },
        { headers }
      );
      downloadFile(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Erreur récup URL signée:', error);
      Swal.fire({ icon: 'error', title: 'Erreur', text: 'Impossible de télécharger ce doc.' });
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      window.open(viewImageUrl, '_blank');
      setViewImageUrl('');
    }
  }, [viewImageUrl]);

  // ---------------------------------------------------------------------
  // 5) Upload file => handleFileChange
  // ---------------------------------------------------------------------
  const handleFileChange = async (e, fieldName) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Vérif taille
    const maxFileSize = 2 * 1024 * 1024;
    if (file.size > maxFileSize) {
      Swal.fire({ icon: 'error', title: 'Fichier trop volumineux', text: 'max 2 Mo' });
      return;
    }

    // Vérif type
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      Swal.fire({ icon: 'error', title: 'Type invalide', text: 'PNG, JPEG, ou PDF seulement' });
      return;
    }

    try {
      // Récup URL présignée
      const resp = await axios.post(
        `${BASE_URL}medical-items/presigned`,
        { name: file.name, type: file.type },
        { headers }
      );
      const { url, fields } = resp.data;

      // Upload
      const uploadData = new FormData();
      Object.entries(fields).forEach(([k, v]) => uploadData.append(k, v));
      uploadData.append('file', file);

      await axios.post(url, uploadData, { headers: { 'Content-Type': file.type } });

      // On stocke la clé S3
      const uploadedFileUrl = `${fields.key}`;
      setFormData((prev) => ({ ...prev, [fieldName]: uploadedFileUrl }));
    }
    catch (err) {
      console.error('Erreur upload fichier:', err);
      Swal.fire({ icon: 'error', title: 'Upload impossible', text: 'Veuillez réessayer' });
    }
  };

  // ---------------------------------------------------------------------
  // 6) Format / coloration
  // ---------------------------------------------------------------------
  const formatDateDisplay = (dateString) => {
    if (!dateString || dateString.trim() === '') return 'sans date';
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  const getRevisionColor = (revisionDate) => {
    if (!revisionDate) return 'inherit';
    const currentDate = new Date();
    const revision = new Date(revisionDate);

    // +1 an
    const expirationDate = new Date(revision);
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    // alerte 3 mois avant
    const warningDate = new Date(expirationDate);
    warningDate.setMonth(expirationDate.getMonth() - 3);

    if (currentDate >= expirationDate) {
      return '#ffcccc'; // rouge
    }
    if (currentDate >= warningDate) {
      return '#ffebcc'; // orange
    }
    return 'inherit';
  };

  // ---------------------------------------------------------------------
  // 7) Filtrage + pagination
  // ---------------------------------------------------------------------
  const filteredMedicalItems = medicalItems.filter((item) => (
    (!filterCompany || item.company === filterCompany)
    && (!filterCategory || item.category === filterCategory)
    && (!searchName || item.name.toLowerCase().includes(searchName.toLowerCase()))
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ---------------------------------------------------------------------
  // 8) Valeur Totale
  // ---------------------------------------------------------------------
  const totalStockValue = filteredMedicalItems
    .reduce((total, item) => total + (parseFloat(item.value) || 0), 0)
    .toLocaleString('fr-FR', { minimumFractionDigits: 0 });

  // ---------------------------------------------------------------------
  // 9) Export Excel
  // ---------------------------------------------------------------------
  const handleExportExcel = () => {
    const data = filteredMedicalItems.map((item) => ({
      Nom: item.name,
      'Numéro de série': item.serial_number,
      'Date de révision': formatDateDisplay(item.revision_date),
      "Date d'achat": formatDateDisplay(item.purchase_date),
      "Prix d'achat (€ HT)": item.purchase_price,
      'Valeur (€ HT)': item.value,
      Société: item.company,
      Catégorie: item.category,
      Localisation: item.ownership
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Stock Médical');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(dataBlob, 'stock_medical.xlsx');
  };

  // ---------------------------------------------------------------------
  // 10) Factures
  // ---------------------------------------------------------------------
  const [facturesGroupedByMonth, setFacturesGroupedByMonth] = useState({});

  const fetchFacturesByMedicalItem = async (medicalItemId) => {
    try {
      const response = await axios.get(`${BASE_URL}medical-items/${medicalItemId}/factures`, { headers });
      const sortedFactures = response.data.factures.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Regroupement par mois
      const grouped = sortedFactures.reduce((acc, facture) => {
        const dateObj = new Date(facture.date);
        const monthYear = dateObj.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
        if (!acc[monthYear]) acc[monthYear] = [];
        acc[monthYear].push(facture);
        return acc;
      }, {});
      setFacturesGroupedByMonth(grouped || {});
    }
    catch (error) {
      console.error('Erreur récupération factures:', error);
    }
  };

  const handleOpenFactureDialog = (medicalItem) => {
    setCurrentMedicalItem(medicalItem);
    fetchFacturesByMedicalItem(medicalItem.id);
    setFactureDialogOpen(true);
  };

  const handleCloseFactureDialog = () => {
    setFactureDialogOpen(false);
    setCurrentMedicalItem(null);
  };

  const handleAddFacture = async (factureData) => {
    try {
      await axios.post(`${BASE_URL}medical-items/${currentMedicalItem.id}/factures`, factureData, { headers });
      Swal.fire({ title: 'Facture ajoutée !', icon: 'success' });
      fetchFacturesByMedicalItem(currentMedicalItem.id);
    }
    catch (error) {
      Swal.fire({ title: 'Erreur', text: 'Erreur lors de l\'ajout de la facture.', icon: 'error' });
    }
  };

  const handleDeleteFacture = async (factureId) => {
    const result = await Swal.fire({
      title: 'Confirmer la suppression',
      text: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}medical-items/${currentMedicalItem.id}/factures`, {
          headers,
          data: { factureId },
        });
        Swal.fire({ title: 'Supprimée!', text: 'La facture a été supprimée.', icon: 'success' });
        fetchFacturesByMedicalItem(currentMedicalItem.id);
      }
      catch (error) {
        console.error('Erreur suppression facture:', error);
        Swal.fire({ title: 'Erreur', text: 'Impossible de supprimer la facture.', icon: 'error' });
      }
    }
  };

  // ---------------------------------------------------------------------
  // 11) Alertes / tâches (révision)
  // ---------------------------------------------------------------------
  const checkIfTaskExists = async (name, serialNumber, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;
      return tasks.some((t) => t.field_name === `${type} - Matériel ${name} - ${serialNumber}`);
    }
    catch (error) {
      console.error('Error checking tasks:', error);
      return false;
    }
  };

  const createTask = async (medicalItem, type, expirationDate) => {
    const fieldName = `${type} - Matériel ${medicalItem.name} - ${medicalItem.serial_number}`;
    const comment = `${type} pour le matériel ${medicalItem.name} (n° série ${medicalItem.serial_number}) expire le ${format(new Date(expirationDate), 'dd-MM-yyyy')}`;

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      medical_item_id: medicalItem.id,
      role_name: 'Administrateur',
      comment,
    };

    const taskExists = await checkIfTaskExists(medicalItem.name, medicalItem.serial_number, type);
    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  useEffect(() => {
    const checkMedicalItemRevisionDates = async () => {
      const currentDate = new Date();
      const alertMessages = [];

      for (const medicalItem of medicalItems) {
        if (medicalItem.revision_date) {
          const revisionDate = new Date(medicalItem.revision_date);
          const expirationDate = new Date(revisionDate);
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);

          const daysUntilExpiration = differenceInDays(expirationDate, currentDate);

          if (daysUntilExpiration <= 30) {
            await createTask(medicalItem, 'Révision', expirationDate);
          }
          if (daysUntilExpiration <= 0) {
            alertMessages.push(
              `Le matériel ${medicalItem.name} (n° série ${medicalItem.serial_number}) a une révision expirée.`
            );
          }
        }
      }

      if (alertMessages.length > 0) {
        Swal.fire({
          title: 'Alertes de Révision du Matériel Médical',
          html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    };

    if (medicalItems.length > 0) {
      checkMedicalItemRevisionDates();
    }
  }, [medicalItems]);

  // ---------------------------------------------------------------------
  // Rendu
  // ---------------------------------------------------------------------
  if (isLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      {/* Barre de filtres + export */}
      <Grid container style={{ paddingTop: '16px', justifyContent: 'center' }}>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Rechercher par Nom"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="filter-company-label">Société</InputLabel>
            <Select
              labelId="filter-company-label"
              label="Société"
              value={filterCompany}
              onChange={(e) => setFilterCompany(e.target.value)}
            >
              <MenuItem value="">Toutes les sociétés</MenuItem>
              {companies.map((co) => (
                <MenuItem key={co.code} value={co.code}>
                  {co.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="filter-category-label">Catégorie</InputLabel>
            <Select
              labelId="filter-category-label"
              label="Catégorie"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <MenuItem value="">Toutes les catégories</MenuItem>
              {categories.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Button
          variant="contained"
          color="grey"
          onClick={handleExportExcel}
          style={{ margin: '16px' }}
        >
          Exporter en Excel
        </Button>
      </Grid>

      {/* Bouton d’ajout */}
      {isAuthorizedRole() && (
        <Tooltip title="Ajouter un article médical">
          <IconButton color="black" onClick={() => handleOpen(null)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* Dialog CREATE/UPDATE */}
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ margin: 'auto' }}>
          {isEditing ? 'Modifier un article médical' : 'Créer un nouvel article médical'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {/* ********** Photo principale ********** */}
            <MedicalUploadField
              uploadField={{
                id: 'photo-medical-item',
                label: 'Photo du Matériel',
                fieldName: 'photo'
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />

            {/* Nom, N° série, Date révision */}
            <TextField
              label="Nom"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              label="Numéro de série"
              name="serial_number"
              value={formData.serial_number}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              label="Date de révision"
              name="revision_date"
              type="date"
              value={formData.revision_date || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />

            {/* ********** Fichier de révision ********** */}
            <MedicalUploadField
              uploadField={{
                id: 'img_revision-input',
                label: 'Procès verbal révision',
                fieldName: 'img_revision'
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />

            {/* Date achat, prix, valeur */}
            <TextField
              label="Date d'achat"
              name="purchase_date"
              type="date"
              value={formData.purchase_date || ''}
              onChange={(e) => {
                handleInputChange(e);
                const newDate = e.target.value;
                const updatedValue = calculateDepreciatedValue(formData.purchase_price, newDate);
                setFormData((prev) => ({
                  ...prev,
                  purchase_date: newDate,
                  value: updatedValue,
                }));
              }}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Prix d'achat (€ HT)"
              name="purchase_price"
              type="number"
              value={formData.purchase_price}
              onChange={(e) => {
                handleInputChange(e);
                const newPrice = e.target.value;
                const updatedValue = calculateDepreciatedValue(newPrice, formData.purchase_date);
                setFormData((prev) => ({
                  ...prev,
                  purchase_price: newPrice,
                  value: updatedValue,
                }));
              }}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Valeur (€ HT)"
              name="value"
              value={formData.value || calculateDepreciatedValue(formData.purchase_price, formData.purchase_date)}
              fullWidth
              disabled
              margin="normal"
              InputProps={{ readOnly: true }}
            />

            {/* Société + Catégorie + Localisation */}
            <FormControl fullWidth required sx={{ marginTop: 2 }}>
              <InputLabel id="company-label">Société</InputLabel>
              <Select
                required
                labelId="company-label"
                label="Société"
                name="company"
                id="company"
                value={formData.company}
                onChange={handleInputChange}
              >
                {companies.map((co) => (
                  <MenuItem key={co.id} value={co.code}>
                    {co.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal" required>
              <InputLabel id="category-label">Catégorie</InputLabel>
              <Select
                labelId="category-label"
                label="Catégorie"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal" required>
              <InputLabel id="ownership-label">Localisation</InputLabel>
              <Select
                labelId="ownership-label"
                label="Localisation"
                name="ownership"
                value={formData.ownership}
                onChange={handleInputChange}
              >
                <MenuItem value="Stock">Stock</MenuItem>
                <MenuItem value="En entretien">En entretien</MenuItem>
                {vehicules.map((vehicle) => (
                  <MenuItem key={vehicle.id} value={vehicle.code}>
                    {vehicle.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DialogActions sx={{ marginTop: 2 }}>
              <Button onClick={confirmCloseModal} color="primary">
                Annuler
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {isEditing ? 'Mettre à jour' : 'Créer'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Tableau principal */}
      <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Photo</TableCell>
              <TableCell align="center">Nom</TableCell>
              <TableCell align="center">Numéro de série</TableCell>
              <TableCell align="center">Date de révision</TableCell>
              <TableCell align="center">Date d'achat</TableCell>
              <TableCell align="center">Prix d'achat (€ HT)</TableCell>
              <TableCell align="center">Valeur (€ HT)</TableCell>
              <TableCell align="center">Société</TableCell>
              <TableCell align="center">Catégorie</TableCell>
              <TableCell align="center">Localisation</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMedicalItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    {/* Aperçu miniature de la photo */}
                    <ArticleImage imageKey={item.photo} width={50} height={50} />
                  </TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.serial_number}</TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: getRevisionColor(item.revision_date) }}
                  >
                    {formatDateDisplay(item.revision_date)}
                  </TableCell>
                  <TableCell align="center">{formatDateDisplay(item.purchase_date)}</TableCell>
                  <TableCell align="center">{item.purchase_price}</TableCell>
                  <TableCell align="center">
                    {calculateDepreciatedValue(item.purchase_price, item.purchase_date)}
                  </TableCell>
                  <TableCell align="center">{item.company}</TableCell>
                  <TableCell align="center">{item.category}</TableCell>
                  <TableCell align="center">{item.ownership}</TableCell>
                  <TableCell align="center">
                    {isAuthorizedRole() && (
                      <>
                        <Tooltip title="Modifier l'article médical">
                          <IconButton onClick={() => handleOpen(item)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer l'article médical">
                          <IconButton onClick={() => handleDelete(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Ajouter/Consulter les factures">
                          <IconButton onClick={() => handleOpenFactureDialog(item)} style={{ color: 'grey' }}>
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Box display="flex" justifyContent="flex-end" p={2}>
          <TablePagination
            rowsPerPageOptions={[8, 16, 24]}
            component="div"
            count={filteredMedicalItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Résultats par page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`}
          />
        </Box>
      </TableContainer>

      {/* Valeur Totale */}
      <Typography
        variant="h6"
        component="div"
        sx={{
          color: 'grey',
          fontSize: '16px',
          textAlign: 'right',
          paddingRight: '10px'
        }}
      >
        Valeur du matériel médical: {totalStockValue} € HT
      </Typography>

      {/* Dialog Factures */}
      <Dialog open={factureDialogOpen} onClose={handleCloseFactureDialog} fullWidth maxWidth="md">
        <DialogTitle>Gestion des factures pour {currentMedicalItem?.name}</DialogTitle>
        <DialogContent>
          <MedicalStockForm onSaveFacture={handleAddFacture} />
          <h3 className="addModalTitle">Charges existantes</h3>
          {Object.keys(facturesGroupedByMonth)
            .sort((a, b) => new Date(b) - new Date(a))
            .map((monthYear) => (
              <Accordion key={monthYear}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{monthYear}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Details</TableCell>
                          <TableCell>Commentaires</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {facturesGroupedByMonth[monthYear].map((facture, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {new Date(facture.date).toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </TableCell>
                            <TableCell>{facture.type}</TableCell>
                            <TableCell>
                              {Object.keys(facture.details).map((key) => (
                                facture.details[key] && (
                                  <div key={key}>
                                    <strong>{key}:</strong> {facture.details[key]}
                                  </div>
                                )
                              ))}
                            </TableCell>
                            <TableCell>{facture.commentaires}</TableCell>
                            <TableCell>
                              <Tooltip title="Supprimer la facture">
                                <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFactureDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(MedicalStockManagement);
