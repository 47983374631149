/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './staffManagementStyles.scss';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as XLSX from 'xlsx';
import AddStaffModal from './addStaffModal/addStaffModal';
import UpdateStaffModal from './updateStaffModal/updateStaffModal';
import usePageTitle from '../../hooks/usePageTitle';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';

function Staff({
  theme, userRole, userToken, userEmail
}) {
  usePageTitle('DRIVESOFT | GESTION DU PERSONNEL');
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [showExitedStaff, setShowExitedStaff] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterCompany, setFilterCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [currentStaff, setCurrentStaff] = useState(null);
  const [selectedTotal, setSelectedTotal] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [alertShown, setAlertShown] = useState(false);

  // --- Configuration de tri ---
  // 'key' : la clé correspondante dans l'objet staff (ex: 'medicalvisit')
  // 'direction' : 'asc' ou 'desc'
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const modalStaffRef = useRef(null);
  const UpdateStaffModalRef = useRef(null);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation', 'Ressources humaines'];
    return authorizedRoles.includes(userRole);
  };

  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  const handleClick = (e) => {
    if (isAuthorizedRole(userRole)) {
      if (modalStaffRef.current && !modalStaffRef.current.contains(e.target)) {
        setShowModal(false);
      }
    }
  };

  const handleDoubleClick = (staff) => {
    if (isAuthorizedRole(userRole)) {
      setCurrentStaff(staff);
      setShowUpdateModal(true);
    }
    else {
      Swal.fire({
        title: 'Vous n\'avez pas les droits pour modifier le personnel',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchAllStaff = async () => {
    try {
      const res = await axios.get(`${BASE_URL}staff`, { headers });
      // Vous pouvez faire un premier tri par nom/prénom à la récupération si vous le souhaitez.
      const sortedStaff = res.data.staff.sort((a, b) => {
        const lastNameA = a.last_name ?? '';
        const lastNameB = b.last_name ?? '';
        return lastNameA.localeCompare(lastNameB);
      });
      setStaffList(sortedStaff);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  // ************************** Vérification / alertes sur les dates de certifications **************************
  const checkIfTaskExists = async (staffName, staffLastName, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;
      const taskExists = tasks.some((task) => task.field_name === `${type} - ${staffName} ${staffLastName}`);
      return taskExists;
    }
    catch (error) {
      console.error('Error checking tasks:', error);
      return false;
    }
  };

  const createTask = async (staff, type, expirationDate) => {
    const fieldName = `${type} - ${staff.name} ${staff.last_name}`;
    const comment = `${type} de ${staff.name} ${staff.last_name} expire le ${moment(expirationDate).format('DD-MM-YYYY')}.`;

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      vehicule_code: '',
      role_name: 'Ressources humaines',
      comment,
    };

    const taskExists = await checkIfTaskExists(staff.name, staff.last_name, type);

    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  const checkCertificationExpirations = async () => {
    const alertMessages = [];

    for (const staff of staffList) {
      // Skip staff with an exit date
      if (staff.exitdate) continue;

      const certifications = [
        { date: staff.medicalvisit, type: 'Visite médicale', expirationYears: 5 },
        { date: staff.drivinglicensevisit, type: 'Visite du permis de conduire', expirationYears: 5 },
        { date: staff.afgsu, type: 'AFGSU', expirationYears: 4 },
      ];

      for (const cert of certifications) {
        if (cert.date && moment(cert.date, moment.ISO_8601, true).isValid()) {
          const expirationDate = moment(cert.date).add(cert.expirationYears, 'years');
          const daysUntilExpiration = expirationDate.diff(moment(), 'days');

          if (daysUntilExpiration < 0) {
            alertMessages.push(`${cert.type} de ${staff.name} ${staff.last_name} est expiré (expire le ${expirationDate.format('DD-MM-YYYY')}).`);
            await createTask(staff, cert.type, expirationDate.toDate());
          }
          else if (daysUntilExpiration <= 30) {
            alertMessages.push(`${cert.type} de ${staff.name} ${staff.last_name} expire bientôt (expire le ${expirationDate.format('DD-MM-YYYY')}).`);
            await createTask(staff, cert.type, expirationDate.toDate());
          }
        }
        else {
          console.warn(`Date invalide ou absente pour ${cert.type} de ${staff.name} ${staff.last_name}: ${cert.date}`);
        }
      }
    }

    if (alertMessages.length > 0) {
      Swal.fire({
        title: 'Alertes Certifications Employés',
        html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  useEffect(() => {
    // Affiche l'alerte une seule fois
    if (staffList.length > 0 && !alertShown) {
      checkCertificationExpirations();
      setAlertShown(true);
    }
  }, [staffList, alertShown]);

  // ************************** Fin vérification / alertes sur les dates de certifications **************************

  useEffect(() => {
    fetchAllStaff();
    fetchCompanies();
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        setModalState(false);
      }
    });
  };

  const handleAddStaff = (staff) => {
    setStaffList([...staffList, staff]);
    setShowModal(false);
    Swal.fire({
      title: 'Personnel ajouté !',
      icon: 'success',
      confirmButtonText: 'OK',
    });
    fetchAllStaff();
  };

  const handleUpdateStaff = async (updatedStaff) => {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier ce personnel ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, mettre à jour ',
      cancelButtonText: 'Non',
    });
    if (confirmation.value) {
      const index = staffList.findIndex((staff) => staff.id === currentStaff.id);
      const newList = [...staffList.slice(0, index), updatedStaff, ...staffList.slice(index + 1)];
      setStaffList(newList);
      setShowUpdateModal(false);
      Swal.fire({
        title: 'Personnel modifié !',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      fetchAllStaff();
    }
  };

  // Fonctions de colorisation selon la proximité de la date d'expiration
  function getAFGSUVisitColor(AFGSUVisitDate) {
    const expirationDate = moment(AFGSUVisitDate).add(4, 'years');
    const diffInDays = expirationDate.diff(moment(), 'days');

    if (diffInDays > 365) return 'black'; // Plus d'un an
    if (diffInDays > 90) return 'rose'; // Moins de 6 mois
    if (diffInDays > 0) return 'orange'; // Moins d'un mois
    return 'red'; // Expiré
  }

  function getDrivingLicenseVisitColor(drivingLicenseVisitDate) {
    const expirationDate = moment(drivingLicenseVisitDate).add(5, 'years');
    const diffInDays = expirationDate.diff(moment(), 'days');

    if (diffInDays > 365) return 'black';
    if (diffInDays > 90) return 'rose';
    if (diffInDays > 0) return 'orange';
    return 'red'; // Expiré
  }

  function getMedicalVisitColor(medicalVisitDate) {
    const expirationDate = moment(medicalVisitDate).add(5, 'years');
    const diffInDays = expirationDate.diff(moment(), 'days');

    if (diffInDays > 365) return 'black';
    if (diffInDays > 90) return 'rose';
    if (diffInDays > 0) return 'orange';
    return 'red'; // Expiré
  }

  // --- Fonction pour gérer le tri lors du clic sur un en-tête ---
  const handleSort = (key) => {
    if (sortConfig.key === key) {
      // Inverser le sens de tri si on reclique sur la même colonne
      setSortConfig((prev) => ({
        ...prev,
        direction: prev.direction === 'asc' ? 'desc' : 'asc',
      }));
    }
    else {
      // Définir la nouvelle colonne à trier en mode ascendant
      setSortConfig({ key, direction: 'asc' });
    }
  };

  // --- Fonction de tri pour les dates ---
  const sortStaffList = (list) => {
    const { key, direction } = sortConfig;
    if (!key) {
      // Si pas de colonne spécifiée, on ne trie pas
      return list;
    }

    // On trie par date (ou par moment si besoin)
    return [...list].sort((a, b) => {
      const dateA = moment(a[key]);
      const dateB = moment(b[key]);
      // Si une des deux dates est invalide, on place l'élément en dernier ou on le considère comme 0
      if (!dateA.isValid() && !dateB.isValid()) return 0;
      if (!dateA.isValid()) return 1; // Date invalide => en bas
      if (!dateB.isValid()) return -1; // Date invalide => en bas

      if (dateA.isBefore(dateB)) return direction === 'asc' ? -1 : 1;
      if (dateA.isAfter(dateB)) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // *********************************************************************************************
  // Filtres (société, sortie) + search
  const filteredStaffList = staffList.filter((staff) => {
    const fullName = `${staff.last_name} ${staff.name}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  }).filter((staff) => (!filterCompany || staff.company === filterCompany)
      && (showExitedStaff ? staff.exitdate : !staff.exitdate));

  // Application du tri seulement après le filtrage
  const sortedAndFilteredStaffList = sortStaffList(filteredStaffList);

  // *********************************************************************************************
  // Export Excel
  const columnMappings = {
    name: 'Nom',
    last_name: 'Prénom',
    code: 'Code',
    phonenumber: 'Téléphone',
    email: 'Email',
    adress: 'Adresse',
    city: 'Ville',
    zipcode: 'Code postal',
    company: 'Société',
    graduation: 'Diplôme',
    socialnumber: 'Numéro de sécurité sociale',
    drivinglicense: 'Permis de conduire',
    medicalvisit: 'Visite médicale',
    drivinglicensevisit: 'Visite du permis de conduire',
    afgsu: 'AFGSU',
    entrydate: 'Date d\'entrée',
    responsability: 'Responsabilité',
    sexe: 'Sexe',
    date_de_naissance: 'Date de naissance'
  };
  const columnsToExclude = [
    'idcard',
    'rib',
    'vaccinationlicense',
    'exitdate',
    'incidents',
    'id',
    'order_index',
    'pro_driving_license',
    'contrat',
    'graduation_document',
    'afgsu_document',
    'medical_visit_document',
    'code'
  ];

  const formatDateForExport = (dateString) => moment(dateString).format('DD/MM/YYYY');

  const transformDataForExport = () => staffList.reduce((acc, staff) => {
    // Ne prend pas ceux qui ont une date de sortie OU qui ne correspondent pas à la société filtrée (si filtre)
    if ((filterCompany && staff.company !== filterCompany) || staff.exitdate) {
      return acc;
    }

    const transformedStaff = { ...staff };

    // Formatage des dates
    transformedStaff.medicalvisit = formatDateForExport(staff.medicalvisit);
    transformedStaff.drivinglicensevisit = formatDateForExport(staff.drivinglicensevisit);
    transformedStaff.afgsu = formatDateForExport(staff.afgsu);
    transformedStaff.entrydate = formatDateForExport(staff.entrydate);
    transformedStaff.date_de_naissance = formatDateForExport(staff.date_de_naissance);

    // Supprime les colonnes indésirables
    columnsToExclude.forEach((key) => delete transformedStaff[key]);

    // Renomme les clés en français
    const transformedStaffWithFrenchColumns = {};
    Object.keys(transformedStaff).forEach((key) => {
      const newKey = columnMappings[key] || key;
      transformedStaffWithFrenchColumns[newKey] = transformedStaff[key];
    });

    acc.push(transformedStaffWithFrenchColumns);
    return acc;
  }, []);

  const exportToExcel = () => {
    const transformedData = transformDataForExport();
    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DonnéesEmployés');

    const filename = `DonnéesPersonnel_${filterCompany || 'Toutes'}_${moment().format('DDMMYYYY')}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  // *********************************************************************************************
  // Comptage
  const getTotalStaff = () => staffList.filter((staff) => !staff.exitdate).length;

  const groupDiplomas = (diploma) => {
    if (diploma === 'CCA' || diploma === 'DEA') {
      return 'CCA/DEA';
    }
    return diploma;
  };

  const getTotalByDiploma = () => {
    const count = {};
    staffList.forEach((staff) => {
      if (!staff.exitdate) {
        const groupedDiploma = groupDiplomas(staff.graduation);
        count[groupedDiploma] = (count[groupedDiploma] || 0) + 1;
      }
    });
    return count;
  };

  const getTotalByCompany = () => {
    const count = {};
    staffList.forEach((staff) => {
      if (!staff.exitdate) {
        count[staff.company] = (count[staff.company] || 0) + 1;
      }
    });
    return count;
  };

  const formatCountObject = (countObject) => Object.entries(countObject)
    .map(([key, value]) => `${key} - ${value}`)
    .join(' / ');

  const handleFilterCompanyChange = (company) => {
    setFilterCompany(company);
    if (company === '') {
      setSelectedTotal(null);
    }
    else {
      const totalByDiplomaForCompany = staffList
        .filter((staff) => staff.company === company)
        .reduce((acc, staff) => {
          const groupedDiploma = groupDiplomas(staff.graduation);
          acc[groupedDiploma] = (acc[groupedDiploma] || 0) + 1;
          return acc;
        }, {});
      setSelectedTotal(totalByDiplomaForCompany);
    }
  };

  // *********************************************************************************************
  // Rendu
  if (isLoading) {
    return <AmbysoftLoader />;
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '90%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll',
      overflowX: 'hidden',
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  return (
    <div className="staffManagement">
      <div className="regulation__header__button">
        {isAuthorizedRole(userRole) && (
          <button type="button" className="AddTransport" onClick={() => setShowModal(true)}>
            Ajouter une personne
          </button>
        )}
      </div>
      {isAuthorizedRole(userRole) && (
        <button className="hide-on-mobile" type="button" onClick={exportToExcel}>
          Exporter en Excel
        </button>
      )}

      <div className="search-bar hide-on-mobile">
        <button type="button" onClick={() => setShowExitedStaff(!showExitedStaff)}>
          {showExitedStaff ? 'Cacher les sorties' : 'Afficher les sorties'}
        </button>
        <select className="select-input" onChange={(event) => handleFilterCompanyChange(event.target.value)}>
          <option value="">Toutes les sociétés</option>
          {companies.map((company) => (
            <option key={company.id} value={company.code}>
              {company.code}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Rechercher par nom"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="staff-counters">
        {filterCompany === '' ? (
          <>
            <div>Nombre d'employés: {getTotalStaff()}</div>
            <div>{formatCountObject(getTotalByDiploma())}</div>
          </>
        ) : (
          <>
            <div>Total par société sélectionnée ({filterCompany}): {getTotalByCompany()[filterCompany]}</div>
            <div>Total par diplôme pour {filterCompany}: {formatCountObject(selectedTotal)}</div>
          </>
        )}
      </div>

      <table className="table">
        <thead>
          <tr className="table-row listing">
            {/* Colonnes non triées */}
            <th className="table__header">Nom</th>
            <th className="table__header">Prénom</th>
            <th className="table__header">Entreprise</th>
            <th className="table__header">Diplôme</th>
            {/* Colonnes triées par date */}
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('medicalvisit')}
            >
              Visite médicale
            </th>
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('drivinglicensevisit')}
            >
              Visite du permis
            </th>
            <th
              className="table__header"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('afgsu')}
            >
              AFGSU
            </th>
            <th className="table__header">Observations</th>
          </tr>
        </thead>
        <tbody className="transportBody">
          {sortedAndFilteredStaffList.map((staff) => (
            <tr
              key={staff.id}
              className="table-row listing"
              onClick={() => {
                if (!isMobile()) {
                  handleDoubleClick(staff);
                }
              }}
            >
              <td className="table__cell">{staff.name}</td>
              <td className="table__cell">{staff.last_name}</td>
              <td className="table__cell">{staff.company}</td>
              <td className="table__cell">{staff.graduation}</td>
              <td
                className="table__cell"
                style={{ color: getMedicalVisitColor(staff.medicalvisit) }}
              >
                {staff.medicalvisit ? moment(staff.medicalvisit).format('DD-MM-YYYY') : ''}
              </td>
              <td
                className="table__cell"
                style={{ color: getDrivingLicenseVisitColor(staff.drivinglicensevisit) }}
              >
                {staff.drivinglicensevisit ? moment(staff.drivinglicensevisit).format('DD-MM-YYYY') : ''}
              </td>
              <td
                className="table__cell"
                style={{ color: getAFGSUVisitColor(staff.afgsu) }}
              >
                {staff.afgsu ? moment(staff.afgsu).format('DD-MM-YYYY') : ''}
              </td>
              <td className="table__cell">{staff.observations}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal">
          <Modal
            isOpen={showModal}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => confirmCloseModal(setShowModal)}
            ref={(el) => {
              modalStaffRef.current = el && el.contentRef;
            }}
          >
            <AddStaffModal onAddStaff={handleAddStaff} userToken={userToken} />
          </Modal>
        </div>
      )}

      <Modal
        isOpen={showUpdateModal}
        onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
        style={customStyles}
        ariaHideApp={false}
        ref={(el) => {
          UpdateStaffModalRef.current = el && el.contentRef;
        }}
      >
        <UpdateStaffModal
          onUpdateStaff={handleUpdateStaff}
          currentStaff={currentStaff}
          userToken={userToken}
        />
      </Modal>
    </div>
  );
}

export default React.memo(Staff);
