/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
// MyCalendar.jsx
import React, { useState, useEffect } from 'react';
import { RRule, rrulestr } from 'rrule';
import axios from 'axios';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import {
  format, parse, startOfWeek, getDay, formatISO9075,
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Grid,
  Typography,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import {
  Delete as DeleteIcon,
  Link as LinkIcon,
  Event as EventIcon,
} from '@mui/icons-material';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendarStyle.css';
import { BASE_URL } from '../../../utils/api';

const locales = {
  fr: fr,
};

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const roleColors = {
  Administrateur: '#FF8A80',
  Direction: '#80D8FF',
  'Directeur financier': '#c3d2ba',
  'Responsable d’exploitation': '#FF80AB',
  Régulation: '#CCFF90',
  Facturation: '#EA80FC',
  'Ressources humaines': '#82B1FF',
  Comptabilité: '#FFD180',
  Personnel: '#BCAAA4',
  Garagiste: '#B0BEC5',
  Norme: '#B388FF',
  'Appel d’offre': '#FF5252',
  'Responsable opérationnel': '#18FFFF',
  'Responsable de véhicule': '#FF80AB',
  'Gestionnaire de stock': '#CCFF90',
};

function MyCalendar({ userToken, theme: currentTheme, userRole }) {
  const [events, setEvents] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteOptions, setDeleteOptions] = useState({ deleteSeries: false });
  const [newEvent, setNewEvent] = useState({
    title: '', start: '', end: '', roles: [], link: ''
  });
  const [errors, setErrors] = useState({});
  const [filterRole, setFilterRole] = useState('all');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const days = [
    { label: 'LUN', value: 'MO' },
    { label: 'MAR', value: 'TU' },
    { label: 'MER', value: 'WE' },
    { label: 'JEU', value: 'TH' },
    { label: 'VEN', value: 'FR' },
    { label: 'SAM', value: 'SA' },
    { label: 'DIM', value: 'SU' },
  ];

  const weekdayMapping = {
    0: 'MO',
    1: 'TU',
    2: 'WE',
    3: 'TH',
    4: 'FR',
    5: 'SA',
    6: 'SU',
  };

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}calendar/events`, { headers });
      const fetchedEvents = response.data.events.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      setEvents(fetchedEvents);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des événements', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}roles`, { headers });
      setRoles(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des rôles', error);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchRoles();
  }, []);

  const handleSelectSlot = ({
    start, end, slots
  }) => {
    const isSingleDay = slots.length === 1;
    const isAllDayEvent = isSingleDay;

    if (isAllDayEvent) {
      end = new Date(start);
      end.setHours(23, 59, 59, 999);
    }

    setCurrentEvent(null);
    setNewEvent({
      title: '',
      start,
      end,
      roles: [],
      link: '',
      allDay: isAllDayEvent,
    });
    setErrors({});
    setOpenDialog(true);
  };

  const freqMapping = {
    [RRule.YEARLY]: 'YEARLY',
    [RRule.MONTHLY]: 'MONTHLY',
    [RRule.WEEKLY]: 'WEEKLY',
    [RRule.DAILY]: 'DAILY',
  };

  const handleSelectEvent = (event) => {
    setCurrentEvent(event);
    const recurrenceOptions = {};

    if (event.recurrence_rule) {
      const rule = rrulestr(event.recurrence_rule);
      const { options } = rule;
      let freqValue = freqMapping[options.freq];

      // Si l'intervalle est 2 et la fréquence est WEEKLY, on considère que c'est du bihebdomadaire
      if (options.freq === RRule.WEEKLY && options.interval === 2) {
        freqValue = 'BIWEEKLY';
      }

      recurrenceOptions.recurrenceFrequency = freqValue;
      recurrenceOptions.recurrenceEndDate = options.until ? options.until.toISOString().split('T')[0] : '';
      recurrenceOptions.recurrenceByDay = options.byweekday
        ? options.byweekday.map((weekday) => weekdayMapping[weekday.weekday])
        : [];
    }

    setNewEvent({
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      roles: event.roles ? event.roles.map((role) => role.id) : [],
      link: event.link || '',
      description: event.description || '',
      ...recurrenceOptions,
    });
    setErrors({});
    setOpenDialog(true);
  };

  const handleSaveEvent = async () => {
    if (!newEvent.title) {
      setErrors({ ...errors, title: 'Le titre est obligatoire.' });
      return;
    }

    if (!isAuthorizedRole()) {
      const userRoleId = roles.find((role) => role.name === userRole)?.id;
      if (userRoleId) {
        newEvent.roles = [userRoleId];
      }
      else {
        setErrors({ ...errors, roles: 'Le rôle de l’utilisateur est introuvable.' });
        return;
      }
    }
    else if (!newEvent.roles.length) {
      setErrors({ ...errors, roles: 'Au moins un rôle est obligatoire.' });
      return;
    }

    const startDate = new Date(newEvent.start);
    const endDate = new Date(newEvent.end);

    if (newEvent.allDay) {
      startDate.setHours(12, 0, 0, 0);
      endDate.setHours(12, 0, 0, 0);
    }

    let recurrenceRule = null;

    if (newEvent.recurrenceFrequency) {
      const options = {};

      // AJOUT BIHEBDOMADAIRE
      if (newEvent.recurrenceFrequency === 'BIWEEKLY') {
        options.freq = RRule.WEEKLY;
        options.interval = 2; // Toutes les 2 semaines
      }
      else {
        options.freq = RRule[newEvent.recurrenceFrequency];
      }

      options.dtstart = startDate;

      if (newEvent.recurrenceEndDate) {
        const recurrenceEndDate = new Date(newEvent.recurrenceEndDate);
        recurrenceEndDate.setHours(12, 0, 0, 0);
        options.until = recurrenceEndDate;
      }

      if (newEvent.recurrenceByDay && newEvent.recurrenceByDay.length > 0) {
        options.byweekday = newEvent.recurrenceByDay.map((day) => RRule[day]);
      }

      const rule = new RRule(options);
      recurrenceRule = rule.toString();
    }

    try {
      const eventToAdd = {
        title: newEvent.title,
        start: startDate,
        end: endDate,
        roles: newEvent.roles,
        link: newEvent.link || null,
        description: newEvent.description || '',
        recurrenceRule,
        allDay: newEvent.allDay || false,
      };

      let response;
      if (currentEvent) {
        response = await axios.put(`${BASE_URL}calendar/events/${currentEvent.id}`, eventToAdd, { headers });
        setEvents(events.map((evt) => (evt.id === currentEvent.id ? response.data.updatedEvent : evt)));
      }
      else {
        response = await axios.post(`${BASE_URL}calendar/events`, eventToAdd, { headers });
        setEvents([...events, response.data.newEvent]);
      }

      setOpenDialog(false);
      setNewEvent({
        title: '', start: '', end: '', roles: [], link: '', description: '', allDay: false
      });
      setCurrentEvent(null);
    }
    catch (error) {
      console.error('Erreur lors de la sauvegarde de l\'événement', error);
    }
  };

  const deleteEvent = async ({ deleteSeries }) => {
    if (!currentEvent) return;
    try {
      if (deleteSeries) {
        await axios.delete(`${BASE_URL}calendar/events/${currentEvent.id}`, { headers });
        setEvents(events.filter((evt) => evt.id !== currentEvent.id));
      }
      else {
        await axios.post(`${BASE_URL}calendar/events/${currentEvent.id}/deleteOccurrence`, {
          occurrenceDate: currentEvent.start,
        }, { headers });
        fetchEvents();
      }
      setOpenDialog(false);
      setCurrentEvent(null);
      setOpenDeleteDialog(false);
    }
    catch (error) {
      console.error('Erreur lors de la suppression de l\'événement', error);
    }
  };

  const handleDeleteEvent = () => {
    if (!currentEvent) return;

    if (currentEvent.recurrence_rule) {
      setDeleteOptions({ deleteSeries: false });
      setOpenDeleteDialog(true);
    }
    else {
      deleteEvent({ deleteSeries: true });
    }
  };

  const handleEventDrop = async ({ event, start, end }) => {
    try {
      const updatedEvent = {
        ...event,
        start,
        end,
        roles: event.roles ? event.roles.map((role) => role.id) : [],
      };
      const response = await axios.put(`${BASE_URL}calendar/events/${event.id}`, updatedEvent, { headers });
      setEvents(events.map((evt) => (evt.id === event.id ? response.data.updatedEvent : evt)));
    }
    catch (error) {
      console.error('Erreur lors du déplacement de l\'événement', error);
    }
  };

  const handleEventResize = async ({ event, start, end }) => {
    try {
      const updatedEvent = {
        ...event,
        start,
        end,
        roles: event.roles ? event.roles.map((role) => role.id) : [],
      };
      const response = await axios.put(`${BASE_URL}calendar/events/${event.id}`, updatedEvent, { headers });
      setEvents(events.map((evt) => (evt.id === event.id ? response.data.updatedEvent : evt)));
    }
    catch (error) {
      console.error('Erreur lors du redimensionnement de l\'événement', error);
    }
  };

  const filteredEvents = events.filter((event) => {
    if (isAuthorizedRole()) {
      return filterRole === 'all' || event.roles.some((role) => role.id === filterRole);
    }
    return event.roles.some((role) => role.name === userRole);
  });

  const eventStyleGetter = (event) => {
    const roleNames = event.roles ? event.roles.map((role) => role.name) : [];
    const backgroundColor = roleNames.length > 0
      ? roleColors[roleNames[0]] || '#29B6F6'
      : '#29B6F6';

    return {
      style: {
        backgroundColor,
        borderRadius: '4px',
        border: 'none',
        color: '#ffffff',
        padding: '2px 4px',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    };
  };

  const eventPropGetter = (event) => ({
    style: eventStyleGetter(event).style,
  });

  function EventComponent({ event }) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
        <EventIcon style={{ marginRight: 2, fontSize: 12 }} />
        <span style={{
          flexGrow: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '12px',
        }}
        >
          {event.title}
        </span>
        {event.link && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              window.open(event.link, '_blank');
            }}
            style={{ color: 'white', padding: 0 }}
          >
            <LinkIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  }

  const messages = {
    next: 'Suivant',
    previous: 'Précédent',
    today: "Aujourd'hui",
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Événement',
    allDay: 'Toute la journée',
    showMore: (total) => `+ ${total} plus`,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }} id={currentTheme}>
      <div style={{ padding: '10px' }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" style={{ fontWeight: 600 }}>Calendrier</Typography>
          </Grid>
          {isAuthorizedRole() && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="filter-role-label">Filtrer par rôle</InputLabel>
                <Select
                  labelId="filter-role-label"
                  label="Filtrer par rôle"
                  value={filterRole}
                  onChange={(e) => setFilterRole(e.target.value)}
                >
                  <MenuItem value="all">Tous les rôles</MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>

      <div style={{ flexGrow: 1 }}>
        <DragAndDropCalendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor={(event) => new Date(event.start)}
          endAccessor={(event) => new Date(event.end)}
          selectable
          resizable
          onEventDrop={handleEventDrop}
          onEventResize={handleEventResize}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          defaultView={isMobile ? 'agenda' : 'month'}
          views={['month', 'week', 'day', 'agenda']}
          eventPropGetter={eventPropGetter}
          components={{
            event: EventComponent,
          }}
          messages={messages}
          culture="fr"
          style={{ height: '100%', backgroundColor: theme.palette.background.default }}
        />
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{currentEvent ? 'Modifier l\'événement' : 'Ajouter un événement'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Titre"
            fullWidth
            margin="dense"
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            label="Description"
            fullWidth
            margin="dense"
            multiline
            rows={4}
            value={newEvent.description || ''}
            onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
            error={!!errors.description}
            helperText={errors.description}
          />

          {isAuthorizedRole() && (
          <FormControl fullWidth margin="dense" error={!!errors.roles}>
            <InputLabel id="role-label">Rôles</InputLabel>
            <Select
              labelId="role-label"
              label="Rôles"
              multiple
              value={newEvent.roles}
              onChange={(e) => setNewEvent({ ...newEvent, roles: e.target.value })}
              renderValue={(selected) => roles
                .filter((role) => selected.includes(role.id))
                .map((role) => role.name)
                .join(', ')}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  <Checkbox checked={newEvent.roles.indexOf(role.id) > -1} />
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
            {errors.roles && (
            <Typography variant="caption" color="error">
              {errors.roles}
            </Typography>
            )}
          </FormControl>
          )}

          <FormControlLabel
            control={(
              <Checkbox
                checked={newEvent.allDay || false}
                onChange={(e) => setNewEvent({ ...newEvent, allDay: e.target.checked })}
              />
             )}
            label="Toute la journée"
          />

          <TextField
            label="Date de début"
            type="datetime-local"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            value={newEvent.start ? formatISO9075(newEvent.start).slice(0, 16) : ''}
            onChange={(e) => setNewEvent({ ...newEvent, start: new Date(e.target.value) })}
            disabled={newEvent.allDay}
          />
          <TextField
            label="Date de fin"
            type="datetime-local"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            value={newEvent.end ? formatISO9075(newEvent.end).slice(0, 16) : ''}
            onChange={(e) => setNewEvent({ ...newEvent, end: new Date(e.target.value) })}
            disabled={newEvent.allDay}
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="recurrence-frequency-label">Fréquence</InputLabel>
            <Select
              labelId="recurrence-frequency-label"
              label="Fréquence"
              value={newEvent.recurrenceFrequency || ''}
              onChange={(e) => setNewEvent({ ...newEvent, recurrenceFrequency: e.target.value })}
            >
              <MenuItem value="">Aucune</MenuItem>
              <MenuItem value="DAILY">Quotidienne</MenuItem>
              <MenuItem value="WEEKLY">Hebdomadaire</MenuItem>
              <MenuItem value="BIWEEKLY">Bihebdomadaire</MenuItem>
              <MenuItem value="MONTHLY">Mensuelle</MenuItem>
              <MenuItem value="YEARLY">Annuelle</MenuItem>
            </Select>
          </FormControl>

          {['WEEKLY', 'BIWEEKLY'].includes(newEvent.recurrenceFrequency) && (
          <FormControl component="fieldset" margin="dense">
            <FormLabel component="legend">Jours</FormLabel>
            <FormGroup row>
              {days.map((day) => (
                <FormControlLabel
                  key={day.value}
                  control={(
                    <Checkbox
                      checked={newEvent.recurrenceByDay?.includes(day.value) || false}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setNewEvent((prev) => {
                          const byDay = prev.recurrenceByDay || [];
                          if (checked) {
                            return { ...prev, recurrenceByDay: [...byDay, day.value] };
                          }
                          return { ...prev, recurrenceByDay: byDay.filter((d) => d !== day.value) };
                        });
                      }}
                      name={day.value}
                    />
                  )}
                  label={day.label}
                />
              ))}
            </FormGroup>
          </FormControl>
          )}

          <TextField
            label="Date de fin de récurrence"
            type="date"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            value={newEvent.recurrenceEndDate || ''}
            onChange={(e) => setNewEvent({ ...newEvent, recurrenceEndDate: e.target.value })}
          />

          <TextField
            label="Lien"
            fullWidth
            margin="dense"
            value={newEvent.link}
            onChange={(e) => setNewEvent({ ...newEvent, link: e.target.value })}
            placeholder="https://exemple.com"
          />
        </DialogContent>
        <DialogActions>
          {currentEvent && (
          <Button onClick={handleDeleteEvent} color="secondary" startIcon={<DeleteIcon />}>
            Supprimer
          </Button>
          )}
          <Button onClick={() => setOpenDialog(false)} color="secondary">Annuler</Button>
          <Button onClick={handleSaveEvent} color="primary" variant="contained">
            {currentEvent ? 'Enregistrer' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Supprimer l'événement</DialogTitle>
        <DialogContent>
          <Typography>
            Cet événement fait partie d'une série récurrente. Voulez-vous supprimer :
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={deleteOptions.deleteSeries ? 'series' : 'occurrence'}
              onChange={(e) => setDeleteOptions({ deleteSeries: e.target.value === 'series' })}
            >
              <FormControlLabel value="occurrence" control={<Radio />} label="Cette occurrence" />
              <FormControlLabel value="series" control={<Radio />} label="Toute la série" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Annuler</Button>
          <Button onClick={() => deleteEvent(deleteOptions)} color="primary">Supprimer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(MyCalendar);
