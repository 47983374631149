/* eslint-disable consistent-return */
// src/utils/authService.js

import axios from 'axios';
import { BASE_URL } from './api';

export const handleLogOut = () => {
  // Clés à supprimer lors de la déconnexion
  const keysToRemove = ['token', 'userEmail', 'userRole', 'userId', 'shownEvents', 'vehicleId', 'vehicleIdsByDate'];

  // Boucler sur les clés à supprimer et les supprimer une par une
  keysToRemove.forEach((key) => localStorage.removeItem(key));

  // Rediriger l'utilisateur vers la page de connexion
  window.location = '/';
};

export const refreshToken = async () => {
  try {
    const tokenRefresh = localStorage.getItem('refreshToken');
    const response = await axios.post(`${BASE_URL}refresh-token`, { token: tokenRefresh });

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    return response.data.token;
  }
  catch (error) {
    // Optionally, redirect to login page or show an error message
    handleLogOut();
  }
};
