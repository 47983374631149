/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Grid, Tooltip, Accordion, AccordionSummary, AccordionDetails,
  Typography, Checkbox, FormControlLabel
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Swal from 'sweetalert2';
import moment from 'moment';
import { Worker } from '@react-pdf-viewer/core';

import VehiculeImages from '../vehiculeManagement/vehiculeImages/vehiculeImages';
import LocalFactureForm from './locauxFacturesForm';
import usePageTitle from '../../hooks/usePageTitle';
import { BASE_URL } from '../../../utils/api';

// ==============================
// STYLES
// ==============================
const useStyles = {
  input: { display: 'none' },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
  localLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#333',
  },
  localButton: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: '#f5f5f5',
    padding: '16px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  textField: { marginBottom: '20px' },
  checkboxLabel: { marginLeft: '8px' },
  gridItem: { padding: '12px' },
};

// ==============================
// NOUVEAU StyledUploadField
// ==============================
function StyledUploadField({
  uploadField,
  formData,
  handleFileChange,
  userToken,
  fetchSignedUrlForViewing,
  fetchSignedUrlForDownloading,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const styles = useStyles;

  // Récupère la key du document depuis formData
  const fileKey = formData[uploadField.fieldName];
  const filename = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = filename.split('.').pop().toLowerCase();

  // Ouvrir la fenêtre de sélection de fichier
  const triggerFileSelect = () => {
    document.getElementById(uploadField.id)?.click();
  };

  return (
    <Grid item xs={12} sm={6} md={4} style={styles.gridItem} key={uploadField.id}>
      {/* Input file caché */}
      <input
        accept="image/*, application/pdf"
        style={styles.input}
        id={uploadField.id}
        type="file"
        onChange={(e) => handleFileChange(e, uploadField.fieldName)}
      />

      {/*
         ===============================
          Bloc cliquable (si fileKey existe)
         ===============================
      */}
      <div
        style={{
          ...styles.label,
          ...(isHovered ? { backgroundColor: '#e0e0e0' } : {}),
          cursor: fileKey ? 'pointer' : 'default',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="LocalCard"
        onClick={() => {
          // Si on a déjà un fichier uploadé, on ouvre l’aperçu
          if (fileKey) {
            fetchSignedUrlForViewing(fileKey);
          }
        }}
      >
        <p style={styles.localLabel} className="localLabel">
          {uploadField.label}
        </p>

        {fileKey ? (
          // =============== IL Y A DEJA UN FICHIER ===============
          <>
            {fileExtension === 'pdf' ? (
              <PictureAsPdfIcon style={{ fontSize: 40 }} />
            ) : (
              <div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <VehiculeImages
                    imageKey={fileKey}
                    key={fileKey}
                    userToken={userToken}
                  />
                </Worker>
              </div>
            )}

            <div
              style={styles.localButton}
              className="localButton"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Bouton pour remplacer le fichier */}
              <Tooltip title="Remplacer le document">
                <IconButton onClick={triggerFileSelect}>
                  <AddCircleOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              {/* Bouton pour télécharger */}
              <Tooltip title="Télécharger le document">
                <IconButton onClick={() => fetchSignedUrlForDownloading(fileKey)}>
                  <CloudDownloadIcon size="small" />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ) : (
          // =============== PAS DE FICHIER ===============
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Ajouter un document">
              <IconButton onClick={triggerFileSelect}>
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Grid>
  );
}

// ==============================
// Composant principal
// ==============================
function LocauxManagement({ userToken, userEmail, userRole }) {
  usePageTitle('Gestion des locaux');

  const [viewImageUrl, setViewImageUrl] = useState('');
  const [locaux, setLocaux] = useState([]);
  const [factures, setFactures] = useState([]);

  const [open, setOpen] = useState(false); // Modal creation/edition local
  const [isEditing, setIsEditing] = useState(false);
  const [currentLocal, setCurrentLocal] = useState(null);
  const [factureDialogOpen, setFactureDialogOpen] = useState(false);
  const [alertShown, setAlertShown] = useState(false);

  // Cases à cocher
  const [hasVerificationElementSecurite, setHasVerificationElementSecurite] = useState(false);
  const [hasVerificationPorteSectionnelle, setHasVerificationPorteSectionnelle] = useState(false);

  // Form data
  const [formData, setFormData] = useState({
    nom: '',
    adresse: '',
    date_verification_electrique: '',
    date_verification_extincteur: '',
    date_verification_elementsecurite: '',
    date_entretien_elementsecurite: '',
    date_entretien_extincteur: '',
    date_verification_porte: '',
    img_verif_electique: '',
    img_verif_ext: '',
    img_verif_element: '',
    img_entretien_elementsecurite: '',
    img_entretien_extincteur: '',
    img_verif_porte: '',
    img_q18: '',
    observations: '',
  });

  const [errors, setErrors] = useState({
    nom: '',
    adresse: '',
    date_verification_electrique: '',
    date_verification_extincteur: '',
    date_verification_elementsecurite: '',
    date_entretien_extincteur: '',
    date_verification_porte: '',
  });

  const headers = { Authorization: `Bearer ${userToken}` };

  // ------------------------------
  // (A) GESTION DU TRI
  // ------------------------------
  // On stocke la clé (colonne) et la direction ('asc' ou 'desc')
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Fonction de tri appelée avant l'affichage du tableau
  const sortLocaux = (list) => {
    const { key, direction } = sortConfig;
    if (!key) return list;

    return [...list].sort((a, b) => {
      const dateA = a[key] ? moment(a[key]) : null;
      const dateB = b[key] ? moment(b[key]) : null;

      // Si date invalide, on le met en bas
      if (!dateA || !dateA.isValid()) return 1;
      if (!dateB || !dateB.isValid()) return -1;

      if (dateA.isBefore(dateB)) return direction === 'asc' ? -1 : 1;
      if (dateA.isAfter(dateB)) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Clic sur l'en-tête de colonne
  const handleSort = (key) => {
    if (sortConfig.key === key) {
      // Inverse le sens
      setSortConfig((prev) => ({
        key,
        direction: prev.direction === 'asc' ? 'desc' : 'asc',
      }));
    }
    else {
      // Nouvelle colonne => direction ascendante par défaut
      setSortConfig({ key, direction: 'asc' });
    }
  };

  // ------------------------------
  // 1) CHARGEMENT DES LOCAUX
  // ------------------------------
  const fetchLocaux = async () => {
    try {
      const response = await axios.get(`${BASE_URL}locaux`, { headers });
      setLocaux(response.data.locaux);
    }
    catch (error) {
      console.error('Failed to fetch locaux', error);
    }
  };

  useEffect(() => {
    fetchLocaux();
  }, []);

  // ------------------------------
  // 2) VALIDATION
  // ------------------------------
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.nom.trim()) {
      newErrors.nom = 'Le nom est requis';
      isValid = false;
    }
    if (!formData.adresse.trim()) {
      newErrors.adresse = 'L\'adresse est requise';
      isValid = false;
    }
    if (!formData.date_verification_electrique.trim()) {
      newErrors.date_verification_electrique = 'La date de vérification électrique est requise';
      isValid = false;
    }
    if (!formData.date_verification_extincteur.trim()) {
      newErrors.date_verification_extincteur = 'La date de vérification extincteur est requise';
      isValid = false;
    }
    if (!formData.date_entretien_extincteur.trim()) {
      newErrors.date_entretien_extincteur = 'Entretien extincteur requis';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // ------------------------------
  // 3) OUVERTURE / FERMETURE MODALE
  // ------------------------------
  const handleOpen = (local = null) => {
    setIsEditing(!!local);
    setCurrentLocal(local);

    if (local) {
      const isoToShort = (d) => (d ? d.split('T')[0] : '');
      setFormData({
        ...local,
        date_verification_electrique: isoToShort(local.date_verification_electrique),
        date_verification_extincteur: isoToShort(local.date_verification_extincteur),
        date_verification_elementsecurite: isoToShort(local.date_verification_elementsecurite),
        date_entretien_elementsecurite: isoToShort(local.date_entretien_elementsecurite),
        date_entretien_extincteur: isoToShort(local.date_entretien_extincteur),
        date_verification_porte: isoToShort(local.date_verification_porte),
      });
      setHasVerificationElementSecurite(
        !!local.date_verification_elementsecurite || !!local.date_entretien_elementsecurite
      );
      setHasVerificationPorteSectionnelle(!!local.date_verification_porte);
    }
    else {
      setFormData({
        nom: '',
        adresse: '',
        date_verification_electrique: '',
        date_verification_extincteur: '',
        date_verification_elementsecurite: '',
        date_entretien_elementsecurite: '',
        date_entretien_extincteur: '',
        date_verification_porte: '',
        img_verif_electique: '',
        img_verif_ext: '',
        img_verif_element: '',
        img_entretien_elementsecurite: '',
        img_entretien_extincteur: '',
        img_verif_porte: '',
        img_q18: '',
        observations: '',
      });
      setHasVerificationElementSecurite(false);
      setHasVerificationPorteSectionnelle(false);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentLocal(null);
  };

  // ------------------------------
  // SAUVEGARDE (POST / PUT)
  // ------------------------------
  const handleSave = async () => {
    if (!validateForm()) return;

    const dataToSave = {
      ...formData,
      date_verification_elementsecurite: hasVerificationElementSecurite ? formData.date_verification_elementsecurite : null,
      date_entretien_elementsecurite: hasVerificationElementSecurite ? formData.date_entretien_elementsecurite : null,
      date_verification_porte: hasVerificationPorteSectionnelle ? formData.date_verification_porte : null,
    };

    try {
      if (isEditing) {
        await axios.put(`${BASE_URL}locaux/${currentLocal.id}`, dataToSave, { headers });
        Swal.fire('Succès', 'Local modifié !', 'success');
      }
      else {
        await axios.post(`${BASE_URL}locaux`, dataToSave, { headers });
        Swal.fire('Succès', 'Local ajouté !', 'success');
      }
      fetchLocaux();
      handleClose();
    }
    catch (error) {
      console.error('Erreur lors de la sauvegarde', error);
      Swal.fire('Erreur', 'Une erreur est survenue lors de la sauvegarde.', 'error');
    }
  };

  // ------------------------------
  // SUPPRESSION
  // ------------------------------
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce local ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non',
    });
    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}locaux/${id}`, { headers });
        fetchLocaux();
        Swal.fire('Local supprimé !', '', 'success');
      }
      catch (error) {
        console.error('Failed to delete local', error);
        Swal.fire('Erreur', 'Une erreur est survenue lors de la suppression du local.', 'error');
      }
    }
  };

  // ------------------------------
  // 4) UPLOAD FICHIERS
  // ------------------------------
  const handleFileChange = async (e, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxFileSize = 2 * 1024 * 1024;
    if (file.size > maxFileSize) {
      console.error('Fichier trop volumineux.');
      return;
    }
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Type de fichier invalide.');
      return;
    }

    try {
      const resp = await axios.post(`${BASE_URL}locaux/presigned`, {
        name: file.name,
        type: file.type,
      }, { headers });

      const { url, fields } = resp.data;

      const uploadData = new FormData();
      Object.entries(fields).forEach(([k, v]) => uploadData.append(k, v));
      uploadData.append('file', file);

      await axios.post(url, uploadData, {
        headers: { 'Content-Type': file.type },
      });

      const uploadedFileUrl = `${fields.key}`;
      setFormData((prev) => ({ ...prev, [fieldName]: uploadedFileUrl }));
    }
    catch (err) {
      console.error('Error uploading file:', err);
    }
  };

  // ------------------------------
  // 5) VISUALISATION / TÉLÉCHARGEMENT
  // ------------------------------
  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}locaux/get-signed-url`, { key }, { headers });
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const downloadFile = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const contentType = response.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([response.data], { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}locaux/get-signed-url`, { key }, { headers });
      downloadFile(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      // Ouvre l'aperçu dans un nouvel onglet
      window.open(viewImageUrl, '_blank');
    }
  }, [viewImageUrl]);

  // ------------------------------
  // 6) COLORATION DES DATES
  // ------------------------------
  const calculateControlColor = (controlDate) => {
    if (!controlDate) return 'inherit';
    const currentDate = moment();
    const expirationDate = moment(controlDate).add(1, 'years');
    const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

    if (daysUntilExpiration < 0) return 'red';
    if (daysUntilExpiration <= 30) return 'red';
    if (daysUntilExpiration <= 90) return 'orange';
    return 'inherit';
  };

  // ------------------------------
  // 7) CRÉATION DE TÂCHES / ALERTES
  // ------------------------------
  const checkIfTaskExists = async (localName, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;
      return tasks.some((task) => task.field_name === `${type} - ${localName}`);
    }
    catch (error) {
      console.error('Erreur lors de la vérification des tâches:', error);
      return false;
    }
  };

  const createTask = async (local, type, expirationDate, customComment) => {
    const fieldName = `${type} - ${local.nom}`;
    const comment = customComment || `${type} du local ${local.nom} expire bientôt.`;

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      vehicule_code: local.nom,
      role_name: 'Administrateur',
      comment,
    };

    const taskExists = await checkIfTaskExists(local.nom, type);
    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Erreur lors de la création de la tâche:', error.response?.data || error.message);
        Swal.fire('Erreur', `Erreur lors de la création de la tâche: ${error.response?.data?.message || error.message}`, 'error');
      }
    }
  };

  useEffect(() => {
    const checkControlDates = async () => {
      const alertMessages = [];
      for (const local of locaux) {
        const checks = [
          { date: local.date_verification_electrique, type: 'Vérification électrique' },
          { date: local.date_verification_extincteur, type: 'Vérification extincteur' },
          { date: local.date_verification_elementsecurite, type: 'Vérification éléments sécurité' },
          { date: local.date_entretien_elementsecurite, type: 'Entretien éléments sécurité' },
          { date: local.date_entretien_extincteur, type: 'Entretien extincteur' },
          { date: local.date_verification_porte, type: 'Vérification porte' },
        ];

        for (const check of checks) {
          if (check.date && moment(check.date, moment.ISO_8601, true).isValid()) {
            const controlDate = moment(check.date).add(1, 'years');
            const daysUntilExpiration = controlDate.diff(moment(), 'days');

            if (daysUntilExpiration < 0) {
              alertMessages.push(`${check.type} du local ${local.nom} est expiré.`);
              await createTask(local, check.type, controlDate.toDate(), `${check.type} du local ${local.nom} est expiré.`);
            }
            else if (daysUntilExpiration <= 30) {
              alertMessages.push(`${check.type} du local ${local.nom} expire dans moins d'un mois.`);
              await createTask(local, check.type, controlDate.toDate(), `${check.type} du local ${local.nom} expire dans moins d'un mois.`);
            }
          }
        }
      }

      if (alertMessages.length > 0 && !alertShown) {
        Swal.fire({
          title: 'Alertes Contrôles Locaux',
          html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        setAlertShown(true);
      }
    };

    if (locaux.length > 0 && !alertShown) {
      checkControlDates();
    }
  }, [locaux, alertShown]);

  // ------------------------------
  // 8) RÔLES / AUTORISATION
  // ------------------------------
  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  // ------------------------------
  // 9) FACTURES
  // ------------------------------
  const fetchFacturesByLocal = async (localId) => {
    try {
      const response = await axios.get(`${BASE_URL}locaux/${localId}/factures`, { headers });
      setFactures(response.data.factures || []);
    }
    catch (error) {
      console.error('Erreur récupération factures:', error);
    }
  };

  const handleOpenFactureDialog = (local) => {
    setCurrentLocal(local);
    fetchFacturesByLocal(local.id);
    setFactureDialogOpen(true);
  };

  const handleCloseFactureDialog = () => {
    setFactureDialogOpen(false);
    setCurrentLocal(null);
  };

  const handleAddFacture = async (factureData) => {
    try {
      await axios.post(`${BASE_URL}locaux/${currentLocal.id}/factures`, factureData, { headers });
      Swal.fire('Facture ajoutée !', '', 'success');
      fetchFacturesByLocal(currentLocal.id);
    }
    catch (error) {
      Swal.fire('Erreur', 'Erreur lors de l\'ajout de la facture.', 'error');
    }
  };

  const handleDeleteFacture = async (factureId) => {
    const result = await Swal.fire({
      title: 'Confirmer la suppression',
      text: 'Supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
    });
    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}locaux/${currentLocal.id}/factures`, {
          headers,
          data: { factureId },
        });
        Swal.fire('Supprimée!', 'Facture supprimée avec succès.', 'success');
        fetchFacturesByLocal(currentLocal.id);
      }
      catch (error) {
        console.error('Erreur lors de la suppression de la facture :', error);
        Swal.fire('Erreur', 'Impossible de supprimer la facture.', 'error');
      }
    }
  };

  const groupFacturesByMonth = (factures) => factures.reduce((acc, f) => {
    const date = new Date(f.date);
    const monthYear = date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
    if (!acc[monthYear]) acc[monthYear] = [];
    acc[monthYear].push(f);
    return acc;
  }, {});
  const facturesGroupedByMonth = groupFacturesByMonth(factures);

  // ------------------------------
  // 10) HISTORIQUE DOCUMENTS
  // ------------------------------
  const [historicalDocs, setHistoricalDocs] = useState([]);
  const [openHistoricModal, setOpenHistoricModal] = useState(false);

  const [docFile, setDocFile] = useState(null);
  const [docTitle, setDocTitle] = useState('');
  const [docObservations, setDocObservations] = useState('');

  const fetchLocalDocuments = async (localId) => {
    try {
      const res = await axios.get(`${BASE_URL}locaux/${localId}/documents`, { headers });
      setHistoricalDocs(res.data.documents || []);
    }
    catch (err) {
      console.error('Erreur récupération documents:', err);
    }
  };

  const handleOpenHistoricModal = (local) => {
    setCurrentLocal(local);
    fetchLocalDocuments(local.id);
    setOpenHistoricModal(true);
  };

  const handleCloseHistoricModal = () => {
    setOpenHistoricModal(false);
    setCurrentLocal(null);
    setHistoricalDocs([]);
    setDocFile(null);
    setDocTitle('');
    setDocObservations('');
  };

  const handleAddHistoricalDocument = async () => {
    if (!currentLocal) return;
    if (!docFile) {
      Swal.fire('Erreur', 'Veuillez choisir un fichier.', 'error');
      return;
    }
    if (!docTitle.trim()) {
      Swal.fire('Erreur', 'Veuillez saisir un titre.', 'error');
      return;
    }

    try {
      const resp = await axios.post(`${BASE_URL}locaux/presigned`, {
        name: docFile.name,
        type: docFile.type,
      }, { headers });
      const { url, fields } = resp.data;

      const formData = new FormData();
      Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
      formData.append('file', docFile);
      await axios.post(url, formData);

      await axios.post(`${BASE_URL}locaux/${currentLocal.id}/documents`, {
        document_type: docTitle,
        document_key: fields.key,
        observations: docObservations,
      }, { headers });

      Swal.fire('Succès', 'Document historique ajouté!', 'success');
      setDocFile(null);
      setDocTitle('');
      setDocObservations('');
      fetchLocalDocuments(currentLocal.id);
    }
    catch (error) {
      console.error(error);
      Swal.fire('Erreur', 'Impossible d’ajouter ce document historique.', 'error');
    }
  };

  const handleDeleteHistoricalDocument = async (docId) => {
    if (!currentLocal) return;
    const result = await Swal.fire({
      title: 'Supprimer ce document historique ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non',
    });
    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}locaux/${currentLocal.id}/documents/${docId}`, { headers });
        Swal.fire('Supprimé!', 'Document historique supprimé.', 'success');
        fetchLocalDocuments(currentLocal.id);
      }
      catch (err) {
        console.error(err);
        Swal.fire('Erreur', 'Impossible de supprimer le document.', 'error');
      }
    }
  };

  // ------------------------------
  // FORMAT DE DATE SIMPLIFIÉ
  // ------------------------------
  const formatDate = (dateString) => {
    if (!dateString) return '—';
    return moment(dateString).format('DD/MM/YYYY');
  };

  const styles = useStyles;

  // On applique le tri à nos "locaux" avant l'affichage
  const sortedLocaux = sortLocaux(locaux);

  return (
    <div>
      {isAuthorizedRole() && (
        <Tooltip title="Ajouter un local">
          <IconButton color="black" onClick={() => handleOpen(null)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* TABLE DES LOCAUX */}
      <TableContainer component={Paper}>
        <Table aria-label="table-locaux">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Nom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Adresse</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Observations</TableCell>

              {/* Tri sur clic : date_verification_electrique */}
              <TableCell
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleSort('date_verification_electrique')}
              >
                Date vérif. électrique
              </TableCell>

              {/* Tri sur clic : date_verification_extincteur */}
              <TableCell
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleSort('date_verification_extincteur')}
              >
                Date vérif. extincteur
              </TableCell>

              {/* Tri sur clic : date_verification_elementsecurite */}
              <TableCell
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleSort('date_verification_elementsecurite')}
              >
                Date vérif. éléments sécurité
              </TableCell>

              {/* Tri sur clic : date_entretien_elementsecurite */}
              <TableCell
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleSort('date_entretien_elementsecurite')}
              >
                Date entretien éléments sécurité
              </TableCell>

              {/* Tri sur clic : date_entretien_extincteur */}
              <TableCell
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleSort('date_entretien_extincteur')}
              >
                Date entretien extincteur
              </TableCell>

              {/* Tri sur clic : date_verification_porte */}
              <TableCell
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => handleSort('date_verification_porte')}
              >
                Date vérif. porte
              </TableCell>

              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedLocaux.map((local) => (
              <TableRow key={local.id}>
                <TableCell style={{ textAlign: 'center' }}>{local.nom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.adresse}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.observations}</TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    color: calculateControlColor(local.date_verification_electrique),
                  }}
                >
                  {formatDate(local.date_verification_electrique)}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    color: calculateControlColor(local.date_verification_extincteur),
                  }}
                >
                  {formatDate(local.date_verification_extincteur)}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    color: calculateControlColor(local.date_verification_elementsecurite),
                  }}
                >
                  {local.date_verification_elementsecurite
                    ? formatDate(local.date_verification_elementsecurite)
                    : ''}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    color: calculateControlColor(local.date_entretien_elementsecurite),
                  }}
                >
                  {local.date_entretien_elementsecurite
                    ? formatDate(local.date_entretien_elementsecurite)
                    : ''}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    color: calculateControlColor(local.date_entretien_extincteur),
                  }}
                >
                  {formatDate(local.date_entretien_extincteur)}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    color: calculateControlColor(local.date_verification_porte),
                  }}
                >
                  {local.date_verification_porte
                    ? formatDate(local.date_verification_porte)
                    : ''}
                </TableCell>

                <TableCell style={{ textAlign: 'center' }}>
                  {isAuthorizedRole() && (
                    <>
                      <Tooltip title="Modifier le local">
                        <IconButton onClick={() => handleOpen(local)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Supprimer le local">
                        <IconButton onClick={() => handleDelete(local.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Ajouter/Consulter une facture">
                        <IconButton onClick={() => handleOpenFactureDialog(local)}>
                          <AddCircleOutlineIcon style={{ color: 'grey' }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Historique de documents">
                        <IconButton onClick={() => handleOpenHistoricModal(local)}>
                          <PictureAsPdfIcon style={{ color: 'red' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* MODAL CREER / MODIFIER LOCAL */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle style={styles.dialogTitle}>
          {isEditing ? 'Modifier Local' : 'Ajouter Local'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ paddingTop: '10px' }}>
            {[
              { name: 'nom', label: 'Nom' },
              { name: 'adresse', label: 'Adresse' },
              { name: 'observations', label: 'Observations' },
              { name: 'date_verification_electrique', label: 'Date vérif. électrique', type: 'date' },
              { name: 'date_verification_extincteur', label: 'Date vérif. extincteur', type: 'date' },
              { name: 'date_entretien_extincteur', label: 'Date entretien extincteur', type: 'date' },
            ].map((field) => (
              <Grid item xs={12} sm={6} key={field.name}>
                <TextField
                  type={field.type || 'text'}
                  name={field.name}
                  label={field.label}
                  value={formData[field.name]}
                  onChange={(e) => setFormData({ ...formData, [field.name]: e.target.value })}
                  InputLabelProps={field.type === 'date' ? { shrink: true } : undefined}
                  fullWidth
                  style={styles.textField}
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name] || ''}
                />
              </Grid>
            ))}

            {/* Checkbox "Vérification des éléments de sécurité" */}
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={hasVerificationElementSecurite}
                    onChange={() => setHasVerificationElementSecurite(!hasVerificationElementSecurite)}
                  />
                )}
                label="Soumis à une vérification des éléments de sécurité"
              />
            </Grid>
            {hasVerificationElementSecurite && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    name="date_verification_elementsecurite"
                    label="Date vérif. éléments sécurité"
                    value={formData.date_verification_elementsecurite}
                    onChange={(e) => setFormData({ ...formData, date_verification_elementsecurite: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    style={styles.textField}
                    error={Boolean(errors.date_verification_elementsecurite)}
                    helperText={errors.date_verification_elementsecurite || ''}
                  />
                </Grid>
                <StyledUploadField
                  uploadField={{
                    id: 'verification-elementsecurite-input',
                    label: 'Vérification éléments sécurité',
                    fieldName: 'img_verif_element',
                  }}
                  formData={formData}
                  handleFileChange={handleFileChange}
                  userToken={userToken}
                  fetchSignedUrlForViewing={fetchSignedUrlForViewing}
                  fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
                />

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    name="date_entretien_elementsecurite"
                    label="Date entretien éléments sécurité"
                    value={formData.date_entretien_elementsecurite}
                    onChange={(e) => setFormData({ ...formData, date_entretien_elementsecurite: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    style={styles.textField}
                  />
                </Grid>
                <StyledUploadField
                  uploadField={{
                    id: 'entretien-elementsecurite-input',
                    label: 'Entretien éléments sécurité',
                    fieldName: 'img_entretien_elementsecurite',
                  }}
                  formData={formData}
                  handleFileChange={handleFileChange}
                  userToken={userToken}
                  fetchSignedUrlForViewing={fetchSignedUrlForViewing}
                  fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
                />
              </>
            )}

            {/* Checkbox "Porte sectionnelle" */}
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={hasVerificationPorteSectionnelle}
                    onChange={() => setHasVerificationPorteSectionnelle(!hasVerificationPorteSectionnelle)}
                  />
                )}
                label="Soumis à une vérification de la porte sectionnelle"
              />
            </Grid>
            {hasVerificationPorteSectionnelle && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    name="date_verification_porte"
                    label="Date vérif. porte sectionnelle"
                    value={formData.date_verification_porte}
                    onChange={(e) => setFormData({ ...formData, date_verification_porte: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    style={styles.textField}
                    error={Boolean(errors.date_verification_porte)}
                    helperText={errors.date_verification_porte || ''}
                  />
                </Grid>
                <StyledUploadField
                  uploadField={{
                    id: 'verification-porte-input',
                    label: 'Vérification porte sectionnelle',
                    fieldName: 'img_verif_porte',
                  }}
                  formData={formData}
                  handleFileChange={handleFileChange}
                  userToken={userToken}
                  fetchSignedUrlForViewing={fetchSignedUrlForViewing}
                  fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
                />
              </>
            )}

            {/* Uploads non conditionnels */}
            <StyledUploadField
              uploadField={{
                id: 'verification-electrique-input',
                label: 'Vérification électrique',
                fieldName: 'img_verif_electique',
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
            <StyledUploadField
              uploadField={{
                id: 'verification-extincteur-input',
                label: 'Vérification extincteur',
                fieldName: 'img_verif_ext',
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
            <StyledUploadField
              uploadField={{
                id: 'entretien-extincteur-input',
                label: 'Entretien extincteur',
                fieldName: 'img_entretien_extincteur',
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
            <StyledUploadField
              uploadField={{
                id: 'q18_input',
                label: 'Q18',
                fieldName: 'img_q18',
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Annuler</Button>
          <Button onClick={handleSave} color="primary">Enregistrer</Button>
        </DialogActions>
      </Dialog>

      {/* MODAL FACTURES */}
      <Dialog open={factureDialogOpen} onClose={handleCloseFactureDialog} fullWidth maxWidth="md">
        <DialogTitle>Gestion des factures pour {currentLocal?.nom}</DialogTitle>
        <DialogContent>
          <LocalFactureForm onSaveFacture={handleAddFacture} />

          <h3 className="addModalTitle">Charges existantes</h3>
          {Object.keys(facturesGroupedByMonth).map((monthYear) => (
            <Accordion key={monthYear}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{monthYear}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Details</TableCell>
                        <TableCell>Commentaires</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {facturesGroupedByMonth[monthYear].map((facture, index) => (
                        <TableRow key={index}>
                          <TableCell>{moment(facture.date).format('DD/MM/YYYY')}</TableCell>
                          <TableCell>{facture.type}</TableCell>
                          <TableCell>
                            {Object.keys(facture.details).map((key) => (
                              facture.details[key] && (
                                <div key={key}>
                                  <strong>{key}:</strong> {facture.details[key]}
                                </div>
                              )
                            ))}
                          </TableCell>
                          <TableCell>{facture.commentaires}</TableCell>
                          <TableCell>
                            <Tooltip title="Supprimer la facture">
                              <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFactureDialog} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>

      {/* MODAL HISTORIQUE DE DOCUMENTS */}
      <Dialog open={openHistoricModal} onClose={handleCloseHistoricModal} fullWidth maxWidth="md">
        <DialogTitle>Historique de documents pour {currentLocal?.nom}</DialogTitle>
        <DialogContent>
          {/* Formulaire d’upload d’un nouveau document */}
          <TextField
            label="Titre du document"
            value={docTitle}
            onChange={(e) => setDocTitle(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Observations / Année"
            value={docObservations}
            onChange={(e) => setDocObservations(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
          <div style={{ marginBottom: '1rem' }}>
            <Button variant="outlined" component="label">
              Choisir un fichier
              <input
                type="file"
                hidden
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setDocFile(e.target.files[0]);
                  }
                }}
              />
            </Button>
            {docFile && <span style={{ marginLeft: '8px' }}>{docFile.name}</span>}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddHistoricalDocument}
            disabled={!docFile || !docTitle}
          >
            Ajouter dans l'historique
          </Button>

          <hr style={{ margin: '1rem 0' }} />
          <Typography variant="h6">Documents historiques existants</Typography>
          {historicalDocs.length === 0 ? (
            <Typography>Aucun document historique</Typography>
          ) : (
            historicalDocs.map((doc) => (
              <Paper key={doc.id} style={{ margin: '1rem 0', padding: '1rem' }}>
                <Typography variant="subtitle1">
                  <strong>Titre:</strong> {doc.document_type || '—'}
                </Typography>
                <Typography variant="body2">
                  <strong>Observations:</strong> {doc.observations || '—'}
                </Typography>
                <div style={{ marginTop: '0.5rem' }}>
                  <Tooltip title="Visualiser">
                    <IconButton onClick={() => fetchSignedUrlForViewing(doc.document_key)}>
                      <ImageSearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Télécharger">
                    <IconButton onClick={() => fetchSignedUrlForDownloading(doc.document_key)}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Supprimer">
                    <IconButton onClick={() => handleDeleteHistoricalDocument(doc.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Paper>
            ))
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistoricModal} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(LocauxManagement);
