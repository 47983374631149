/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './updateVehiculeModalStyles.scss';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import QRCode from 'qrcode';
import { Worker } from '@react-pdf-viewer/core';
import { BASE_URL } from '../../../../utils/api';
import VehiculeImages from '../vehiculeImages/vehiculeImages';
import Logo from '../../../../images/logoJussieu.png';

function UpdateVehiculeModal({
  currentVehicule, onUpdateVehicule, userToken, userEmail,
}) {
  const [responsables, setResponsables] = useState([]);
  const [errors, setErrors] = useState({});
  const [companies, setCompanies] = useState([]);
  const [viewImageUrl, setViewImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [factures, setFactures] = useState([]);
  const [facturesDate, setFacturesDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [facturesType, setFacturesType] = useState('');
  const [factureSousType, setFactureSousType] = useState('');
  const [sousTypeEntretien, setSousTypeEntretien] = useState('');
  const typesEntretien = ['Climatisation', 'Disques AR', 'Disques AV', 'Eclairage', 'Liquides', 'Plaquettes AR', 'Plaquettes AV', 'Pneus AR', 'Pneus AV', 'Vidange/Révision'];
  const [sousTypeReparation, setSousTypeReparation] = useState('');
  const typesReparation = ['Alternateur', 'Amortisseur AR', 'Amortisseur AV', 'Batterie', 'Boîte de vitesse', 'Courroie', 'Démarreur', 'Direction', 'Echappement', 'Embrayage', 'Moteur', 'Suspension'];
  const [facturesPrices, setFacturesPrices] = useState('');
  const [facturesKm, setFacturesKm] = useState('');
  const [facturesNumero, setFacturesNumero] = useState('');
  const [facturesCommentaires, setFacturesCommentaires] = useState('');
  const [showFacturesModal, setShowFacturesModal] = useState(false);
  const [showAddFacturesModal, setShowAddFacturesModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [showAlertsOnly, setShowAlertsOnly] = useState(true);
  const [filterType, setFilterType] = useState('');
  const [filterSousType, setFilterSousType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showCounterVisitDate, setShowCounterVisitDate] = useState(!!currentVehicule.counter_visit_date);
  const [counterVisitDate, setCounterVisitDate] = useState(currentVehicule.counter_visit_date || '');

  const [logoDataURL, setLogoDataURL] = useState('');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const alertThresholds = {
    'vidange/révision': 20000,
    'pneus av': 40000,
    'pneus ar': 40000,
    'plaquettes av': 20000,
    'plaquettes ar': 25000,
    'disques av': 40000,
    'disques ar': 50000,
  };

  const [formData, setFormData] = useState({
    societe: currentVehicule.societe,
    code: currentVehicule.code,
    immat: currentVehicule.immat,
    marque: currentVehicule.marque,
    type: currentVehicule.type,
    categorie: currentVehicule.categorie,
    entry_date: currentVehicule.entry_date,
    exit_date: currentVehicule.exit_date,
    technical_control_date: currentVehicule.technical_control_date,
    pneu_dim: currentVehicule.pneu_dim,
    pneu_secour: currentVehicule.pneu_secour,
    second_key: currentVehicule.second_key,
    km: currentVehicule.km,
    responsable_id: currentVehicule.responsable_id,
    phone_number: currentVehicule.phone_number,
    carte_grise: currentVehicule.carte_grise,
    assurance: currentVehicule.assurance,
    utac: currentVehicule.utac,
    contrat_location: currentVehicule.contrat_location,
    ams: currentVehicule.ams,
    observation: currentVehicule.observation,
    imei: currentVehicule.imei,
    carte_carburant: currentVehicule.carte_carburant,
    telepeage: currentVehicule.telepeage,
    pinpuk: currentVehicule.pinpuk,
    code_carburant: currentVehicule.code_carburant,
    technical_control: currentVehicule.technical_control,
    qr_code: currentVehicule.qr_code,
    factures: currentVehicule.factures,
    counter_visit_date: currentVehicule.counter_visit_date || '',
    location_date: currentVehicule.location_date,
  });

  useEffect(() => {
    setFormData({
      societe: currentVehicule.societe,
      code: currentVehicule.code,
      immat: currentVehicule.immat,
      marque: currentVehicule.marque,
      type: currentVehicule.type,
      categorie: currentVehicule.categorie,
      entry_date: moment(currentVehicule.entry_date).format('YYYY-MM-DD'),
      exit_date: currentVehicule.exit_date ? moment(currentVehicule.exit_date).format('YYYY-MM-DD') : null,
      technical_control_date: moment(currentVehicule.technical_control_date).format('YYYY-MM-DD'),
      pneu_dim: currentVehicule.pneu_dim,
      pneu_secour: currentVehicule.pneu_secour,
      second_key: currentVehicule.second_key,
      km: currentVehicule.km,
      responsable_id: currentVehicule.responsable_id,
      phone_number: currentVehicule.phone_number,
      carte_grise: currentVehicule.carte_grise,
      assurance: currentVehicule.assurance,
      utac: currentVehicule.utac,
      contrat_location: currentVehicule.contrat_location,
      ams: currentVehicule.ams,
      observation: currentVehicule.observation,
      imei: currentVehicule.imei,
      carte_carburant: currentVehicule.carte_carburant,
      telepeage: currentVehicule.telepeage,
      pinpuk: currentVehicule.pinpuk,
      code_carburant: currentVehicule.code_carburant,
      technical_control: currentVehicule.technical_control,
      qr_code: currentVehicule.qr_code,
      factures: currentVehicule.factures,
      counter_visit_date: moment(currentVehicule.counter_visit_date).format('YYYY-MM-DD'),
      location_date: currentVehicule.location_date,
    });
    setShowCounterVisitDate(!!currentVehicule.counter_visit_date);
    setCounterVisitDate(moment(currentVehicule.counter_visit_date).format('YYYY-MM-DD'));
  }, [currentVehicule]);

  const getLastFactureBySousType = (facturesArray, sous_type) => {
    const filteredFactures = facturesArray.filter((facture) => facture.sous_type === sous_type);
    if (filteredFactures.length === 0) {
      return null;
    }
    return filteredFactures.reduce((latest, current) => {
      const latestKm = parseInt(latest.km, 10);
      const currentKm = parseInt(current.km, 10);
      return currentKm > latestKm ? current : latest;
    });
  };

  const getBase64Image = (imgUrl, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', imgUrl);
    xhr.responseType = 'blob';
    xhr.send();
  };

  useEffect(() => {
    getBase64Image(Logo, (base64Img) => {
      setLogoDataURL(base64Img);
    });
  }, []);

  const normalizeSousType = (sous_type) => sous_type.trim().toLowerCase();

  const getAlertState = (sous_type, lastKm, currentKm) => {
    const normalizedSousType = normalizeSousType(sous_type);

    let threshold = alertThresholds[normalizedSousType];

    if (!threshold) return null;

    threshold = parseInt(threshold, 10);

    const distanceSinceLast = currentKm - lastKm;

    if (distanceSinceLast >= threshold) {
      return 'red';
    }
    if (distanceSinceLast >= threshold * 0.75) {
      return 'orange';
    }
    return 'none';
  };

  useEffect(() => {
    const fetchResponsables = async () => {
      try {
        const response = await axios.get(`${BASE_URL}staff`, { headers });
        const filteredAndSortedStaff = response.data.staff
          .filter((staff) => !staff.exitdate)
          .sort((a, b) => a.name.localeCompare(b.name));
        setResponsables(filteredAndSortedStaff);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des membres du staff', error);
      }
    };

    fetchResponsables();
  }, []);

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCompanies();
  }, []);

  const checkAndCreateTasks = async (facturesArray) => {
    for (const [index, facture] of facturesArray.entries()) {
      if (!facture.task_created) {
        const lastFacture = getLastFactureBySousType(facturesArray, facture.sous_type);
        const lastKm = lastFacture ? parseInt(lastFacture.km, 10) : 0;
        const alertState = getAlertState(facture.sous_type, lastKm, formData.km);

        if (alertState === 'red' || alertState === 'orange') {
          const taskData = {
            procedure_id: 0,
            status: 'à faire',
            author_name: userEmail,
            field_name: `${facture.type} - ${facture.sous_type}`,
            vehicule_code: formData.code,
            role_name: 'Garagiste',
            comment: `Entretien à réaliser : ${facture.sous_type}. Kilométrage actuel : ${formData.km} km. Kilométrage de la dernière facture : ${facture.km} km.`,
          };
          try {
            await axios.post(`${BASE_URL}tasks`, taskData, { headers });

            const updatedFacture = { ...facture, task_created: true };
            await axios.put(`${BASE_URL}vehicules/${currentVehicule.id}/factures/${index}`, updatedFacture, { headers });
          }
          catch (error) {
            console.error('Error creating task:', error);
          }
        }
      }
    }
  };

  const handleCounterVisitChange = (e) => {
    const isChecked = e.target.checked;
    setShowCounterVisitDate(isChecked);
    if (!isChecked) {
      setCounterVisitDate('');
      setFormData((prevState) => ({
        ...prevState,
        counter_visit_date: null,
      }));
    }
  };

  const fetchFactures = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules/${currentVehicule.id}/factures`, { headers });
      const facturesData = response.data.factures;
      setFactures(facturesData);
      checkAndCreateTasks(facturesData);
    }
    catch (error) {
      console.error('Error fetching factures:', error);
    }
  };

  const handleCloseAddFacturesModal = () => {
    setShowAddFacturesModal(false);
    setEditIndex(null);
    setFacturesDate(format(new Date(), 'yyyy-MM-dd'));
    setFacturesType('');
    setFactureSousType('');
    setFacturesPrices('');
    setFacturesKm('');
    setFacturesCommentaires('');
  };

  const sortFactures = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleAddFactures = async () => {
    // Validations
    if (!facturesDate || !facturesType || !facturesPrices || !facturesKm) {
      Swal.fire({
        title: 'Erreur',
        text: 'Veuillez remplir tous les champs obligatoires : date, type, prix et kilométrage.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    const newFacture = {
      date: format(new Date(facturesDate), 'dd/MM/yyyy'),
      type: facturesType,
      sous_type: factureSousType,
      numero: facturesNumero,
      Prices: parseFloat(facturesPrices).toFixed(2),
      km: parseInt(facturesKm, 10),
      commentaires: facturesCommentaires,
      task_created: false,
    };

    const confirmText = editIndex !== null
      ? 'Êtes-vous sûr de vouloir modifier cette facture ?'
      : 'Êtes-vous sûr de vouloir ajouter cette facture ?';

    const result = await Swal.fire({
      title: 'Confirmer',
      text: confirmText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    });

    if (result.isConfirmed) {
      try {
        if (editIndex !== null) {
          await axios.put(`${BASE_URL}vehicules/${currentVehicule.id}/factures/${editIndex}`, newFacture, { headers });
        }
        else {
          await axios.post(`${BASE_URL}vehicules/${currentVehicule.id}/factures`, newFacture, { headers });
        }

        const updatedResponse = await axios.get(`${BASE_URL}vehicules/${currentVehicule.id}/factures`, { headers });
        setFactures(updatedResponse.data.factures);
        fetchFactures();

        Swal.fire({
          title: 'Succès',
          text: 'Facture enregistrée avec succès.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      catch (error) {
        console.error('Error saving facture:', error);
        Swal.fire({
          title: 'Erreur',
          text: "Une erreur est survenue lors de l'enregistrement de la facture.",
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

      handleCloseAddFacturesModal();
    }
  };

  const handleEditFacture = (index) => {
    const factureToEdit = factures[index];
    const dateParts = factureToEdit.date.split('/');
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    setFacturesDate(formattedDate);
    setFacturesType(factureToEdit.type);
    setFacturesNumero(factureToEdit.numero || '');
    setFactureSousType(factureToEdit.sous_type);
    setFacturesPrices(factureToEdit.Prices);
    setFacturesKm(factureToEdit.km);
    setFacturesCommentaires(factureToEdit.commentaires);
    setShowAddFacturesModal(true);
    setEditIndex(index);
  };

  const handleDeleteFacture = async (index) => {
    const result = await Swal.fire({
      title: 'Confirmer',
      text: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}vehicules/${currentVehicule.id}/factures/${index}`, { headers });
        const response = await axios.get(`${BASE_URL}vehicules/${currentVehicule.id}/factures`, { headers });
        setFactures(response.data.factures);
        Swal.fire({
          title: 'Succès',
          text: 'Facture supprimée avec succès.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      catch (error) {
        console.error('Error deleting facture:', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression de la facture.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const sortedAndFilteredFactures = factures
    .filter((facture) => {
      const lastFacture = getLastFactureBySousType(factures, facture.sous_type);
      const alertState = lastFacture && facture.km === lastFacture.km
        ? getAlertState(facture.sous_type, parseInt(lastFacture.km, 10), formData.km)
        : 'none';

      const matchesAlertFilter = showAlertsOnly
        ? (alertState === 'red' || alertState === 'orange')
        : true;
      const matchesTypeFilter = filterType ? facture.type === filterType : true;
      const matchesSousTypeFilter = filterSousType ? facture.sous_type === filterSousType : true;

      return matchesAlertFilter && matchesTypeFilter && matchesSousTypeFilter;
    })
    .sort((a, b) => {
      if (!sortConfig.key) return 0;
      if (sortConfig.key === 'date') {
        const dateA = moment(a.date, 'DD/MM/YYYY').toDate();
        const dateB = moment(b.date, 'DD/MM/YYYY').toDate();
        if (dateA < dateB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      }
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

  const handleChangeFacturesType = (e) => {
    const type = e.target.value;
    setFacturesType(type);
    setFactureSousType('');
    setSousTypeEntretien('');
    setSousTypeReparation('');
  };

  const handleChangeSousTypeEntretien = (e) => {
    const sous_type = e.target.value;
    setSousTypeEntretien(sous_type);
    setFactureSousType(sous_type);
  };

  const handleChangeSousTypeReparation = (e) => {
    const sous_type = e.target.value;
    setSousTypeReparation(sous_type);
    setFactureSousType(sous_type);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.societe) {
      newErrors.societe = 'La société est requise';
    }
    if (!formData.code) {
      newErrors.code = 'Le code est requis';
    }
    if (!formData.immat) {
      newErrors.immat = "L'immatriculation est requise";
    }
    if (!formData.km) {
      newErrors.km = 'Le kilométrage est requis';
    }
    if (!formData.marque) {
      newErrors.marque = 'La marque est requise';
    }
    if (!formData.type) {
      newErrors.type = 'Le type est requis';
    }
    if (!formData.categorie) {
      newErrors.categorie = 'La catégorie est requise';
    }
    if (!formData.entry_date) {
      newErrors.entry_date = "La date d'entrée est requise";
    }
    if (!formData.technical_control_date) {
      newErrors.technical_control_date = 'La date de contrôle technique est requise';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleVehiculeFileChange = async (e, imageField) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file size
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    // Check file type
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF.');
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}vehicules/presigned`,
        { name: file.name, type: file.type },
        { headers },
      );
      const { url, fields } = response.data;

      // Upload the file to S3
      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', file);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': file.type,
        },
      });

      // Set the picture field to the URL of the uploaded file
      const uploadedFileUrl = `${fields.key}`;
      setFormData((prevState) => ({ ...prevState, [imageField]: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleOpenFacturesModal = () => {
    fetchFactures();
    setShowFacturesModal(true);
  };

  const handleCloseFacturesModal = () => {
    setShowFacturesModal(false);
    setFacturesNumero('');
    fetchFactures();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Si le tableau factures est vide, on utilise ceux du véhicule actuel
    const facturesToSend = factures.length > 0 ? factures : currentVehicule.factures;

    const data = {
      societe: formData.societe,
      code: formData.code,
      immat: formData.immat,
      marque: formData.marque,
      type: formData.type,
      categorie: formData.categorie,
      entry_date: formData.entry_date,
      exit_date: formData.exit_date === '' ? null : formData.exit_date,
      technical_control_date: formData.technical_control_date,
      pneu_dim: formData.pneu_dim,
      pneu_secour: formData.pneu_secour,
      second_key: formData.second_key,
      km: formData.km,
      responsable_id: formData.responsable_id,
      phone_number: formData.phone_number,
      carte_grise: formData.carte_grise,
      assurance: formData.assurance,
      utac: formData.utac,
      contrat_location: formData.contrat_location,
      ams: formData.ams,
      technical_control: formData.technical_control,
      observation: formData.observation,
      imei: formData.imei,
      telepeage: formData.telepeage,
      carte_carburant: formData.carte_carburant,
      pinpuk: formData.pinpuk,
      code_carburant: formData.code_carburant,
      factures: facturesToSend,
      counter_visit_date: showCounterVisitDate ? counterVisitDate : null,
      qr_code: formData.qr_code,
      location_date: formData.location_date,
    };

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.put(`${BASE_URL}vehicules/${currentVehicule.id}`, data, { headers });
      const updatedVehicule = response.data;
      onUpdateVehicule(updatedVehicule);

      // Fetch updated factures
      const updatedResponse = await axios.get(`${BASE_URL}vehicules/${currentVehicule.id}/factures`, { headers });
      setFactures(updatedResponse.data.factures);
    }
    catch (error) {
      console.error('Error updating vehicule:', error);
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}vehicules/get-signed-url`, { key }, { headers });
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const downloadImage = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'image/jpeg' }); // Ajustez le type MIME si nécessaire
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'document.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}vehicules/get-signed-url`, { key }, { headers });
      downloadImage(response.data.url, key.split('/').pop()); // Utilise le nom de fichier d'après la clé
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      window.open(viewImageUrl, '_blank');
    }
  }, [viewImageUrl]);

  const formatImmatriculation = (value) => {
    // Retire tout caractère non-alphabétique et non-numérique et met en majuscules
    const cleaned = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

    if (cleaned.length <= 2) return cleaned;
    if (cleaned.length <= 5) return `${cleaned.slice(0, 2)}-${cleaned.slice(2)}`;

    // Format final : deux lettres, trois chiffres, deux lettres (ex: EK-130-BZ)
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 5)}-${cleaned.slice(5, 7)}`;
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
  };

  const handleChange = (event) => {
    const {
      name, value, checked, type,
    } = event.target;
    let formattedValue = type === 'checkbox' ? checked : value;

    if (name === 'phone_number') {
      formattedValue = formatPhoneNumber(value);
    }
    else if (name === 'immat') {
      formattedValue = formatImmatriculation(value);
    }

    setFormData((prevForm) => ({
      ...prevForm,
      [name]: formattedValue,
    }));
  };

  const formatKilometrage = (kilometrage) => kilometrage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const loadPdfMake = async () => {
    const { default: pdfMake } = await import('pdfmake/build/pdfmake');
    const { default: pdfFonts } = await import('pdfmake/build/vfs_fonts');
    pdfMake.vfs = pdfFonts.vfs;
    return pdfMake;
  };
  const exportAlertsToPDF = async () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir exporter les alertes en PDF?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, exporter!',
      cancelButtonText: 'Non, annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Filtrer pour ne garder que la dernière facture pour chaque sous_type
        const lastFacturesBySousType = factures.reduce((acc, facture) => {
          if (!acc[facture.sous_type] || acc[facture.sous_type].km < facture.km) {
            acc[facture.sous_type] = facture;
          }
          return acc;
        }, {});

        const alertRows = Object.values(lastFacturesBySousType).map((facture) => {
          const alertState = getAlertState(facture.sous_type, facture.km, formData.km);
          if (alertState === 'red' || alertState === 'orange') {
            return {
              vehicule: `${formData.type} - ${formData.immat}`,
              kilometrageActuel: formatKilometrage(formData.km),
              type: facture.type,
              sousType: facture.sous_type,
              derniereIntervention: `${formatKilometrage(facture.km)} km (${facture.date})`,
              commentaires: facture.commentaires,
            };
          }
          return null;
        }).filter(Boolean);

        // Génère le QR code en base64
        let qrCodeDataURL;
        try {
          qrCodeDataURL = await QRCode.toDataURL(formData.qr_code);
        }
        catch (error) {
          console.error('Error generating QR code:', error);
        }

        const docDefinition = {
          content: [
            {
              columns: [
                {
                  image: logoDataURL,
                  width: 100,
                },
                {
                  text: "Demande d'intervention",
                  style: 'header',
                  alignment: 'right',
                },
              ],
            },
            { text: '', margin: [0, 10, 0, 10] }, // Espace
            {
              columns: [
                { text: `Société : ${formData.societe}`, style: 'subheader' },
                qrCodeDataURL && {
                  image: qrCodeDataURL,
                  width: 150,
                  textAlignment: 'center',
                },
              ],
            },
            { text: `Date : ${format(new Date(), 'dd/MM/yyyy')}`, style: 'subheader' },
            { text: `Pour : ${formData.marque} - ${formData.immat}`, style: 'subheader' },
            { text: `Kilométrage actuel : ${formatKilometrage(formData.km)}`, style: 'subheader' },
            { text: '', margin: [0, 10, 0, 10] }, // Espace
            {
              table: {
                headerRows: 1,
                widths: ['*', '*', '*'],
                body: [
                  [
                    { text: "Type d'entretien", style: 'tableHeader' },
                    { text: 'Dernier entretien', style: 'tableHeader' },
                    { text: 'Commentaires', style: 'tableHeader' },
                  ],
                  ...alertRows.map((row) => ([
                    { text: row.sousType, style: 'content' },
                    { text: row.derniereIntervention, style: 'content' },
                    { text: row.commentaires, style: 'content' },
                  ])),
                ],
              },
            },
          ],
          styles: {
            header: {
              fontSize: 22,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            content: {
              fontSize: 14,
              margin: [0, 5, 0, 5],
            },
            tableHeader: {
              bold: true,
              fontSize: 15,
              color: 'black',
              fillColor: '#eeeeee',
            },
          },
          defaultStyle: {
            columnGap: 20,
          },
        };

        const fileName = `demande_d'intervention_${formData.immat}_${format(new Date(), 'yyyy-MM-dd')}.pdf`;
        try {
          const pdfMake = await loadPdfMake();
          pdfMake.createPdf(docDefinition).download(fileName);
        }
        catch (error) {
          console.error('Erreur lors du chargement de pdfmake :', error);
        }
      }
    });
  };

  return (
    <Box component="form" noValidate autoComplete="off" className="updateTransportModal">
      <div className="headerModal">
        <h2 className="addModalTitle">Mettre à jour le véhicule</h2>
        <Button
          onClick={handleOpenFacturesModal}
          sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
          variant="contained"
        >
          Factures/Entretiens
        </Button>
      </div>
      {/* ------------------------------ MODAL FACTURES/ENTRETIENS -------------------------- */}
      <Dialog open={showFacturesModal} onClose={handleCloseFacturesModal} maxWidth="md" fullWidth>
        <DialogTitle sx={{ margin: 'auto' }}>Factures/Entretiens</DialogTitle>
        <DialogContent>
          <Button
            onClick={exportAlertsToPDF}
            sx={{ margin: 'auto', backgroundColor: '#505154', width: '100%' }}
            variant="contained"
          >
            Exporter les demandes d'interventions
          </Button>
          {/* Filter by Type */}
          <FormControl sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
            <InputLabel id="filter-type-label">Filtrer par type</InputLabel>
            <Select
              labelId="filter-type-label"
              id="filter-type"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              label="Filtrer par type"
            >
              <MenuItem value="">Tous les types</MenuItem>
              <MenuItem value="Carrosserie">Carrosserie</MenuItem>
              <MenuItem value="Entretien">Entretien</MenuItem>
              <MenuItem value="Réparation">Réparation</MenuItem>
              <MenuItem value="Télépéage">Télépéage</MenuItem>
              <MenuItem value="Carburant">Carburant</MenuItem>
              <MenuItem value="Location/Credit">Mensualité location/crédit</MenuItem>
              <MenuItem value="Assurance">Assurance</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </Select>
          </FormControl>

          {/* Filter by Sous-Type */}
          {filterType && (filterType === 'Entretien' || filterType === 'Réparation') && (
            <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
              <InputLabel id="filter-sous-type-label">Filtrer par sous-type</InputLabel>
              <Select
                labelId="filter-sous-type-label"
                id="filter-sous-type"
                value={filterSousType}
                onChange={(e) => setFilterSousType(e.target.value)}
                label="Filtrer par sous-type"
              >
                <MenuItem value="">Tous les sous-types</MenuItem>
                {(filterType === 'Entretien' ? typesEntretien : typesReparation).map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Button
            onClick={() => setShowAlertsOnly(!showAlertsOnly)}
            sx={{
              margin: 'auto', backgroundColor: '#505154', width: '100%', marginTop: '10px',
            }}
            variant="contained"
          >
            {showAlertsOnly ? 'Afficher toutes les factures' : 'Afficher uniquement les entretiens à réaliser'}
          </Button>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => sortFactures('date')}>
                    Date
                    {sortConfig.key === 'date'
                      ? (sortConfig.direction === 'asc'
                        ? <ArrowUpwardIcon style={{ fontSize: '16px' }} />
                        : <ArrowDownwardIcon style={{ fontSize: '16px' }} />)
                      : <ArrowDownwardIcon style={{ fontSize: '16px', visibility: 'hidden' }} />}
                  </TableCell>
                  <TableCell>Numéro de Facture</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Sous-type</TableCell>
                  <TableCell onClick={() => sortFactures('Prices')}>
                    Prix (€)
                    {sortConfig.key === 'Prices'
                      ? (sortConfig.direction === 'asc'
                        ? <ArrowUpwardIcon style={{ fontSize: '16px' }} />
                        : <ArrowDownwardIcon style={{ fontSize: '16px' }} />)
                      : <ArrowDownwardIcon style={{ fontSize: '16px', visibility: 'hidden' }} />}
                  </TableCell>
                  <TableCell>Kilométrage (km)</TableCell>
                  <TableCell>Commentaires</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedAndFilteredFactures.map((facture, index) => {
                  const lastFacture = getLastFactureBySousType(factures, facture.sous_type);
                  const alertColor = lastFacture && facture.km === lastFacture.km
                    ? getAlertState(facture.sous_type, parseInt(lastFacture.km, 10), formData.km)
                    : 'transparent';

                  return (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: alertColor === 'red'
                          ? '#ffcccc'
                          : alertColor === 'orange'
                            ? '#ffebcc'
                            : 'transparent',
                      }}
                    >
                      <TableCell>{facture.date}</TableCell>
                      <TableCell>{facture.numero}</TableCell>
                      <TableCell>{facture.type}</TableCell>
                      <TableCell>{facture.sous_type}</TableCell>
                      <TableCell>{facture.Prices}</TableCell>
                      <TableCell>{facture.km}</TableCell>
                      <TableCell>{facture.commentaires}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditFacture(index)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteFacture(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </TableContainer>
          <Button
            onClick={() => setShowAddFacturesModal(true)}
            sx={{ margin: 'auto', backgroundColor: '#505154', width: '100%' }}
            variant="contained"
          >
            Ajouter une facture
          </Button>

          {/* ------------------------------ MODAL AJOUT OU EDIT FACTURE -------------------------- */}
          <Dialog open={showAddFacturesModal} onClose={handleCloseAddFacturesModal} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ margin: 'auto' }}>Détails de la facture</DialogTitle>
            <DialogContent>
              <FormControl sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                <InputLabel id="factures-type-label">Type de Facture</InputLabel>
                <Select
                  labelId="factures-type-label"
                  id="factures-type"
                  value={facturesType}
                  onChange={handleChangeFacturesType}
                  label="Type de Facture"
                >
                  <MenuItem value="Carrosserie">Carrosserie</MenuItem>
                  <MenuItem value="Entretien">Entretien</MenuItem>
                  <MenuItem value="Réparation">Réparation</MenuItem>
                  <MenuItem value="Télépéage">Télépéage</MenuItem>
                  <MenuItem value="Caburant">Carburant</MenuItem>
                  <MenuItem value="Location/Credit">Mensualité location/crédit</MenuItem>
                  <MenuItem value="Assurance">Assurance</MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                </Select>
              </FormControl>

              {facturesType === 'Entretien' && (
                <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
                  <InputLabel id="sous-type-entretien-label">Type d'entretien</InputLabel>
                  <Select
                    labelId="sous-type-entretien-label"
                    id="sous-type-entretien"
                    value={sousTypeEntretien}
                    onChange={handleChangeSousTypeEntretien}
                    label="Type d'entretien"
                  >
                    {typesEntretien.map((type) => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                    <MenuItem value="Autre">Autre</MenuItem>
                  </Select>
                </FormControl>
              )}

              {facturesType === 'Réparation' && (
                <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
                  <InputLabel id="sous-type-reparation-label">Type de réparation</InputLabel>
                  <Select
                    labelId="sous-type-reparation-label"
                    id="sous-type-reparation"
                    value={sousTypeReparation}
                    onChange={handleChangeSousTypeReparation}
                    label="Type de réparation"
                  >
                    {typesReparation.map((type) => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                    <MenuItem value="Autre">Autre</MenuItem>
                  </Select>
                </FormControl>
              )}

              <TextField
                fullWidth
                id="factures-prices"
                label="Total de la facture HT (€)"
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                value={facturesPrices}
                onChange={(e) => setFacturesPrices(e.target.value)}
              />

              <TextField
                id="factures-date"
                label="Date"
                type="date"
                value={facturesDate}
                sx={{ marginY: '10px', width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setFacturesDate(e.target.value)}
              />

              <TextField
                fullWidth
                id="factures-numero"
                label="Numéro de Facture"
                variant="outlined"
                value={facturesNumero}
                onChange={(e) => setFacturesNumero(e.target.value)}
                sx={{ marginBottom: '10px' }}
              />

              <TextField
                fullWidth
                id="factures-km"
                label="Kilométrage"
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={facturesKm}
                onChange={(e) => setFacturesKm(e.target.value)}
              />

              <TextField
                fullWidth
                id="factures-commentaires"
                label="Commentaire"
                variant="outlined"
                multiline
                rows={4}
                value={facturesCommentaires}
                onChange={(e) => setFacturesCommentaires(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddFactures} color="primary">Ajouter</Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
      </Dialog>

      {/* ------------------------------ FORMULAIRE PRINCIPAL -------------------------- */}
      <form onSubmit={handleSubmit}>
        <div className="firstLine">
          <TextField
            id="code"
            name="code"
            label="Code"
            variant="outlined"
            value={formData.code}
            onChange={handleChange}
            error={!!errors.code}
            helperText={errors.code}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="immat"
            name="immat"
            label="Immatriculation"
            variant="outlined"
            value={formData.immat}
            onChange={handleChange}
            error={!!errors.immat}
            helperText={errors.immat}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="marque"
            name="marque"
            label="Marque"
            variant="outlined"
            value={formData.marque}
            onChange={handleChange}
            error={!!errors.marque}
            helperText={errors.marque}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="km"
            name="km"
            label="Kilométrage"
            variant="outlined"
            value={formData.km}
            onChange={handleChange}
            error={!!errors.km}
            helperText={errors.km}
            style={{ width: '20%', marginRight: '10px' }}
          />
        </div>

        <div className="secondLine">
          <FormControl sx={{ width: '30%', padding: '10px' }}>
            <InputLabel id="societe">Responsable</InputLabel>
            <Select
              id="responsable_id"
              name="responsable_id"
              label="Responsable"
              variant="outlined"
              value={formData.responsable_id}
              onChange={handleChange}
            >
              {responsables.map((responsable) => (
                <MenuItem key={responsable.id} value={responsable.id}>
                  {`${responsable.name} ${responsable.last_name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '20%', padding: '10px' }}>
            <InputLabel id="type">Type</InputLabel>
            <Select
              name="type"
              id="type"
              value={formData.type}
              onChange={handleChange}
            >
              <MenuItem value="AMB">AMB</MenuItem>
              <MenuItem value="PARA">PARA</MenuItem>
              <MenuItem value="TAXI">TAXI</MenuItem>
              <MenuItem value="VSL">VSL</MenuItem>
              <MenuItem value="BARIA">BARIA</MenuItem>
              <MenuItem value="PSY">PSY</MenuItem>
              <MenuItem value="VL">VL</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: '20%', padding: '10px' }}>
            <InputLabel id="categorie">Categorie</InputLabel>
            <Select
              name="categorie"
              id="categorie"
              value={formData.categorie}
              onChange={handleChange}
              error={!!errors.categorie}
              helperText={errors.categorie}
            >
              <MenuItem value="C TYPE A">C type A</MenuItem>
              <MenuItem value="A TYPE B">A type B</MenuItem>
              <MenuItem value="A TYPE C">A type C</MenuItem>
              <MenuItem value="VL">Véhicule léger</MenuItem>
              <MenuItem value="MULLE">Mulle</MenuItem>
              <MenuItem value="VS">Véhicule société</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: '20%', padding: '10px' }}>
            <InputLabel id="societe">Société</InputLabel>
            <Select
              name="societe"
              id="societe"
              value={formData.societe}
              onChange={handleChange}
            >
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.code}>
                  {company.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            required
            id="phone_number"
            name="phone_number"
            label="Numéro de téléphone"
            variant="outlined"
            value={formData.phone_number}
            onChange={handleChange}
            error={!!errors.phone_number}
            helperText={errors.phone_number}
          />
        </div>

        <div className="secondLine">
          <TextField
            id="pinpuk"
            name="pinpuk"
            label="Code pin/puk"
            variant="outlined"
            value={formData.pinpuk}
            onChange={handleChange}
          />
          <TextField
            id="imei"
            name="imei"
            label="Numéro imei"
            variant="outlined"
            value={formData.imei}
            onChange={handleChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="carte_carburant"
            name="carte_carburant"
            label="Carte carburant"
            variant="outlined"
            value={formData.carte_carburant}
            onChange={handleChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="code_carburant"
            name="code_carburant"
            label="Code carburant"
            variant="outlined"
            value={formData.code_carburant}
            onChange={handleChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="telepeage"
            name="telepeage"
            label="Télépéage"
            variant="outlined"
            value={formData.telepeage}
            onChange={handleChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
        </div>

        <div className="thirdLineVehicule">
          <FormControlLabel
            label="Roue de secours"
            control={(
              <Checkbox
                name="pneu_secour"
                checked={formData.pneu_secour}
                onChange={handleChange}
              />
            )}
          />
          <TextField
            id="pneu_dim"
            name="pneu_dim"
            label="Dimension des pneus"
            variant="outlined"
            value={formData.pneu_dim}
            onChange={handleChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <FormControlLabel
            label="Double de clef"
            control={(
              <Checkbox
                name="second_key"
                checked={formData.second_key}
                onChange={handleChange}
              />
            )}
          />
        </div>

        <div className="fifthLine">
          <TextField
            type="date"
            id="entry_date"
            name="entry_date"
            label="Date d'entrée"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.entry_date}
            onChange={handleChange}
            error={!!errors.entry_date}
            helperText={errors.entry_date}
          />
          <TextField
            type="date"
            id="exit_date"
            name="exit_date"
            label="Date de sortie"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.exit_date}
            onChange={handleChange}
          />
          <TextField
            type="date"
            id="technical_control_date"
            name="technical_control_date"
            label="Date de contrôle technique"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.technical_control_date}
            onChange={handleChange}
            error={!!errors.technical_control_date}
            helperText={errors.technical_control_date}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={showCounterVisitDate}
                onChange={handleCounterVisitChange}
              />
            )}
            label="Contre visite"
          />
          {showCounterVisitDate && (
            <TextField
              type="date"
              id="counter_visit_date"
              name="counter_visit_date"
              label="Date de contre visite"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={counterVisitDate}
              onChange={(e) => {
                setCounterVisitDate(e.target.value);
                setFormData((prevState) => ({
                  ...prevState,
                  counter_visit_date: e.target.value,
                }));
              }}
            />
          )}

          <TextField
            type="date"
            id="location_date"
            name="location_date"
            label="Date contrat de location"
            variant="outlined"
            style={{ marginLeft: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.location_date}
            onChange={handleChange}
          />

          <TextField
            id="observation"
            name="observation"
            label="Observation"
            variant="outlined"
            multiline
            rows={4}
            maxRows={8}
            value={formData.observation}
            onChange={handleChange}
          />
        </div>

        {/* ------------------------------ GESTION DES DOCUMENTS -------------------------- */}
        <div className="vehiculesFiles">

          {/* ================= CARTE GRISE ================= */}
          <Box
            mb={2}
            className="VehiculeCard"
            onClick={() => {
              if (formData.carte_grise) {
                fetchSignedUrlForViewing(formData.carte_grise);
              }
            }}
            style={{ cursor: formData.carte_grise ? 'pointer' : 'default' }}
          >
            <p className="vehiculeLabel">Carte grise</p>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <VehiculeImages
                imageKey={formData.carte_grise}
                key={formData.carte_grise}
                userToken={userToken}
              />
            </Worker>

            <div className="vehiculeButton">
              <Tooltip title="Ajouter un document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById('carte_grise-input').click();
                  }}
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
              </Tooltip>
              {/* Icone pour télécharger (si document existe) */}
              {formData.carte_grise && (
                <Tooltip title="Télécharger le document">
                  <IconButton
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchSignedUrlForDownloading(formData.carte_grise);
                    }}
                  >
                    <CloudDownloadIcon size="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="carte_grise-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'carte_grise')}
          />

          {/* ================= UTAC ================= */}
          <Box
            mb={2}
            className="VehiculeCard"
            onClick={() => {
              if (formData.utac) {
                fetchSignedUrlForViewing(formData.utac);
              }
            }}
            style={{ cursor: formData.utac ? 'pointer' : 'default' }}
          >
            <p className="vehiculeLabel">UTAC</p>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <VehiculeImages
                imageKey={formData.utac}
                key={formData.utac}
                userToken={userToken}
              />
            </Worker>
            <div className="vehiculeButton">
              <Tooltip title="Ajouter un document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById('utac-input').click();
                  }}
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
              </Tooltip>
              {formData.utac && (
                <Tooltip title="Télécharger le document">
                  <IconButton
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchSignedUrlForDownloading(formData.utac);
                    }}
                  >
                    <CloudDownloadIcon size="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="utac-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'utac')}
          />

          {/* ================= ASSURANCE ================= */}
          <Box
            mb={2}
            className="VehiculeCard"
            onClick={() => {
              if (formData.assurance) {
                fetchSignedUrlForViewing(formData.assurance);
              }
            }}
            style={{ cursor: formData.assurance ? 'pointer' : 'default' }}
          >
            <p className="vehiculeLabel">Assurance</p>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <VehiculeImages
                imageKey={formData.assurance}
                key={formData.assurance}
                userToken={userToken}
              />
            </Worker>
            <div className="vehiculeButton">
              <Tooltip title="Ajouter un document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById('assurance-input').click();
                  }}
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
              </Tooltip>
              {formData.assurance && (
                <Tooltip title="Télécharger le document">
                  <IconButton
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchSignedUrlForDownloading(formData.assurance);
                    }}
                  >
                    <CloudDownloadIcon size="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="assurance-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'assurance')}
          />

          {/* ================= CONTRÔLE TECHNIQUE ================= */}
          <Box
            mb={2}
            className="VehiculeCard"
            onClick={() => {
              if (formData.technical_control) {
                fetchSignedUrlForViewing(formData.technical_control);
              }
            }}
            style={{ cursor: formData.technical_control ? 'pointer' : 'default' }}
          >
            <p className="vehiculeLabel">Contrôle technique</p>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <VehiculeImages
                imageKey={formData.technical_control}
                key={formData.technical_control}
                userToken={userToken}
              />
            </Worker>
            <div className="vehiculeButton">
              <Tooltip title="Ajouter un document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById('technical_control-input').click();
                  }}
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
              </Tooltip>
              {formData.technical_control && (
                <Tooltip title="Télécharger le document">
                  <IconButton
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchSignedUrlForDownloading(formData.technical_control);
                    }}
                  >
                    <CloudDownloadIcon size="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="technical_control-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'technical_control')}
          />

          {/* ================= CONTRAT DE LOCATION ================= */}
          <Box
            mb={2}
            className="VehiculeCard"
            onClick={() => {
              if (formData.contrat_location) {
                fetchSignedUrlForViewing(formData.contrat_location);
              }
            }}
            style={{ cursor: formData.contrat_location ? 'pointer' : 'default' }}
          >
            <p className="vehiculeLabel">Contrat de location</p>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <VehiculeImages
                imageKey={formData.contrat_location}
                key={formData.contrat_location}
                userToken={userToken}
              />
            </Worker>
            <div className="vehiculeButton">
              <Tooltip title="Ajouter un document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById('contrat_location-input').click();
                  }}
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
              </Tooltip>
              {formData.contrat_location && (
                <Tooltip title="Télécharger le document">
                  <IconButton
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchSignedUrlForDownloading(formData.contrat_location);
                    }}
                  >
                    <CloudDownloadIcon size="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="contrat_location-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'contrat_location')}
          />
        </div>

        <div className="sixLine">
          <Button
            type="submit"
            sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? 'Loading...' : 'Modifier le véhicule'}
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default React.memo(UpdateVehiculeModal);
