/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import useLocalStorage from 'use-local-storage';
import { parseISO, differenceInHours } from 'date-fns';
import ProtectedRoute from '../../utils/protectedRoutes';
import { handleLogOut } from '../../utils/authServices';
import { BASE_URL } from '../../utils/api';

// Base components
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';
import Menu from '../Menu/menu';
import Privacy from '../About/privacy';
import Terms from '../About/terms';
import LegalMentions from '../About/legalMentions';

// documentation
import DocumentationSystem from '../Documentation/documentationSystem';

// Dashboard
import Dashboard from '../Dashboard/dashboard';

// Auth
import LogIn from '../logIn/logIn';
import ForgotPassword from '../ForgotPassword/forgotPassword';
import ResetPassword from '../ResetPassword/resetPassword';

// Admin
import AdminDashboard from '../Admin/adminDashboard';

// Regulation
import Regulation from '../Regulation/regulation';

// Facturation
import Facturation from '../Facturation/facturationManagement';
import Tarifs from '../Facturation/tarifsManagement/tarifsManagement';
import Prescripteurs from '../Facturation/prescripteursManagement/prescripteursManagement';
import Caisses from '../Facturation/caissesManagement/caissesManagement';
import RisquesExoneration from '../Facturation/risquesExonerationManagement/risquesExonerationManagement';
import MarchesManagement from '../Facturation/marchesManagement/marchesManagement';
import MutuellesManagement from '../Facturation/mutuellesManagement/mutuellesManagement';
import AssuresManagement from '../Facturation/assuresManagement/assuresManagement';
import FacturesManagement from '../Facturation/facturesManagement/facturesManagement';
import Devis from '../Facturation/devisManagement/devisManagement';
import DevisRapide from '../Facturation/devisManagement/DevisRapide/devisRapide';

// Porte Document
import PorteDocument from '../DocumentsHolder/documentHolder';

// Activity Management
import ActivityManagement from '../ActivityManagement/activityManagement';
import StaffManagement from '../ActivityManagement/staffManagement/staffManagement';
import ManagementData from '../managementData/managementData';
import VehiculeManagement from '../ActivityManagement/vehiculeManagement/vehiculeManagement';
import AnnualPlanning from '../ActivityManagement/planningManagement/annualPlanning';
import TimeClock from '../ActivityManagement/timeClock/timeClock';
import Crews from '../ActivityManagement/crews/crews';
import EtablissementsManagement from '../ActivityManagement/etablissementsManagement/etablissementsManagement';
import CompaniesManagement from '../ActivityManagement/companiesManagement/companiesManagement';
import SeriesManagement from '../ActivityManagement/seriesManagement/seriesManagement';
import StockManagement from '../ActivityManagement/stockManagement/stockManagement';
import MedicalStockManagement from '../ActivityManagement/medicalStockManagement/medicalStockManagement';
import HistoriqueStock from '../ActivityManagement/stockManagement/Historique/historique';
import ProcessManagement from '../ActivityManagement/processManagement/procedures';
import TaskManagement from '../ActivityManagement/tasksManagement/tasksManagement';
import LocauxManagement from '../ActivityManagement/locauxManagement/locauxManagement';
import Calendar from '../ActivityManagement/calendar/calendar';
import historiqueProcedures from '../ActivityManagement/processManagement/historiqueProcedures';
import FournisseursManagement from '../ActivityManagement/fournisseursManagement/fournisseursManagement';

function App() {
  const navigate = useNavigate();
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    const checkTokenAndLoginState = () => {
      const token = localStorage.getItem('token');
      if (token) {
        // Parse the token to get the expiration date
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const expirationDate = new Date(tokenPayload.exp * 1000);

        if (expirationDate < new Date()) {
          swal.fire({
            title: 'Session Expirée',
            text: 'Votre session a expiré, veuillez vous reconnecter.',
            icon: 'warning',
            confirmButtonText: 'OK'
          }).then(() => {
            handleLogOut();
            navigate('/');
          });
        }
        else {
          // Token is valid
          setIsLoggedIn(true);
          setUserRole(localStorage.getItem('userRole'));
          setUserEmail(localStorage.getItem('userEmail'));
          setUserId(localStorage.getItem('userId'));
          setUserToken(token);
        }
      }
      else {
        setIsLoggedIn(false);
      }
    };

    checkTokenAndLoginState();
  }, [navigate]);

  // Ajoutez cet effet pour gérer les popups des événements
  useEffect(() => {
    if (isLoggedIn && userRole && userToken) {
      const fetchEvents = async () => {
        try {
          const headers = { Authorization: `Bearer ${userToken}` };
          const response = await axios.get(`${BASE_URL}calendar/events`, { headers });
          const { events } = response.data;

          const now = new Date();

          const roleEvents = events.filter((event) => {
            const isRoleMatched = event.roles && event.roles.some((role) => role.name === userRole);

            if (!isRoleMatched) {
              return false;
            }

            const eventStart = parseISO(event.start);
            const hoursUntilEvent = differenceInHours(eventStart, now);

            return hoursUntilEvent <= 72 && hoursUntilEvent >= 0;
          });

          roleEvents.forEach((event) => {
            const eventStart = parseISO(event.start);

            const shownEvents = JSON.parse(localStorage.getItem('shownEvents') || '{}');
            const today = new Date().toDateString();

            if (!shownEvents[event.id] || shownEvents[event.id] !== today) {
              // Define the link icon HTML
              const linkHtml = event.link
                ? `<a href="${event.link}" target="_blank" style="text-decoration: none;">
                     <img src="https://img.icons8.com/ios-filled/50/000000/link.png" alt="Link Icon" style="width: 20px; height: 20px; vertical-align: middle; margin-left: 5px;"/>
                   </a>`
                : 'Aucun';

              // Show the popup with clickable link icon
              swal.fire({
                title: `Événement à venir : ${event.title}`,
                html: `L'événement "${event.title}" aura lieu le ${eventStart.toLocaleString()}.<br><br>Lien : ${linkHtml}`,
                icon: 'info',
                confirmButtonText: 'OK'
              });

              shownEvents[event.id] = today;
              localStorage.setItem('shownEvents', JSON.stringify(shownEvents));
            }
          });
        }
        catch (error) {
          console.error('Erreur lors de la récupération des événements', error);
        }
      };

      fetchEvents();

      const intervalId = setInterval(fetchEvents, 24 * 60 * 60 * 1000);

      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, userRole, userToken]);

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      // Parse the token to get the expiration date
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const expirationDate = new Date(tokenPayload.exp * 1000);

      if (expirationDate < new Date()) {
        swal.fire({
          title: 'Session Expirée',
          text: 'Votre session a expiré, veuillez vous reconnecter.',
          icon: 'warning',
          confirmButtonText: 'OK'
        }).then(() => {
          handleLogOut();
          navigate('/');
        });
      }
    };

    // Check token expiration every minute
    const interval = setInterval(checkTokenExpiration, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [navigate]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 403) {
          console.log('403 error intercepted'); // Log for debugging
          swal.fire({
            title: 'Session Expirée',
            text: 'Votre session a expiré, veuillez vous reconnecter.',
            icon: 'warning',
            confirmButtonText: 'OK'
          }).then(() => {
            handleLogOut();
            navigate('/');
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  return (
    <div className="App" id={theme}>
      {isLoading && <AmbysoftLoader theme={theme} />}
      <ToastContainer />
      {isLoggedIn ? (
        <>
          <Menu theme={theme} switchTheme={switchTheme} />
          <Routes>
            <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} userRole={userRole} userToken={userToken} />} />
            <Route path="/regulation" element={<ProtectedRoute element={Regulation} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturation" element={<ProtectedRoute element={Facturation} userRole={userRole} userToken={userToken} theme={theme} />} />
            <Route path="/" element={<ProtectedRoute element={ActivityManagement} userRole={userRole} userToken={userToken} theme={theme} />} />
            <Route path="/activityManagement/staffManagement" element={<ProtectedRoute element={StaffManagement} theme={theme} userRole={userRole} userToken={userToken} userEmail={userEmail} />} />
            <Route path="/managementData" element={<ProtectedRoute element={ManagementData} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/vehiculeManagement" element={<ProtectedRoute element={VehiculeManagement} theme={theme} userRole={userRole} userEmail={userEmail} userToken={userToken} />} />
            <Route path="/activityManagement/planningManagement" element={<ProtectedRoute element={AnnualPlanning} userRole={userRole} userEmail={userEmail} theme={theme} userToken={userToken} />} />
            <Route path="/activityManagement/etablissementsManagement" element={<ProtectedRoute element={EtablissementsManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/timeClock" element={<ProtectedRoute element={TimeClock} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/crews" element={<ProtectedRoute element={Crews} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/companiesManagement" element={<ProtectedRoute element={CompaniesManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/seriesManagement" element={<ProtectedRoute element={SeriesManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/stockManagement" element={<ProtectedRoute element={StockManagement} theme={theme} userId={userId} userRole={userRole} userToken={userToken} userEmail={userEmail} />} />
            <Route path="/activityManagement/medicalStockManagement" element={<ProtectedRoute element={MedicalStockManagement} theme={theme} userId={userId} userRole={userRole} userToken={userToken} userEmail={userEmail} />} />
            <Route path="/activityManagement/stockManagement/historique" element={<ProtectedRoute element={HistoriqueStock} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/processManagement" element={<ProtectedRoute element={ProcessManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/processManagement/historique" element={<ProtectedRoute element={historiqueProcedures} theme={theme} userRole={userRole} userToken={userToken} userEmail={userEmail} />} />
            <Route path="/activityManagement/taskManagement" element={<ProtectedRoute element={TaskManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/locauxManagement" element={<ProtectedRoute element={LocauxManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/porte-document" element={<ProtectedRoute element={PorteDocument} theme={theme} userRole={userRole} userToken={userToken} userEmail={userEmail} />} />
            <Route path="/facturationManagement/tarifsManagement" element={<ProtectedRoute element={Tarifs} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/caissesManagement" element={<ProtectedRoute element={Caisses} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/prescripteursManagement" element={<ProtectedRoute element={Prescripteurs} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/risquesExonerationManagement" element={<ProtectedRoute element={RisquesExoneration} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/marchesManagement" element={<ProtectedRoute element={MarchesManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/mutuellesManagement" element={<ProtectedRoute element={MutuellesManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/assuresManagement" element={<ProtectedRoute element={AssuresManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/facturesManagement" element={<ProtectedRoute element={FacturesManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/facturationManagement/devis" element={<ProtectedRoute element={Devis} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/devis" element={<ProtectedRoute element={DevisRapide} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/calendar" element={<ProtectedRoute element={Calendar} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/documentation" element={<ProtectedRoute element={DocumentationSystem} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/activityManagement/fournisseursManagement" element={<ProtectedRoute element={FournisseursManagement} theme={theme} userRole={userRole} userToken={userToken} />} />
            <Route path="/privacy" element={<ProtectedRoute element={Privacy} userRole={userRole} userToken={userToken} />} />
            <Route path="/terms" element={<ProtectedRoute element={Terms} userRole={userRole} userToken={userToken} />} />
            <Route path="/legal-mentions" element={<ProtectedRoute element={LegalMentions} userRole={userRole} userToken={userToken} />} />
            {isLoggedIn && userRole === 'Administrateur' ? (
              <Route path="/admin" element={<ProtectedRoute element={AdminDashboard} userRole={userRole} userToken={userToken} />} />
            ) : null}
          </Routes>
        </>
      ) : (
        <>
          <Menu theme={theme} switchTheme={switchTheme} />
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default React.memo(App);
