/* eslint-disable react/prop-types */
import { useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import Swal from 'sweetalert2';

function PrintQRCodes({ vehiculeList }) {
  const pdfContent = useRef([]);

  const loadPdfMake = async () => {
    const { default: pdfMake } = await import('pdfmake/build/pdfmake');
    const { default: pdfFonts } = await import('pdfmake/build/vfs_fonts');
    pdfMake.vfs = pdfFonts.vfs;
    return pdfMake;
  };

  useEffect(() => {
    const generateQRCodes = async () => {
      const qrPromises = vehiculeList.map((vehicule) => new Promise((resolve, reject) => {
        if (vehicule.qr_code) {
          // Simplified QR code settings for easier scanning on Android
          QRCode.toDataURL(vehicule.qr_code, {
            errorCorrectionLevel: 'L', // Low error correction level
            scale: 4, // Slightly smaller size
            margin: 2, // Smaller margin
          }, (err, url) => {
            if (err) reject(err);
            resolve({ ...vehicule, qrCodeUrl: url });
          });
        }
        else {
          resolve({ ...vehicule, qrCodeUrl: null });
        }
      }));

      const qrResults = await Promise.all(qrPromises);
      pdfContent.current = qrResults.map((vehicule) => (vehicule.qrCodeUrl ? [
        { image: vehicule.qrCodeUrl, width: 150, alignment: 'center' }, // Adjusted width for better clarity
        { text: `${vehicule.marque} - ${vehicule.code} - ${vehicule.immat}`, style: 'content', alignment: 'center' }
      ] : []));
    };

    generateQRCodes();
  }, [vehiculeList]);

  const printPDF = async () => {
    const qrCodeRows = [];
    const columnsPerRow = 2; // Number of QR codes per row

    for (let i = 0; i < pdfContent.current.length; i += columnsPerRow) {
      const row = pdfContent.current.slice(i, i + columnsPerRow);
      while (row.length < columnsPerRow) {
        row.push({ text: '' });
      }
      qrCodeRows.push({ columns: row });
      qrCodeRows.push({ text: '', margin: [0, 10, 0, 10] }); // Add spacing between rows
    }

    const docDefinition = {
      content: [
        { text: 'QR Codes des Véhicules', style: 'header', alignment: 'center' },
        ...qrCodeRows
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 10],
        },
        content: {
          fontSize: 12,
          alignment: 'center',
          margin: [0, 5, 0, 5],
        },
      },
    };

    try {
      const pdfMake = await loadPdfMake();
      pdfMake.createPdf(docDefinition).download('qr_codes_vehicules.pdf');
    }
    catch (error) {
      console.error('Erreur lors du chargement de pdfmake :', error);
    }
  };

  const confirmAndPrintPDF = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir imprimer tous les QR codes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, imprimer!',
      cancelButtonText: 'Non, annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        printPDF();
      }
    });
  };

  return (
    <button type="button" onClick={confirmAndPrintPDF} className="btn-print-qr">
      Imprimer les QR Codes
    </button>
  );
}

export default PrintQRCodes;
