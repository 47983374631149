/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable quote-props */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import 'moment/locale/fr';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import {
  Card, CardContent, Grid, TextField, Typography, Button, ThemeProvider, createTheme, Box, Tooltip, IconButton,
  FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import usePageTitle from '../../hooks/usePageTitle';
import Logo from '../../../images/logoJussieu.png';
import SignatureEmployeur from '../../../images/signature.png';
import { BASE_URL } from '../../../utils/api';
import { sendNotificationEmail, sendNotificationSMS } from '../../../utils/notifications';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';

import './timeClockStyles.scss';

function TimeClock({ theme, userRole, userToken }) {
  usePageTitle('DRIVESOFT | POINTEUSE');
  const [dateFilter, setDateFilter] = useState(moment().format('YYYY-MM-DD'));
  const isoDateFilter = new Date(dateFilter).toISOString();
  const [crews, setCrews] = useState([]);
  const [timeClockEntries, setTimeClockEntries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [weeks, setWeeks] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [refusalState, setRefusalState] = useState({});
  const [openRefusalDialog, setOpenRefusalDialog] = useState(false);
  const [currentStaffId, setCurrentStaffId] = useState(null);

  const [logoDataURL, setLogoDataURL] = useState('');
  const [signatureDataURL, setSignatureDataURL] = useState('');

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [exportType, setExportType] = useState(null);
  const [dialogSelectedWeek, setDialogSelectedWeek] = useState('');
  const [dialogExportCompany, setDialogExportCompany] = useState('Tous');

  // Nouvel état pour le filtre de société
  const [selectedCompany, setSelectedCompany] = useState('Tous');

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // Fetch vehicles from the server
  const getVehicles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules`, { headers });
      return response.data.vehicule;
    }
    catch (error) {
      console.error('Failed to fetch vehicles', error);
      throw error;
    }
  };

  const getBase64Image = (imgUrl, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', imgUrl);
    xhr.responseType = 'blob';
    xhr.send();
  };

  useEffect(() => {
    getBase64Image(Logo, (base64Img) => {
      setLogoDataURL(base64Img);
    });
  }, []);

  useEffect(() => {
    getBase64Image(SignatureEmployeur, (base64Img) => {
      setSignatureDataURL(base64Img);
    });
  }, []);

  const getSignedUrl = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}staff/get-signed-url`, { key }, { headers });
      return response.data.url;
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const getBase64FromUrl = async (url) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });

      const blob = response.data;
      if (blob.size === 0) {
        throw new Error('Blob size is 0, image download failed.');
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }
    catch (error) {
      console.error('Error fetching image:', error);
      return '';
    }
  };

  const getStaffMember = async (staffId) => {
    try {
      const response = await axios.get(`${BASE_URL}staff/${staffId}`, { headers });
      return response.data.staff;
    }
    catch (error) {
      console.error(`Failed to fetch staff member with id ${staffId}`, error);
      throw error;
    }
  };

  const getCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(response.data.companies);
    }
    catch (error) {
      console.error('Failed to fetch companies', error);
      throw error;
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  // const isPublicHoliday = (date) => {
  //   const frenchPublicHolidays = [
  //     // Dates of French public holidays
  //     '01-01', // New Year's Day
  //     '05-01', // Labor Day
  //     '05-08', // Victory in Europe Day
  //     '07-14', // Bastille Day
  //     '08-15', // Assumption of Mary
  //     '11-01', // All Saints' Day
  //     '11-11', // Armistice Day
  //     '12-25', // Christmas Day
  //   ];

  //   const formattedDate = moment(date).format('MM-DD'); // Formate en 'MM-DD'
  //   return frenchPublicHolidays.includes(formattedDate);
  // };

  const getWeekRange = (date) => {
    const startOfWeek = moment(date).startOf('isoWeek');
    const endOfWeek = moment(date).endOf('isoWeek');
    return { startDate: startOfWeek.format('YYYY-MM-DD'), endDate: endOfWeek.format('YYYY-MM-DD') };
  };

  const calculateWorkedHours = (entry) => {
    // Convert all times to moment instances
    const clockInTime = moment(entry.clock_in_time, 'HH:mm:ss');
    let clockOutTime = moment(entry.clock_out_time, 'HH:mm:ss');

    // Add a day to clockOutTime if it is before clockInTime
    if (clockOutTime.isBefore(clockInTime)) {
      clockOutTime = clockOutTime.add(1, 'day');
    }

    // Calculate the total shift duration
    const workedHours = moment.duration(clockOutTime.diff(clockInTime));

    // Create durations for the lunch break and regular breaks
    const lunchBreakDuration = moment.duration(30, 'minutes');
    const breakDuration = moment.duration(20, 'minutes');

    // Subtract the lunch break if it exists
    if (entry.lunch_break_time !== '00:00:00' && entry.lunch_break_time !== null) {
      workedHours.subtract(lunchBreakDuration);
    }

    // Subtract the regular breaks based on the number of break times
    let numberOfBreaks = 0;
    if (entry.break_time !== '00:00:00' && entry.break_time !== null) numberOfBreaks++;
    if (entry.break_time2 !== '00:00:00' && entry.break_time2 !== null) numberOfBreaks++;
    if (entry.break_time3 !== '00:00:00' && entry.break_time3 !== null) numberOfBreaks++;
    if (entry.break_time4 !== '00:00:00' && entry.break_time4 !== null) numberOfBreaks++;

    // Subtract the total break time
    const totalBreakTime = moment.duration(breakDuration.asMilliseconds() * numberOfBreaks);
    workedHours.subtract(totalBreakTime);

    // Return the total worked hours in hours:minutes:seconds format
    return moment.utc(workedHours.asMilliseconds()).format('HH:mm:ss');
  };

  const calculateWorkedHoursInCentiemes = (entry) => {
    // Convertir les heures en instances moment
    const clockInTime = moment(entry.clock_in_time, 'HH:mm:ss');
    let clockOutTime = moment(entry.clock_out_time, 'HH:mm:ss');

    // Ajuster si l'heure de sortie est avant l'heure d'entrée (passage à minuit)
    if (clockOutTime.isBefore(clockInTime)) {
      clockOutTime = clockOutTime.add(1, 'day');
    }

    // Calculer la durée totale de travail
    let workedDuration = moment.duration(clockOutTime.diff(clockInTime));

    // Soustraire la pause déjeuner si elle existe
    if (entry.lunch_break_time !== '00:00:00' && entry.lunch_break_time !== null) {
      workedDuration = workedDuration.subtract(moment.duration(30, 'minutes'));
    }

    // Soustraire les pauses régulières (20 minutes par pause)
    let numberOfBreaks = 0;
    if (entry.break_time !== '00:00:00' && entry.break_time !== null) numberOfBreaks++;
    if (entry.break_time2 !== '00:00:00' && entry.break_time2 !== null) numberOfBreaks++;
    if (entry.break_time3 !== '00:00:00' && entry.break_time3 !== null) numberOfBreaks++;
    if (entry.break_time4 !== '00:00:00' && entry.break_time4 !== null) numberOfBreaks++;

    const totalBreakTime = moment.duration(20 * numberOfBreaks, 'minutes');
    workedDuration = workedDuration.subtract(totalBreakTime);

    // Convertir la durée totale en minutes
    const totalMinutes = workedDuration.asMinutes();

    // Calculer les heures et les centièmes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const hundredths = Math.round((minutes * 100) / 60);

    // Formater le résultat en 'H,CC'
    const workedHoursInCentiemes = `${hours},${hundredths.toString().padStart(2, '0')}`;

    return workedHoursInCentiemes;
  };
  // Calculate the night allowance based on the clock-in and clock-out times
  const calculateNightAllowance = (clockInTime, clockOutTime) => {
    const nightStartTime = moment('22:00', 'HH:mm');
    const nightEndTime = moment('05:00', 'HH:mm').add(1, 'day');

    if (clockInTime.isBefore(nightStartTime) && clockOutTime.isAfter(nightEndTime)) {
      // Full night allowance
      const formattedNightAllowance = moment.utc(800 * 60000).format('HH:mm:ss');
      return formattedNightAllowance;
    } if (clockInTime.isBefore(nightStartTime) && clockOutTime.isAfter(nightStartTime)) {
      // Calculate night allowance based on clock-out time
      const nightDuration = moment.duration(clockOutTime.diff(nightStartTime));
      const nightAllowance = (nightDuration.asMinutes() / 60) * 100;
      const formattedNightAllowance = moment.utc(nightAllowance * 60000).format('HH:mm:ss');
      return formattedNightAllowance;
    } if (clockInTime.isBefore(nightEndTime) && clockOutTime.isAfter(nightEndTime)) {
      // Calculate night allowance based on clock-in time
      const nightDuration = moment.duration(nightEndTime.diff(clockInTime));
      const nightAllowance = (nightDuration.asMinutes() / 60) * 100;
      const formattedNightAllowance = moment.utc(nightAllowance * 60000).format('HH:mm:ss');
      return formattedNightAllowance;
    }

    return '0.00'; // No night allowance
  };

  // // Calculate the Sunday and public holiday allowance
  // const calculateSundayPHAllowance = (date) => {
  //   const momentDate = moment(date);
  //   if (momentDate.isoWeekday() === 7 || isPublicHoliday(momentDate)) {
  //     return '1'; // 1 day allowance
  //   }
  //   return '0'; // No allowance
  // };

  // // Calculate the IDAJ (extra time worked beyond 12 hours)
  // const calculateIDAJ = (workedHours) => {
  //   const twelveHours = 12 * 60; // 12 hours in minutes
  //   const workedMinutes = moment.duration(workedHours).asMinutes();

  //   if (workedMinutes > twelveHours) {
  //     const idajMinutes = workedMinutes - twelveHours;
  //     const idajHours = Math.floor(idajMinutes / 60);
  //     const idajHundredths = Math.floor((idajMinutes % 60) * 100 / 60);
  //     return `${idajHours}h${idajHundredths.toFixed(0).padStart(2, '0')}`;
  //   }

  //   return '0h00';
  // };

  // // Calculate the amplitude (total time span of the shift)
  // const calculateAmplitude = (clockInTime, clockOutTime) => {
  //   let simulatedClockOutTime = clockOutTime;

  //   if (clockOutTime.isBefore(clockInTime)) {
  //     simulatedClockOutTime = clockOutTime.clone().add(1, 'day');
  //   }

  //   const amplitude = moment.duration(simulatedClockOutTime.diff(clockInTime));
  //   const formattedAmplitude = moment.utc(amplitude.asMilliseconds()).format('HH:mm:ss');
  //   return formattedAmplitude;
  // };

  const exportToExcel = async () => {
    if (!dialogSelectedWeek) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner une semaine pour exporter.',
      });
      return;
    }
    const { startDate, endDate } = getWeekRange(dialogSelectedWeek);

    const filteredEntries = timeClockEntries.filter(
      (entry) => moment(entry.date).isSameOrAfter(moment(startDate))
        && moment(entry.date).isSameOrBefore(moment(endDate).endOf('day'))
        && entry.staff_id !== null
    );

    // Récupérer les informations du personnel correspondant à chaque entrée
    const entriesWithStaffInfo = await Promise.all(
      filteredEntries.map(async (entry) => {
        if (entry.staff_id) {
          const staff = await getStaffMember(entry.staff_id);
          return {
            ...entry,
            staff_name: staff ? staff.name : '',
            staff_last_name: staff ? staff.last_name : '',
            staff_company: staff ? staff.company : '',
            staff_diplome: staff ? staff.graduation : '',
          };
        }
        return {
          ...entry,
          staff_name: '',
          staff_last_name: '',
          staff_company: '',
          staff_diplome: '',
        };
      })
    );

    // Filtrer par société sélectionnée pour l'export
    let exportEntries = entriesWithStaffInfo;
    if (dialogExportCompany !== 'Tous') {
      exportEntries = entriesWithStaffInfo.filter((entry) => entry.staff_company === dialogExportCompany);
    }

    if (exportEntries.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Pas de données',
        text: 'Aucune donnée à exporter pour la sélection actuelle.',
      });
      return; // Stop the export if no data
    }

    // Groupement des entrées par employé
    const groupedEntries = exportEntries.reduce((acc, entry) => {
      const key = `${entry.staff_id}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(entry);
      return acc;
    }, {});

    // Pour chaque employé, trier ses entrées par date
    const sortedEntries = [];
    Object.values(groupedEntries).forEach((entries) => {
      entries.sort((a, b) => new Date(a.date) - new Date(b.date));
      sortedEntries.push(...entries);
    });

    // Calculer IDAJ, allocation de nuit, et allocation de dimanche et jours fériés pour chaque entrée
    const formattedEntries = sortedEntries.map((entry) => {
      const workedHours = calculateWorkedHoursInCentiemes(entry);
      const clockInTime = moment(entry.clock_in_time, 'HH:mm:ss');
      const clockOutTime = moment(entry.clock_out_time, 'HH:mm:ss');
      const nightAllowance = calculateNightAllowance(clockInTime, clockOutTime);
      const pauseCount = entry.break_time ? 1 : 0;
      const lunchBreakCount = entry.lunch_break_time ? 1 : 0;

      return {
        'SOCIETE': entry.staff_company,
        'NOM': entry.staff_name,
        'PRENOM': entry.staff_last_name,
        'DIPLOME': entry.staff_diplome || '',
        'DATE': moment(entry.date).format('DD/MM/YYYY'),
        'DEBUTSERVICE': entry.clock_in_time || '',
        'FINSERVICE': entry.clock_out_time || '',
        'DUREE': workedHours,
        'NUIT': nightAllowance,
        'PAUSE': lunchBreakCount,
        'AUTREPAUSE': pauseCount,
        'PR': entry.lunch_break_time || '',
        'PL': entry.break_time || '',
        'PAN1': entry.break_time2 || '',
        'PAN2': entry.break_time3 || '',
        'PAN3': entry.break_time4 || '',
      };
    });

    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(formattedEntries, {
      skipHeader: false,
    });

    XLSX.utils.book_append_sheet(wb, ws, 'TimeClock');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convertir en Blob pour sauvegarder le fichier
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    };

    // Formater le nom du fichier en utilisant startDate et endDate
    const filename = `Pointeuse_du_${moment(startDate).format('DD-MM-YYYY')}_au_${moment(endDate).format('DD-MM-YYYY')}_société_${dialogExportCompany}.xlsx`;

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), filename);
  };

  const generateWeeks = () => {
    const weeksInYear = moment().weeksInYear();
    const weeksArray = [];
    for (let week = 1; week <= weeksInYear; week++) {
      const startOfWeek = moment().week(week).startOf('isoWeek');
      weeksArray.push({
        label: `Semaine ${week} (${startOfWeek.format('DD/MM')})`,
        value: startOfWeek.format('YYYY-MM-DD'),
      });
    }
    setWeeks(weeksArray);
  };

  useEffect(() => {
    generateWeeks();
  }, []);

  const handleOpenRefusalDialog = (staffId) => {
    setCurrentStaffId(staffId);
    setOpenRefusalDialog(true);
  };

  const handleCloseRefusalDialog = () => {
    setOpenRefusalDialog(false);
    setCurrentStaffId(null);
  };

  const groupEntriesByEmployee = (entries) => entries.reduce((acc, entry) => {
    if (!acc[entry.staff_id]) {
      acc[entry.staff_id] = [];
    }
    acc[entry.staff_id].push(entry);
    return acc;
  }, {});

  function getAllDatesOfWeek(startDate, endDate) {
    const dates = [];
    let currentDate = moment(startDate);
    while (currentDate <= moment(endDate)) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }
    return dates;
  }

  const loadPdfMake = async () => {
    const { default: pdfMake } = await import('pdfmake/build/pdfmake');
    const { default: pdfFonts } = await import('pdfmake/build/vfs_fonts');
    pdfMake.vfs = pdfFonts.vfs;
    return pdfMake;
  };
  function getPausesWithData(entries, pauses) {
    const pausesWithData = new Set();

    entries.forEach((entry) => {
      pauses.forEach((pause) => {
        const timeValue = entry[pause.timeField];
        const locationValue = entry.break_locations ? entry.break_locations[pause.locationField] : null;

        if (
          (timeValue && timeValue !== '00:00:00' && timeValue !== '00:00' && timeValue !== '')
          || (locationValue && locationValue.trim() !== '')
        ) {
          pausesWithData.add(pause.timeField);
        }
      });
    });

    return pausesWithData;
  }

  function mergeEntriesWithDates(dates, entries, pausesWithData, pauses) {
    return dates.map((date) => {
      const entry = entries.find((e) => moment(e.date).format('YYYY-MM-DD') === date);

      const row = [
        { text: `${moment(date).format('dddd')}, ${moment(date).format('DD-MM-YYYY')}` },
        { text: entry && entry.is_public_holiday ? 'Oui' : 'Non' },
        { text: entry ? entry.clock_in_time || '' : '-', style: 'entryText' },
        { text: entry ? entry.clock_out_time || '' : '-', style: 'entryText' },
      ];

      // Ajouter dynamiquement les pauses avec données
      pauses.forEach((pause) => {
        if (pausesWithData.has(pause.timeField)) {
          if (entry) {
            row.push(
              { text: entry[pause.timeField] || '', style: 'entryText' },
              { text: entry.break_locations ? entry.break_locations[pause.locationField] || '' : '', style: 'entryText' }
            );
          }
          else {
            row.push(
              { text: '-', style: 'entryText' },
              { text: '-', style: 'entryText' }
            );
          }
        }
      });

      // Ajouter les colonnes fixes restantes
      if (entry) {
        const workedHours = calculateWorkedHours(entry);
        row.push(
          { text: workedHours || '0:00' },
          { text: entry.additional_tasks || '', style: 'entryText' },
          null, // Placeholder pour la signature de l'employeur
          null // Placeholder pour la signature de l'employé
        );
      }
      else {
        row.push(
          { text: '0:00' },
          { text: '-', style: 'entryText' },
          null, // Placeholder pour la signature de l'employeur
          null // Placeholder pour la signature de l'employé
        );
      }

      return row;
    });
  }

  function calculateTotalHours(dataForPDF, workedHoursIndex) {
    let totalMinutes = 0;

    dataForPDF.forEach((day) => {
      const hoursText = day[workedHoursIndex].text;
      if (hoursText !== '0:00' && hoursText !== '-') {
        const parts = hoursText.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        totalMinutes += hours * 60 + minutes; // Convertir tout en minutes
      }
    });

    const hours = Math.floor(totalMinutes / 60); // Convertir les minutes totales en heures
    const minutes = totalMinutes % 60; // Obtenir les minutes restantes

    const formattedTotal = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedTotal;
  }

  const exportToPDF = async () => {
    if (!dialogSelectedWeek) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner une semaine pour exporter les feuilles de route hebdomadaires.',
      });
      return;
    }
    const { startDate, endDate } = getWeekRange(dialogSelectedWeek);
    const weekNumber = moment(startDate).isoWeek();
    const startMoment = moment(startDate, 'YYYY-MM-DD');
    const endMoment = moment(endDate).endOf('day');

    const filteredEntries = timeClockEntries.filter((entry) => moment(entry.date).isSameOrAfter(startMoment)
      && moment(entry.date).isSameOrBefore(endMoment)
      && entry.status === 'Validé'
      && entry.staff_id !== null);

    // Récupérer les informations du personnel correspondant à chaque entrée
    const entriesWithStaffInfo = await Promise.all(
      filteredEntries.map(async (entry) => {
        if (entry.staff_id) {
          const staff = await getStaffMember(entry.staff_id);
          return {
            ...entry,
            staff_company: staff ? staff.company : '',
          };
        }
        return {
          ...entry,
          staff_company: '',
        };
      })
    );

    // Filtrer par société sélectionnée pour l'export
    let exportEntries = entriesWithStaffInfo;
    if (dialogExportCompany !== 'Tous') {
      exportEntries = entriesWithStaffInfo.filter((entry) => entry.staff_company === dialogExportCompany);
    }

    const entriesGroupedByEmployee = groupEntriesByEmployee(exportEntries);

    if (Object.keys(entriesGroupedByEmployee).length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Pas de PDF disponible',
        text: 'Aucun document PDF à extraire pour la sélection actuelle.',
      });
      return; // Arrêter l'export si aucune donnée
    }

    // Définir le tableau des pauses
    const pauses = [
      {
        timeField: 'lunch_break_time', locationField: 'lunch_break_time', label: 'Pause déjeuner', locationLabel: 'Lieu pause repas*'
      },
      {
        timeField: 'break_time', locationField: 'break_time', label: 'Pause 1', locationLabel: 'Lieu pause 1*'
      },
      {
        timeField: 'break_time2', locationField: 'break_time2', label: 'Pause 2', locationLabel: 'Lieu Pause 2*'
      },
      {
        timeField: 'break_time3', locationField: 'break_time3', label: 'Pause 3', locationLabel: 'Lieu Pause 3*'
      },
      {
        timeField: 'break_time4', locationField: 'break_time4', label: 'Pause 4', locationLabel: 'Lieu Pause 4*'
      },
    ];

    for (const staffId of Object.keys(entriesGroupedByEmployee)) {
      const entries = entriesGroupedByEmployee[staffId];
      const staffInfo = await getStaffMember(staffId);

      if (dialogExportCompany !== 'Tous' && staffInfo.company !== dialogExportCompany) {
        continue; // Passer à l'employé suivant si la société ne correspond pas
      }

      let employeeSignatureBase64 = '';

      // Vérifiez si la clé du contrat n'est pas nulle
      if (staffInfo.contrat) {
        try {
          // Obtenir l'URL signée de la signature de l'employé
          const employeeSignatureUrl = await getSignedUrl(staffInfo.contrat);
          if (!employeeSignatureUrl) {
            throw new Error('Failed to get signed URL.');
          }
          // Convertir l'URL signée en base64
          employeeSignatureBase64 = await getBase64FromUrl(employeeSignatureUrl);
          if (!employeeSignatureBase64) {
            throw new Error('Failed to convert employee signature to base64.');
          }
        }
        catch (error) {
          console.error('Error fetching or converting employee signature:', error);
        }
      }

      // Trier les entrées par date avant de les mapper
      entries.sort((a, b) => new Date(a.date) - new Date(b.date));

      const allDatesOfWeek = getAllDatesOfWeek(startDate, endDate);

      // Déterminer les pauses avec données
      const pausesWithData = getPausesWithData(entries, pauses);

      // Construire dynamiquement les en-têtes de table et les largeurs
      const tableHeader = [
        { text: 'Date', style: 'tableHeader' },
        { text: 'Jour férié', style: 'tableHeader' },
        { text: 'Heure de prise du service', style: 'tableHeader' },
        { text: 'Heure de fin de service', style: 'tableHeader' },
      ];
      const widths = ['*', '*', '*', '*'];

      pauses.forEach((pause) => {
        if (pausesWithData.has(pause.timeField)) {
          tableHeader.push({ text: pause.label, style: 'tableHeader' });
          tableHeader.push({ text: pause.locationLabel, style: 'tableHeader' });
          widths.push('*', '*'); // Ajuster les largeurs si nécessaire
        }
      });

      tableHeader.push(
        { text: 'Amplitude journalière', style: 'tableHeader' },
        { text: 'Tâches complémentaires ou activités annexes', style: 'tableHeader' },
        { text: 'Signature Employeur **', style: 'tableHeader' },
        { text: 'Signature Employé', style: 'tableHeader' },
      );
      widths.push('*', '*', 50, 'auto'); // Ajuster les largeurs si nécessaire

      // Calculer l'index de la colonne 'Amplitude journalière'
      const workedHoursIndex = tableHeader.findIndex((header) => header.text === 'Amplitude journalière');

      // Préparer les données pour le PDF
      const dataForPDF = mergeEntriesWithDates(allDatesOfWeek, entries, pausesWithData, pauses);

      // Calculer les heures totales
      const totalHours = calculateTotalHours(dataForPDF, workedHoursIndex);

      // Ajuster les données pour le PDF (inclure les signatures)
      const adjustedDataForPDF = dataForPDF.map((entry) => {
        const newEntry = [...entry];
        // Remplacer la signature de l'employeur
        newEntry[newEntry.length - 2] = { image: signatureDataURL, width: 50, alignment: 'center' };
        // Remplacer la signature de l'employé
        newEntry[newEntry.length - 1] = employeeSignatureBase64
          ? { image: employeeSignatureBase64, width: 30, alignment: 'center' }
          : { text: '', style: 'entryText' };
        return newEntry;
      });

      const staffObservations = entries
        .filter((entry) => entry.observations)
        .map((entry) => `${moment(entry.date).format('DD/MM/YYYY')}: ${entry.observations}`)
        .join('\n');

      const docDefinition = {
        pageOrientation: 'landscape',
        pageMargins: [30, 20, 30, 20],
        content: [
          // Ajouter les titres et le logo sur la même ligne
          {
            columns: [
              {
                image: logoDataURL,
                width: 100,
                alignment: 'left'
              },
              {
                stack: [
                  { text: 'TRANSPORT SANITAIRE', style: 'subHeader', alignment: 'center' },
                  { text: 'FEUILLE DE ROUTE HEBDOMADAIRE', style: 'headerLarge', alignment: 'center' },
                  { text: 'Accord-Cadre du 4 mai 2000 modifié', style: 'subHeader', alignment: 'center' },
                ],
                alignment: 'center'
              }
            ]
          },
          // Informations personnelles sur la même ligne
          {
            columns: [
              { text: `Nom: ${staffInfo.name} ${staffInfo.last_name}`, style: 'personalInfo', alignment: 'left' },
              { text: `Société: ${staffInfo.company}`, style: 'personalInfo', alignment: 'center' },
              { text: `Poste occupé: ${staffInfo.graduation}`, style: 'personalInfo', alignment: 'right' },
            ]
          },
          // Numéro de semaine centré
          { text: `Numéro de semaine: ${weekNumber} (du ${moment(startDate).format('DD-MM-YYYY')} au ${moment(endDate).format('DD-MM-YYYY')})`, style: 'personalInfo', alignment: 'center' },
          // Le reste du contenu demeure inchangé
          {
            style: 'tableExample',
            table: {
              headerRows: 1,
              widths: widths,
              body: [
                tableHeader,
                ...adjustedDataForPDF
              ]
            },
            layout: 'lightHorizontalLines'
          },
          { text: `Cumul hebdomadaire: ${totalHours}`, style: 'totalHours' },
          // Ajouter les notes de bas de page
          { text: '* Mettre "ENT" pour repas pris à l\'entreprise. "DOM" pour repas pris au domicile et "EXT" pour repas pris hors de l\'entreprise et du domicile.', style: 'footnote' },
          { text: '** La signature doit être apposée chaque jour, ou en cas d\'impossibilité dans les meilleurs délais. En tout état de cause la feuille de route doit être signée chaque semaine.', style: 'footnote' },
          // Ajouter le tableau pour "Observations éventuelles"
          {
            style: 'tableObservation',
            table: {
              widths: ['*'],
              body: [
                [{ text: 'Observations éventuelles', style: 'tableHeader' }],
                [{ text: staffObservations || 'Aucune observation', style: 'observationField', margin: [0, 10, 0, 10] }]
              ]
            },
            layout: 'noBorders'
          },
        ],
        styles: {
          headerLarge: {
            fontSize: 16,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 5]
          },
          subHeader: {
            fontSize: 15,
            italics: true,
            alignment: 'center',
            margin: [0, 0, 0, 5]
          },
          personalInfo: {
            fontSize: 12,
            margin: [0, 5, 0, 5],
            alignment: 'center'
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableObservation: {
            margin: [0, 15, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: 'black',
            fillColor: '#eeeeee',
            alignment: 'center'
          },
          totalHours: {
            fontSize: 12,
            bold: true,
            alignment: 'right',
            margin: [0, 5, 0, 10]
          },
          entryText: {
            fontSize: 9,
            alignment: 'center'
          },
          footnote: {
            fontSize: 8,
            margin: [0, 5, 0, 0]
          },
          observationField: {
            fontSize: 10,
            alignment: 'left',
            margin: [0, 5, 0, 5]
          },
        }
      };
      const pdfMake = await loadPdfMake();
      pdfMake.createPdf(docDefinition).download(`Feuille_de_route_hebdomadaire_${staffInfo.name}_${staffInfo.last_name}_de_${startDate}_à_${endDate}.pdf`);
    }
  };

  const handleExportPDF = async () => {
    if (!dialogSelectedWeek) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner une semaine pour exporter les feuilles de route hebdomadaires.',
      });
      return;
    }
    const { startDate, endDate } = getWeekRange(dialogSelectedWeek);
    await exportToPDF(startDate, endDate);
  };

  const handleExport = () => {
    setOpenExportDialog(false);
    if (exportType === 'excel') {
      exportToExcel(dialogSelectedWeek, dialogExportCompany);
    }
    else if (exportType === 'pdf') {
      handleExportPDF(dialogSelectedWeek, dialogExportCompany);
    }
  };

  // Fetch staff from the server
  const getStaff = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      setLoading(false);
      return response.data.staff;
    }
    catch (error) {
      console.error('Failed to fetch staff', error);
      throw error;
    }
  };

  const getCrews = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}crews`, { headers });
      setLoading(false);
      return response.data.crews;
    }
    catch (error) {
      console.error('Failed to fetch crews', error);
      throw error;
    }
  };

  // Fetch time clock entries from the server
  const getTimeClockEntries = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}timeClock`, { headers });
      setLoading(false);
      return response.data.timeClockEntries;
    }
    catch (error) {
      console.error('Failed to fetch time clock entries', error);
      throw error;
    }
  };

  const updateTimeClockEntry = async (staffId, date, timeClockEntry) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à modifier des entrées de pointeuse!",
      });
      return;
    }
    try {
      await axios.put(`${BASE_URL}timeClock/${staffId}/${date}`, timeClockEntry, { headers });
    }
    catch (error) {
      console.error(`Failed to update time clock entry for staff ${staffId}`, error);
      throw error;
    }
  };

  const deleteTimeClockEntry = async (staffId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à supprimer des entrées de pointeuse!",
      });
      return;
    }
    try {
      await axios.delete(`${BASE_URL}timeClock/${staffId}/${dateFilter}`, { headers });
    }
    catch (error) {
      console.error(`Failed to delete time clock entry for staff ${staffId}`, error);
      throw error;
    }
    window.location.reload();
  };

  const fetchData = async () => {
    try {
      const [crewsResponse, timeClockEntriesResponse, vehiclesData, staffData] = await Promise.all([
        getCrews(),
        getTimeClockEntries(),
        getVehicles(),
        getStaff(),
      ]);

      const vehiclesLookup = vehiclesData.reduce((lookup, vehicle) => {
        lookup[vehicle.id] = vehicle;
        return lookup;
      }, {});

      const staffLookup = staffData.reduce((lookup, staff) => {
        lookup[staff.id] = staff;
        return lookup;
      }, {});

      const enrichedCrews = crewsResponse.map((crew) => ({
        ...crew,
        vehicle: vehiclesLookup[crew.vehicule_id],
        staff_1: staffLookup[crew.staff_id_1],
        staff_2: staffLookup[crew.staff_id_2],
      }));

      // Filtrer les équipages par date
      let filteredCrews = enrichedCrews.filter(
        (crew) => moment(crew.date).format('YYYY-MM-DD') === dateFilter
      );

      // Filtrer les équipages par société sélectionnée (basé sur crew.vehicle.societe)
      if (selectedCompany !== 'Tous') {
        filteredCrews = filteredCrews.filter((crew) => crew.vehicle && crew.vehicle.societe === selectedCompany);
      }

      // Trier les équipages par code de véhicule (du plus petit au plus grand)
      filteredCrews.sort((a, b) => {
        if (a.vehicle && b.vehicle) {
          return a.vehicle.code.localeCompare(b.vehicle.code, 'en', { numeric: true });
        }
        return 0;
      });

      // Mettre à jour l'état avec les équipages filtrés
      setCrews(filteredCrews);

      // Mettre à jour l'état avec les entrées de pointeuse récupérées
      setTimeClockEntries(timeClockEntriesResponse);
    }
    catch (error) {
      console.error('Failed to fetch data', error);
    }
  };

  const handleStatusChange = (staffId, newStatus, refusalReason = '') => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à modifier des entrées de pointeuse!",
      });
      return;
    }

    const updatedTimeClockEntries = timeClockEntries.map((entry) => {
      if (entry.staff_id === staffId && entry.date === isoDateFilter) {
        return {
          ...entry,
          status: newStatus,
          refusal_reason: newStatus === 'Refusé' ? refusalReason : '',
          validation_employee: newStatus === 'Refusé' ? false : entry.validation_employee,
        };
      }
      return entry;
    });

    setTimeClockEntries(updatedTimeClockEntries);
    const timeClockEntry = updatedTimeClockEntries.find((entry) => entry.staff_id === staffId && entry.date === isoDateFilter);
    updateTimeClockEntry(staffId, dateFilter, timeClockEntry);
  };

  const toggleRefusalField = (staffId) => {
    setRefusalState((prevState) => ({
      ...prevState,
      [staffId]: {
        ...prevState[staffId],
        show: !prevState[staffId]?.show,
        reason: prevState[staffId]?.reason || ''
      }
    }));
  };

  const handleRefusalReasonChange = (staffId, reason) => {
    setRefusalState((prevState) => ({
      ...prevState,
      [staffId]: {
        ...prevState[staffId],
        reason: reason
      }
    }));
  };

  const handleSendClick = (staffId) => {
    const refusalInfo = refusalState[staffId];
    handleStatusChange(staffId, 'Refusé', refusalInfo.reason);
    toggleRefusalField(staffId); // Fermer le champ après l'envoi
  };

  const handleClockChange = async (staffId, field, value) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à modifier des entrées de pointeuse!",
      });
      return;
    }

    const updatedTimeClockEntries = timeClockEntries.map((entry) => {
      if (entry.staff_id === staffId && entry.date === isoDateFilter) {
        const updatedEntry = { ...entry, [field]: value === '' ? null : value };

        // Si le champ est 'break_locations', mettre à jour le champ JSON
        if (field.startsWith('break_location')) {
          const breakLocationField = field.replace('break_location_', '');
          updatedEntry.break_locations = {
            ...entry.break_locations,
            [breakLocationField]: value,
          };
        }

        return updatedEntry;
      }
      return entry;
    });

    setTimeClockEntries(updatedTimeClockEntries);

    const timeClockEntry = updatedTimeClockEntries.find(
      (entry) => entry.staff_id === staffId && entry.date === isoDateFilter
    );

    await updateTimeClockEntry(staffId, dateFilter, timeClockEntry);
  };

  const handleDelete = async (staffId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à supprimer des entrées de pointeuse!",
      });
      return;
    }
    // Delete time clock entry in the server
    await deleteTimeClockEntry(staffId);
    const updatedTimeClockEntries = timeClockEntries.filter((entry) => entry.staff_id !== staffId);
    setTimeClockEntries(updatedTimeClockEntries);
  };

  const handleDateChange = (event) => {
    // Update the selected date and fetch data for the new date
    setDateFilter(event.target.value);
  };

  const sendNotificationsToCrew = async (staffIds) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à envoyer des notifications!",
      });
      return;
    }
    try {
      const notifications = staffIds.map((staffId) => sendNotificationEmail(staffId, dateFilter));
      await Promise.all(notifications);
      Swal.fire({
        icon: 'success',
        title: 'Notifications envoyées!',
        text: 'Les notifications ont été envoyées aux membres de l\'équipage.'
      });
    }
    catch (error) {
      console.error('Failed to send notification emails to all staff', error);
    }
  };

  const sendNotificationsToAll = async () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à envoyer des notifications!",
      });
      return;
    }
    try {
      // Create a new Set to store unique staff IDs
      const staffIds = new Set();

      // Iterate over all crews and add each staff ID to the set
      crews.forEach((crew) => {
        staffIds.add(crew.staff_id_1);
        if (crew.staff_id_2) {
          staffIds.add(crew.staff_id_2);
        }
      });

      // Convert the set back to an array
      const uniqueStaffIds = Array.from(staffIds);

      // Send a notification to each staff member
      const notifications = uniqueStaffIds.map((staffId) => sendNotificationEmail(staffId, dateFilter));
      await Promise.all(notifications);

      Swal.fire({
        icon: 'success',
        title: 'Notifications envoyées!',
        text: 'Les notifications ont été envoyées à tous les membres du personnel.'
      });
    }
    catch (error) {
      console.error('Failed to send notification emails to all staff', error);
    }
  };

  const sendNotificationsToCrewSMS = async (staffIds) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à envoyer des notifications!",
      });
      return;
    }
    try {
      const notifications = staffIds.map((staffId) => sendNotificationSMS(staffId, dateFilter));
      await Promise.all(notifications);
      Swal.fire({
        icon: 'success',
        title: 'Notifications envoyées!',
        text: 'Les notifications ont été envoyées aux membres de l\'équipage.'
      });
    }
    catch (error) {
      console.error('Failed to send SMS notifications to crew members', error);
    }
  };

  const sendNotificationsToAllSMS = async () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous n'êtes pas autorisé à envoyer des notifications!",
      });
      return;
    }
    try {
      const staffIds = new Set();

      crews.forEach((crew) => {
        staffIds.add(crew.staff_id_1);
        if (crew.staff_id_2) {
          staffIds.add(crew.staff_id_2);
        }
      });

      const uniqueStaffIds = Array.from(staffIds);

      const notifications = uniqueStaffIds.map((staffId) => sendNotificationSMS(staffId, dateFilter));
      await Promise.all(notifications);

      Swal.fire({
        icon: 'success',
        title: 'Notifications envoyées!',
        text: 'Les notifications ont été envoyées à tous les membres du personnel.'
      });
    }
    catch (error) {
      console.error('Failed to send SMS notifications to all staff members', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isoDateFilter, selectedCompany]); // Ajout de selectedCompany comme dépendance

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      // You can set your default TextField color here
      primary: {
        main: '#000',
      },
    },
  });

  // Your Dark theme
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#feba00',
      },
    },
  });
  // Filtrer les équipages en fonction de la recherche et de la société sélectionnée
  const filteredCrews = crews.filter((crew) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesName = crew.staff_1.name.toLowerCase().includes(searchLower)
                        || (crew.staff_2 && crew.staff_2.name.toLowerCase().includes(searchLower));
    const matchesSurname = crew.staff_1.last_name.toLowerCase().includes(searchLower)
                           || (crew.staff_2 && crew.staff_2.last_name.toLowerCase().includes(searchLower));
    const matchesVehicle = crew.vehicle.code.toLowerCase().includes(searchLower);

    const matchesCompany = selectedCompany === 'Tous' || (crew.vehicle && crew.vehicle.societe === selectedCompany);

    return (matchesName || matchesSurname || matchesVehicle) && matchesCompany;
  });

  if (loading) {
    return <AmbysoftLoader />;
  }
  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      {/* Sélection de la semaine */}
      <Box sx={{ mb: 2 }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Sélecteur de date avec flèches */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => setDateFilter(moment(dateFilter).subtract(1, 'days').format('YYYY-MM-DD'))}>
                  <ArrowBackIcon />
                </IconButton>
                <TextField
                  type="date"
                  value={moment(dateFilter).format('YYYY-MM-DD')}
                  onChange={handleDateChange}
                  sx={{ mx: 1 }}
                />
                <IconButton onClick={() => setDateFilter(moment(dateFilter).add(1, 'days').format('YYYY-MM-DD'))}>
                  <ArrowForwardIcon />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2 }}>
                  {moment(dateFilter).locale('fr').format('dddd D MMMM YYYY')}
                </Typography>
              </Box>
            </Grid>

            {/* Boutons d'exportation et de notification */}
            <Grid item xs={12} md={6}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveAltIcon />}
                  onClick={() => {
                    setExportType('excel');
                    setOpenExportDialog(true);
                  }}
                  sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    },
                  }}
                >
                  Exporter Excel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={() => {
                    setExportType('pdf');
                    setOpenExportDialog(true);
                  }}
                  sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    },
                  }}
                >
                  Exporter les feuilles de route
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EmailIcon />}
                  onClick={sendNotificationsToAll}
                  sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    },
                  }}
                >
                  Envoyer planning Email
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SmsIcon />}
                  onClick={sendNotificationsToAllSMS}
                  sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    },
                  }}
                >
                  Envoyer planning SMS
                </Button>
              </Stack>
            </Grid>

            {/* Champ de recherche */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                startIcon={<SearchIcon />}
                label="Recherche (Nom, Prénom, Véhicule)"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Filtre par société */}
      <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filtrer par société</InputLabel>
          <Select
            value={selectedCompany}
            label="Filtrer par société"
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            <MenuItem value="Tous">Tous</MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.code} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Dialog open={openRefusalDialog} onClose={handleCloseRefusalDialog}>
        <DialogTitle>Raison du refus</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Raison du refus"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={refusalState[currentStaffId]?.reason || ''}
            onChange={(e) => handleRefusalReasonChange(currentStaffId, e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRefusalDialog}>Annuler</Button>
          <Button
            onClick={() => {
              handleSendClick(currentStaffId);
              handleCloseRefusalDialog();
            }}
            color="primary"
          >
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openExportDialog} onClose={() => setOpenExportDialog(false)}>
        <DialogTitle>Sélectionnez les options d'exportation</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" sx={{ minWidth: 200, marginTop: 1 }}>
            <InputLabel>Semaine</InputLabel>
            <Select
              value={dialogSelectedWeek}
              onChange={(e) => setDialogSelectedWeek(e.target.value)}
              label="Semaine"
            >
              <MenuItem value="">
                <em>Sélectionnez une semaine</em>
              </MenuItem>
              {weeks.map((week) => (
                <MenuItem key={week.value} value={week.value}>
                  {week.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 200, marginTop: 1 }}>
            <InputLabel>Société</InputLabel>
            <Select
              value={dialogExportCompany}
              onChange={(e) => setDialogExportCompany(e.target.value)}
              label="Société"
            >
              <MenuItem value="Tous">Tous</MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.code} value={company.code}>
                  {company.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenExportDialog(false)}>Annuler</Button>
          <Button
            onClick={handleExport}
            disabled={!dialogSelectedWeek}
          >
            Exporter
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container justifyContent="center">
        {filteredCrews.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Aucun équipage trouvé pour la société sélectionnée et la date choisie.
            </Typography>
          </Grid>
        ) : (
          filteredCrews.map((crew) => {
            const timeClockEntry1 = timeClockEntries.find(
              (entry) => entry.staff_id === crew.staff_id_1 && moment(entry.date).format('YYYY-MM-DD') === dateFilter
            ) || {};
            const timeClockEntry2 = timeClockEntries.find(
              (entry) => entry.staff_id === crew.staff_id_2 && moment(entry.date).format('YYYY-MM-DD') === dateFilter
            ) || {};
            return (
              <Grid item key={crew.id}>
                <Box
                  component={Card}
                  className="crew-card"
                  sx={{
                    width: { xs: '100%', sm: '45rem', md: '31rem' },
                    maxHeight: 980,
                    overflow: 'hidden',
                    mx: 'auto',
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" component="h2" sx={{ textAlign: 'center' }}>
                      {crew.vehicle.code}
                    </Typography>
                    <div className="staff-info-row">

                      <div className="staff-info">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleStatusChange(crew.staff_id_1, 'Validé')}
                          sx={{
                            color: 'white',
                            width: '50%',
                            margin: 'auto',
                            borderBlockColor: 'black',
                            backgroundColor: '#B6CBA0',
                            '&:hover': { backgroundColor: 'grey', },
                          }}
                        >
                          Validé
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenRefusalDialog(crew.staff_id_1)}
                          sx={{
                            color: 'white',
                            width: '50%',
                            margin: 'auto',
                            borderBlockColor: 'black',
                            backgroundColor: '#FF6961',
                            '&:hover': { backgroundColor: 'grey', },
                          }}
                        >
                          Refusé
                        </Button>
                        {refusalState[crew.staff_id_1]?.show && (
                          <TextField
                            multiline
                            rows={4}
                            label="Raison du refus"
                            variant="outlined"
                            value={refusalState[crew.staff_id_1].reason}
                            onChange={(e) => handleRefusalReasonChange(crew.staff_id_1, e.target.value)}
                            sx={{ width: '100%', marginTop: '8px' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleSendClick(crew.staff_id_1)}
                                    edge="end"
                                  >
                                    <SendIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                        {timeClockEntry1.status === 'Refusé' && (
                          <div className="staffRefus">
                            Raison du refus : {timeClockEntry1.refusal_reason}
                          </div>
                        )}
                        {timeClockEntry1.justification && (
                          <div className="employeeJustification">
                            <Typography variant="body2" color="textSecondary">
                              <strong>Justification de l'employé :</strong> {timeClockEntry1.justification}
                            </Typography>
                          </div>
                        )}

                        <Typography variant="body2" component="p" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {timeClockEntry1.validation_employee && (
                            <CheckCircleIcon color="success" sx={{ mr: 1 }} /> // Icône de succès avec une marge à droite
                          )}
                          {crew.staff_1.name} {crew.staff_1.last_name}
                        </Typography>
                        <TextField
                          type="time"
                          label="Début"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={timeClockEntry1.clock_in_time || ''}
                          onChange={(e) => handleClockChange(crew.staff_id_1, 'clock_in_time', e.target.value)}
                          disabled={timeClockEntry1.status === 'Validé'}
                        />
                        <TextField
                          type="time"
                          label="Fin"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={timeClockEntry1.clock_out_time || ''}
                          onChange={(e) => handleClockChange(crew.staff_id_1, 'clock_out_time', e.target.value)}
                          disabled={timeClockEntry1.status === 'Validé'}
                        />
                        <div className="pause-info">
                          <TextField
                            sx={{ mb: 1 }}
                            label="Pause repas"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="time"
                            value={timeClockEntry1.lunch_break_time || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_1, 'lunch_break_time', e.target.value)}
                            disabled={timeClockEntry1.status === 'Validé'}
                          />
                          <FormControl sx={{ width: '50%' }}>
                            <InputLabel>Lieu</InputLabel>
                            <Select
                              value={timeClockEntry1.break_locations?.lunch_break_time || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_1, 'break_location_lunch_break_time', e.target.value)}
                              label="Lieu"
                              disabled={timeClockEntry1.status === 'Validé'}
                            >
                              <MenuItem value="">
                                <em>Aucun</em>
                              </MenuItem>
                              <MenuItem value="EXT">EXT</MenuItem>
                              <MenuItem value="DOM">DOM</MenuItem>
                              <MenuItem value="ENT">ENT</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            type="time"
                            label="Pause"
                            sx={{ mb: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={timeClockEntry1.break_time || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_1, 'break_time', e.target.value)}
                            disabled={timeClockEntry1.status === 'Validé'}
                          />
                          <FormControl sx={{ width: '50%' }}>
                            <InputLabel>Lieu</InputLabel>
                            <Select
                              value={timeClockEntry1.break_locations?.break_time || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_1, 'break_location_break_time', e.target.value)}
                              label="Lieu"
                              disabled={timeClockEntry1.status === 'Validé'}
                            >
                              <MenuItem value="">
                                <em>Aucun</em>
                              </MenuItem>
                              <MenuItem value="EXT">EXT</MenuItem>
                              <MenuItem value="DOM">DOM</MenuItem>
                              <MenuItem value="ENT">ENT</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            type="time"
                            label="Pause 2"
                            sx={{ mb: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={timeClockEntry1.break_time2 || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_1, 'break_time2', e.target.value)}
                            disabled={timeClockEntry1.status === 'Validé'}
                          />
                          <FormControl sx={{ width: '50%' }}>
                            <InputLabel>Lieu</InputLabel>
                            <Select
                              value={timeClockEntry1.break_locations?.break_time2 || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_1, 'break_location_break_time2', e.target.value)}
                              label="Lieu"
                              disabled={timeClockEntry1.status === 'Validé'}
                            >
                              <MenuItem value="">
                                <em>Aucun</em>
                              </MenuItem>
                              <MenuItem value="EXT">EXT</MenuItem>
                              <MenuItem value="DOM">DOM</MenuItem>
                              <MenuItem value="ENT">ENT</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            type="time"
                            label="Pause 3"
                            sx={{ mb: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={timeClockEntry1.break_time3 || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_1, 'break_time3', e.target.value)}
                            disabled={timeClockEntry1.status === 'Validé'}
                          />
                          <FormControl sx={{ width: '50%' }}>
                            <InputLabel>Lieu</InputLabel>
                            <Select
                              value={timeClockEntry1.break_locations?.break_time3 || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_1, 'break_location_break_time3', e.target.value)}
                              label="Lieu"
                              disabled={timeClockEntry1.status === 'Validé'}
                            >
                              <MenuItem value="">
                                <em>Aucun</em>
                              </MenuItem>
                              <MenuItem value="EXT">EXT</MenuItem>
                              <MenuItem value="DOM">DOM</MenuItem>
                              <MenuItem value="ENT">ENT</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            type="time"
                            label="Pause 4"
                            sx={{ mb: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={timeClockEntry1.break_time4 || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_1, 'break_time4', e.target.value)}
                            disabled={timeClockEntry1.status === 'Validé'}
                          />
                          <FormControl sx={{ width: '50%' }}>
                            <InputLabel>Lieu</InputLabel>
                            <Select
                              value={timeClockEntry1.break_locations?.break_time4 || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_1, 'break_location_break_time4', e.target.value)}
                              label="Lieu"
                              disabled={timeClockEntry1.status === 'Validé'}
                            >
                              <MenuItem value="">
                                <em>Aucun</em>
                              </MenuItem>
                              <MenuItem value="EXT">EXT</MenuItem>
                              <MenuItem value="DOM">DOM</MenuItem>
                              <MenuItem value="ENT">ENT</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                          <InputLabel>Tâches annexes</InputLabel>
                          <Select
                            value={timeClockEntry1.additional_tasks || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_1, 'additional_tasks', e.target.value)}
                            label="Tâches annexes"
                            disabled={timeClockEntry1.status === 'Validé'}
                          >
                            <MenuItem value="">
                              <em>Aucun</em>
                            </MenuItem>
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                          </Select>
                        </FormControl>

                        <Tooltip title="Supprimer les entrées" arrow>
                          <IconButton
                            onClick={() => handleDelete(crew.staff_id_1)}
                            disabled={timeClockEntry1.status === 'Validé'}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {isAuthorizedRole() && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                color: 'white',
                                borderBlockColor: 'black',
                                backgroundColor: '#505154',
                                '&:hover': { backgroundColor: 'grey', },
                              }}
                              onClick={() => sendNotificationEmail(crew.staff_id_1, dateFilter)}
                            >
                              Envoie Planning Email
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                color: 'white',
                                borderBlockColor: 'black',
                                backgroundColor: '#505154',
                                '&:hover': { backgroundColor: 'grey', },
                              }}
                              onClick={() => sendNotificationSMS(crew.staff_id_1, dateFilter)}
                            >
                              Envoie Planning SMS
                            </Button>
                          </>
                        )}
                      </div>

                      <div className="staff-info">
                        {crew.staff_id_2 && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleStatusChange(crew.staff_id_2, 'Validé')}
                              sx={{
                                color: 'white',
                                width: '50%',
                                margin: 'auto',
                                borderBlockColor: 'black',
                                backgroundColor: '#B6CBA0',
                                '&:hover': { backgroundColor: 'grey', },
                              }}
                            >
                              Validé
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => toggleRefusalField(crew.staff_id_2)}
                              sx={{
                                color: 'white',
                                width: '50%',
                                margin: 'auto',
                                borderBlockColor: 'black',
                                backgroundColor: '#FF6961',
                                '&:hover': { backgroundColor: 'grey', },
                              }}
                            >
                              Refusé
                            </Button>
                            {refusalState[crew.staff_id_2]?.show && (
                            <TextField
                              multiline
                              rows={4}
                              label="Raison du refus"
                              variant="outlined"
                              value={refusalState[crew.staff_id_2].reason}
                              onChange={(e) => handleRefusalReasonChange(crew.staff_id_2, e.target.value)}
                              sx={{ width: '100%', marginTop: '8px' }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => handleSendClick(crew.staff_id_2)}
                                      edge="end"
                                    >
                                      <SendIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            )}
                            {timeClockEntry2.status === 'Refusé' && (
                            <div className="staffRefus">
                              Raison du refus : {timeClockEntry2.refusal_reason}
                            </div>
                            )}
                          </>
                        )}
                        {timeClockEntry2.justification && (
                          <div className="employeeJustification">
                            <Typography variant="body2" color="textSecondary">
                              <strong>Justification de l'employé :</strong> {timeClockEntry2.justification}
                            </Typography>
                          </div>
                        )}

                        {crew.staff_id_2 && (
                        <>
                          <Typography variant="body2" component="p" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {timeClockEntry2.validation_employee && (
                            <CheckCircleIcon color="success" sx={{ mr: 1 }} /> // Icône de succès avec une marge à droite
                            )}
                            {crew.staff_2.name} {crew.staff_2.last_name}
                          </Typography>
                          <TextField
                            type="time"
                            label="Début"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={timeClockEntry2.clock_in_time || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_2, 'clock_in_time', e.target.value)}
                            disabled={timeClockEntry2.status === 'Validé'}
                          />
                          <TextField
                            type="time"
                            label="Fin"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={timeClockEntry2.clock_out_time || ''}
                            onChange={(e) => handleClockChange(crew.staff_id_2, 'clock_out_time', e.target.value)}
                            disabled={timeClockEntry2.status === 'Validé'}
                          />

                          <div className="pause-info">
                            <TextField
                              type="time"
                              label="Pause repas"
                              sx={{ mb: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={timeClockEntry2.lunch_break_time || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_2, 'lunch_break_time', e.target.value)}
                              disabled={timeClockEntry2.status === 'Validé'}
                            />
                            <FormControl sx={{ width: '50%' }}>
                              <InputLabel>Lieu</InputLabel>
                              <Select
                                value={timeClockEntry1.break_locations?.lunch_break_time || ''}
                                onChange={(e) => handleClockChange(crew.staff_id_1, 'break_location_lunch_break_time', e.target.value)}
                                label="Lieu"
                                disabled={timeClockEntry1.status === 'Validé'}
                              >
                                <MenuItem value="">
                                  <em>Aucun</em>
                                </MenuItem>
                                <MenuItem value="EXT">EXT</MenuItem>
                                <MenuItem value="DOM">DOM</MenuItem>
                                <MenuItem value="ENT">ENT</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              type="time"
                              label="Pause"
                              sx={{ mb: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={timeClockEntry2.break_time || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_2, 'break_time', e.target.value)}
                              disabled={timeClockEntry2.status === 'Validé'}
                            />
                            <FormControl sx={{ width: '50%' }}>
                              <InputLabel>Lieu</InputLabel>
                              <Select
                                value={timeClockEntry2.break_locations?.break_time || ''}
                                onChange={(e) => handleClockChange(crew.staff_id_2, 'break_location_break_time', e.target.value)}
                                label="Lieu"
                                disabled={timeClockEntry2.status === 'Validé'}
                              >
                                <MenuItem value="">
                                  <em>Aucun</em>
                                </MenuItem>
                                <MenuItem value="EXT">EXT</MenuItem>
                                <MenuItem value="DOM">DOM</MenuItem>
                                <MenuItem value="ENT">ENT</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              type="time"
                              label="Pause 2"
                              sx={{ mb: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={timeClockEntry2.break_time2 || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_2, 'break_time2', e.target.value)}
                              disabled={timeClockEntry2.status === 'Validé'}
                            />
                            <FormControl sx={{ width: '50%' }}>
                              <InputLabel>Lieu</InputLabel>
                              <Select
                                value={timeClockEntry2.break_locations?.break_time2 || ''}
                                onChange={(e) => handleClockChange(crew.staff_id_2, 'break_location_break_time2', e.target.value)}
                                label="Lieu"
                                disabled={timeClockEntry2.status === 'Validé'}
                              >
                                <MenuItem value="">
                                  <em>Aucun</em>
                                </MenuItem>
                                <MenuItem value="EXT">EXT</MenuItem>
                                <MenuItem value="DOM">DOM</MenuItem>
                                <MenuItem value="ENT">ENT</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              type="time"
                              label="Pause 3"
                              sx={{ mb: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={timeClockEntry2.break_time3 || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_2, 'break_time3', e.target.value)}
                              disabled={timeClockEntry2.status === 'Validé'}
                            />
                            <FormControl sx={{ width: '50%' }}>
                              <InputLabel>Lieu</InputLabel>
                              <Select
                                value={timeClockEntry2.break_locations?.break_time3 || ''}
                                onChange={(e) => handleClockChange(crew.staff_id_2, 'break_location_break_time3', e.target.value)}
                                label="Lieu"
                                disabled={timeClockEntry2.status === 'Validé'}
                              >
                                <MenuItem value="">
                                  <em>Aucun</em>
                                </MenuItem>
                                <MenuItem value="EXT">EXT</MenuItem>
                                <MenuItem value="DOM">DOM</MenuItem>
                                <MenuItem value="ENT">ENT</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              type="time"
                              label="Pause 4"
                              color="primary"
                              sx={{ mb: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={timeClockEntry2.break_time4 || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_2, 'break_time4', e.target.value)}
                              disabled={timeClockEntry2.status === 'Validé'}
                            />
                            <FormControl sx={{ width: '50%' }}>
                              <InputLabel>Lieu</InputLabel>
                              <Select
                                value={timeClockEntry2.break_locations?.break_time4 || ''}
                                onChange={(e) => handleClockChange(crew.staff_id_2, 'break_location_break_time4', e.target.value)}
                                label="Lieu"
                                disabled={timeClockEntry2.status === 'Validé'}
                              >
                                <MenuItem value="">
                                  <em>Aucun</em>
                                </MenuItem>
                                <MenuItem value="EXT">EXT</MenuItem>
                                <MenuItem value="DOM">DOM</MenuItem>
                                <MenuItem value="ENT">ENT</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                            <InputLabel>Tâches complémentaires</InputLabel>
                            <Select
                              value={timeClockEntry1.additional_tasks || ''}
                              onChange={(e) => handleClockChange(crew.staff_id_2, 'additional_tasks', e.target.value)}
                              label="Tâches complémentaires"
                              disabled={timeClockEntry1.status === 'Validé'}
                            >
                              <MenuItem value="">
                                <em>Aucun</em>
                              </MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                            </Select>
                          </FormControl>
                          <Tooltip title="Supprimer les entrées" arrow>
                            <IconButton
                              onClick={() => handleDelete(crew.staff_id_2)}
                              disabled={timeClockEntry2.status === 'Validé'}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          {isAuthorizedRole() && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                color: 'white',
                                borderBlockColor: 'black',
                                backgroundColor: '#505154',
                                '&:hover': { backgroundColor: 'grey', },
                              }}
                              onClick={() => sendNotificationEmail(crew.staff_id_2, dateFilter)}
                            >
                              Envoie planning Email
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                color: 'white',
                                borderBlockColor: 'black',
                                backgroundColor: '#505154',
                                '&:hover': { backgroundColor: 'grey', },
                              }}
                              onClick={() => sendNotificationSMS(crew.staff_id_2, dateFilter)}
                            >
                              Envoie Planning SMS
                            </Button>
                          </>
                          )}
                        </>
                        )}
                      </div>
                    </div>

                    <div className="crew-sendButtons">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          color: 'white',
                          borderBlockColor: 'black',
                          backgroundColor: '#505154',
                          '&:hover': { backgroundColor: 'grey', },
                        }}
                        onClick={() => sendNotificationsToCrew([crew.staff_id_1, crew.staff_id_2, dateFilter])}
                      >
                        Envoie planning équipage Email
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          color: 'white',
                          borderBlockColor: 'black',
                          backgroundColor: '#505154',
                          '&:hover': { backgroundColor: 'grey', },
                        }}
                        onClick={() => sendNotificationsToCrewSMS([crew.staff_id_1, crew.staff_id_2, dateFilter])}
                      >
                        Envoie planning équipage SMS
                      </Button>
                    </div>
                  </CardContent>
                </Box>
              </Grid>
            );
          })
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default React.memo(TimeClock);
