/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
// ProceduresResponses.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Rating,
  Slider,
} from '@mui/material';
import {
  Book as ChapterIcon,
  Info as InfoIcon,
  TextFields as TextIcon,
  Filter1 as NumberIcon,
  Event as DateIcon,
  DateRange as DateRangeIcon,
  AccessTime as DateTimeIcon,
  AttachFile as FileIcon,
  Notes as MultilineIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckboxIcon,
  ToggleOn as ToggleOnIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  ImageSearch as ImageSearchIcon,
} from '@mui/icons-material';
import { BASE_URL } from '../../../../../utils/api';

function ProceduresResponses({ procedureId, userToken, responseId = null }) {
  const [procedures, setProcedures] = useState([]);
  const [responses, setResponses] = useState([]);
  const [viewImageUrl, setViewImageUrl] = useState('');
  const [selectedFilterId, setSelectedFilterId] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [isSecurityOrQualityProcedure, setIsSecurityOrQualityProcedure] = useState(false);
  const [locaux, setLocaux] = useState([]);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  console.log(responses);

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}procedures/get-signed-url`, { key }, { headers });
      setViewImageUrl(response.data.url);
      setOpenImageDialog(true);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const downloadImage = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}procedures/get-signed-url`, { key }, { headers });
      downloadImage(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [proceduresRes, locauxRes] = await Promise.all([
          axios.get(`${BASE_URL}procedures`, { headers }),
          axios.get(`${BASE_URL}locaux`, { headers }),
        ]);

        const responsesRes = responseId
          ? await axios.get(`${BASE_URL}procedure-responses/response/${responseId}`, { headers })
          : await axios.get(`${BASE_URL}procedure-responses?procedureId=${procedureId}`, { headers });

        const responsesData = Array.isArray(responsesRes.data) ? responsesRes.data : [responsesRes.data];

        setProcedures(proceduresRes.data);
        setResponses(responsesData);
        setLocaux(locauxRes.data.locaux);

        // On recherche la procédure correspondant au procedureId
        const foundProcedure = proceduresRes.data.find((proc) => proc.id === procedureId);

        // Vérifier qu'on a bien trouvé la procédure
        if (foundProcedure) {
          // Vérifier si c'est une procédure de type Sécurité ou Qualité
          const isSecOrQual = foundProcedure.types.some(
            (type) => type.name === 'SECURITE' || type.name === 'QUALITE',
          );
          setIsSecurityOrQualityProcedure(isSecOrQual);

          // Déterminer si c'est une procédure locale
          const isLocalProcedure = foundProcedure.name === 'Contrôle du local';

          if (isSecOrQual) {
            // Filtre par utilisateur
            const uniqueUserNames = [
              ...new Set(responsesData.map((response) => response.user_id)),
            ];
            setFilterOptions(uniqueUserNames.map((userName) => ({
              id: userName,
              name: userName,
            })));
          }
          else if (isLocalProcedure) {
            // Filtre par local_id
            const uniqueLocalIds = [
              ...new Set(responsesData.map((response) => response.local_id)),
            ];

            // Pour chaque local_id unique, trouver le nom du local
            const localFilterOptions = uniqueLocalIds
              .filter((localId) => localId !== null)
              .map((localId) => {
                const localFound = locauxRes.data.locaux.find((l) => l.id === localId);
                return {
                  id: localId,
                  name: localFound ? localFound.nom : `Local ID ${localId}`,
                };
              });
            setFilterOptions(localFilterOptions);
          }
          else {
            // Filtre par vehicule_id
            const uniqueVehicleIds = [
              ...new Set(responsesData.map((response) => response.vehicule_id)),
            ];
            setFilterOptions(
              uniqueVehicleIds
                .filter((vehId) => vehId !== null)
                .map((vehId) => ({ id: vehId, name: vehId })),
            );
          }
        }
        else {
          // Si on ne trouve pas la procédure => On peut décider d'afficher un message
          // ou tout simplement ne pas définir de filtres.
          console.warn("Aucune procédure trouvée pour l'ID :", procedureId);
        }
      }
      catch (error) {
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchData();
  }, [procedureId, responseId, userToken, headers]);

  const getProcedureById = (id) => procedures.find((proc) => proc.id === id);

  const filteredResponses = (() => {
    const procedure = getProcedureById(procedureId);
    const isLocalProcedure = procedure && procedure.name === 'Contrôle du local';

    return responses
      .filter((response) => {
        if (selectedFilterId) {
          if (isSecurityOrQualityProcedure) {
            return response.user_id === selectedFilterId;
          } if (isLocalProcedure) {
            // Filtre par local_id
            return response.local_id === Number(selectedFilterId);
          }
          // Filtre par vehicule_id
          return response.vehicule_id === selectedFilterId;
        }
        return true;
      })
      .filter((response) => {
        if (selectedDate) {
          const responseDate = new Date(response.submitted_at).toDateString();
          const selectedDateString = new Date(selectedDate).toDateString();
          return responseDate === selectedDateString;
        }
        return true;
      });
  })();

  const displayResponse = (response, fieldType, field) => {
    switch (fieldType) {
      case 'chapter':
      case 'info':
        return (
          <Typography variant="subtitle1" align="center">
            {response}
          </Typography>
        );
      case 'text':
      case 'multiline':
        return (
          <Typography variant="body1" align="center">
            {response}
          </Typography>
        );
      case 'number':
        return (
          <Typography variant="body1" align="center">
            {Number(response)}
          </Typography>
        );
      case 'date':
      case 'datelimite':
        return (
          <Typography variant="body1" align="center">
            {new Date(response).toLocaleDateString()}
          </Typography>
        );
      case 'datetime':
        return (
          <Typography variant="body1" align="center">
            {new Date(response).toLocaleString()}
          </Typography>
        );
      case 'file':
        let images = response;

        if (!images) {
          return <Typography>Aucune image disponible</Typography>;
        }

        if (Array.isArray(images)) {
          // images est déjà un tableau
        }
        else if (typeof images === 'object' && images.key) {
          // image unique
          images = [images];
        }
        else {
          // Autre cas: affichage texte
          return (
            <Typography variant="body1" align="center">
              {images}
            </Typography>
          );
        }

        return (
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  <Grid item>
                    <Tooltip title="Visualiser le document">
                      <IconButton onClick={() => fetchSignedUrlForViewing(image.key)}>
                        <ImageSearchIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Télécharger le document">
                      <IconButton onClick={() => fetchSignedUrlForDownloading(image.key)}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        );

      case 'radio':
        return (
          <Typography variant="body1" align="center">
            {response}
          </Typography>
        );
      case 'checkbox':
        return (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {response.map((item) => (
              <li key={item}>
                <Typography variant="body1" align="center">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        );
      case 'satisfaction':
        return (
          <Rating
            name="read-only"
            value={Number(response)}
            readOnly
            max={5}
          />
        );
      case 'jauge':
        return (
          <div style={{ width: '100%', padding: '0 10px' }}>
            <Typography variant="body2" align="center">{`${response}%`}</Typography>
            <Slider
              value={Number(response)}
              readOnly
              disabled
              marks
              min={0}
              max={100}
            />
          </div>
        );
      case 'toggle':
        return (
          <Typography
            variant="body1"
            align="center"
            color={response === 'OK' ? 'success.main' : 'error.main'}
          >
            {response === 'OK' ? (
              <>
                OK <CheckIcon fontSize="small" />
              </>
            ) : (
              <>
                KO <CloseIcon fontSize="small" />
              </>
            )}
          </Typography>
        );
      case 'quantity':
        const { quantity, perimable, date } = response || {};
        return (
          <div style={{ textAlign: 'center' }}>
            <Typography variant="body1">
              Quantité : {quantity || 0}/{field.minQuantity || 0}
            </Typography>
            {date && (
            <Typography variant="body2" color="textSecondary">
              {perimable ? 'Date limite :' : 'Date renseignée :'}{' '}
              {new Date(date).toLocaleDateString()}
            </Typography>
            )}
          </div>
        );

      default:
        return (
          <Typography variant="body1" align="center">
            {response}
          </Typography>
        );
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      {!responseId && (
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="filter-select-label">
                {isSecurityOrQualityProcedure
                  ? 'Personnel'
                  : (() => {
                    const procedure = getProcedureById(procedureId);
                    const isLocalProcedure = procedure && procedure.name === 'Contrôle du local';
                    return isLocalProcedure ? 'Local' : 'Véhicule';
                  })()}
              </InputLabel>
              <Select
                labelId="filter-select-label"
                value={selectedFilterId}
                label={
                  isSecurityOrQualityProcedure
                    ? 'Personnel'
                    : (() => {
                      const procedure = getProcedureById(procedureId);
                      const isLocalProcedure = procedure && procedure.name === 'Contrôle du local';
                      return isLocalProcedure ? 'Local' : 'Véhicule';
                    })()
                }
                onChange={(e) => setSelectedFilterId(e.target.value)}
              >
                <MenuItem value="">
                  <em>Tous</em>
                </MenuItem>
                {filterOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="date"
              label="Date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      )}

      {filteredResponses.map((response) => {
        const procedure = getProcedureById(response.procedure_id);
        const dateObj = new Date(response.submitted_at);
        const options = {
          day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'
        };
        const formattedDate = dateObj.toLocaleDateString('fr-FR', options);

        const isLocalProcedure = procedure.name === 'Contrôle du local';

        let localName = '';
        if (isLocalProcedure && response.local_id) {
          const localFound = locaux.find((l) => l.id === response.local_id);
          if (localFound) {
            localName = localFound.nom;
          }
        }

        return (
          <Card key={response.response_id} sx={{ marginBottom: 3 }}>
            <CardHeader
              title={procedure.name}
              subheader={
                isSecurityOrQualityProcedure
                  ? `Soumis par ${response.user_id} le ${formattedDate}`
                  : isLocalProcedure && localName
                    ? `Soumis par l'utilisateur ${response.user_id} le ${formattedDate} pour le local ${localName}`
                    : `Soumis par l'utilisateur ${response.user_id} le ${formattedDate} pour le véhicule ${response.vehicule_id}`
              }
            />
            <CardContent>
              {procedure.fields.map((field) => (
                <div key={field.id} style={{ marginBottom: '1rem' }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                        {field.type === 'chapter' && <ChapterIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'info' && <InfoIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'text' && <TextIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'number' && <NumberIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'date' && <DateIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'datelimite' && <DateRangeIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'datetime' && <DateTimeIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'file' && <FileIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'multiline' && <MultilineIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'radio' && <RadioButtonCheckedIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'checkbox' && <CheckboxIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'toggle' && <ToggleOnIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'quantity' && <NumberIcon sx={{ marginRight: 1 }} />}
                        {field.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {displayResponse(response.response_data[field.id], field.type, field)}
                    </Grid>
                  </Grid>
                </div>
              ))}
            </CardContent>
          </Card>
        );
      })}

      <Dialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          {viewImageUrl && (
            <img src={viewImageUrl} alt="Document" style={{ width: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageDialog(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default React.memo(ProceduresResponses);
