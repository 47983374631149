/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './menuStyles.scss';
import {
  // Brightness4 as Brightness4Icon,
  // Brightness7 as Brightness7Icon,
  QueryStats as QueryStatsIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Apps as AppsIcon,
  Euro as EuroIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Logout as LogoutIcon,
  DataUsage as DataUsageIcon,
  Help as HelpIcon,
  Contacts as ContactsIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { handleLogOut } from '../../utils/authServices';
import logoLight from '../../images/icon.png';
import logoDark from '../../images/icon.png';

function Menu({ theme, }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('userRole');
    if (token) {
      setIsLoggedIn(true);
      setUserRole(role);
    }
  }, []);

  const renderLink = (to, text, Icon) => (
    <Tooltip title={text} placement="right">
      <NavLink
        to={to}
        alt="navigation buttons"
        className="menu__link icon-only"
        activeClassName="active"
      >
        <Icon />
      </NavLink>
    </Tooltip>
  );

  return (
    <div className="menu collapsed">
      <div className="menu__top">
        <div className="menu__logo">
          <img src={theme === 'dark' ? logoDark : logoLight} className="menu__logo__img" alt="logo" />
        </div>
      </div>

      <ul className="menu__links">
        {isLoggedIn ? (
          <>
            {userRole !== 'Personnel' && renderLink('/regulation', 'Régulation', EmojiTransportationIcon)}
            {userRole !== 'Personnel' && renderLink('/', "Gestion d'activité", AppsIcon)}
            {userRole !== 'Personnel' && renderLink('/facturation', 'Facturation', EuroIcon)}
            {userRole !== 'Personnel' && renderLink('/dashboard', 'Tableau de bord', QueryStatsIcon)}
            {userRole !== 'Personnel' && renderLink('/managementData', 'Données exploitation', DataUsageIcon)}
            {renderLink('/porte-document', 'Porte-documents', ContactsIcon)}
            {renderLink('/documentation', 'Documentation', HelpIcon)}
            {userRole === 'Administrateur' && renderLink('/admin', 'Admin', AdminPanelSettingsIcon)}
            <Tooltip title="Déconnexion" placement="right">
              <div className="menu__link icon-only" onClick={handleLogOut}>
                <LogoutIcon />
              </div>
            </Tooltip>
          </>
        ) : (
          <>
          </>
        )}
      </ul>

      <div className="menu__bottom">
        <div className="brightButton">
          {/* <IconButton type="button" alt="Bouton pour changer le thème" onClick={switchTheme}>
            {theme === 'light' ? (
              <Brightness4Icon style={{ color: 'black' }} />
            ) : (
              <Brightness7Icon style={{ color: '#feba00' }} />
            )}
          </IconButton> */}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Menu);
