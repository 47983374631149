/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import {
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Collapse,
  Snackbar,
  Alert
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

// Le modal pour afficher la réponse
import ProceduresResponsesModal from '../processManagement/userProcedures/proceduresResponses/proceduresResponsesModal';

import { BASE_URL } from '../../../utils/api';
import './tasksManagementStyles.scss';

// -----------------------------
// Task
// -----------------------------
function Task({
  task,
  index,
  onDelete,
  onArchive,
  onUpdatePriority,
  onUpdateRole,
  onOpenResponseModal,
  userToken,
  userRole,
  roles
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentRoles, setCurrentRoles] = useState(task.role_name || []);
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [isEditingComment, setIsEditingComment] = useState(false);
  const [tempComment, setTempComment] = useState(task.comment);

  // Mise à jour du commentaire
  const handleSaveComment = async () => {
    try {
      // On envoie également le status pour éviter l'erreur côté API
      await axios.put(
        `${BASE_URL}tasks/${task.id}`,
        {
          status: task.status,
          comment: tempComment
        },
        { headers }
      );
      task.comment = tempComment;
      setIsEditingComment(false);
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour du commentaire', error);
    }
  };

  // Mise à jour des rôles
  const handleRoleChange = async () => {
    try {
      await axios.put(
        `${BASE_URL}tasks/${task.id}/role`,
        { role_name: currentRoles },
        { headers }
      );
      onUpdateRole(task.id, currentRoles);
      setSnackbarMessage('Les rôles de la tâche ont été mis à jour.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour des rôles de la tâche', error);
      setSnackbarMessage('Une erreur est survenue lors de la mise à jour des rôles de la tâche.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Suppression de la tâche
  const handleDelete = async () => {
    if (!['Direction', 'Administrateur', 'Responsable exploitation'].includes(userRole)) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'êtes pas autorisé à supprimer les tâches.",
      });
      return;
    }
    try {
      await axios.delete(`${BASE_URL}tasks/${task.id}`, { headers });
      onDelete(task.id);
    }
    catch (error) {
      console.error('Erreur lors de la suppression de la tâche', error);
    }
  };

  // Archivage de la tâche
  const handleArchive = async () => {
    if (!['Direction', 'Administrateur', 'Responsable exploitation'].includes(userRole)) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'êtes pas autorisé à archiver les tâches.",
      });
      return;
    }
    try {
      await axios.put(`${BASE_URL}tasks/${task.id}`, { status: 'archivé' }, { headers });
      onArchive(task.id);
    }
    catch (error) {
      console.error("Erreur lors de l'archivage de la tâche", error);
    }
  };

  // Mise à jour de la priorité
  const handlePriorityChange = async (newPriority) => {
    try {
      await axios.put(
        `${BASE_URL}tasks/${task.id}`,
        { ...task, priority: newPriority },
        { headers }
      );
      onUpdatePriority(task.id, newPriority);
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour de la priorité', error);
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const cardClass = `task-card ${task.priority}`;
  const createdTimeAgo = formatDistanceToNow(new Date(task.created_at), { locale: fr });
  const updatedTimeAgo = formatDistanceToNow(new Date(task.updated_at), { locale: fr });

  return (
    <Draggable draggableId={task.id.toString()} index={index}>
      {(provided) => (
        <>
          <Card
            className={cardClass}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{ mb: 2 }}
          >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" component="div" gutterBottom>
                  {task.field_name}
                </Typography>
                <IconButton onClick={handleToggleExpand} aria-label="expand">
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </div>

              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                {isEditingComment ? (
                  <>
                    <TextField
                      multiline
                      fullWidth
                      value={tempComment}
                      onChange={(e) => setTempComment(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleSaveComment}>
                      Enregistrer
                    </Button>
                    <Button variant="text" onClick={() => setIsEditingComment(false)}>
                      Annuler
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="body2" gutterBottom>
                      {task.comment}
                    </Typography>
                    {/* Bouton pour passer en mode édition */}
                    {!isEditingComment && (
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => setIsEditingComment(true)}
                        sx={{ marginTop: 1 }}
                      >
                        Modifier
                      </Button>
                    )}
                  </>
                )}
                <Typography variant="body2" color="text.secondary">
                  Véhicule: {task.vehicule_code || 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Créée par: {task.author_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task.status === 'à faire'
                    ? `À faire depuis ${createdTimeAgo}`
                    : `En cours depuis ${updatedTimeAgo}`}
                </Typography>

                {['Direction', 'Administrateur', 'Responsable exploitation'].includes(userRole) && (
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }}>
                    <InputLabel id={`role-select-label-${task.id}`}>Rôles</InputLabel>
                    <Select
                      labelId={`role-select-label-${task.id}`}
                      id={`role-select-${task.id}`}
                      multiple
                      value={currentRoles}
                      onChange={(event) => setCurrentRoles(event.target.value)}
                      onClose={handleRoleChange}
                      renderValue={(selected) => selected.join(', ')}
                      label="Rôles"
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <Typography variant="body2" color="text.secondary">
                  Rôles : {currentRoles.join(', ')}
                </Typography>
                {/* Bouton pour voir la réponse associée */}
                {task.procedure_response_id && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      // Appel de la fonction parent qu'on a reçue en props
                      onOpenResponseModal(task.procedure_id, task.procedure_response_id);
                    }}
                  >
                    Voir la réponse associée
                  </Button>
                )}

                <div className="actions">
                  {/* Boutons de priorité */}
                  <Button
                    variant={task.priority === 'forecast' ? 'contained' : 'outlined'}
                    color="info"
                    onClick={() => handlePriorityChange('forecast')}
                    sx={{ mr: 1 }}
                  >
                    À prévoir
                  </Button>
                  <Button
                    variant={task.priority === 'low' ? 'contained' : 'outlined'}
                    color="success"
                    onClick={() => handlePriorityChange('low')}
                    sx={{ mr: 1 }}
                  >
                    Basse
                  </Button>
                  <Button
                    variant={task.priority === 'medium' ? 'contained' : 'outlined'}
                    color="warning"
                    onClick={() => handlePriorityChange('medium')}
                    sx={{ mr: 1 }}
                  >
                    Moyenne
                  </Button>
                  <Button
                    variant={task.priority === 'high' ? 'contained' : 'outlined'}
                    color="error"
                    onClick={() => handlePriorityChange('high')}
                    sx={{ mr: 1 }}
                  >
                    Haute
                  </Button>

                  {/* Bouton pour archiver si pas encore archivé */}
                  {task.status !== 'archivé' && (
                    <Tooltip title="Archiver la tâche" placement="top">
                      <IconButton aria-label="archive" onClick={handleArchive}>
                        <ArchiveIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* Bouton pour supprimer */}
                  <Tooltip title="Supprimer la tâche" placement="top">
                    <IconButton aria-label="delete" onClick={handleDelete}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </Collapse>
            </CardContent>
          </Card>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </Draggable>
  );
}

// -----------------------------
// Column
// -----------------------------
function Column({
  columnId,
  tasks,
  title,
  onDelete,
  onArchive,
  onUpdatePriority,
  onUpdateRole,
  onOpenResponseModal,
  isAuthorizedRole,
  userToken,
  userRole,
  roles,
  archiveAllDoneTasks,
  deleteAllDoneTasks
}) {
  const priorityOrder = {
    high: 1,
    medium: 2,
    low: 3,
    forecast: 4,
  };

  // Tri local par priorité
  const sortedTasks = tasks.sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);

  return (
    <Droppable droppableId={columnId}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="column">
          {/* En-tête centré de la colonne avec icônes positionnées en absolu */}
          <div style={{ position: 'relative', textAlign: 'center' }}>
            <h2 style={{ margin: 0 }}>{title} ({sortedTasks.length})</h2>
            {columnId === 'done' && isAuthorizedRole && (
              <div
                style={{
                  position: 'absolute',
                  right: '8px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  display: 'flex',
                  gap: '4px'
                }}
              >
                <Tooltip title="Archiver toutes les tâches terminées">
                  <IconButton onClick={archiveAllDoneTasks} size="small">
                    <ArchiveIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer toutes les tâches terminées">
                  <IconButton onClick={deleteAllDoneTasks} size="small" color="error">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          {sortedTasks.map((task, index) => (
            <Task
              key={task.id}
              task={task}
              index={index}
              onDelete={onDelete}
              onArchive={onArchive}
              onUpdatePriority={onUpdatePriority}
              onUpdateRole={onUpdateRole}
              isAuthorizedRole={isAuthorizedRole}
              userToken={userToken}
              userRole={userRole}
              onOpenResponseModal={onOpenResponseModal}
              roles={roles}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

// -----------------------------
// IntegratedTaskPlanner
// -----------------------------
function IntegratedTaskPlanner({ userRole, userToken }) {
  const [columns, setColumns] = useState({
    todo: { title: 'À faire', taskIds: [] },
    inProgress: { title: 'En cours', taskIds: [] },
    done: { title: 'Terminé', taskIds: [] },
  });

  const [selectedVehicle, setSelectedVehicle] = useState('all');
  const [selectedRole, setSelectedRole] = useState('all');
  const [showTaskCreationModal, setShowTaskCreationModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [roles, setRoles] = useState([]);

  // -- ICI on gère le modal pour la réponse associée
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [selectedProcedureId, setSelectedProcedureId] = useState(null);
  const [selectedResponseId, setSelectedResponseId] = useState(null);

  // Données de la nouvelle tâche
  const [newTaskData, setNewTaskData] = useState({
    field_name: '',
    author_name: '',
    vehicule_code: '',
    status: 'à faire',
    role_name: [userRole],
    procedure_id: 0,
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // -------------------------------------
  // Récupération des rôles
  // -------------------------------------
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}roles`, { headers });
        setRoles(response.data);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des rôles', error);
      }
    };
    fetchRoles();
  }, []);

  // -------------------------------------
  // Fonction pour vérifier le role
  // -------------------------------------
  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  // -------------------------------------
  // Modal de création de tâche
  // -------------------------------------
  const handleOpenTaskCreationModal = () => {
    setNewTaskData({
      field_name: '',
      author_name: '',
      vehicule_code: '',
      status: 'à faire',
      role_name: [userRole],
      procedure_id: 0,
    });
    setShowTaskCreationModal(true);
  };

  const handleCloseTaskCreationModal = () => setShowTaskCreationModal(false);

  const handleNewTaskDataChange = (e) => {
    const { name, value } = e.target;
    setNewTaskData((prev) => ({ ...prev, [name]: value }));
  };

  // -------------------------------------
  // Gérer l'affichage ou non des tâches archivées
  // -------------------------------------
  const toggleArchivedTasks = () => {
    setShowArchived(!showArchived);
  };

  // -------------------------------------
  // Récupération des véhicules
  // -------------------------------------
  useEffect(() => {
    const fetchVehicules = async () => {
      try {
        const response = await axios.get(`${BASE_URL}vehicules`, { headers });
        // Filtrer les véhicules sans exitdate
        const filteredVehicules = response.data.vehicule.filter((v) => !v.exitdate);
        // Trier
        const sortedVehicules = filteredVehicules.sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true }));
        setVehicules(sortedVehicules);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des véhicules', error);
      }
    };
    fetchVehicules();
  }, []);

  // -------------------------------------
  // Filtrage local par vehicle
  // -------------------------------------
  const filterTasksByVehicle = (tasks, vehicle) => (vehicle === 'all' ? tasks : tasks.filter((t) => t.vehicule_code === vehicle));

  // -------------------------------------
  // Récupération de toutes les tâches
  // -------------------------------------
  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      if (response.data.tasks) {
        // Filtrage par rôle de l'utilisateur
        const filteredByUserRole = isAuthorizedRole()
          ? response.data.tasks // Les rôles autorisés voient tout
          : response.data.tasks.filter((t) => t.role_name.includes(userRole));

        // Filtrage par rôle sélectionné
        const filteredBySelectedRole = selectedRole === 'all'
          ? filteredByUserRole
          : filteredByUserRole.filter((t) => t.role_name.includes(selectedRole));

        // Filtrage par véhicule
        const filteredByVehicle = selectedVehicle === 'all'
          ? filteredBySelectedRole
          : filteredBySelectedRole.filter((t) => t.vehicule_code === selectedVehicle);

        // Filtrage par recherche
        const filteredBySearch = searchQuery.trim() === ''
          ? filteredByVehicle
          : filteredByVehicle.filter((t) => t.field_name.toLowerCase().includes(searchQuery.toLowerCase()));

        // Organiser les tâches par colonne
        const newColumns = {
          todo: { title: 'À faire', taskIds: [] },
          inProgress: { title: 'En cours', taskIds: [] },
          done: { title: 'Terminé', taskIds: [] },
          archived: { title: 'Archivé', taskIds: [] },
        };

        filteredBySearch.forEach((t) => {
          if (t.status === 'à faire') {
            newColumns.todo.taskIds.push(t);
          }
          else if (t.status === 'en cours') {
            newColumns.inProgress.taskIds.push(t);
          }
          else if (t.status === 'terminé') {
            newColumns.done.taskIds.push(t);
          }
          else if (t.status === 'archivé') {
            newColumns.archived.taskIds.push(t);
          }
        });

        // Extraction de la liste unique des véhicules (pour le select)
        const uniqueVehicles = Array.from(
          new Set(
            response.data.tasks
              .filter((task) => task.status !== 'archivé')
              .map((task) => task.vehicule_code)
          )
        ).filter(Boolean);

        // Tri de la liste des véhicules
        uniqueVehicles.sort((a, b) => {
          const aNum = parseInt(a, 10);
          const bNum = parseInt(b, 10);
          if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
            return aNum - bNum;
          }
          if (!Number.isNaN(aNum)) return -1;
          if (!Number.isNaN(bNum)) return 1;
          return a.localeCompare(b);
        });

        setVehicles(uniqueVehicles);

        // Application du filtre par véhicule aux colonnes
        const filteredColumns = {
          todo: {
            title: 'À faire',
            taskIds: filterTasksByVehicle(newColumns.todo.taskIds, selectedVehicle),
          },
          inProgress: {
            title: 'En cours',
            taskIds: filterTasksByVehicle(newColumns.inProgress.taskIds, selectedVehicle),
          },
          done: {
            title: 'Terminé',
            taskIds: filterTasksByVehicle(newColumns.done.taskIds, selectedVehicle),
          },
          archived: {
            title: 'Archivé',
            taskIds: filterTasksByVehicle(newColumns.archived.taskIds, selectedVehicle),
          },
        };

        setColumns(filteredColumns);
      }
      else {
        console.error('Format de données inattendu', response.data);
      }
    }
    catch (error) {
      console.error('Erreur lors de la récupération des tâches', error);
    }
  };

  // -------------------------------------
  // Chargement des tâches avec un debounce
  // -------------------------------------
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTasks();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, selectedVehicle, selectedRole, showArchived]);

  // -------------------------------------
  // MàJ du rôle dans la Task
  // -------------------------------------
  const updateTaskRole = (taskId) => {
    // On retire la tâche de toutes les colonnes, puis on refetch
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };
      Object.keys(newColumns).forEach((colId) => {
        newColumns[colId].taskIds = newColumns[colId].taskIds.filter((t) => t.id !== taskId);
      });
      return newColumns;
    });
    fetchTasks();
  };

  // -------------------------------------
  // Création d'une nouvelle tâche
  // -------------------------------------
  const handleAddNewTask = async (status) => {
    try {
      const taskData = {
        ...newTaskData,
        status,
        role_name: userRole,
      };
      await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      handleCloseTaskCreationModal();
      fetchTasks();
    }
    catch (error) {
      console.error('Erreur lors de la création de la tâche', error);
    }
  };

  // -------------------------------------
  // Archiver une tâche
  // -------------------------------------
  const handleArchiveTask = async (taskId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'êtes pas autorisé à modifier les tâches.",
      });
      return;
    }
    try {
      await axios.put(`${BASE_URL}tasks/${taskId}`, { status: 'archivé' }, { headers });
      fetchTasks();
    }
    catch (error) {
      console.error("Erreur lors de l'archivage de la tâche", error);
    }
  };

  // -------------------------------------
  // Suppression d'une tâche
  // -------------------------------------
  const handleDeleteTask = (taskId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'êtes pas autorisé à supprimer les tâches.",
      });
      return;
    }
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };
      Object.keys(newColumns).forEach((colId) => {
        newColumns[colId].taskIds = newColumns[colId].taskIds.filter((t) => t.id !== taskId);
      });
      return newColumns;
    });
  };

  // -------------------------------------
  // Fonction pour archiver en masse toutes les tâches de la colonne "Terminé"
  // -------------------------------------
  const archiveAllDoneTasks = async () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'êtes pas autorisé à modifier les tâches.",
      });
      return;
    }
    const doneTasks = columns.done.taskIds;
    try {
      await Promise.all(
        doneTasks.map((task) => axios.put(`${BASE_URL}tasks/${task.id}`, { status: 'archivé' }, { headers }))
      );
      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Toutes les tâches terminées ont été archivées.',
      });
      fetchTasks();
    }
    catch (error) {
      console.error("Erreur lors de l'archivage en masse", error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Erreur lors de l'archivage en masse.",
      });
    }
  };

  // -------------------------------------
  // Fonction pour supprimer en masse toutes les tâches de la colonne "Terminé"
  // -------------------------------------
  const deleteAllDoneTasks = async () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'êtes pas autorisé à supprimer les tâches.",
      });
      return;
    }
    const doneTasks = columns.done.taskIds;
    try {
      await Promise.all(
        doneTasks.map((task) => axios.delete(`${BASE_URL}tasks/${task.id}`, { headers }))
      );
      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Toutes les tâches terminées ont été supprimées.',
      });
      fetchTasks();
    }
    catch (error) {
      console.error('Erreur lors de la suppression en masse', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Erreur lors de la suppression en masse.',
      });
    }
  };

  // -------------------------------------
  // Drag & Drop (mise à jour du statut)
  // -------------------------------------
  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const task = columns[source.droppableId].taskIds[source.index];
    const newColumns = { ...columns };

    // Retrait de la tâche dans sa colonne d'origine
    newColumns[source.droppableId].taskIds.splice(source.index, 1);
    // Ajout dans la nouvelle
    newColumns[destination.droppableId].taskIds.splice(destination.index, 0, task);

    const newStatus = destination.droppableId === 'todo'
      ? 'à faire'
      : destination.droppableId === 'inProgress'
        ? 'en cours'
        : destination.droppableId === 'done'
          ? 'terminé'
          : 'archivé';

    setColumns(newColumns);

    try {
      await axios.put(
        `${BASE_URL}tasks/${task.id}`,
        { ...task, status: newStatus },
        { headers }
      );
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche', error);
    }
  };

  // -------------------------------------
  // Mise à jour de la priorité (cf. handlePriorityChange)
  // -------------------------------------
  const handleUpdatePriority = (taskId, newPriority) => {
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };
      Object.keys(newColumns).forEach((colId) => {
        newColumns[colId].taskIds = newColumns[colId].taskIds.map((t) => {
          if (t.id === taskId) {
            return { ...t, priority: newPriority };
          }
          return t;
        });
      });
      return newColumns;
    });
  };

  // -------------------------------------
  // Ouvrir le modal pour voir la réponse
  // -------------------------------------
  const handleOpenResponseModal = (procedureId, responseId) => {
    setSelectedProcedureId(procedureId);
    setSelectedResponseId(responseId);
    setShowResponseModal(true);
  };

  // -------------------------------------
  // Rendu des colonnes
  // -------------------------------------
  const renderColumns = () => {
    // Si on veut voir les archivées, on affiche la colonne archived
    // Sinon, on ne l'affiche pas
    const columnIds = showArchived ? ['archived'] : ['todo', 'inProgress', 'done'];

    return columnIds.map((columnId) => {
      const column = columns[columnId];
      return (
        <Column
          key={columnId}
          columnId={columnId}
          title={column.title}
          tasks={column.taskIds}
          onDelete={handleDeleteTask}
          onArchive={handleArchiveTask}
          onUpdatePriority={handleUpdatePriority}
          onUpdateRole={updateTaskRole}
          isAuthorizedRole={isAuthorizedRole()}
          userToken={userToken}
          userRole={userRole}
          onOpenResponseModal={handleOpenResponseModal}
          roles={roles}
          {...(columnId === 'done' ? { archiveAllDoneTasks, deleteAllDoneTasks } : {})}
        />
      );
    });
  };

  return (
    <>
      <div className="taskManagement">
        <div className="search-bar">
          <TextField
            label="Rechercher une tâche"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mr: 2, width: '300px' }}
          />
          <select value={selectedVehicle} onChange={(e) => setSelectedVehicle(e.target.value)}>
            <option value="all">Toutes les entités</option>
            {vehicles.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>

          {['Direction', 'Administrateur', 'Responsable exploitation'].includes(userRole) && (
            <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
              <option value="all">Tous les rôles</option>
              {roles.map((role) => (
                <option key={role.id} value={role.name}>
                  {role.name}
                </option>
              ))}
            </select>
          )}

          <Button variant="outlined" onClick={toggleArchivedTasks} sx={{ ml: 2 }}>
            {showArchived ? 'Masquer' : 'Voir'} les tâches archivées
          </Button>
        </div>

        <Button
          variant="contained"
          style={{ backgroundColor: '#505154 ' }}
          onClick={handleOpenTaskCreationModal}
          sx={{ mt: 2 }}
        >
          Ajouter une tâche
        </Button>
      </div>

      {/* Dialog de création d'une tâche */}
      <Dialog open={showTaskCreationModal} onClose={handleCloseTaskCreationModal}>
        <DialogTitle>Ajouter une tâche</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            name="field_name"
            label="Nom de la tâche"
            variant="outlined"
            value={newTaskData.field_name}
            onChange={handleNewTaskDataChange}
            required
            sx={{ mb: 2, mt: 2 }}
          />
          <TextField
            fullWidth
            name="comment"
            label="Commentaire"
            variant="outlined"
            value={newTaskData.comment}
            onChange={handleNewTaskDataChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="author_name"
            label="Nom de l'auteur"
            variant="outlined"
            value={newTaskData.author_name}
            onChange={handleNewTaskDataChange}
          />

          <div className="form-control">
            <label htmlFor="vehicule-code-select">Code du véhicule</label>
            <select
              id="vehicule-code-select"
              name="vehicule_code"
              value={newTaskData.vehicule_code}
              onChange={handleNewTaskDataChange}
            >
              <option value="">Sélectionnez un véhicule</option>
              {vehicules.map((vehicule) => (
                <option key={vehicule.id} value={vehicule.code}>
                  {vehicule.code}
                </option>
              ))}
            </select>
          </div>

          {isAuthorizedRole() && (
            <div className="form-control">
              <label htmlFor="role-select">Attribuer aux rôles</label>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="role-select-label">Rôles</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  multiple
                  name="role_name"
                  value={newTaskData.role_name}
                  onChange={(e) => setNewTaskData((prev) => ({
                    ...prev,
                    role_name: e.target.value
                  }))}
                  renderValue={(selected) => selected.join(', ')}
                  label="Rôles"
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: '#505154 ', margin: 'auto' }}
            onClick={() => handleAddNewTask('à faire')}
          >
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>

      {/* Drag & Drop */}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="task-planner">{renderColumns()}</div>
      </DragDropContext>

      {/* Modal pour afficher la réponse liée à la tâche */}
      <ProceduresResponsesModal
        procedureId={selectedProcedureId}
        responseId={selectedResponseId}
        isOpen={showResponseModal}
        onRequestClose={() => setShowResponseModal(false)}
        userToken={userToken}
      />
    </>
  );
}

export default React.memo(IntegratedTaskPlanner);
