/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable default-case-last */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useEffect, useState, useMemo,
} from 'react';
import axios from 'axios';
import {
  Card, CardContent, CardActions, Typography, IconButton, Tooltip, Collapse,
  Select, MenuItem, FormControl, InputLabel, Grid, Badge, Chip, CircularProgress,
  RadioGroup, FormControlLabel, Radio, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  format, isWithinInterval, parseISO, addDays, startOfWeek, endOfWeek,
  startOfMonth, endOfMonth, eachDayOfInterval, isSameDay,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BASE_URL } from '../../../utils/api';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import ProcedureResponsesModal from './userProcedures/proceduresResponses/proceduresResponsesModal';

function HistoriqueProcedures({
  userToken, userEmail, userRole,
}) {
  usePageTitle('DRIVESOFT | HISTORIQUE DES PROCÉDURES');

  const [historique, setHistorique] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [locaux, setLocaux] = useState([]); // <-- Pour gérer les "Contrôle du local"

  const [crewsList, setCrewsList] = useState([]);
  const [userCompany, setUserCompany] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  // Vue journalière (collapse)
  const [expandedProcedureId, setExpandedProcedureId] = useState(null);

  // Modal "voir détails"
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResponseId, setSelectedResponseId] = useState(null);

  // Véhicules/locaux non répondants
  const [nonRespondingVehicles, setNonRespondingVehicles] = useState({});
  const [loadingNonResponding, setLoadingNonResponding] = useState({});

  // Filtres
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedVehicule, setSelectedVehicule] = useState('');

  // Date
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Mode de vue
  const [viewMode, setViewMode] = useState('daily');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // Helpers date
  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy', { locale: fr });
  const formatDateFull = (date) => format(new Date(date), 'EEEE dd MMMM yyyy', { locale: fr });

  // Rôles autorisés
  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  // ------------------------------------------------------------------------
  // getDateRange
  // ------------------------------------------------------------------------
  function getDateRange(date, mode) {
    switch (mode) {
      case 'weekly':
        return {
          start: startOfWeek(date, { locale: fr, weekStartsOn: 1 }),
          end: endOfWeek(date, { locale: fr, weekStartsOn: 1 }),
        };
      case 'monthly':
        return {
          start: startOfMonth(date),
          end: endOfMonth(date),
        };
      default: // daily
        const start = new Date(date);
        start.setHours(0, 0, 0, 0);
        const end = new Date(date);
        end.setHours(23, 59, 59, 999);
        return { start, end };
    }
  }

  const { start: startDate, end: endDate } = useMemo(
    () => getDateRange(selectedDate, viewMode),
    [selectedDate, viewMode],
  );

  // ------------------------------------------------------------------------
  // isSansTypeProcedure
  // => si “Contrôle mécanique”, “Contrôle du local”, ou "SANS TYPES"
  // ------------------------------------------------------------------------
  function isSansTypeProcedure(procedure) {
    const hasSansType = procedure.types?.some((pt) => pt.name === 'SANS TYPES');
    const isControleMeca = procedure.name === 'Contrôle mécanique';
    const isControleLocal = procedure.name === 'Contrôle du local';
    return hasSansType || isControleMeca || isControleLocal;
  }

  // ------------------------------------------------------------------------
  // isLocalProcedure
  // => on détecte si c’est la procédure “Contrôle du local”
  // ------------------------------------------------------------------------
  function isLocalProcedure(procedure) {
    return procedure.name === 'Contrôle du local';
  }

  // ------------------------------------------------------------------------
  // filteredHistorique
  // Filtrage global par date + véhicule + ...
  // ------------------------------------------------------------------------
  const filteredHistorique = useMemo(() => historique.filter((record) => {
    // On vérifie la date
    const subDate = parseISO(record.submitted_at);
    if (!isWithinInterval(subDate, { start: startDate, end: endDate })) return false;

    // Filtre sur le code véhicule, si choisi (uniquement si c’est un veh)
    if (selectedVehicule) {
      if (!record.vehicule_id) return false;
      const matchVehCode = String(record.vehicule_id)
        .toLowerCase()
        .includes(String(selectedVehicule).toLowerCase());
      if (!matchVehCode) return false;
    }
    return true;
  }), [historique, selectedVehicule, startDate, endDate]);

  // ------------------------------------------------------------------------
  // Navigation
  // ------------------------------------------------------------------------
  function handleDateChange(direction) {
    if (viewMode === 'daily') {
      setSelectedDate((prev) => addDays(prev, direction === 'next' ? 1 : -1));
    }
    else if (viewMode === 'weekly') {
      setSelectedDate((prev) => addDays(prev, direction === 'next' ? 7 : -7));
    }
    else {
      setSelectedDate((prev) => {
        const newDate = new Date(prev);
        newDate.setMonth(prev.getMonth() + (direction === 'next' ? 1 : -1));
        return newDate;
      });
    }
  }

  function handleViewModeChange(e) {
    setViewMode(e.target.value);
  }

  function handleCompanyFilterChange(e) {
    setSelectedCompany(e.target.value);
  }
  function handleVehiculeFilterChange(e) {
    setSelectedVehicule(e.target.value);
  }

  // daily collapse
  function handleExpandClick(procedureId) {
    setExpandedProcedureId((prev) => (prev === procedureId ? null : procedureId));
  }

  // Modal
  function handleViewDetails(responseId) {
    setSelectedResponseId(responseId);
    setIsModalOpen(true);
  }
  function handleCloseModal() {
    setSelectedResponseId(null);
    setIsModalOpen(false);
  }

  // ------------------------------------------------------------------------
  // FETCH
  // ------------------------------------------------------------------------
  async function fetchUserCompany() {
    try {
      const { data } = await axios.get(`${BASE_URL}staff`, { headers });
      const userInfo = data.staff.find(
        (st) => st.email.trim().toLowerCase() === userEmail.trim().toLowerCase()
      );
      if (userInfo) {
        setUserCompany(userInfo.company);
      }
    }
    catch (error) {
      console.error('Erreur fetchUserCompany:', error);
    }
  }

  async function fetchVehicules() {
    try {
      const [vehiculesResp, staffsResp] = await Promise.all([
        axios.get(`${BASE_URL}vehicules`, { headers }),
        axios.get(`${BASE_URL}staff`, { headers }),
      ]);

      const responsablesMap = new Map(staffsResp.data.staff.map((s) => [s.id, s]));

      const vehiculesWithResp = vehiculesResp.data.vehicule
        // Exclure cat “VS” et “MULLE” et ceux sortis
        .filter((v) => !v.exit_date
          && v.categorie !== 'VS'
          && v.categorie !== 'MULLE')
        .map((v) => ({
          ...v,
          responsable: responsablesMap.get(v.responsable_id),
        }))
        .sort((a, b) => a.code - b.code);

      let filtered = [];
      if (isAuthorizedRole()) {
        filtered = vehiculesWithResp;
      }
      else if (userRole === 'Responsable opérationnel') {
        if (userCompany === 'ASJ') {
          filtered = vehiculesWithResp.filter((veh) => ['ASJ', 'APAP'].includes(veh.societe));
        }
        else {
          filtered = vehiculesWithResp.filter((veh) => veh.societe === userCompany);
        }
      }
      else {
        // Filtrage par responsable
        filtered = vehiculesWithResp.filter((veh) => {
          if (!veh.responsable?.email) return false;
          return (
            veh.responsable.email.trim().toLowerCase() === userEmail.trim().toLowerCase()
          );
        });
      }
      setVehicules(filtered);
    }
    catch (error) {
      console.error('Erreur fetchVehicules:', error);
    }
  }

  async function fetchLocaux() {
    try {
      const { data } = await axios.get(`${BASE_URL}locaux`, { headers });
      setLocaux(data.locaux || []);
    }
    catch (error) {
      console.error('Erreur fetchLocaux:', error);
    }
  }

  async function fetchHistorique() {
    try {
      const { data } = await axios.get(`${BASE_URL}procedure-responses`, { headers });
      setHistorique(data);
    }
    catch (error) {
      console.error('Erreur fetchHistorique:', error);
    }
  }

  async function fetchProcedures() {
    try {
      const { data } = await axios.get(`${BASE_URL}procedures`, { headers });
      setProcedures(data);
    }
    catch (error) {
      console.error('Erreur fetchProcedures:', error);
    }
  }

  async function fetchCrews() {
    try {
      const { data } = await axios.get(`${BASE_URL}crews`, { headers });
      setCrewsList(data.crews);
    }
    catch (error) {
      console.error('Erreur fetchCrews:', error);
    }
  }

  // ------------------------------------------------------------------------
  // findVehiclesNotResponded
  // => ignore tout véhicule/locaux non planifié (ou non concerné)
  // ------------------------------------------------------------------------
  async function findVehiclesNotResponded(procedure) {
    try {
      // Récup réponses pour la procédure
      const resp = await axios.get(
        `${BASE_URL}procedure-responses?procedureId=${procedure.id}`,
        { headers }
      );
      const responses = resp.data;

      const { start, end } = getDateRange(selectedDate, viewMode);

      // Est-ce un "Contrôle du local" ?
      if (isLocalProcedure(procedure)) {
        // => On gère l'absence d'un "vehicule_id" => c'est local_id
        // 1) Récupérer la liste des locaux
        let relevantLocaux = [...locaux];

        // Filtre par société si needed
        if (selectedCompany) {
          relevantLocaux = relevantLocaux.filter((loc) => (loc.societe || '').trim().toLowerCase() === selectedCompany.trim().toLowerCase());
        }

        // Filtrer réponses dans [start, end]
        const allRespInRange = responses.filter((r) => {
          const d = parseISO(r.submitted_at);
          return isWithinInterval(d, { start, end });
        });

        // Ensemble des local_id qui ont répondu
        const respondedLocalSet = new Set(
          allRespInRange.map((r) => String(r.local_id))
        );

        const notResp = [];
        // Optionnel : si vous aviez un "planning local", vous le vérifieriez ici
        for (const loc of relevantLocaux) {
          const locIdStr = String(loc.id);
          if (!respondedLocalSet.has(locIdStr)) {
            notResp.push(loc.nom); // on identifie le local par son nom
          }
        }
        return notResp;
      }

      // => Procédure "classique" avec vehicule
      let localVehicules = [...vehicules];

      // Filtres de base
      if (selectedCompany) {
        localVehicules = localVehicules.filter(
          (v) => v.societe.trim().toLowerCase() === selectedCompany.trim().toLowerCase()
        );
      }
      if (selectedVehicule) {
        localVehicules = localVehicules.filter(
          (v) => String(v.code).toLowerCase() === String(selectedVehicule).toLowerCase()
        );
      }

      // “sans type” => on n’applique pas le filtrage type
      const isSansType = isSansTypeProcedure(procedure);
      if (!isSansType) {
        localVehicules = localVehicules.filter((v) => procedure.types.some((pt) => pt.name === v.type));
      }

      // Réponses dans [start, end]
      const allRespInRange = responses.filter((r) => {
        const d = parseISO(r.submitted_at);
        return isWithinInterval(d, { start, end });
      });
      const respondedSet = new Set(
        allRespInRange.map((r) => String(r.vehicule_id).toLowerCase())
      );

      const notResp = [];
      for (const v of localVehicules) {
        // Est-il planifié ce jour dans crewsList ?
        const hasCrew = crewsList.some(
          (c) => c.vehicule_id === v.id && isWithinInterval(new Date(c.date), { start, end })
        );
        if (!hasCrew) continue; // s'il n'est pas planifié, on ignore (affichage "-")

        // A-t-il répondu ?
        const codeLow = String(v.code).toLowerCase();
        const matched = [...respondedSet].some((vid) => vid.includes(codeLow));
        if (!matched) {
          notResp.push(v.code);
        }
      }
      return notResp;
    }
    catch (error) {
      console.error('Erreur findVehiclesNotResponded:', error);
      return [];
    }
  }

  async function fetchNonRespondingVehicles(procedureId) {
    setLoadingNonResponding((prev) => ({ ...prev, [procedureId]: true }));
    try {
      const proc = procedures.find((p) => p.id === procedureId);
      if (!proc) return;
      const notResp = await findVehiclesNotResponded(proc);
      setNonRespondingVehicles((prev) => ({ ...prev, [procedureId]: notResp }));
    }
    catch (error) {
      console.error('Erreur fetchNonRespondingVehicles:', error);
    }
    setLoadingNonResponding((prev) => ({ ...prev, [procedureId]: false }));
  }

  // ------------------------------------------------------------------------
  // CHARGEMENT INITIAL
  // ------------------------------------------------------------------------
  useEffect(() => {
    let mounted = true;
    (async () => {
      try {
        await fetchUserCompany();
        await fetchVehicules();
        await fetchLocaux(); // <--- Récup des locaux
        await fetchHistorique();
        await fetchProcedures();
        await fetchCrews();
        if (mounted) setIsLoading(false);
      }
      catch (err) {
        console.error(err);
        if (mounted) setIsLoading(false);
      }
    })();
    return () => {
      mounted = false;
    };
  }, []);

  // ------------------------------------------------------------------------
  // Recalculer non respondants à chaque changement de période / filtre
  // ------------------------------------------------------------------------
  useEffect(() => {
    let canceled = false;
    if (procedures.length === 0) return;

    async function run() {
      const tmp = {};
      procedures.forEach((p) => {
        tmp[p.id] = [];
      });
      setNonRespondingVehicles(tmp);

      for (const p of procedures) {
        if (canceled) break;
        await fetchNonRespondingVehicles(p.id);
      }
    }
    run();

    return () => {
      canceled = true;
    };
  }, [procedures, selectedDate, viewMode, selectedCompany, selectedVehicule]);

  // ------------------------------------------------------------------------
  // LOADER
  // ------------------------------------------------------------------------
  if (isLoading) {
    return <AmbysoftLoader />;
  }

  // ------------------------------------------------------------------------
  // VUE JOURNALIÈRE
  // => on n’affiche que les procédures “Quotidienne”
  // ------------------------------------------------------------------------
  function DailyView() {
    const dailyProcedures = procedures.filter((p) => {
      if (p.frequency === 'Ponctuelle') return false;
      return p.frequency === 'Quotidienne';
    });

    return (
      <>
        {dailyProcedures.map((procedure) => {
          // Réponses
          const procedureResponses = filteredHistorique.filter(
            (r) => r.procedure_id === procedure.id
          );

          const notResp = nonRespondingVehicles[procedure.id] || [];
          const hasNotResp = notResp.length > 0;
          const notRespCount = notResp.length;

          const localProc = isLocalProcedure(procedure);

          // S'il n'y a ni réponses ni non-répondants => skip
          if (procedureResponses.length === 0 && !hasNotResp) {
            return null;
          }

          return (
            <Card key={procedure.id} style={{ marginBottom: 20 }}>
              <CardContent>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">{procedure.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Fréquence : {procedure.frequency}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={
                        loadingNonResponding[procedure.id]
                          ? 'Chargement...'
                          : `${notRespCount} non répondant(s)`
                      }
                      arrow
                    >
                      <Badge
                        badgeContent={
                          loadingNonResponding[procedure.id]
                            ? <CircularProgress size={16} color="inherit" />
                            : notRespCount
                        }
                        color="error"
                        invisible={!hasNotResp}
                      >
                        <IconButton onClick={() => handleExpandClick(procedure.id)}>
                          <ExpandMoreIcon
                            style={{
                              transform: expandedProcedureId === procedure.id
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                              transition: 'transform 0.3s',
                            }}
                          />
                        </IconButton>
                      </Badge>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardContent>

              <Collapse
                in={expandedProcedureId === procedure.id}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  {hasNotResp && (
                    <div style={{ marginBottom: 16 }}>
                      <Typography variant="subtitle1">Non répondants :</Typography>
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', gap: 8, marginTop: 8,
                      }}
                      >
                        {notResp.map((codeOrNom) => (
                          <Chip
                            key={codeOrNom}
                            label={
                              localProc
                                ? `Local : ${codeOrNom}`
                                : `Véhicule ${codeOrNom}`
                            }
                            color="secondary"
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {procedureResponses.map((rec) => {
                    if (localProc) {
                      // Contrôle du local => rec.local_id
                      const locFound = locaux.find((l) => l.id === rec.local_id);
                      if (!locFound) return null;

                      return (
                        <Card key={rec.response_id} style={{ marginBottom: 10 }}>
                          <CardContent>
                            <Typography color="textSecondary">
                              Date : {formatDate(rec.submitted_at)}
                            </Typography>
                            <Typography color="textSecondary">
                              Local : {locFound.nom}
                            </Typography>
                            <Typography color="textSecondary">
                              Utilisateur : {rec.user_id}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Tooltip title="Voir les détails" arrow>
                              <IconButton onClick={() => handleViewDetails(rec.response_id)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </CardActions>
                        </Card>
                      );
                    }
                    // Procédure véhicule
                    const vehFound = vehicules.find((v) => {
                      if (!rec.vehicule_id) return false;
                      return rec.vehicule_id.toLowerCase().includes(String(v.code).toLowerCase());
                    });
                    if (!vehFound) return null;

                    return (
                      <Card key={rec.response_id} style={{ marginBottom: 10 }}>
                        <CardContent>
                          <Typography color="textSecondary">
                            Date : {formatDate(rec.submitted_at)}
                          </Typography>
                          <Typography color="textSecondary">
                            Véhicule : {vehFound.code}
                          </Typography>
                          <Typography color="textSecondary">
                            Utilisateur : {rec.user_id}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Tooltip title="Voir les détails" arrow>
                            <IconButton onClick={() => handleViewDetails(rec.response_id)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </CardActions>
                      </Card>
                    );
                  })}
                </CardContent>
              </Collapse>
            </Card>
          );
        })}
      </>
    );
  }

  // ------------------------------------------------------------------------
  // VUE HEBDOMADAIRE / MENSUELLE
  // => on affiche TOUTES les procédures (sauf Ponctuelle)
  // ------------------------------------------------------------------------
  function WeeklyMonthlyView() {
    const daysArray = eachDayOfInterval({ start: startDate, end: endDate });

    function RenderDailyProcedureForMultiDays({ procedure, procedureResponses }) {
      // Par ex. fréquence = "Quotidienne"
      const localProc = isLocalProcedure(procedure);

      if (localProc) {
        // Tableau Locaux en lignes / days en colonnes
        let relevantLocaux = [...locaux];
        if (selectedCompany) {
          relevantLocaux = relevantLocaux.filter((loc) => (loc.societe || '').trim().toLowerCase() === selectedCompany.trim().toLowerCase());
        }

        return (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Local</TableCell>
                  {daysArray.map((day) => (
                    <TableCell align="center" key={day}>
                      {format(day, 'dd/MM', { locale: fr })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {relevantLocaux.map((loc) => (
                  <TableRow key={loc.id}>
                    <TableCell>{loc.nom}</TableCell>
                    {daysArray.map((day) => {
                      const foundResp = procedureResponses.find((r) => {
                        if (r.local_id !== loc.id) return false;
                        const rDate = parseISO(r.submitted_at);
                        return isSameDay(rDate, day);
                      });
                      const hasResp = !!foundResp;
                      // AJOUT : si vous aviez un planning "local", vous feriez un check "isScheduled" pareil
                      //         si pas planifié => '-'
                      //         sinon => (hasResp ? '✅' : '❌')
                      return (
                        <TableCell
                          key={day}
                          align="center"
                          style={{ cursor: hasResp ? 'pointer' : 'default' }}
                          onClick={() => {
                            if (hasResp) {
                              handleViewDetails(foundResp.response_id);
                            }
                          }}
                        >
                          {hasResp ? '✅' : '❌'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }

      // => Procédure véhicule
      // Tableau Véhicules en lignes / days en colonnes
      let relevantVehicules = [...vehicules];
      if (selectedCompany) {
        relevantVehicules = relevantVehicules.filter(
          (v) => v.societe.trim().toLowerCase() === selectedCompany.trim().toLowerCase()
        );
      }
      const isSansType = isSansTypeProcedure(procedure);
      if (!isSansType) {
        relevantVehicules = relevantVehicules.filter((v) => procedure.types.some((pt) => pt.name === v.type));
      }

      return (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Véhicule</TableCell>
                {daysArray.map((day) => (
                  <TableCell align="center" key={day}>
                    {format(day, 'dd/MM', { locale: fr })}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {relevantVehicules.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.code}</TableCell>
                  {daysArray.map((day) => {
                    // On vérifie d’abord si le véhicule est planifié ce jour-là
                    // AJOUT : check si un crew est censé travailler (isScheduled)
                    const isScheduled = crewsList.some(
                      (c) => c.vehicule_id === v.id && isSameDay(new Date(c.date), day)
                    );

                    // Trouve la réponse s’il y en a une
                    const foundResp = procedureResponses.find((r) => {
                      if (!r.vehicule_id) return false;
                      if (!r.vehicule_id.toLowerCase().includes(String(v.code).toLowerCase())) return false;
                      const rDate = parseISO(r.submitted_at);
                      return isSameDay(rDate, day);
                    });
                    const hasResp = !!foundResp;

                    // AJOUT : si pas planifié => '-'
                    //         si planifié => (hasResp ? '✅' : '❌')
                    let cellContent;
                    if (!isScheduled) {
                      cellContent = '-';
                    }
                    else {
                      cellContent = hasResp ? '✅' : '❌';
                    }

                    return (
                      <TableCell
                        key={day}
                        align="center"
                        style={{ cursor: hasResp ? 'pointer' : 'default' }}
                        onClick={() => {
                          if (hasResp) {
                            handleViewDetails(foundResp.response_id);
                          }
                        }}
                      >
                        {cellContent}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    function RenderNonDailyProcedureSingleCheck({ procedure, procedureResponses }) {
      const localProc = isLocalProcedure(procedure);

      if (localProc) {
        // => Tableau 2 colonnes : local | réponse (une fois dans la période)
        let relevantLocaux = [...locaux];
        if (selectedCompany) {
          relevantLocaux = relevantLocaux.filter(
            (loc) => (loc.societe || '').trim().toLowerCase() === selectedCompany.trim().toLowerCase()
          );
        }

        return (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Local</TableCell>
                  <TableCell align="center">Réponse sur la période</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relevantLocaux.map((loc) => {
                  const found = procedureResponses.find((r) => r.local_id === loc.id);
                  const hasResp = !!found;
                  // Idem : si planning local => on vérifie si planifié
                  // sinon => '-' si pas planifié
                  return (
                    <TableRow key={loc.id}>
                      <TableCell>{loc.nom}</TableCell>
                      <TableCell
                        align="center"
                        style={{ cursor: hasResp ? 'pointer' : 'default' }}
                        onClick={() => {
                          if (hasResp) {
                            handleViewDetails(found.response_id);
                          }
                        }}
                      >
                        {hasResp ? '✅' : '❌'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }

      // => Procédure véhicule
      // Sur la période, on vérifie s’il y a eu au moins une réponse
      let relevantVehicules = [...vehicules];
      const isSansType = isSansTypeProcedure(procedure);

      if (selectedCompany) {
        relevantVehicules = relevantVehicules.filter(
          (v) => v.societe.trim().toLowerCase() === selectedCompany.trim().toLowerCase()
        );
      }
      if (!isSansType) {
        relevantVehicules = relevantVehicules.filter((v) => procedure.types.some((pt) => pt.name === v.type));
      }

      return (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Véhicule</TableCell>
                <TableCell align="center">Réponse sur la période</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {relevantVehicules.map((v) => {
                // On regarde s’il est planifié au moins un jour dans [start, end]
                // AJOUT
                const scheduledOnce = crewsList.some(
                  (c) => c.vehicule_id === v.id
                    && isWithinInterval(new Date(c.date), { start: startDate, end: endDate })
                );

                // A-t-il répondu au moins une fois ?
                const codeLow = String(v.code).toLowerCase();
                const answeredOnce = procedureResponses.some((r) => {
                  if (!r.vehicule_id) return false;
                  if (!r.vehicule_id.toLowerCase().includes(codeLow)) return false;
                  const rDate = parseISO(r.submitted_at);
                  return isWithinInterval(rDate, { start: startDate, end: endDate });
                });

                let cellContent;
                if (!scheduledOnce) {
                  // Non planifié => '-'
                  cellContent = '-';
                }
                else {
                  // Planifié => '❌' ou '✅'
                  cellContent = answeredOnce ? '✅' : '❌';
                }

                return (
                  <TableRow key={v.id}>
                    <TableCell>{v.code}</TableCell>
                    <TableCell
                      align="center"
                      style={{ cursor: answeredOnce ? 'pointer' : 'default' }}
                      onClick={() => {
                        if (answeredOnce) {
                          // On récupère n’importe quelle réponse de la période
                          const found = procedureResponses.find((r) => {
                            if (!r.vehicule_id) return false;
                            return r.vehicule_id.toLowerCase().includes(codeLow);
                          });
                          if (found) {
                            handleViewDetails(found.response_id);
                          }
                        }
                      }}
                    >
                      {cellContent}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return (
      <>
        {procedures.map((procedure) => {
          if (procedure.frequency === 'Ponctuelle') return null;

          const procedureResponses = filteredHistorique.filter(
            (rec) => rec.procedure_id === procedure.id
          );
          const vehiclesNotResponded = nonRespondingVehicles[procedure.id] || [];
          const hasNotResponding = vehiclesNotResponded.length > 0;
          const notRespCount = vehiclesNotResponded.length;

          if (procedureResponses.length === 0 && !hasNotResponding) {
            return null;
          }

          const isDailyFrequency = procedure.frequency?.toLowerCase() === 'quotidienne';

          return (
            <Paper key={procedure.id} style={{ marginBottom: 20, padding: 16 }}>
              <Grid container alignItems="center" justifyContent="space-between" mb={2}>
                <Grid item>
                  <Typography variant="h6">{procedure.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Fréquence : {procedure.frequency}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      loadingNonResponding[procedure.id]
                        ? 'Chargement...'
                        : `${notRespCount} non répondant(s)`
                    }
                    arrow
                  >
                    <Badge
                      badgeContent={
                        loadingNonResponding[procedure.id]
                          ? <CircularProgress size={16} color="inherit" />
                          : notRespCount
                      }
                      color="error"
                      invisible={!hasNotResponding}
                    >
                      <ExpandMoreIcon />
                    </Badge>
                  </Tooltip>
                </Grid>
              </Grid>

              {hasNotResponding && (
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="subtitle1">
                    Non répondants :
                  </Typography>
                  <div style={{
                    display: 'flex', flexWrap: 'wrap', gap: 8, marginTop: 8,
                  }}
                  >
                    {vehiclesNotResponded.map((codeOrNom) => (
                      <Chip
                        key={codeOrNom}
                        label={
                          isLocalProcedure(procedure)
                            ? `Local : ${codeOrNom}`
                            : `Véhicule ${codeOrNom}`
                        }
                        color="primary"
                      />
                    ))}
                  </div>
                </div>
              )}

              {isDailyFrequency ? (
                <RenderDailyProcedureForMultiDays
                  procedure={procedure}
                  procedureResponses={procedureResponses}
                />
              ) : (
                <RenderNonDailyProcedureSingleCheck
                  procedure={procedure}
                  procedureResponses={procedureResponses}
                />
              )}
            </Paper>
          );
        })}
      </>
    );
  }

  // ------------------------------------------------------------------------
  // RENDU FINAL
  // ------------------------------------------------------------------------
  return (
    <div style={{ padding: 20 }}>
      {/* Choix du mode */}
      <FormControl component="fieldset" style={{ marginBottom: 20 }}>
        <RadioGroup row value={viewMode} onChange={handleViewModeChange}>
          <FormControlLabel value="daily" control={<Radio />} label="Journalière" />
          <FormControlLabel value="weekly" control={<Radio />} label="Hebdomadaire" />
          <FormControlLabel value="monthly" control={<Radio />} label="Mensuelle" />
        </RadioGroup>
      </FormControl>

      {/* Navigation sur la période */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        style={{ marginBottom: 20 }}
      >
        <Grid item>
          <IconButton onClick={() => handleDateChange('previous')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          {viewMode === 'daily' && (
            <Typography variant="h5">{formatDateFull(selectedDate)}</Typography>
          )}
          {viewMode === 'weekly' && (
            <Typography variant="h6">
              Semaine du {formatDate(startDate)} au {formatDate(endDate)}
            </Typography>
          )}
          {viewMode === 'monthly' && (
            <Typography variant="h6">
              Mois du {formatDate(startDate)} au {formatDate(endDate)}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleDateChange('next')}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Filtres */}
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        {isAuthorizedRole() && (
          <Grid item xs={12} sm={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="company-select-label">Société</InputLabel>
              <Select
                labelId="company-select-label"
                value={selectedCompany}
                onChange={handleCompanyFilterChange}
                label="Société"
              >
                <MenuItem value="">
                  <em>Toutes les sociétés</em>
                </MenuItem>
                {Array.from(new Set(vehicules.map((v) => v.societe))).map((soc) => (
                  <MenuItem key={soc} value={soc}>{soc}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="vehicule-select-label">Véhicule</InputLabel>
            <Select
              labelId="vehicule-select-label"
              value={selectedVehicule}
              onChange={handleVehiculeFilterChange}
              label="Véhicule"
            >
              <MenuItem value="">
                <em>Tous les véhicules</em>
              </MenuItem>
              {vehicules.map((v) => (
                <MenuItem key={v.code} value={v.code}>
                  {v.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Affichage selon le mode */}
      {viewMode === 'daily' ? <DailyView /> : <WeeklyMonthlyView />}

      {/* Modal détails */}
      {selectedResponseId && (
        <ProcedureResponsesModal
          responseId={selectedResponseId}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          userToken={userToken}
        />
      )}
    </div>
  );
}

export default React.memo(HistoriqueProcedures);
