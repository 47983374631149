/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import axios from 'axios';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BASE_URL } from '../../../utils/api';

export default function FournisseurImages({ imageKey, userToken, style }) {
  const [signedUrl, setSignedUrl] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  useEffect(() => {
    if (!imageKey) return;
    const parts = imageKey.split('.');
    if (parts.length > 1) {
      const ext = parts[parts.length - 1].toLowerCase();
      setFileExtension(ext);
    }
    const fetchSignedUrl = async () => {
      try {
        const resp = await axios.post(
          `${BASE_URL}fournisseurs/get-signed-url`,
          { key: imageKey },
          { headers: { Authorization: `Bearer ${userToken}` } }
        );
        setSignedUrl(resp.data.url);
      }
      catch (err) {
        console.error('Impossible de récupérer l’URL signée:', err);
      }
    };
    fetchSignedUrl();
  }, [imageKey, userToken]);

  if (!imageKey) return null;
  if (!signedUrl) return <div style={{ textAlign: 'center' }}>Chargement...</div>;

  if (fileExtension === 'pdf') {
    return (
      <div style={{ textAlign: 'center', ...style }}>
        <PictureAsPdfIcon style={{ fontSize: 80 }} />
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <img
        src={signedUrl}
        alt="Fournisseur logo"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          ...style,
        }}
      />
    </div>
  );
}
