/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable quote-props */
/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useRef, useCallback, useMemo
} from 'react';
import './managementDataStyles.scss';
import axios from 'axios';
import moment from 'moment';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DatePicker from 'react-datepicker';
import debounce from 'lodash.debounce';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';
import 'react-datepicker/dist/react-datepicker.css';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';
import ProceduresManagementData from './proceduresManagementData';
import RessourcesHumainesData from './RessourcesHumainesData';
import VehicleManagementData from './vehicleManagementData';
import SalaryDataTab from './salaryData';
import StockResourcesData from './StockResourcesData';
import StaffIncidents from './staffIncidents';
import FacilitiesIncidents from './facilitiesIncidents';
import MedicalDataTab from './medicalData';
import CompanyDataTab from './companyData';
import LocauxDataTab from './locauxData';
import TimeClockDataTab from './timeClockData';

import Navbar from '../hooks/navBar';

const pastelColors = [
  'rgba(255, 179, 186, 0.6)', // pastel pink
  'rgba(255, 223, 186, 0.6)', // pastel orange
  'rgba(255, 255, 186, 0.6)', // pastel yellow
  'rgba(186, 255, 201, 0.6)', // pastel green
  'rgba(186, 225, 255, 0.6)', // pastel blue
  'rgba(255, 204, 229, 0.6)', // pastel light pink
  'rgba(255, 204, 204, 0.6)', // pastel salmon
  'rgba(204, 229, 255, 0.6)', // pastel light blue
  'rgba(204, 255, 229, 0.6)', // pastel mint
  'rgba(255, 229, 204, 0.6)', // pastel peach
  'rgba(255, 255, 204, 0.6)', // pastel light yellow
  'rgba(229, 204, 255, 0.6)', // pastel lavender
  'rgba(255, 204, 255, 0.6)', // pastel mauve
  'rgba(204, 255, 255, 0.6)', // pastel cyan
  'rgba(204, 255, 204, 0.6)', // pastel lime
  'rgba(255, 229, 229, 0.6)', // pastel red
  'rgba(255, 255, 229, 0.6)', // pastel light gold
  'rgba(204, 229, 229, 0.6)', // pastel teal
  'rgba(255, 179, 255, 0.6)', // pastel magenta
  'rgba(229, 204, 204, 0.6)', // pastel dusty rose
  'rgba(204, 255, 179, 0.6)', // pastel light lime
  'rgba(255, 204, 255, 0.6)', // pastel orchid
  'rgba(229, 255, 204, 0.6)', // pastel light olive
  'rgba(204, 229, 255, 0.6)', // pastel sky blue
  'rgba(255, 229, 255, 0.6)', // pastel pink lavender
  'rgba(255, 204, 255, 0.6)', // pastel fuchsia
  'rgba(204, 255, 204, 0.6)', // pastel seafoam
  'rgba(255, 229, 204, 0.6)', // pastel apricot
  'rgba(229, 255, 255, 0.6)', // pastel ice blue
  'rgba(229, 204, 255, 0.6)', // pastel periwinkle
  'rgba(204, 255, 204, 0.6)', // pastel light forest green
  'rgba(255, 255, 179, 0.6)', // pastel banana
  'rgba(204, 229, 255, 0.6)', // pastel powder blue
  'rgba(255, 204, 255, 0.6)', // pastel light purple
  'rgba(229, 229, 255, 0.6)', // pastel light indigo
  'rgba(204, 255, 229, 0.6)', // pastel turquoise
  'rgba(255, 229, 179, 0.6)', // pastel butterscotch
  'rgba(229, 255, 179, 0.6)', // pastel lemon lime
  'rgba(255, 204, 179, 0.6)', // pastel melon
  'rgba(204, 255, 255, 0.6)', // pastel aqua
  'rgba(179, 255, 255, 0.6)', // pastel light teal
  'rgba(255, 229, 204, 0.6)', // pastel light coral
  'rgba(179, 255, 204, 0.6)', // pastel spring green
  'rgba(204, 179, 255, 0.6)', // pastel violet
  'rgba(255, 255, 204, 0.6)', // pastel cream
  'rgba(204, 229, 255, 0.6)', // pastel baby blue
  'rgba(255, 204, 255, 0.6)', // pastel light fuchsia
  'rgba(255, 204, 255, 0.6)', // pastel pink purple
  'rgba(204, 255, 179, 0.6)', // pastel lime green
  'rgba(179, 255, 204, 0.6)', // pastel mint green
  'rgba(229, 255, 204, 0.6)', // pastel light lime
  'rgba(204, 204, 255, 0.6)', // pastel blue purple
  'rgba(204, 255, 204, 0.6)', // pastel green
  'rgba(255, 204, 204, 0.6)', // pastel rose
];

function managementData({ theme, userToken }) {
  usePageTitle('DRIVESOFT | DONNÉES D\'EXPLOITATION');
  const [tauxAbsenteeisme, setTauxAbsenteeisme] = useState(0);
  const [tauxPresence, setTauxPresence] = useState(0);
  const [tauxTurnOver, setTauxTurnOver] = useState(0);
  const [tempsFormation, setTempsFormation] = useState(0);
  const [indiceFrequenceAccidents, setIndiceFrequenceAccidents] = useState(0);
  const [indiceArretMaladies, setIndiceArretMaladies] = useState(0);
  const [indiceCongerParental, setIndiceCongerParental] = useState(0);
  const [genderBalance, setGenderBalance] = useState({ homme: 0, femme: 0 });
  const [averageAge, setAverageAge] = useState(0);
  const [ageDistribution, setAgeDistribution] = useState({ '18-30': 0, '31-50': 0, '51-70': 0 });
  const [incidentsByEstablishment, setIncidentsByEstablishment] = useState({}); // added this state variable
  const [establishmentIncidentData, setEstablishmentIncidentData] = useState({});
  const [plannings, setPlannings] = useState([]); // added this state variable
  const [staff, setStaff] = useState([]);
  const [staffIncidents, setStaffIncidents] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState('');
  const [establishments, setEstablishments] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState('');
  const currentYear = new Date().getUTCFullYear();
  const [startDate, setStartDate] = useState(new Date(Date.UTC(currentYear, 0, 1)));
  const [endDate, setEndDate] = useState(new Date(Date.UTC(currentYear, 11, 31)));
  const [filteredIncidents, setFilteredIncidents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [incidentsPerPage] = useState(5);
  const [currentStaffPage, setCurrentStaffPage] = useState(1);
  const [incidentsPerStaffPage] = useState(5); // ou toute autre valeur que vous préférez
  const [articlesData, setArticlesData] = useState([]);
  const [historique, setHistorique] = useState([]);
  const [totalRetire, setTotalRetire] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [vehiculesFactures, setVehiculesFactures] = useState([]);
  const [currentVehiclePage, setCurrentVehiclePage] = useState(1);
  const [vehiculesPerPage] = useState(10); // Par exemple, 10 éléments par page
  const [selectedVehicule, setSelectedVehicule] = useState('');
  const [procedures, setProcedures] = useState([]);
  const [procedureProblems, setProcedureProblems] = useState({});
  const [positiveResponses, setPositiveResponses] = useState({});
  const [procedureResponses, setProcedureResponses] = useState([]);
  const [statsByEmployee, setStatsByEmployee] = useState({});
  const [statsByVehicle, setStatsByVehicle] = useState({});
  const [medicalFactures, setMedicalFactures] = useState([]);
  const [currentMedicalPage, setCurrentMedicalPage] = useState(1);
  const facturesPerPage = 5;
  const [filteredStaff, setFilteredStaff] = useState([]); // Stocke le staff filtré
  const [staffFactures, setStaffFactures] = useState([]);
  const [currentSalaryPage, setCurrentSalaryPage] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [companyFactures, setCompanyFactures] = useState([]);
  const [currentCompanyPage, setCurrentCompanyPage] = useState(1);
  const [locaux, setLocaux] = useState([]);
  const [locauxFactures, setLocauxFactures] = useState([]);
  const [currentLocauxPage, setCurrentLocauxPage] = useState(1);
  const [selectedLocaux, setSelectedLocaux] = useState('');
  const [salaryBreakdownData, setSalaryBreakdownData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProcedureType, setSelectedProcedureType] = useState('');
  const [selectedDetailedType, setSelectedDetailedType] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [selectedOptionFilter, setSelectedOptionFilter] = useState('');
  const [questionAverages, setQuestionAverages] = useState({});
  const [radioResponses, setRadioResponses] = useState({});
  const [currentTab, setCurrentTab] = useState('humanResourcesData');
  const [timeClockEntries, setTimeClockEntries] = useState([]);
  const [averageAmplitude, setAverageAmplitude] = useState(0);
  const [averageBreakTime, setAverageBreakTime] = useState(0);
  const [overtime, setOvertime] = useState({ hoursAt125: 0, hoursAt150: 0 });
  const [idaj, setIdaj] = useState(0);
  const [selectedGraduation, setSelectedGraduation] = useState('');
  const [selectedSalaryField, setSelectedSalaryField] = useState('');

  const pagesToShow = 5; // Number of page buttons to show at once

  const doughnutRef = useRef(null);

  const calculateFactureCost = (facture) => {
    if (!facture.details) return 0;

    // Additionner tous les champs de détails présents dans la facture
    return Object.keys(facture.details).reduce((acc, key) => {
      const value = parseFloat(facture.details[key]) || 0;
      return acc + value;
    }, 0);
  };

  Chart.register(...registerables, ChartDataLabels);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const cleanAndParseValue = (value) => parseFloat(value.toString().replace(/\s/g, '').replace(',', '.')) || 0;

  const convertDateStringToDateObject = useCallback((dateString) => {
    const parts = dateString.split('-');
    const year = parseInt(parts[2], 10) + 2000; // Ajouter 2000 pour convertir en format "yyyy"
    const month = parseInt(parts[1], 10) - 1; // Les mois sont indexés à partir de 0 en JavaScript
    const day = parseInt(parts[0], 10);

    return new Date(year, month, day);
  }, []);

  const fetchTimeClockEntries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}timeClock`, { headers });
      setTimeClockEntries(response.data.timeClockEntries);
    }
    catch (error) {
      console.error('Failed to fetch time clock entries', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchStaff = async () => {
    setIsLoading(true); // Activer le loader

    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });

      // Exclure les employés ayant une exit_date et trier par nom
      const filteredAndSortedStaff = response.data.staff
        .filter((staffMember) => !staffMember.exit_date) // exclut tous ceux qui ont une exitdate
        .sort((a, b) => a.name.localeCompare(b.name));

      setStaff(filteredAndSortedStaff);
    }
    finally {
      setIsLoading(false); // Désactiver le loader
    }
  };
  const fetchStaffAndFactures = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      const fetchedStaff = response.data.staff;

      const factures = [];

      fetchedStaff.forEach((staffMember) => {
        // On récupère la graduation
        const { graduation } = staffMember;

        if (staffMember.factures && Array.isArray(staffMember.factures)) {
          staffMember.factures.forEach((facture) => {
            const factureDate = new Date(facture.date);

            // Filtrer par période
            if (factureDate >= startDate && factureDate <= endDate) {
              // Filtrer la société
              const companyMatch = !selectedCompany || staffMember.company === selectedCompany;
              if (companyMatch) {
                // Calcul du coût de la facture (on fera la logique fine plus tard)
                let factureCost = 0;

                // Exemple simplifié (vous avez déjà le code de calcul)
                if (facture.type === 'Salaire') {
                  // Pour l’instant, on calcule "tous" les champs comme d’habitude
                  const salaryFields = [
                    'salaire_brut',
                    'cotisations_patronales',
                    'indemnites_repas',
                    'indemnites_repas_2',
                    'prime_salissure',
                    'transport',
                    'taxe_salaire',
                  ];
                  salaryFields.forEach((field) => {
                    if (facture.details?.[field]) {
                      const val = facture.details[field]
                        .toString()
                        .replace(/\s/g, '')
                        .replace(',', '.');
                      factureCost += parseFloat(val) || 0;
                    }
                  });
                }
                else {
                  // Pour les autres types
                  const val = (facture.details?.cout || '')
                    .toString()
                    .replace(/\s/g, '')
                    .replace(',', '.');
                  factureCost = parseFloat(val) || 0;
                }

                factures.push({
                  ...facture,
                  staffName: `${staffMember.name} ${staffMember.last_name}`,
                  staffGraduation: graduation, // <-- On ajoute la graduation
                  totalCost: factureCost,
                  company: staffMember.company,
                });
              }
            }
          });
        }
      });

      setStaffFactures(factures);
    }
    catch (error) {
      console.error('Error fetching staff and factures:', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const ALL_SALARY_FIELDS = [
    'salaire_brut',
    'cotisations_patronales',
    'indemnites_repas',
    'indemnites_repas_2',
    'prime_salissure',
    'transport',
    'taxe_salaire',
  ];

  function getFactureCost(facture) {
    // Vérifier le type
    if (facture.type === 'Salaire') {
      // Si un champ spécifique est sélectionné
      if (selectedSalaryField) {
        // On prend seulement ce champ
        return cleanAndParseValue(facture.details?.[selectedSalaryField] || 0);
      }
      // Sinon, on additionne tout
      return ALL_SALARY_FIELDS.reduce((sum, field) => {
        const v = cleanAndParseValue(facture.details?.[field] || 0);
        return sum + v;
      }, 0);
    }

    // Si ce n’est pas "Salaire" et qu’un salaryField précis est demandé,
    // on ignore ces factures
    if (selectedSalaryField) {
      return 0;
    }

    // Sinon, on prend la valeur du champ "cout" (comme avant)
    return cleanAndParseValue(facture.details?.cout || 0);
  }

  // -- 4. Préparation des calculs
  // Filtrer par la graduation si nécessaire
  const filteredByGraduation = staffFactures.filter((facture) => {
    if (!selectedGraduation) return true;
    return facture.staffGraduation === selectedGraduation;
  });

  // totalSalaryCost
  const totalSalaryCost = useMemo(() => filteredByGraduation
    .filter((facture) => {
      // Filtrer par société si souhaité
      const companyMatch = !selectedCompany || facture.company === selectedCompany;
      return companyMatch;
    })
    .reduce((acc, facture) => acc + getFactureCost(facture), 0), [filteredByGraduation, selectedCompany, getFactureCost]);

  // totalSalaryCostPerEmployee
  const totalSalaryCostPerEmployee = useMemo(() => filteredByGraduation
    .filter((facture) => {
      const companyMatch = !selectedCompany || facture.company === selectedCompany;
      const employeeMatch = selectedEmployee ? facture.staffName === selectedEmployee : true;
      return companyMatch && employeeMatch;
    })
    .reduce((acc, facture) => acc + getFactureCost(facture), 0), [filteredByGraduation, selectedCompany, selectedEmployee, getFactureCost]);

  // salaryCostsByType
  const salaryCostsByType = useMemo(() => {
    const result = {};
    filteredByGraduation.forEach((facture) => {
      const companyMatch = !selectedCompany || facture.company === selectedCompany;
      if (!companyMatch) return;

      if (!result[facture.type]) {
        result[facture.type] = 0;
      }
      result[facture.type] += getFactureCost(facture);
    });

    // Arrondir si besoin
    Object.keys(result).forEach((type) => {
      result[type] = Math.round(result[type]);
    });
    return result;
  }, [filteredByGraduation, selectedCompany, getFactureCost]);

  // salaryCostsByEmployee
  const salaryCostsByEmployee = useMemo(() => {
    const result = {};
    filteredByGraduation.forEach((facture) => {
      const companyMatch = !selectedCompany || facture.company === selectedCompany;
      if (!companyMatch) return;

      if (!result[facture.staffName]) {
        result[facture.staffName] = 0;
      }
      result[facture.staffName] += getFactureCost(facture);
    });

    // Arrondir si besoin
    Object.keys(result).forEach((staffName) => {
      result[staffName] = Math.round(result[staffName]);
    });
    return result;
  }, [filteredByGraduation, selectedCompany, getFactureCost]);

  // -- Filtrage final pour la pagination
  const filteredStaffFactures = useMemo(() => filteredByGraduation.filter((facture) => {
    const companyMatch = !selectedCompany || facture.company === selectedCompany;
    const employeeMatch = !selectedEmployee || facture.staffName === selectedEmployee;
    return companyMatch && employeeMatch;
  }), [filteredByGraduation, selectedCompany, selectedEmployee]);

  const fetchCompanyFactures = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}companies`, { headers });
      const fetchedCompanies = response.data.companies;

      const factures = [];
      const costByType = {};

      fetchedCompanies.forEach((company) => {
        if (company.factures && Array.isArray(company.factures)) {
          company.factures.forEach((facture) => {
            const factureDate = new Date(facture.date);

            // Filtrer par période (startDate, endDate)
            if (factureDate >= startDate && factureDate <= endDate) {
              const factureCost = cleanAndParseValue(facture.details?.Coût || 0);

              // Exclure les factures de la catégorie "Chiffre d'affaires"
              if (facture.type !== "Chiffre d'affaires") {
                // Regrouper les factures par type pour le Doughnut
                if (!costByType[facture.type]) {
                  costByType[facture.type] = 0;
                }
                costByType[facture.type] += factureCost;

                factures.push({
                  ...facture,
                  companyName: company.companyname,
                  companyCode: company.code,
                  cost: factureCost, // Nettoyage du coût ajouté à chaque facture
                });
              }
            }
          });
        }
      });

      setCompanyFactures(factures); // Stocker les factures filtrées
    }
    catch (error) {
      console.error('Error fetching company factures:', error);
    }
    finally {
      setIsLoading(false);
    }
  };
  const fetchEtablissements = async () => {
    setIsLoading(true); // Activer le loader

    try {
      const response = await axios.get(`${BASE_URL}etablissements`, { headers });
      setEstablishments(response.data.etablissements);
    }
    finally {
      setIsLoading(false); // Désactiver le loader
    }
  };

  const fetchPlannings = async () => {
    try {
      const response = await axios.get(`${BASE_URL}planning`, { headers });
      setPlannings(response.data.plannings);
    }
    catch (error) {
      // Handle the error
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}articles`, { headers });
      setArticlesData(response.data.articles);
    }

    finally {
      setIsLoading(false); // Désactiver le loader
    }
  };

  const fetchHistorique = async () => {
    setIsLoading(true); // Activer le loader

    try {
      const response = await axios.get(`${BASE_URL}historique`, { headers });
      const historiqueFetched = response.data.records;

      const filteredData = historiqueFetched.filter((record) => new Date(record.date) >= startDate && new Date(record.date) <= endDate);

      setHistorique(filteredData);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
    }
    catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchLocauxFactures = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}locaux`, { headers });
      const fetchedLocaux = response.data.locaux;

      const factures = [];
      const costByType = {};

      fetchedLocaux.forEach((local) => {
        if (local.factures && Array.isArray(local.factures)) {
          local.factures.forEach((facture) => {
            const factureDate = new Date(facture.date);

            // Filtrer par période (startDate, endDate)
            if (factureDate >= startDate && factureDate <= endDate) {
              const factureCost = calculateFactureCost(facture);

              // Regrouper les factures par type pour le Doughnut
              if (!costByType[facture.type]) {
                costByType[facture.type] = 0;
              }
              costByType[facture.type] += factureCost;

              factures.push({
                ...facture,
                localName: local.nom,
                localCode: local.code,
              });
            }
          });
        }
      });

      setLocauxFactures(factures); // Stocker les factures
      setLocaux(fetchedLocaux);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchMedicalItemsAndFactures = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}medical-items`, { headers });
      const fetchedItems = response.data.medicalItems;

      const factures = [];
      const costByType = {};

      fetchedItems.forEach((item) => {
        if (item.factures && Array.isArray(item.factures)) {
          item.factures.forEach((facture) => {
            const factureDate = new Date(facture.date); // Assure-toi que la date est bien un objet Date
            // Filtrer par période (startDate, endDate)
            if (factureDate >= startDate && factureDate <= endDate) {
              const companyMatch = !selectedCompany || item.company === selectedCompany;

              if (companyMatch) {
                const factureCost = parseFloat(facture.details?.Coût || 0); // Get the cost from facture details

                if (!costByType[facture.type]) {
                  costByType[facture.type] = 0;
                }
                costByType[facture.type] += factureCost;

                factures.push({
                  ...facture,
                  itemName: item.name,
                  serialNumber: item.serial_number,
                  itemPurchaseDate: item.purchase_date,
                  itemPurchasePrice: item.purchase_price,
                });
              }
            }
          });
        }
      });

      setMedicalFactures(factures);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchVehicules = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}vehicules`, { headers });
      let fetchedVehicules = res.data.vehicule;
      fetchedVehicules = fetchedVehicules.sort((a, b) => parseInt(a.code, 10) - parseInt(b.code, 10));

      const factures = [];
      fetchedVehicules.forEach((vehicule) => {
        if (vehicule.factures) {
          vehicule.factures.forEach((facture) => {
            const factureDate = new Date(facture.date.split('/').reverse().join('-'));
            if ((!startDate || factureDate >= startDate) && (!endDate || factureDate <= endDate)) {
              factures.push({
                ...facture,
                vehiculeCode: vehicule.code,
                vehiculeImmat: vehicule.immat
              });
            }
          });
        }
      });

      setVehicules(fetchedVehicules);
      setVehiculesFactures(factures);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchProcedures = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}procedures`, { headers });
      const proceduresFetched = response.data;

      setProcedures(proceduresFetched);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchProcedureResponses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}procedure-responses`, { headers });
      const procedureResponsesFetched = response.data;

      setProcedureResponses(procedureResponsesFetched);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleFilterCompanyChange = useCallback((company) => {
    setSelectedCompany(company);

    // Filtrer les employés en fonction de la société sélectionnée
    if (company) {
      const staffForSelectedCompany = staff.filter((employee) => employee.company === company);
      setFilteredStaff(staffForSelectedCompany);
    }
    else {
      setFilteredStaff(staff);
    }
  }, [staff]);

  useEffect(() => {
    setFilteredStaff(staff); // Par défaut, afficher tout le staff
  }, [staff]);

  const formatDate = (dateString) => {
    if (!dateString) return 'sans date';
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  const procedureHasType = (procedure, typeName) => procedure.types.some((type) => type.name === typeName);

  const uniqueProcedureTypes = useMemo(() => {
    const types = procedures.flatMap((proc) => proc.types.map((type) => type.name));
    return [...new Set(types)];
  }, [procedures]);

  // Filtrage des réponses en fonction de selectedDetailedType, selectedProcedureType, selectedProcedure
  const filteredResponses = useMemo(() => procedureResponses.filter((response) => {
    const procedure = procedures.find((p) => p.id === response.procedure_id);
    if (!procedure) return false;

    // Filtre sur le type de procédure global (selectedProcedureType) :
    const matchesGlobalType = !selectedProcedureType || procedureHasType(procedure, selectedProcedureType);

    // Filtre sur le type détaillé (selectedDetailedType) :
    // Si selectedDetailedType est 'QUALITE', on ne garde que les procédures de type QUALITE
    // Si selectedDetailedType est 'SECURITE', on ne garde que les procédures de type SECURITE
    // Si selectedDetailedType est '', on ne filtre pas sur le détail.
    const matchesDetailedType = !selectedDetailedType || procedureHasType(procedure, selectedDetailedType);

    // Filtre sur la procédure
    const matchesProcedure = !selectedProcedure || procedure.id === parseInt(selectedProcedure, 10);

    return matchesGlobalType && matchesDetailedType && matchesProcedure;
  }), [
    procedureResponses,
    procedures,
    selectedProcedureType,
    selectedDetailedType,
    selectedProcedure,
  ]);

  // Calculer les statistiques
  const calculateStats = () => {
    const employeeStats = {};
    const vehicleStats = {};

    filteredResponses.forEach((response) => {
      const { user_id, vehicule_id, submitted_at } = response;
      const responseDate = new Date(submitted_at);
      if (responseDate >= startDate && responseDate <= endDate) {
        // Statistiques par employé
        employeeStats[user_id] = (employeeStats[user_id] || 0) + 1;

        // Statistiques par véhicule
        vehicleStats[vehicule_id] = (vehicleStats[vehicule_id] || 0) + 1;
      }
    });

    setStatsByEmployee(employeeStats);
    setStatsByVehicle(vehicleStats);
  };

  const analyzeProcedureResponses = () => {
    const problemsCount = {};
    const positiveResponsesCount = {};
    const averages = {};
    const radioResponsesAcc = {};

    // Si on a sélectionné QUALITE ou SECURITE, on affiche l'analyse détaillée
    if (selectedDetailedType === 'QUALITE' || selectedDetailedType === 'SECURITE') {
      // On filtre encore les réponses pour appliquer le filtre "option" si on est en QUALITE et qu'une option est sélectionnée
      let filteredResponsesForAnalysis = filteredResponses;

      if (selectedDetailedType === 'QUALITE' && selectedOptionFilter) {
        filteredResponsesForAnalysis = filteredResponsesForAnalysis.filter((response) => {
          const procedure = procedures.find((p) => p.id === response.procedure_id);
          if (!procedure) return false;
          const responseData = response.response_data;
          let hasSelectedOption = false;
          Object.entries(responseData).forEach(([key, value]) => {
            const field = procedure.fields.find((f) => f.id === parseInt(key, 10));
            if (field && field.type === 'radio' && value === selectedOptionFilter) {
              hasSelectedOption = true;
            }
          });
          return hasSelectedOption;
        });
      }

      filteredResponsesForAnalysis.forEach((response) => {
        const responseDate = new Date(response.submitted_at);
        if (responseDate >= startDate && responseDate <= endDate) {
          const procedure = procedures.find((p) => p.id === response.procedure_id);
          if (!procedure) return;

          const procId = procedure.id;
          if (!averages[procId]) {
            averages[procId] = {};
          }
          if (!radioResponsesAcc[procId]) {
            radioResponsesAcc[procId] = {};
          }

          Object.entries(response.response_data).forEach(([key, value]) => {
            const field = procedure.fields.find((f) => f.id === parseInt(key, 10));
            if (!field) return;

            if (field.type === 'satisfaction') {
              const numericValue = parseFloat(value);
              if (!Number.isNaN(numericValue)) {
                if (!averages[procId][field.name]) {
                  averages[procId][field.name] = { total: 0, count: 0 };
                }
                averages[procId][field.name].total += numericValue;
                averages[procId][field.name].count += 1;
              }
            }
            else if (field.type === 'radio' || field.type === 'checkbox') {
              if (!radioResponsesAcc[procId][field.name]) {
                radioResponsesAcc[procId][field.name] = {};
              }
              if (Array.isArray(value)) {
                // Si value est un tableau, traiter chaque élément
                value.forEach((v) => {
                  radioResponsesAcc[procId][field.name][v] =
                    (radioResponsesAcc[procId][field.name][v] || 0) + 1;
                });
              }
              else {
                // Sinon, traiter la valeur comme d'habitude
                radioResponsesAcc[procId][field.name][value] =
                  (radioResponsesAcc[procId][field.name][value] || 0) + 1;
              }
            }
          });
        }
      });

      setQuestionAverages(averages);
      setRadioResponses(radioResponsesAcc);
      setProcedureProblems({});
      setPositiveResponses({});
    }
    else {
      // Analyse globale (quand selectedDetailedType est vide)
      filteredResponses.forEach((response) => {
        const responseDate = new Date(response.submitted_at);
        if (responseDate >= startDate && responseDate <= endDate) {
          const procedure = procedures.find((p) => p.id === response.procedure_id);
          if (!procedure) {
            return;
          }

          Object.entries(response.response_data).forEach(([key, value]) => {
            const field = procedure.fields.find((f) => f.id === parseInt(key, 10));
            if (!field) {
              return;
            }

            if (field.type === 'radio' || field.type === 'toggle') {
              // Supposons que "KO" indique un problème
              if (value === 'KO') {
                problemsCount[procedure.name] = (problemsCount[procedure.name] || 0) + 1;
              }
              else if (value === 'OK') {
                positiveResponsesCount[procedure.name] = (positiveResponsesCount[procedure.name] || 0) + 1;
              }
            }
          });
        }
      });
      setQuestionAverages({});
      setProcedureProblems(problemsCount);
      setPositiveResponses(positiveResponsesCount);
      setRadioResponses({});
    }
  };

  useEffect(() => {
    if (procedureResponses.length > 0 && procedures.length > 0) {
      calculateStats();
      analyzeProcedureResponses();
    }
  }, [
    procedureResponses,
    procedures,
    startDate,
    endDate,
    selectedProcedureType,
    selectedProcedure,
    selectedDetailedType,
    selectedOptionFilter,
  ]);

  useEffect(() => {
    if (!selectedDetailedType) {
      setQuestionAverages({});
      setRadioResponses({});
    }
  }, [selectedDetailedType]);

  // Préparer les données pour les graphiques
  const dataForEmployeesChart = useMemo(() => ({
    labels: Object.keys(statsByEmployee),
    datasets: [
      {
        label: 'Nombre de procédures par employé',
        data: Object.values(statsByEmployee).map((value) => Math.round(value)),
        backgroundColor: pastelColors,
        borderWidth: 1,
      },
    ],
  }), [statsByEmployee]);

  const dataForVehiclesChart = useMemo(() => {
  // Filtrer les véhicules dont l'ID n'est pas null ou "null"
    const filteredStatsByVehicle = Object.entries(statsByVehicle).filter(
      ([key]) => key !== null && key !== 'null'
    );

    const labels = filteredStatsByVehicle.map(([key]) => key);
    const data = filteredStatsByVehicle.map(([, value]) => value);

    return {
      labels,
      datasets: [
        {
          label: 'Nombre de procédures par véhicule',
          data,
          backgroundColor: pastelColors.slice(0, data.length), // Ajuster la couleur en fonction du nombre de données
          borderWidth: 1,
        },
      ],
    };
  }, [statsByVehicle]);

  const dataForProceduresChart = useMemo(() => {
    const allProcedureNames = [
      ...new Set([
        ...Object.keys(positiveResponses),
        ...Object.keys(procedureProblems),
      ]),
    ];

    return {
      labels: allProcedureNames, // Assurez-vous que ce tableau contient toutes les procédures
      datasets: [
        {
          label: 'Réponses positives',
          data: allProcedureNames.map((procName) => Math.round(positiveResponses[procName] || 0)),
          backgroundColor: 'rgba(75, 192, 192, 0.5)', // Vert
        },
        {
          label: 'Problèmes',
          data: allProcedureNames.map((procName) => -Math.round(procedureProblems[procName] || 0)), // Valeurs négatives
          backgroundColor: 'rgba(255, 99, 132, 0.5)', // Rouge
        },
      ],
    };
  }, [positiveResponses, procedureProblems]);

  // ... le reste de votre code

  // Filtrer les factures par local sélectionné
  const filteredLocauxFactures = locauxFactures.filter((facture) => {
    const locauxMatch = !selectedLocaux || facture.localName === selectedLocaux;
    return locauxMatch;
  });

  // Calcul du coût total après filtrage
  const totalLocauxCost = filteredLocauxFactures.reduce((acc, facture) => acc + calculateFactureCost(facture), 0);

  // Calcul des coûts par type après filtrage
  const locauxCostsByType = filteredLocauxFactures.reduce((acc, facture) => {
    if (!acc[facture.type]) {
      acc[facture.type] = 0;
    }

    const cost = calculateFactureCost(facture);
    if (Number.isFinite(cost)) { // Vérifie que 'cost' est un nombre valide
      acc[facture.type] += cost;
    }

    return acc;
  }, {});

  // Arrondir les totaux par type après l'agrégation
  Object.keys(locauxCostsByType).forEach((type) => {
    locauxCostsByType[type] = Math.round(locauxCostsByType[type]);
  });

  // Pagination logic
  const paginateLocaux = (pageNumber) => setCurrentLocauxPage(pageNumber);

  const handleFilterLocauxChange = (local) => {
    setSelectedLocaux(local);
  };

  const indexOfLastLocauxFacture = currentLocauxPage * facturesPerPage;
  const indexOfFirstLocauxFacture = indexOfLastLocauxFacture - facturesPerPage;
  const currentLocauxFacturesPage = filteredLocauxFactures.slice(indexOfFirstLocauxFacture, indexOfLastLocauxFacture);

  // Filtrage des factures par société sélectionnée
  const filteredCompanyFactures = companyFactures.filter((facture) => !selectedCompany || facture.companyCode === selectedCompany);

  // Calcul du coût total après filtrage par société
  const totalCompanyCost = filteredCompanyFactures.reduce(
    (acc, facture) => acc + facture.cost, // Utilisation du coût nettoyé
    0
  );

  // Calcul des coûts par type de facture
  const companyCostsByType = filteredCompanyFactures.reduce((acc, facture) => {
    if (!acc[facture.type]) {
      acc[facture.type] = 0;
    }

    const cost = parseFloat(facture.cost);
    if (Number.isFinite(cost)) { // Vérifie que 'cost' est un nombre valide
      acc[facture.type] += cost;
    }

    return acc;
  }, {});

  // Arrondir les totaux par type après l'agrégation
  Object.keys(companyCostsByType).forEach((type) => {
    companyCostsByType[type] = Math.round(companyCostsByType[type]);
  });

  const paginateCompany = (pageNumber) => setCurrentCompanyPage(pageNumber);

  const indexOfLastCompanyFacture = currentCompanyPage * facturesPerPage;
  const indexOfFirstCompanyFacture = indexOfLastCompanyFacture - facturesPerPage;
  const currentCompanyFacturesPage = filteredCompanyFactures.slice(indexOfFirstCompanyFacture, indexOfLastCompanyFacture);

  const totalPages = Math.ceil(filteredStaffFactures.length / facturesPerPage);

  const getPageNumbers = () => {
    const half = Math.floor(pagesToShow / 2);
    let startPage = Math.max(currentSalaryPage - half, 1);
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    if (endPage - startPage < pagesToShow - 1) {
      startPage = Math.max(endPage - pagesToShow + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx);
  };

  const indexOfLastStaffFacture = currentSalaryPage * facturesPerPage;
  const indexOfFirstStaffFacture = indexOfLastStaffFacture - facturesPerPage;
  const currentStaffFacturesPage = filteredStaffFactures.slice(indexOfFirstStaffFacture, indexOfLastStaffFacture);

  const paginateSalary = (pageNumber) => setCurrentSalaryPage(pageNumber);

  const totalMedicalCost = medicalFactures
    .filter((facture) => {
      const companyMatch = !selectedCompany || facture.company === selectedCompany;
      return companyMatch;
    })
    .reduce((acc, facture) => acc + parseFloat(facture.details?.Coût || 0), 0);

  // Préparation des données pour le graphique Doughnut par type de facture

  const medicalCostsByType = {}; // Objet pour regrouper les coûts par type

  medicalFactures.forEach((facture) => {
    if (!selectedCompany || facture.company === selectedCompany) {
      if (!medicalCostsByType[facture.type]) {
        medicalCostsByType[facture.type] = 0;
      }
      const cout = parseFloat(facture.details?.Coût || 0);
      if (Number.isFinite(cout)) { // Vérifie que 'cout' est un nombre valide
        medicalCostsByType[facture.type] += cout;
      }
    }
  });

  // Arrondir les totaux par type après l'agrégation
  Object.keys(medicalCostsByType).forEach((type) => {
    medicalCostsByType[type] = Math.round(medicalCostsByType[type]);
  });

  const filteredMedicalFactures = medicalFactures
    .filter((facture) => !selectedCompany || facture.company === selectedCompany);

  const indexOfLastMedicalFacture = currentMedicalPage * facturesPerPage;
  const indexOfFirstMedicalFacture = indexOfLastMedicalFacture - facturesPerPage;
  const currentMedicalFacturesPage = filteredMedicalFactures.slice(indexOfFirstMedicalFacture, indexOfLastMedicalFacture);

  const paginateMedical = (pageNumber) => setCurrentMedicalPage(pageNumber);

  const totalCost = vehiculesFactures
    .filter((facture) => {
    // Correspondance du véhicule avec la facture
      const vehicule = vehicules.find((v) => v.code === facture.vehiculeCode);
      if (!vehicule) return false;

      // Vérifier si la société correspond ou si aucune société n'est sélectionnée
      const companyMatch = !selectedCompany || vehicule.societe === selectedCompany;
      // Vérifier si le code du véhicule correspond ou si aucun véhicule n'est sélectionné
      const vehiculeMatch = selectedVehicule === '' || facture.vehiculeCode === selectedVehicule;

      return companyMatch && vehiculeMatch;
    })
    .reduce((acc, facture) => acc + parseFloat(facture.Prices), 0);

  // Préparation des données pour le graphique Doughnut par type de réparation
  const repairsByType = {}; // Objet pour regrouper les réparations par type
  vehiculesFactures.forEach((facture) => {
    const vehicule = vehicules.find((v) => v.code === facture.vehiculeCode);
    if (!vehicule || (selectedCompany && vehicule.societe !== selectedCompany)) return;

    if (!repairsByType[facture.type]) {
      repairsByType[facture.type] = 0;
    }

    const prix = parseFloat(facture.Prices);
    if (Number.isFinite(prix)) {
      repairsByType[facture.type] += prix;
    }
  });

  // Arrondir les totaux par type après l'agrégation
  Object.keys(repairsByType).forEach((type) => {
    repairsByType[type] = Math.round(repairsByType[type]);
  });

  // Préparation des données pour le graphique Doughnut par véhicule
  const repairsByVehicle = {}; // Obj pour regrouper les réparations par véhicule
  vehiculesFactures.forEach((facture) => {
    const vehicule = vehicules.find((v) => v.code === facture.vehiculeCode);
    if (!vehicule || (selectedCompany && vehicule.societe !== selectedCompany)) return;

    const vehicleKey = `${facture.vehiculeCode} - ${facture.vehiculeImmat}`;
    if (!repairsByVehicle[vehicleKey]) {
      repairsByVehicle[vehicleKey] = 0;
    }
    repairsByVehicle[vehicleKey] += parseFloat(facture.Prices);
  });

  const selectedVehicleRepairs = vehiculesFactures
    .filter((facture) => {
      const vehicule = vehicules.find((v) => v.code === facture.vehiculeCode);
      return vehicule &&
           facture.vehiculeCode === selectedVehicule &&
           (!selectedCompany || vehicule.societe === selectedCompany);
    })
    .reduce((acc, facture) => {
      acc[facture.type] = (acc[facture.type] || 0) + parseFloat(facture.Prices);
      return acc;
    }, {});

  // Préparation des données pour le graphique Doughnut du véhicule sélectionné
  // Transformer les données du véhicule sélectionné pour le graphique en barres
  const barDataRepairsByVehicle = useMemo(() => ({
    labels: Object.keys(repairsByVehicle),
    datasets: [
      {
        label: 'Coût total par véhicule',
        data: Object.values(repairsByVehicle).map((value) => Math.round(value)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  }), [repairsByVehicle]);

  const barDataSelectedVehicle = useMemo(() => ({
    labels: Object.keys(selectedVehicleRepairs),
    datasets: [
      {
        label: `Coût par type de réparation pour ${selectedVehicule}`,
        data: Object.values(selectedVehicleRepairs).map((value) => Math.round(value)),
        backgroundColor: pastelColors.slice(0, Object.keys(selectedVehicleRepairs).length),
        borderColor: pastelColors.slice(0, Object.keys(selectedVehicleRepairs).length).map((color) => color.replace('0.6', '1')),
        borderWidth: 1,
      },
    ],
  }), [selectedVehicleRepairs, selectedVehicule, pastelColors]);

  useEffect(() => {
    const fetchDataForCurrentTab = async () => {
      await fetchCompanies();
      if (currentTab === 'humanResourcesData') {
        await fetchStaff();
        await fetchEtablissements();
        await fetchPlannings();
      }
      else if (currentTab === 'facilityIncidents') {
        await fetchEtablissements();
        await fetchHistorique();
      }
      else if (currentTab === 'stockRessourcesData') {
        await fetchArticles();
        await fetchHistorique();
      }
      else if (currentTab === 'vehicleManagementData') {
        await fetchVehicules();
      }
      else if (currentTab === 'ProceduresManagementData') {
        await fetchProcedures();
        await fetchProcedureResponses();
      }
      else if (currentTab === 'medicalDataTab') {
        await fetchMedicalItemsAndFactures();
      }
      else if (currentTab === 'salaryDataTab') {
        await fetchStaffAndFactures();
      }
      else if (currentTab === 'companyDataTab') {
        await fetchCompanyFactures();
      }
      else if (currentTab === 'locauxDataTab') {
        await fetchLocauxFactures();
      }
      else if (currentTab === 'timeClockData') {
        await fetchTimeClockEntries();
        await fetchStaff();
      }
    };

    fetchDataForCurrentTab();
  }, [currentTab, startDate, endDate]);

  const calculateTotalRetire = (historiqueData) => historiqueData
    .filter((record) => record.type === 'subtract')
    .reduce((acc, record) => {
      const article = articlesData.find((a) => a.article_id === record.article_id);
      const prixUnitaire = article ? parseFloat(article.prix_unite) : 0;
      return acc + (prixUnitaire * record.quantite);
    }, 0);

  const breakdownByReasonWithPrice = (historiqueData) => historiqueData
    .filter((record) => record.type === 'subtract')
    .reduce((acc, record) => {
      const article = articlesData.find((a) => a.article_id === record.article_id);
      const prixUnitaire = article ? parseFloat(article.prix_unite) : 0;

      if (!acc[record.raison]) {
        acc[record.raison] = { quantite: 0, prix: 0 };
      }

      acc[record.raison].quantite += record.quantite;
      acc[record.raison].prix += prixUnitaire * record.quantite;
      return acc;
    }, {});

  useEffect(() => {
    setTotalRetire(calculateTotalRetire(historique));
  }, [historique, articlesData]);

  const reapproByAmbulanceWithPrice = (historiqueData) => historiqueData
    .filter((record) => record.type === 'subtract' && record.vehicule_destinataire)
    .reduce((acc, record) => {
      const article = articlesData.find((a) => a.article_id === record.article_id);
      const prixUnitaire = article ? parseFloat(article.prix_unite) : 0;

      if (!acc[record.vehicule_destinataire]) {
        acc[record.vehicule_destinataire] = { quantite: 0, prix: 0 };
      }

      acc[record.vehicule_destinataire].quantite += record.quantite;
      acc[record.vehicule_destinataire].prix += prixUnitaire * record.quantite;
      return acc;
    }, {});

  const breakdownByReasonWithPrices = breakdownByReasonWithPrice(historique);
  const ambulanceReapproWithPrice = reapproByAmbulanceWithPrice(historique);

  const reasonsLabels = Object.keys(breakdownByReasonWithPrices);
  const reasonsPrices = reasonsLabels.map((raison) => Math.round(breakdownByReasonWithPrices[raison].prix));

  const vehiclesLabels = Object.keys(ambulanceReapproWithPrice);
  const vehiclesPrices = vehiclesLabels.map((vehicle) => Math.round(ambulanceReapproWithPrice[vehicle].prix));

  const fetchIncidents = useCallback(debounce(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}transports`, { headers });
      const incidentsF = response.data.transports;

      const filteredData = incidentsF.filter((incident) => new Date(incident.date) >= startDate &&
        new Date(incident.date) <= endDate &&
        incident.incident_type !== null);

      setFilteredIncidents(filteredData);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  }, 500), [startDate, endDate]);

  useEffect(() => {
    fetchIncidents();
  }, [startDate, endDate]);

  // Mémorisation de fetchStaffIncidents avec useCallback
  const fetchStaffIncidents = useCallback(async () => {
    setIsLoading(true);
    try {
      const incidents = [];

      // Filtrer pour n'inclure que les membres du personnel sans exitdate
      const activeStaff = staff.filter((staffMember) => !staffMember.exitdate);

      activeStaff.forEach((staffMember) => {
        if (staffMember.incidents && staffMember.incidents.length > 0) {
          staffMember.incidents.forEach((incident) => {
            incidents.push({
              id: incident.id || Math.random(),
              date: incident.date,
              firstName: staffMember.name,
              lastName: staffMember.last_name,
              type: incident.type,
              description: incident.description,
            });
          });
        }
      });

      setStaffIncidents(incidents);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  }, [staff]);

  const filteredPlannings = plannings.filter((p) => {
    const planningDate = new Date(p.date);
    const isDateInRange = planningDate >= startDate && planningDate <= endDate;
    const isCompanyMatch = selectedCompany ? filteredStaff.some((s) => s.id === p.staff_id) : true;

    return isDateInRange && isCompanyMatch;
  });

  const fetchMetrics = useCallback(async () => {
    setIsLoading(true); // Activer le loader
    try {
      const joursAbsence = filteredPlannings.filter((p) => ['ABS', 'AM', 'AT', 'SS'].includes(p.status)).length;
      const joursTravail = filteredPlannings.filter((p) => p.status === 'T').length;

      if (joursAbsence + joursTravail > 0) {
        const tauxAbsenteeismes = (joursAbsence / (joursAbsence + joursTravail)) * 100;
        setTauxAbsenteeisme(tauxAbsenteeismes);

        const tauxPresences = (joursTravail / (joursAbsence + joursTravail)) * 100;
        setTauxPresence(tauxPresences);
      }
      else {
        setTauxAbsenteeisme(0);
        setTauxPresence(0);
      }

      const getStaffCountAtDate = (date, staffList) => staffList.filter(
        (staffD) => new Date(staffD.entrydate) <= date && (!staffD.exitdate || new Date(staffD.exitdate) > date)
      ).length;

      const staffCountAtStartDate = getStaffCountAtDate(startDate, staff);
      const staffCountAtEndDate = getStaffCountAtDate(endDate, staff);
      const averageStaffCount = (staffCountAtStartDate + staffCountAtEndDate) / 2;

      const staffWhoLeftDuringPeriod = filteredStaff.filter(
        (staffL) => staffL.exitdate && new Date(staffL.exitdate) >= startDate && new Date(staffL.exitdate) <= endDate
      ).length;

      if (averageStaffCount > 0) {
        const tauxTurnOvers = (staffWhoLeftDuringPeriod / averageStaffCount) * 100;
        setTauxTurnOver(tauxTurnOvers);
      }
      else {
        setTauxTurnOver(0);
      }

      if (joursTravail > 0) {
        const tempsFormations = filteredPlannings.filter((p) => p.status === 'EC').length;
        const indiceTempsFormations = (tempsFormations / joursTravail) * 100;
        setTempsFormation(indiceTempsFormations);

        const arretMaladie = filteredPlannings.filter((p) => p.status === 'AM').length;
        const indiceArretsMaladies = (arretMaladie / joursTravail) * 100;
        setIndiceArretMaladies(indiceArretsMaladies);

        const congerParental = filteredPlannings.filter((p) => p.status === 'PAR').length;
        const indiceCongersParental = (congerParental / joursTravail) * 100;
        setIndiceCongerParental(indiceCongersParental);

        const nombreAccidentsTravail = filteredPlannings.filter((p) => p.status === 'AT').length;
        const indiceFrequenceAccidentsCalcul = (nombreAccidentsTravail / joursTravail) * 100;
        setIndiceFrequenceAccidents(indiceFrequenceAccidentsCalcul);
      }
      else {
        setTempsFormation(0);
        setIndiceArretMaladies(0);
        setIndiceCongerParental(0);
        setIndiceFrequenceAccidents(0);
      }

      if (filteredStaff.length > 0) {
        const hommes = filteredStaff.filter((s) => s.sexe === 'Homme').length;
        const femmes = filteredStaff.filter((s) => s.sexe === 'Femme').length;
        setGenderBalance({ homme: (hommes / filteredStaff.length) * 100, femme: (femmes / filteredStaff.length) * 100 });

        const ages = filteredStaff.map((s) => moment().diff(moment(s.date_de_naissance), 'years'));
        const totalAge = ages.reduce((acc, age) => acc + age, 0);
        setAverageAge(totalAge / filteredStaff.length);

        const staffAgeDistribution = {
          '18-30': ages.filter((age) => age >= 18 && age <= 30).length,
          '31-50': ages.filter((age) => age >= 31 && age <= 50).length,
          '51-70': ages.filter((age) => age >= 51 && age <= 70).length,
        };
        setAgeDistribution({
          '18-30': (staffAgeDistribution['18-30'] / filteredStaff.length) * 100,
          '31-50': (staffAgeDistribution['31-50'] / filteredStaff.length) * 100,
          '51-70': (staffAgeDistribution['51-70'] / filteredStaff.length) * 100,
        });
      }
      else {
        setGenderBalance({ homme: 0, femme: 0 });
        setAverageAge(0);
        setAgeDistribution({ '18-30': 0, '31-50': 0, '51-70': 0 });
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false); // Désactiver le loader
    }
  }, [filteredPlannings, startDate, endDate, filteredStaff]);

  const handleVehiculeChange = (event) => {
    setSelectedVehicule(event.target.value);
  };

  const handleEstablishmentChange = (eventOrValue) => {
    // Check if eventOrValue is an event object or a direct value
    const value = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue;
    setSelectedEstablishment(value);
  };

  const barData = useMemo(() => ({
    labels: Object.keys(salaryCostsByEmployee),
    datasets: [
      {
        label: 'Coût total par employé',
        data: Object.values(salaryCostsByEmployee),
        backgroundColor: pastelColors,
      },
    ],
  }), [salaryCostsByEmployee, pastelColors]);

  const handleChartClick = (event, chartElement) => {
    if (!chartElement) {
      console.warn('Le chartElement est null');
      return;
    }
    const elements = chartElement.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
    if (elements.length > 0) {
      const { index } = elements[0];
      const label = chartElement.data.labels[index];
      handleEstablishmentChange(label);
    }
  };

  const handleChartDriverClick = (event) => {
    const chart = doughnutRef.current;
    if (chart) {
      const elements = chart.getElementsAtEventForMode(
        event.nativeEvent,
        'nearest',
        { intersect: true },
        false
      );
      if (elements.length > 0) {
        const { index } = elements[0];
        const driverName = chart.data.labels[index];
        setSelectedDriver(driverName);
      }
    }
  };

  const salaryFields = ['salaire_brut', 'cotisations_patronales', 'indemnites_repas', 'indemnites_repas_2', 'prime_salissure', 'transport', 'taxe_salaire'];

  const handleChartTypeClick = (event, elements) => {
    if (elements && elements.length > 0) {
      const clickedIndex = elements[0].index;
      const clickedLabel = Object.keys(salaryCostsByType)[clickedIndex];

      if (clickedLabel === 'Salaire') {
        // Préparer les données détaillées
        const salaryFieldsTotals = {};
        salaryFields.forEach((field) => {
          salaryFieldsTotals[field] = 0;
        });

        staffFactures.forEach((facture) => {
          const companyMatch = !selectedCompany || facture.company === selectedCompany;
          if (companyMatch && facture.type === 'Salaire') {
            salaryFields.forEach((field) => {
              const value = cleanAndParseValue(facture.details?.[field] || 0);
              if (Number.isFinite(value)) { // Vérifie que 'value' est un nombre valide
                salaryFieldsTotals[field] += value;
              }
            });
          }
        });

        // Arrondir les totaux par champ avant de les stocker
        Object.keys(salaryFieldsTotals).forEach((field) => {
          salaryFieldsTotals[field] = Math.round(salaryFieldsTotals[field]);
        });

        setSalaryBreakdownData(salaryFieldsTotals);
      }
    }
  };

  const handleChartVehClick = (event, chartElement) => {
    const elements = chartElement.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
    if (elements.length > 0) {
      const { index } = elements[0];
      const vehicleKey = chartElement.data.labels[index];
      setSelectedVehicule(vehicleKey.split(' - ')[0]); // Extraire le code du véhicule depuis le label
    }
  };

  const filteredIncidentStaff = staffIncidents
    .filter((incident) => {
      const incidentDate = convertDateStringToDateObject(incident.date);
      return incidentDate >= startDate && incidentDate <= endDate;
    })
    .filter((incident) => selectedDriver === '' || (`${incident.firstName} ${incident.lastName}`) === selectedDriver);

  function parsePrefix(incidentText = '') {
    if (incidentText.startsWith('[DEPART]')) return 'departure';
    if (incidentText.startsWith('[ARRIV]')) return 'arrival';
    if (incidentText.startsWith('[BOTH]')) return 'both';
    return '';
  }

  // Ensuite, dans votre logique de filtre :
  const establishmentFilteredIncidents = filteredIncidents.filter((incident) => {
    if (selectedEstablishment === '') {
      // Pas de filtre, on garde tout
      return true;
    }

    // Récupérer le préfixe
    const prefix = parsePrefix(incident.incident || '');

    // Récupérer labels
    const depLabel = incident.departure_label;
    const arrLabel = incident.arrival_label;

    if (prefix === 'departure') {
      // Incident concerné par l'établissement de départ
      return depLabel === selectedEstablishment;
    }
    if (prefix === 'arrival') {
      // Incident concerné par l'établissement d'arrivée
      return arrLabel === selectedEstablishment;
    }
    if (prefix === 'both') {
      // Incident concerné par LES DEUX
      // => on vérifie si le selectedEstablishment correspond AU MOINS à un des deux
      return (
        depLabel === selectedEstablishment ||
          arrLabel === selectedEstablishment
      );
    }

    // Pas de préfixe => fallback : on suppose que c’est le départ
    return depLabel === selectedEstablishment;
  });
  const filteredFactureVehicule = vehiculesFactures.filter((facture) => {
    const vehicule = vehicules.find((v) => v.code === facture.vehiculeCode);
    // Assurez-vous que vehicule n'est pas undefined avant de continuer
    if (!vehicule) return false;

    const companyMatch = !selectedCompany || vehicule.societe === selectedCompany; // Assurez-vous que companyCode est la bonne propriété
    const vehiculeMatch = selectedVehicule === '' || facture.vehiculeCode === selectedVehicule;

    return companyMatch && vehiculeMatch;
  });

  const totalVehiclePages = Math.ceil(filteredFactureVehicule.length / vehiculesPerPage);

  const indexOfLastVehicule = currentVehiclePage * vehiculesPerPage;
  const indexOfFirstVehicule = indexOfLastVehicule - vehiculesPerPage;
  const currentVehicules = filteredFactureVehicule.slice(indexOfFirstVehicule, indexOfLastVehicule);

  const paginateVehicles = (pageNumber) => setCurrentVehiclePage(pageNumber);

  const driverIncidentData = useMemo(() => {
    const incidentsByDriver = {};
    const incidentsByType = {};

    // On réutilise la même logique de filtre (date ET chauffeur)
    const filteredIncident = staffIncidents
      .filter((incident) => {
        const incidentDate = convertDateStringToDateObject(incident.date);
        return incidentDate >= startDate && incidentDate <= endDate;
      })
      .filter((incident) => {
        const driverName = `${incident.firstName} ${incident.lastName}`;
        return selectedDriver === '' || driverName === selectedDriver;
      });

    filteredIncident.forEach((incident) => {
      const driverName = `${incident.firstName} ${incident.lastName}`;
      const incidentType = incident.type;

      // Incrémente la structure "byDriver"
      if (!incidentsByDriver[driverName]) {
        incidentsByDriver[driverName] = {};
      }
      if (!incidentsByDriver[driverName][incidentType]) {
        incidentsByDriver[driverName][incidentType] = 0;
      }
      incidentsByDriver[driverName][incidentType] += 1;

      // Incrémente la structure "allDrivers"
      if (!incidentsByType[incidentType]) {
        incidentsByType[incidentType] = 0;
      }
      incidentsByType[incidentType] += 1;
    });

    return {
      byDriver: incidentsByDriver,
      allDrivers: incidentsByType,
    };
  }, [staffIncidents, startDate, endDate, selectedDriver, convertDateStringToDateObject]);

  const incidentsPerDriver = {};
  Object.keys(driverIncidentData.byDriver).forEach((driverName) => {
    const incidentsByType = driverIncidentData.byDriver[driverName];
    const totalIncidents = Object.values(incidentsByType).reduce((sum, count) => sum + count, 0);
    incidentsPerDriver[driverName] = totalIncidents;
  });

  function parseIncidentText(text = '') {
    if (text.startsWith('[DEPART]')) return 'departure';
    if (text.startsWith('[ARRIV]')) return 'arrival';
    if (text.startsWith('[BOTH]')) return 'both';
    return null;
  }

  useEffect(() => {
    const establishmentIncidentTypes = {};
    const incidentsByEstablishments = {};

    // Petit helper pour incrémenter un établissement donné
    const incrementEtablissement = (etabLabel, incidentType) => {
      if (!etabLabel) return;
      if (!establishmentIncidentTypes[etabLabel]) {
        establishmentIncidentTypes[etabLabel] = {};
      }
      if (!establishmentIncidentTypes[etabLabel][incidentType]) {
        establishmentIncidentTypes[etabLabel][incidentType] = 0;
      }
      establishmentIncidentTypes[etabLabel][incidentType] += 1;

      if (!incidentsByEstablishments[etabLabel]) {
        incidentsByEstablishments[etabLabel] = 0;
      }
      incidentsByEstablishments[etabLabel] += 1;
    };

    filteredIncidents.forEach((incident) => {
      // 1. On récupère le préfixe dans incident.incident
      const prefix = parseIncidentText(incident.incident || '');
      const incidentType = incident.incident_type;
      // 2. On récupère les 2 labels
      const depLabel = incident.departure_label || 'Inconnu';
      const arrLabel = incident.arrival_label || 'Inconnu';

      // 3. Selon le préfixe, on incrémente
      if (prefix === 'departure') {
        incrementEtablissement(depLabel, incidentType);
      }
      else if (prefix === 'arrival') {
        incrementEtablissement(arrLabel, incidentType);
      }
      else if (prefix === 'both') {
        incrementEtablissement(depLabel, incidentType);
        incrementEtablissement(arrLabel, incidentType);
      }
      else {
        // Pas de préfixe => fallback (par exemple on incrémente l'établissement de départ)
        incrementEtablissement(depLabel, incidentType);
      }
    });

    setEstablishmentIncidentData(establishmentIncidentTypes);
    setIncidentsByEstablishment(incidentsByEstablishments);
    fetchMetrics();
    fetchStaffIncidents();
  }, [filteredIncidents, fetchMetrics]);

  const renderContent = () => {
    switch (currentTab) {
      case 'humanResourcesData':
        return <div>Données ressources humaines</div>;
      case 'facilityIncidents':
        return <div>Incidents établissements</div>;
      case 'staffIncidents':
        return <div>Incidents personnel</div>;
      case 'stockRessourcesData':
        return <div>Données de gestion de stock</div>;
      case 'vehicleManagementData':
        return <div>Données de gestion de véhicules</div>;
      case 'ProceduresManagementData':
        return <div>Données de gestion des procédures</div>;
      case 'medicalDataTab':
        return <div>Données du matériel médical</div>;
      case 'salaryDataTab':
        return <div>Masse salariale</div>;
      case 'companyDataTab':
        return <div>Données des sociétés</div>;
      case 'locauxDataTab':
        return <div>Données des locaux</div>;
      case 'timeClockData':
        return <div>Données de pointeuse</div>;
      default:
        return <div>Bienvenue dans l'application</div>;
    }
  };

  const doughnutDataRH = useMemo(() => ({
    labels: [
      'Taux de Présence',
      'Taux d\'Absentéisme',
      'Taux de Turn-over',
      'Temps de Formation',
      'Indice de Fréquence des Accidents du Travail',
      'Indice d\'Arrêt Maladies',
      'Indice de Congé Parental'
    ],
    datasets: [
      {
        data: [
          Math.round(tauxPresence),
          Math.round(tauxAbsenteeisme),
          Math.round(tauxTurnOver),
          Math.round(tempsFormation),
          Math.round(indiceFrequenceAccidents),
          Math.round(indiceArretMaladies),
          Math.round(indiceCongerParental),
        ],
        backgroundColor: [
          'rgba(158, 135, 105, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        hoverBackgroundColor: [
          'rgba(158, 135, 105, 0.4)',
          'rgba(255, 99, 132, 0.4)',
          'rgba(54, 162, 235, 0.4)',
          'rgba(255, 206, 86, 0.4)',
          'rgba(75, 192, 192, 0.4)',
          'rgba(153, 102, 255, 0.4)',
          'rgba(255, 159, 64, 0.4)',
        ],
      },
    ],
  }), [
    tauxPresence,
    tauxAbsenteeisme,
    tauxTurnOver,
    tempsFormation,
    indiceFrequenceAccidents,
    indiceArretMaladies,
    indiceCongerParental,
  ]);

  // Préparer les données pour le graphique Bar pour la distribution par âge
  const ageDistributionBarDataRH = {
    labels: ['18-30 ans', '31-50 ans', '51-70 ans'],
    datasets: [
      {
        label: 'Pourcentage de personnel par tranche d\'âge',
        data: [
          Math.round(ageDistribution['18-30']),
          Math.round(ageDistribution['31-50']),
          Math.round(ageDistribution['51-70']),
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 2,
      },
    ],
  };
  const incidentColors = {
    'Annuler sur place': 'rgb(255, 182, 193)', // Rose pastel
    'Patient non prêt': 'rgb(173, 216, 230)', // Bleu clair
    'Patient absent': 'rgb(240, 230, 140)', // Kaki clair
    'Patient décédé': 'rgb(255, 160, 122)', // Saumon clair
    'Documents non établis': 'rgb(255, 239, 213)', // Papaye fouetté
    'Erreur de date ou d\'horraire': 'rgb(221, 160, 221)', // Prune
    'Attente imposée': 'rgb(176, 224, 230)', // Poudre bleu
    'Erreur mode de transport': 'rgb(144, 238, 144)', // Vert clair
    'Mauvaise destination': 'rgb(216, 191, 216)', // Thistle
    'Refus du patient': 'rgb(255, 218, 185)', // Pêche
    'autre': 'rgb(224, 255, 255)', // Blanc fumé
    'Absence': 'rgb(204, 229, 255)', // Bleu pastel
    'Retard': 'rgb(255, 204, 204)', // Rouge pastel
    'Comportement inapproprié': 'rgb(204, 255, 204)', // Vert pastel
    'Conflit avec un patient': 'rgb(255, 229, 204)', // Orange pastel
    'Conflit avec un personnel soignant': 'rgb(229, 204, 255)', // Violet pastel
    'Refus de transport': 'rgb(204, 229, 255)', // Bleu clair pastel
    'Perte de temps volontaire': 'rgb(255, 255, 204)', // Jaune pastel
    'Manquement à l\'image': 'rgb(255, 204, 229)', // Rose clair pastel
    'Détérioration du véhicule': 'rgb(204, 255, 229)', // Vert clair pastel
    'Non récupération des papiers d\'un transport': 'rgb(204, 204, 255)', // Lavande pastel
  };

  const indexOfLastIncident = currentPage * incidentsPerPage;
  const indexOfFirstIncident = indexOfLastIncident - incidentsPerPage;
  const currentIncidents = establishmentFilteredIncidents.slice(indexOfFirstIncident, indexOfLastIncident);

  const indexOfLastStaffIncident = currentStaffPage * incidentsPerStaffPage;
  const indexOfFirstStaffIncident = indexOfLastStaffIncident - incidentsPerStaffPage;
  const currentStaffIncidents = filteredIncidentStaff.slice(indexOfFirstStaffIncident, indexOfLastStaffIncident);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const paginateStaff = (pageNumber) => setCurrentStaffPage(pageNumber);

  // Mapper staff_id à company
  const staffIdToCompany = useMemo(() => {
    const mapping = {};
    staff.forEach((employee) => {
      mapping[employee.id] = employee.company;
    });
    return mapping;
  }, [staff]);

  const calculateAverageDailyAmplitude = () => {
    const filteredEntries = timeClockEntries.filter(
      (entry) => moment(entry.date).isBetween(startDate, endDate, null, '[]') &&
        entry.clock_in_time &&
        entry.clock_out_time &&
        (!selectedCompany || staffIdToCompany[entry.staff_id] === selectedCompany)
    );

    let totalAmplitude = 0;
    filteredEntries.forEach((entry) => {
      const clockIn = moment(entry.clock_in_time, 'HH:mm:ss');
      const clockOut = moment(entry.clock_out_time, 'HH:mm:ss');
      if (clockOut.isBefore(clockIn)) {
        clockOut.add(1, 'day');
      }
      const amplitude = clockOut.diff(clockIn, 'hours', true);
      totalAmplitude += amplitude;
    });

    const averageAmplitudes = filteredEntries.length > 0 ? totalAmplitude / filteredEntries.length : 0;
    return averageAmplitudes;
  };

  const calculateAverageBreakTime = () => {
    const filteredEntries = timeClockEntries.filter(
      (entry) => moment(entry.date).isBetween(startDate, endDate, null, '[]') &&
        entry.clock_in_time &&
        entry.clock_out_time &&
        (!selectedCompany || staffIdToCompany[entry.staff_id] === selectedCompany)
    );

    let totalBreakTime = 0;
    filteredEntries.forEach((entry) => {
      let breakTime = 0;

      if (entry.lunch_break_time) {
        breakTime += 0.5; // 30 minutes
      }
      if (entry.break_time) breakTime += 20 / 60;
      if (entry.break_time2) breakTime += 20 / 60;
      if (entry.break_time3) breakTime += 20 / 60;
      if (entry.break_time4) breakTime += 20 / 60;

      totalBreakTime += breakTime;
    });

    const averageBreakTimes = filteredEntries.length > 0 ? totalBreakTime / filteredEntries.length : 0;
    return averageBreakTimes;
  };

  const calculateOvertimeHours = () => {
    const filteredEntries = timeClockEntries.filter(
      (entry) => moment(entry.date).isBetween(startDate, endDate, null, '[]') &&
        entry.clock_in_time &&
        entry.clock_out_time &&
        (!selectedCompany || staffIdToCompany[entry.staff_id] === selectedCompany)
    );

    // Regrouper les entrées par semaine
    const entriesByWeek = {};

    filteredEntries.forEach((entry) => {
      const weekNumber = moment(entry.date).isoWeek();
      const year = moment(entry.date).year();
      const weekKey = `${year}-W${weekNumber}`;

      if (!entriesByWeek[weekKey]) {
        entriesByWeek[weekKey] = [];
      }
      entriesByWeek[weekKey].push(entry);
    });

    let total125 = 0;
    let total150 = 0;

    Object.keys(entriesByWeek).forEach((weekKey) => {
      const entries = entriesByWeek[weekKey];
      let totalWeekHours = 0;

      entries.forEach((entry) => {
        const clockIn = moment(entry.clock_in_time, 'HH:mm:ss');
        const clockOut = moment(entry.clock_out_time, 'HH:mm:ss');
        if (clockOut.isBefore(clockIn)) {
          clockOut.add(1, 'day');
        }
        let workedHours = clockOut.diff(clockIn, 'hours', true);

        let breaks = 0;
        if (entry.lunch_break_time) breaks += 0.5;
        if (entry.break_time) breaks += 20 / 60;
        if (entry.break_time2) breaks += 20 / 60;
        if (entry.break_time3) breaks += 20 / 60;
        if (entry.break_time4) breaks += 20 / 60;

        workedHours -= breaks;
        totalWeekHours += workedHours;
      });

      const overtimes = totalWeekHours - 35;
      if (overtimes > 0) {
        const overtime125 = Math.min(overtime, 8);
        const overtime150 = Math.max(overtime - 8, 0);

        total125 += overtime125;
        total150 += overtime150;
      }
    });

    return {
      hoursAt125: total125,
      hoursAt150: total150,
    };
  };

  const calculateIDAJ = () => {
    const filteredEntries = timeClockEntries.filter(
      (entry) => moment(entry.date).isBetween(startDate, endDate, null, '[]') &&
        entry.clock_in_time &&
        entry.clock_out_time &&
        (!selectedCompany || staffIdToCompany[entry.staff_id] === selectedCompany)
    );

    let idajCount = 0;

    filteredEntries.forEach((entry) => {
      const clockIn = moment(entry.clock_in_time, 'HH:mm:ss');
      const clockOut = moment(entry.clock_out_time, 'HH:mm:ss');
      if (clockOut.isBefore(clockIn)) {
        clockOut.add(1, 'day');
      }
      const amplitude = clockOut.diff(clockIn, 'hours', true);

      if (amplitude > 12) {
        idajCount += 1;
      }
    });

    return idajCount;
  };

  useEffect(() => {
    if (currentTab === 'timeClockData' && timeClockEntries.length > 0 && staff.length > 0) {
      const avgAmplitude = calculateAverageDailyAmplitude();
      const avgBreakTime = calculateAverageBreakTime();
      const overtimeResult = calculateOvertimeHours();
      const idajResult = calculateIDAJ();

      setAverageAmplitude(avgAmplitude);
      setAverageBreakTime(avgBreakTime);
      setOvertime(overtimeResult);
      setIdaj(idajResult);
    }
  }, [timeClockEntries, staff, currentTab, startDate, endDate, selectedCompany]);

  return (
    <div className="managementData">
      {isLoading && <div className="loader"><AmbysoftLoader /></div>}
      <h2 className="managementDataTitle">Données d'exploitation</h2>
      <Navbar currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <main style={{ padding: '20px' }}>
        {renderContent()}
      </main>

      <div className="date-filters">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Sélectionnez une date de début"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Sélectionnez une date de fin"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </div>

      {!isLoading && currentTab === 'humanResourcesData' && (
        <RessourcesHumainesData
          theme={theme}
          companies={companies}
          handleFilterCompanyChange={handleFilterCompanyChange}
          tauxPresence={tauxPresence}
          tauxAbsenteeisme={tauxAbsenteeisme}
          tauxTurnOver={tauxTurnOver}
          tempsFormation={tempsFormation}
          indiceArretMaladies={indiceArretMaladies}
          indiceCongerParental={indiceCongerParental}
          indiceFrequenceAccidents={indiceFrequenceAccidents}
          genderBalance={genderBalance}
          averageAge={averageAge}
          ageDistributionBarData={ageDistributionBarDataRH}
          doughnutData={doughnutDataRH}
        />
      )}
      {currentTab === 'facilityIncidents' && (
        <FacilitiesIncidents
          establishments={establishments}
          selectedEstablishment={selectedEstablishment}
          handleEstablishmentChange={handleEstablishmentChange}
          currentIncidents={currentIncidents}
          paginate={paginate}
          currentPage={currentPage}
          totalPages={Math.ceil(establishmentFilteredIncidents.length / incidentsPerPage)}
          incidentsPerPage={incidentsPerPage}
          establishmentFilteredIncidents={establishmentFilteredIncidents}
          incidentsByEstablishment={incidentsByEstablishment}
          establishmentIncidentData={establishmentIncidentData}
          theme={theme}
          incidentColors={incidentColors}
          pastelColors={pastelColors}
          handleChartClick={handleChartClick}
        />
      )}

        {currentTab === 'staffIncidents' && (
          <StaffIncidents
            staff={staff}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            currentStaffIncidents={currentStaffIncidents}
            paginateStaff={paginateStaff}
            currentStaffPage={currentStaffPage}
            totalStaffPages={Math.ceil(filteredIncidentStaff.length / incidentsPerStaffPage)}
            driverIncidentData={driverIncidentData}
            theme={theme}
            handleChartDriverClick={handleChartDriverClick}
            incidentColors={incidentColors}
          />
        )}

        {currentTab === 'stockRessourcesData' && (
          <StockResourcesData
            totalRetire={totalRetire}
            breakdownByReasonWithPrices={breakdownByReasonWithPrices}
            vehiclesLabels={vehiclesLabels}
            vehiclesPrices={vehiclesPrices}
            reasonsLabels={reasonsLabels}
            reasonsPrices={reasonsPrices}
            pastelColors={pastelColors}
          />
        )}

          {currentTab === 'vehicleManagementData' && (
            <VehicleManagementData
              theme={theme}
              companies={companies}
              handleFilterCompanyChange={handleFilterCompanyChange}
              handleVehiculeChange={handleVehiculeChange}
              vehicules={vehicules}
              selectedVehicule={selectedVehicule}
              currentVehicules={currentVehicules}
              paginateVehicles={paginateVehicles}
              pagesToShow={pagesToShow}
              currentVehiclePage={currentVehiclePage}
              totalVehiclePages={totalVehiclePages}
              totalCost={totalCost}
              repairsByType={repairsByType}
              barDataSelectedVehicle={barDataSelectedVehicle}
              barDataRepairsByVehicle={barDataRepairsByVehicle}
              handleSelectVehicule={setSelectedVehicule}
              handleChartVehClick={handleChartVehClick}
              pastelColors={pastelColors}
            />
          )}
      {!isLoading && currentTab === 'ProceduresManagementData' && (
        <ProceduresManagementData
          theme={theme}
          procedureTypes={uniqueProcedureTypes}
          setSelectedProcedureType={setSelectedProcedureType}
          selectedProcedureType={selectedProcedureType}
          procedures={procedures}
          selectedDetailedType={selectedDetailedType}
          setSelectedDetailedType={setSelectedDetailedType}
          setSelectedProcedure={setSelectedProcedure}
          selectedProcedure={selectedProcedure}
          questionAverages={questionAverages}
          dataForEmployeesChart={dataForEmployeesChart}
          dataForVehiclesChart={dataForVehiclesChart}
          dataForProceduresChart={dataForProceduresChart}
          positiveResponses={positiveResponses}
          procedureProblems={procedureProblems}
          radioResponses={radioResponses}
          selectedOptionFilter={selectedOptionFilter}
          setSelectedOptionFilter={setSelectedOptionFilter}
        />

      )}
        {currentTab === 'medicalDataTab' && (
          <MedicalDataTab
            companies={companies}
            handleFilterCompanyChange={handleFilterCompanyChange}
            currentMedicalFacturesPage={currentMedicalFacturesPage}
            formatDate={formatDate}
            paginateMedical={paginateMedical}
            currentMedicalPage={currentMedicalPage}
            filteredMedicalFactures={filteredMedicalFactures}
            facturesPerPage={facturesPerPage}
            totalMedicalCost={totalMedicalCost}
            medicalCostsByType={medicalCostsByType}
            pastelColors={pastelColors}
          />
        )}
        {currentTab === 'salaryDataTab' && (
        <SalaryDataTab
          companies={companies}
          handleFilterCompanyChange={handleFilterCompanyChange}
          filteredStaff={filteredStaff}
          selectedCompany={selectedCompany}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          selectedGraduation={selectedGraduation}
          setSelectedGraduation={setSelectedGraduation}
          selectedSalaryField={selectedSalaryField}
          setSelectedSalaryField={setSelectedSalaryField}
          currentStaffFacturesPage={currentStaffFacturesPage}
          paginateSalary={paginateSalary}
          currentSalaryPage={currentSalaryPage}
          totalPages={totalPages}
          pagesToShow={pagesToShow}
          getPageNumbers={getPageNumbers}
          totalSalaryCost={totalSalaryCost}
          totalSalaryCostPerEmployee={totalSalaryCostPerEmployee}
          salaryCostsByType={salaryCostsByType}
          barData={barData}
          salaryBreakdownData={salaryBreakdownData}
          setSalaryBreakdownData={setSalaryBreakdownData}
          theme={theme}
          handleChartTypeClick={handleChartTypeClick}
          pastelColors={pastelColors}
        />
        )}

        {currentTab === 'companyDataTab' && (
          <CompanyDataTab
            companies={companies}
            handleFilterCompanyChange={handleFilterCompanyChange}
            currentCompanyFacturesPage={currentCompanyFacturesPage}
            formatDate={formatDate}
            paginateCompany={paginateCompany}
            currentCompanyPage={currentCompanyPage}
            filteredCompanyFactures={filteredCompanyFactures}
            facturesPerPage={facturesPerPage}
            totalCompanyCost={totalCompanyCost}
            companyCostsByType={companyCostsByType}
            pastelColors={pastelColors}
          />
        )}
      {currentTab === 'locauxDataTab' && (
        <LocauxDataTab
          locaux={locaux}
          handleFilterLocauxChange={handleFilterLocauxChange}
          currentLocauxFacturesPage={currentLocauxFacturesPage}
          formatDate={formatDate}
          calculateFactureCost={calculateFactureCost}
          paginateLocaux={paginateLocaux}
          currentLocauxPage={currentLocauxPage}
          filteredLocauxFactures={filteredLocauxFactures}
          facturesPerPage={facturesPerPage}
          totalLocauxCost={totalLocauxCost}
          locauxCostsByType={locauxCostsByType}
          pastelColors={pastelColors}
        />
      )}
      {currentTab === 'timeClockData' && (
        <TimeClockDataTab
          averageAmplitude={averageAmplitude}
          averageBreakTime={averageBreakTime}
          overtime={overtime}
          idaj={idaj}
          selectedCompany={selectedCompany}
          companies={companies}
          handleFilterCompanyChange={handleFilterCompanyChange}
          theme={theme}
        />
      )}
    </div>
  );
}

export default React.memo(managementData);
