/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { Worker } from '@react-pdf-viewer/core';
import fetchAddresses from '../../../../utils/fetchAddresses';
import StaffFactureForm from './StaffFactureForm';
import { BASE_URL } from '../../../../utils/api';
import VehiculeImages from '../../vehiculeManagement/vehiculeImages/vehiculeImages';

function UpdateStaffModal({ onUpdateStaff, currentStaff, userToken }) {
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [incidentType, setIncidentType] = useState('');
  const [incidentDescription, setIncidentDescription] = useState('');
  const [showIncidentModal, setShowIncidentModal] = useState(false);
  const [factureModalOpen, setFactureModalOpen] = useState(false);
  const [factures, setFactures] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewImageUrl, setViewImageUrl] = useState(null);
  const addressSuggestionsRef = useRef(null);
  const [staffList, setStaffList] = useState([]);

  const [staffForm, setStaffForm] = useState({
    name: '',
    last_name: '',
    code: '',
    phoneNumber: '',
    email: '',
    adress: '',
    city: '',
    zipCode: '',
    company: '',
    graduation: '',
    socialNumber: '',
    idCard: null,
    drivingLicense: '',
    medicalVisit: '',
    drivingLicenseVisit: '',
    afgsu: '',
    rib: null,
    vaccinationLicense: null,
    entryDate: '',
    exitDate: null,
    responsability: '',
    incidents: '',
    date_de_naissance: '',
    sexe: '',
    order_index: '',
    observations: '',
    medical_visit_document: null,
    graduation_document: null,
    pro_driving_license: null,
    afgsu_document: null,
    binome: '',
  });

  const labelMapping = {
    salaire_brut: 'Salaire brut',
    cotisations_patronales: 'Cotisations patronales',
    indemnites_repas: 'Indemnités de repas',
    indemnites_repas_2: 'Indemnités de repas 2',
    transport: 'Transport',
    prime_salissure: 'Prime de salissure',
    taxe_salaire: 'Taxe sur salaire',
    cout: 'Coût',
  };

  const orderedKeys = [
    'salaire_brut',
    'cotisations_patronales',
    'indemnites_repas',
    'indemnites_repas_2',
    'transport',
    'prime_salissure',
    'taxe_salaire',
    'cout',
  ];

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    // Charger la liste du personnel
    const fetchStaffList = async () => {
      try {
        const response = await axios.get(`${BASE_URL}staff`, { headers });
        const filteredStaff = response.data.staff.filter(
          (member) => !member.exitdate && member.graduation !== 'ADMINISTRATIF',
        );
        filteredStaff.sort((a, b) => a.name.localeCompare(b.name));
        setStaffList(filteredStaff);
      }
      catch (error) {
        console.error('Failed to fetch staff:', error);
      }
    };

    fetchStaffList();
  }, []);

  const fetchStaffCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staffCompanies/${currentStaff.id}`, { headers });
      const companyIds = response.data.companies.map((company) => company.id);
      setSelectedCompanies(companyIds);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des sociétés du personnel', error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchVehicles = async () => {
    try {
      const res = await axios.get(`${BASE_URL}vehicules`, { headers });
      const filteredAndSortedVehicles = res.data.vehicule
        .filter((vehicle) => !vehicle.exitdate) // Exclut les véhicules avec une exit_date
        .sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true })); // Trie par code
      setVehicles(filteredAndSortedVehicles);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Chargez les entreprises dès que le composant est monté.
    fetchCompanies();
    fetchVehicles();
  }, []);

  useEffect(() => {
    // Chargez les entreprises associées à l'employé dès que 'currentStaff' est défini.
    if (currentStaff && currentStaff.id) {
      fetchStaffCompanies();
    }
  }, [currentStaff]);

  useEffect(() => {
    if (companies.length && selectedCompanies.length === 0) {
      // Trouvez l'ID de la société actuelle de l'employé via son code
      const currentCompanyId = companies.find((c) => c.code === currentStaff.company)?.id;
      if (currentCompanyId) {
        setSelectedCompanies((prev) => [...prev, currentCompanyId]);
      }
    }
  }, [companies, currentStaff, selectedCompanies.length]);

  const handleOpenIncidentModal = () => {
    setShowIncidentModal(true);
  };
  const handleCloseIncidentModal = () => {
    setShowIncidentModal(false);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!staffForm.name) {
      newErrors.name = 'Le nom est requis';
    }
    if (!staffForm.last_name) {
      newErrors.last_name = 'Le prénom est requis';
    }
    if (!staffForm.code) {
      newErrors.code = 'Le code est requis';
    }
    if (!staffForm.phoneNumber) {
      newErrors.phoneNumber = 'Le numéro de téléphone est requis';
    }
    if (!staffForm.email) {
      newErrors.email = "L'email est requis";
    }
    if (!staffForm.adress) {
      newErrors.adress = "L'adresse est requise";
    }
    if (!staffForm.city) {
      newErrors.city = 'La ville est requise';
    }
    if (!staffForm.zipCode) {
      newErrors.zipCode = 'Le code postal est requis';
    }
    if (!staffForm.company) {
      newErrors.company = 'La société est requise';
    }
    if (!staffForm.graduation) {
      newErrors.graduation = 'Le poste est requis';
    }
    if (!staffForm.medicalVisit) {
      newErrors.medicalVisit = 'La visite médicale est requise';
    }
    if (!staffForm.drivingLicenseVisit) {
      newErrors.drivingLicenseVisit = 'La visite du permis de conduire est requise';
    }
    if (!staffForm.afgsu) {
      newErrors.afgsu = "L'afgsu est requis";
    }
    if (!staffForm.entryDate) {
      newErrors.entryDate = "La date d'entrée est requise";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressSuggestionsRef.current && !addressSuggestionsRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addressSuggestionsRef]);

  const handleCompanySelectionChange = (event, company) => {
    if (event.target.checked) {
      setSelectedCompanies([...selectedCompanies, company.id]);
    }
    else {
      setSelectedCompanies(selectedCompanies.filter((id) => id !== company.id));
    }
  };

  useEffect(() => {
    setStaffForm({
      id: currentStaff.id,
      name: currentStaff.name,
      last_name: currentStaff.last_name,
      code: currentStaff.code,
      phoneNumber: currentStaff.phonenumber,
      email: currentStaff.email,
      adress: currentStaff.adress,
      city: currentStaff.city,
      zipCode: currentStaff.zipcode,
      company: currentStaff.company,
      graduation: currentStaff.graduation,
      socialNumber: currentStaff.socialnumber,
      idCard: currentStaff.idcard,
      contrat: currentStaff.contrat,
      drivingLicense: currentStaff.drivinglicense,
      medicalVisit: moment(currentStaff.medicalvisit).format('YYYY-MM-DD'),
      drivingLicenseVisit: moment(currentStaff.drivinglicensevisit).format('YYYY-MM-DD'),
      afgsu: moment(currentStaff.afgsu).format('YYYY-MM-DD'),
      rib: currentStaff.rib,
      vaccinationLicense: currentStaff.vaccinationlicense,
      entryDate: moment(currentStaff.entrydate).format('YYYY-MM-DD'),
      exitDate: currentStaff.exitdate ? moment(currentStaff.exitdate).format('YYYY-MM-DD') : null,
      responsability: currentStaff.responsability,
      incidents: currentStaff.incidents,
      date_de_naissance: moment(currentStaff.date_de_naissance).format('YYYY-MM-DD'),
      sexe: currentStaff.sexe,
      order_index: currentStaff.order_index,
      observations: currentStaff.observations,
      medical_visit_document: currentStaff.medical_visit_document,
      graduation_document: currentStaff.graduation_document,
      pro_driving_license: currentStaff.pro_driving_license,
      afgsu_document: currentStaff.afgsu_document,
      binome: currentStaff.binome || '',
    });
  }, [currentStaff]);

  const handleChange = (event) => {
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      [event.target.name]: event.target.value,
    }));
  };

  // MISE À JOUR — Icône d'upload séparée + clic sur le cadre pour visualiser
  const handleStaffFileChange = async (e, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxFileSize = 4 * 1024 * 1024; // 2MB
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF.');
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}staff/presigned`,
        { name: file.name, type: file.type },
        { headers },
      );
      const { url, fields } = response.data;

      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', file);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const uploadedFileUrl = `${fields.key}`;
      setStaffForm((prevState) => ({ ...prevState, [fieldName]: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(
        `${BASE_URL}staff/get-signed-url`,
        { key },
        { headers },
      );
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const downloadFile = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const contentType = response.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([response.data], { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(
        `${BASE_URL}staff/get-signed-url`,
        { key },
        { headers },
      );
      downloadFile(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      window.open(viewImageUrl, '_blank');
    }
  }, [viewImageUrl]);

  const handleAddIncident = () => {
    const formattedDate = format(incidentDate, 'dd-MM-yy');
    const newIncident = {
      date: formattedDate,
      type: incidentType,
      description: incidentDescription,
    };
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      incidents: Array.isArray(prevStaffForm.incidents)
        ? [...prevStaffForm.incidents, newIncident]
        : [newIncident],
    }));
    handleCloseIncidentModal();
  };

  const fetchFactures = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff/${currentStaff.id}/factures`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      const facturesWithUniqueId = response.data.factures
        .map((facture) => ({
          ...facture,
          uniqueId: `${facture.date}-${facture.type}-${JSON.stringify(facture.details)}`,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      setFactures(facturesWithUniqueId);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des factures :', error);
    }
  };

  useEffect(() => {
    if (factureModalOpen) {
      fetchFactures();
    }
  }, [factureModalOpen, currentStaff.id, userToken]);

  const handleSaveFacture = async (facture) => {
    try {
      await axios.post(`${BASE_URL}staff/${currentStaff.id}/factures`, facture, { headers });
      setFactureModalOpen(false);
    }
    catch (error) {
      console.error("Erreur lors de l'enregistrement de la facture :", error);
    }
  };

  const groupFacturesByMonth = (facturesList) => facturesList.reduce((acc, facture) => {
    const date = new Date(facture.date);
    const monthYear = date.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
    });

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(facture);
    return acc;
  }, {});

  const facturesGroupedByMonth = groupFacturesByMonth(factures);

  const handleDeleteFacture = async (factureId) => {
    const result = await Swal.fire({
      title: 'Confirmer la suppression',
      text: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}staff/${currentStaff.id}/factures`, {
          headers: { Authorization: `Bearer ${userToken}` },
          data: { id: factureId },
        });

        fetchFactures();

        Swal.fire({
          title: 'Supprimée!',
          text: 'La facture a été supprimée avec succès.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      catch (error) {
        console.error('Erreur lors de la suppression de la facture :', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression de la facture.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      name: staffForm.name,
      last_name: staffForm.last_name,
      code: staffForm.code,
      phoneNumber: staffForm.phoneNumber,
      email: staffForm.email,
      adress: staffForm.adress,
      city: staffForm.city,
      zipCode: staffForm.zipCode,
      company: staffForm.company,
      graduation: staffForm.graduation,
      socialNumber: staffForm.socialNumber,
      idCard: staffForm.idCard,
      drivingLicense: staffForm.drivingLicense,
      contrat: staffForm.contrat,
      medicalVisit: staffForm.medicalVisit,
      drivingLicenseVisit: staffForm.drivingLicenseVisit,
      afgsu: staffForm.afgsu,
      rib: staffForm.rib,
      vaccinationLicense: staffForm.vaccinationLicense,
      entryDate: staffForm.entryDate,
      exitDate: staffForm.exitDate,
      responsability: staffForm.responsability,
      incidents: staffForm.incidents,
      date_de_naissance: staffForm.date_de_naissance,
      sexe: staffForm.sexe,
      order_index: staffForm.order_index,
      observations: staffForm.observations,
      medical_visit_document: staffForm.medical_visit_document,
      graduation_document: staffForm.graduation_document,
      pro_driving_license: staffForm.pro_driving_license,
      afgsu_document: staffForm.afgsu_document,
      binome: staffForm.binome || null,
    };

    if (data.exitDate === '') {
      data.exitDate = null;
    }

    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    try {
      await axios.put(`${BASE_URL}staff/${currentStaff.id}`, data, { headers });
      onUpdateStaff(data);

      await axios.post(
        `${BASE_URL}staffCompanies`,
        {
          staff_id: currentStaff.id,
          company_ids: selectedCompanies,
        },
        { headers },
      );
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
    }
  };

  const handleAddressChange = async (event) => {
    const query = event.target.value;
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      adress: query,
    }));

    if (query.length > 2) {
      const suggestions = await fetchAddresses(query, { limit: 5 });
      setAddressSuggestions(suggestions.features || []);
      setOpen(true);
    }
    else {
      setAddressSuggestions([]);
      setOpen(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      adress: suggestion.properties.name,
      city: suggestion.properties.city,
      zipCode: suggestion.properties.postcode,
    }));
    setAddressSuggestions([]);
    setOpen(false);
  };

  const handleSocialNumberChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, ''); // enlever tout ce qui n'est pas chiffre

    // Format n° sécu (13 chiffres + 2 clef)
    const formattedValue = value
      .slice(0, 13)
      .replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})/, '$1 $2 $3 $4 $5 $6');

    // Ajouter la clef si 15 chiffres
    const clef = value.slice(13, 15);
    const finalValue = clef ? `${formattedValue} clef : ${clef}` : formattedValue;

    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      socialNumber: finalValue,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, '');

    // Format 2 par 2
    const formattedValue = value.split('').reduce(
      (acc, digit, index) => (index > 0 && index % 2 === 0 ? `${acc} ${digit}` : acc + digit),
      '',
    );

    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      phoneNumber: formattedValue,
    }));
  };

  return (
    <Box component="form" noValidate autoComplete="off" className="addTransportModal">
      <h2 className="addModalTitle">Modifier le personnel</h2>
      <Button
        onClick={() => setFactureModalOpen(true)}
        sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
        variant="contained"
      >
        Ajouter/consulter une charge
      </Button>
      <Button
        onClick={handleOpenIncidentModal}
        sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
        variant="contained"
      >
        Incident
      </Button>

      {/*
        ==============================
          MODALE D'AJOUT DE FACTURE
        ==============================
      */}
      <Dialog open={factureModalOpen} onClose={() => setFactureModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Ajouter une charge</DialogTitle>
        <DialogContent>
          <StaffFactureForm onSaveFacture={handleSaveFacture} />
          <hr />
          <h3 className="addModalTitle">Charges existantes</h3>
          <div>
            {Object.keys(facturesGroupedByMonth).map((monthYear) => (
              <Accordion key={monthYear}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{monthYear}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Détails</TableCell>
                          <TableCell>Commentaires</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {facturesGroupedByMonth[monthYear].map((facture) => {
                          const totalFacture = Object.values(facture.details)
                            .filter((val) => {
                              const isValid = typeof val === 'string' && val.trim() !== '';
                              return isValid;
                            })
                            .reduce((total, montantStr) => {
                              const montantNumerique = parseFloat(
                                montantStr
                                  .replace(/\s/g, '')
                                  .replace(/[^\d,.-]/g, '')
                                  .replace(',', '.'),
                              );
                              return montantNumerique ? total + montantNumerique : total;
                            }, 0);

                          return (
                            <React.Fragment key={facture.id}>
                              <TableRow>
                                <TableCell>
                                  {new Date(facture.date).toLocaleDateString('fr-FR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                  })}
                                </TableCell>
                                <TableCell>{facture.type}</TableCell>
                                <TableCell>
                                  {orderedKeys.map(
                                    (key) => facture.details[key] && (
                                    <div key={key}>
                                      <strong>{labelMapping[key]}: </strong>
                                      {facture.details[key]}
                                    </div>
                                    ),
                                  )}
                                </TableCell>
                                <TableCell>{facture.commentaires}</TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={4} align="right">
                                  <strong>Total de la facture :</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>{totalFacture.toFixed(2)} €</strong>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFactureModalOpen(false)} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      {/*
        ==============================
          MODALE D'AJOUT D'INCIDENT
        ==============================
      */}
      <Dialog open={showIncidentModal} onClose={handleCloseIncidentModal}>
        <DialogTitle>Ajouter un incident</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <InputLabel id="incident-type-label">Type d'incident</InputLabel>
            <Select
              labelId="incident-type-label"
              id="incident-type"
              value={incidentType}
              onChange={(e) => setIncidentType(e.target.value)}
              label="Type d'incident"
            >
              <MenuItem value="Absence">Absence</MenuItem>
              <MenuItem value="Retard">Retard</MenuItem>
              <MenuItem value="Comportement inapproprié">Comportement inapproprié</MenuItem>
              <MenuItem value="Conflit avec un patient">Conflit avec un patient</MenuItem>
              <MenuItem value="Conflit avec un personnel soignant">Conflit avec un personnel soignant</MenuItem>
              <MenuItem value="Refus de transport">Refus de transport</MenuItem>
              <MenuItem value="Perte de temps volontaire">Perte de temps volontaire</MenuItem>
              <MenuItem value="Manquement à l'image">Manquement à l'image</MenuItem>
              <MenuItem value="Détérioration du véhicule">Détérioration du véhicule</MenuItem>
              <MenuItem value="Non récupération des papiers d'une mission">
                Non récupération des papiers d'une mission
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            id="incident-description"
            label="Description de l'incident"
            variant="outlined"
            value={incidentDescription}
            onChange={(e) => setIncidentDescription(e.target.value)}
          />
          <TextField
            id="incidentDate"
            label="Date d'incident"
            type="date"
            defaultValue={format(new Date(), 'yyyy-MM-dd')}
            sx={{ marginY: '10px', width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setIncidentDate(new Date(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddIncident} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>

      {/* FORMULAIRE PRINCIPAL */}
      <div className="firstLine">
        <TextField
          type="text"
          sx={{ width: '30%' }}
          name="code"
          label="Code"
          InputLabelProps={{
            shrink: true,
          }}
          id="code"
          value={staffForm.code}
          onChange={handleChange}
        />
        {errors.code && <p className="error">{errors.code}</p>}

        <TextField
          type="text"
          sx={{ width: '30%' }}
          name="name"
          label="Nom"
          InputLabelProps={{
            shrink: true,
          }}
          id="nom"
          value={staffForm.name}
          onChange={handleChange}
        />
        {errors.name && <p className="error">{errors.name}</p>}

        <TextField
          type="text"
          sx={{ width: '30%' }}
          name="last_name"
          label="Prénom"
          InputLabelProps={{
            shrink: true,
          }}
          id="last_name"
          value={staffForm.last_name}
          onChange={handleChange}
        />
        {errors.last_name && <p className="error">{errors.last_name}</p>}

        <TextField
          type="date"
          sx={{ width: '30%' }}
          name="date_de_naissance"
          label="Date de naissance"
          InputLabelProps={{
            shrink: true,
          }}
          id="date_de_naissance"
          value={staffForm.date_de_naissance}
          onChange={handleChange}
        />
        {errors.date_de_naissance && <p className="error">{errors.date_de_naissance}</p>}
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginBottom: 1,
          marginTop: 2,
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="company">Société</InputLabel>
          <Select
            required
            name="company"
            id="company"
            label="Société"
            value={staffForm.company}
            onChange={handleChange}
          >
            {errors.company && <p className="error">{errors.company}</p>}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ width: '100%' }}>
          <h3 style={{ fontSize: '1rem', marginBottom: '0' }}>Mise à disposition :</h3>
          <FormGroup row>
            {companies.map((company) => (
              <FormControlLabel
                key={company.id}
                control={(
                  <Checkbox
                    checked={selectedCompanies.includes(company.id)}
                    onChange={(event) => handleCompanySelectionChange(event, company)}
                  />
                )}
                label={company.code}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <FormControl sx={{ width: '20%' }}>
          <InputLabel id="graduation">Diplôme</InputLabel>
          <Select
            required
            label="Diplôme"
            name="graduation"
            id="graduation"
            value={staffForm.graduation}
            onChange={handleChange}
          >
            {errors.graduation && <p className="error">{errors.graduation}</p>}
            <MenuItem value="DEA">DEA</MenuItem>
            <MenuItem value="CCA">CCA</MenuItem>
            <MenuItem value="IDE">IDE</MenuItem>
            <MenuItem value="AA">AA</MenuItem>
            <MenuItem value="CA">CA</MenuItem>
            <MenuItem value="ADMINISTRATIF">ADMINISTRATIF</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: '15%' }}>
          <InputLabel id="sexe">Sexe</InputLabel>
          <Select
            required
            label="Sexe"
            name="sexe"
            id="sexe"
            value={staffForm.sexe}
            onChange={handleChange}
          >
            {errors.sexe && <p className="error">{errors.sexe}</p>}
            <MenuItem value="Homme">Homme</MenuItem>
            <MenuItem value="Femme">Femme</MenuItem>
            <MenuItem value="Autre">Autre</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <div className="secondLine">
        <FormControl sx={{ width: '30%' }}>
          <InputLabel id="responsability">Responsabilité</InputLabel>
          <Select
            required
            label="Responsabilité"
            name="responsability"
            id="responsability"
            value={staffForm.responsability}
            onChange={handleChange}
          >
            {errors.responsability && <p className="error">{errors.responsability}</p>}
            <MenuItem value="">
              <em>Aucune</em>
            </MenuItem>
            {vehicles.map((vehicle) => (
              <MenuItem key={vehicle.id} value={vehicle.code}>
                {vehicle.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '30%' }}>
          <InputLabel id="binome-label">Binôme</InputLabel>
          <Select
            labelId="binome-label"
            id="binome"
            name="binome"
            value={staffForm.binome}
            onChange={handleChange}
            label="Binôme"
          >
            <MenuItem value="">
              <em>Aucun</em>
            </MenuItem>
            {staffList
              .filter((staff) => staff.id !== currentStaff.id)
              .map((staff) => (
                <MenuItem key={staff.id} value={staff.id}>
                  {`${staff.name} ${staff.last_name}`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          type="text"
          sx={{ width: '40%' }}
          name="phoneNumber"
          label="Numéro de téléphone"
          InputLabelProps={{
            shrink: true,
          }}
          id="numeroTelephone"
          value={staffForm.phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}

        <TextField
          type="text"
          sx={{ width: '30%' }}
          name="email"
          label="Email"
          InputLabelProps={{
            shrink: true,
          }}
          id="email"
          value={staffForm.email}
          onChange={handleChange}
        />
        {errors.email && <p className="error">{errors.email}</p>}
      </div>

      <div className="thirdLine">
        <TextField
          type="text"
          sx={{ width: '80%', marginBottom: '1rem' }}
          name="adress"
          label="Adresse"
          InputLabelProps={{
            shrink: true,
          }}
          id="adresse"
          value={staffForm.adress}
          onChange={handleAddressChange}
        />
        {open && addressSuggestions.length > 0 && (
          <ul className="suggestions-list" ref={addressSuggestionsRef}>
            {addressSuggestions.map((suggestion, index) => (
              <li
                key={index}
                className="suggestions-list-item"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.properties.label}
              </li>
            ))}
          </ul>
        )}
        <TextField
          type="text"
          sx={{ width: '80%', marginBottom: '1rem' }}
          name="city"
          label="Ville"
          InputLabelProps={{
            shrink: true,
          }}
          id="ville"
          value={staffForm.city}
          onChange={handleChange}
        />
        {errors.city && <p className="error">{errors.city}</p>}

        <TextField
          type="text"
          sx={{ width: '80%' }}
          name="zipCode"
          label="Code postal"
          InputLabelProps={{
            shrink: true,
          }}
          id="codePostal"
          value={staffForm.zipCode}
          onChange={handleChange}
        />
        {errors.zipCode && <p className="error">{errors.zipCode}</p>}
      </div>

      <div className="fourthLine" style={{ paddingBottom: '1rem' }}>
        <TextField
          type="text"
          sx={{ width: '40%' }}
          name="socialNumber"
          label="N° sécurité sociale"
          InputLabelProps={{
            shrink: true,
          }}
          id="numeroSecuriteSociale"
          value={staffForm.socialNumber}
          onChange={handleSocialNumberChange}
        />

        <TextField
          type="text"
          sx={{ width: '40%' }}
          name="drivingLicense"
          label="N° permis de conduire"
          InputLabelProps={{
            shrink: true,
          }}
          id="drivingLicense"
          value={staffForm.drivingLicense}
          onChange={handleChange}
        />

        <TextField
          type="text"
          sx={{ width: '40%' }}
          name="observations"
          label="Observations"
          InputLabelProps={{
            shrink: true,
          }}
          id="observations"
          value={staffForm.observations}
          onChange={handleChange}
        />
      </div>

      <div className="fifthLine">
        <TextField
          type="date"
          sx={{ width: '20%' }}
          name="medicalVisit"
          label="Visite médicale"
          InputLabelProps={{
            shrink: true,
          }}
          id="visiteMedicale"
          value={staffForm.medicalVisit}
          onChange={handleChange}
        />
        {errors.medicalVisit && <p className="error">{errors.medicalVisit}</p>}

        <TextField
          type="date"
          sx={{ width: '20%' }}
          name="drivingLicenseVisit"
          label="Visite permis de conduire"
          InputLabelProps={{
            shrink: true,
          }}
          id="drivingLicenseVisit"
          value={staffForm.drivingLicenseVisit}
          onChange={handleChange}
        />
        {errors.drivingLicenseVisit && <p className="error">{errors.drivingLicenseVisit}</p>}

        <TextField
          type="date"
          sx={{ width: '20%' }}
          name="afgsu"
          label="Visite Afgsu"
          InputLabelProps={{
            shrink: true,
          }}
          id="afgsu"
          value={staffForm.afgsu}
          onChange={handleChange}
        />
        {errors.afgsu && <p className="error">{errors.afgsu}</p>}
      </div>

      <div className="sixLine">
        <TextField
          type="date"
          sx={{ width: '20%' }}
          name="entryDate"
          label="Date d'entrée"
          InputLabelProps={{
            shrink: true,
          }}
          id="entryDate"
          value={staffForm.entryDate}
          onChange={handleChange}
        />
        {errors.entryDate && <p className="error">{errors.entryDate}</p>}

        <TextField
          type="date"
          sx={{ width: '20%' }}
          name="exitDate"
          label="Date de sortie"
          InputLabelProps={{
            shrink: true,
          }}
          id="dateSortie"
          value={staffForm.exitDate}
          onChange={handleChange}
        />
      </div>

      {/*
        ==============================
          GESTION DES DOCUMENTS
        ==============================
      */}
      <div className="vehiculesFiles">
        {/* ========== Permis de conduire (idCard) ========== */}
        <Box
          mb={2}
          className="VehiculeCard"
          onClick={() => {
            if (staffForm.idCard) {
              fetchSignedUrlForViewing(staffForm.idCard);
            }
          }}
          style={{ cursor: staffForm.idCard ? 'pointer' : 'default' }}
        >
          <p className="vehiculeLabel">Permis de conduire</p>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={staffForm.idCard}
              key={staffForm.idCard}
              userToken={userToken}
            />
          </Worker>
          <div className="vehiculeButton">
            <Tooltip title="Ajouter un document">
              <IconButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation(); // Empêche d'ouvrir le doc en cliquant sur l'icône
                  document.getElementById('idCard-input').click();
                }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
            {staffForm.idCard && (
              <Tooltip title="Télécharger le document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchSignedUrlForDownloading(staffForm.idCard);
                  }}
                >
                  <CloudDownloadIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <input
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          id="idCard-input"
          type="file"
          onChange={(e) => handleStaffFileChange(e, 'idCard')}
        />

        {/* ========== Visite de permis (pro_driving_license) ========== */}
        <Box
          mb={2}
          className="VehiculeCard"
          onClick={() => {
            if (staffForm.pro_driving_license) {
              fetchSignedUrlForViewing(staffForm.pro_driving_license);
            }
          }}
          style={{ cursor: staffForm.pro_driving_license ? 'pointer' : 'default' }}
        >
          <p className="vehiculeLabel">Visite de permis</p>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={staffForm.pro_driving_license}
              key={staffForm.pro_driving_license}
              userToken={userToken}
            />
          </Worker>
          <div className="vehiculeButton">
            <Tooltip title="Ajouter un document">
              <IconButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  document.getElementById('pro_driving_license-input').click();
                }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
            {staffForm.pro_driving_license && (
              <Tooltip title="Télécharger le document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchSignedUrlForDownloading(staffForm.pro_driving_license);
                  }}
                >
                  <CloudDownloadIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <input
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          id="pro_driving_license-input"
          type="file"
          onChange={(e) => handleStaffFileChange(e, 'pro_driving_license')}
        />

        {/* ========== Diplôme (graduation_document) ========== */}
        <Box
          mb={2}
          className="VehiculeCard"
          onClick={() => {
            if (staffForm.graduation_document) {
              fetchSignedUrlForViewing(staffForm.graduation_document);
            }
          }}
          style={{ cursor: staffForm.graduation_document ? 'pointer' : 'default' }}
        >
          <p className="vehiculeLabel">Diplôme</p>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={staffForm.graduation_document}
              key={staffForm.graduation_document}
              userToken={userToken}
            />
          </Worker>
          <div className="vehiculeButton">
            <Tooltip title="Ajouter un document">
              <IconButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  document.getElementById('graduation_document-input').click();
                }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
            {staffForm.graduation_document && (
              <Tooltip title="Télécharger le document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchSignedUrlForDownloading(staffForm.graduation_document);
                  }}
                >
                  <CloudDownloadIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <input
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          id="graduation_document-input"
          type="file"
          onChange={(e) => handleStaffFileChange(e, 'graduation_document')}
        />

        {/* ========== AFGSU (afgsu_document) ========== */}
        <Box
          mb={2}
          className="VehiculeCard"
          onClick={() => {
            if (staffForm.afgsu_document) {
              fetchSignedUrlForViewing(staffForm.afgsu_document);
            }
          }}
          style={{ cursor: staffForm.afgsu_document ? 'pointer' : 'default' }}
        >
          <p className="vehiculeLabel">AFGSU</p>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={staffForm.afgsu_document}
              key={staffForm.afgsu_document}
              userToken={userToken}
            />
          </Worker>
          <div className="vehiculeButton">
            <Tooltip title="Ajouter un document">
              <IconButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  document.getElementById('afgsu_document-input').click();
                }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
            {staffForm.afgsu_document && (
              <Tooltip title="Télécharger le document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchSignedUrlForDownloading(staffForm.afgsu_document);
                  }}
                >
                  <CloudDownloadIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <input
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          id="afgsu_document-input"
          type="file"
          onChange={(e) => handleStaffFileChange(e, 'afgsu_document')}
        />

        {/* ========== Visite médicale (medical_visit_document) ========== */}
        <Box
          mb={2}
          className="VehiculeCard"
          onClick={() => {
            if (staffForm.medical_visit_document) {
              fetchSignedUrlForViewing(staffForm.medical_visit_document);
            }
          }}
          style={{ cursor: staffForm.medical_visit_document ? 'pointer' : 'default' }}
        >
          <p className="vehiculeLabel">Visite médicale</p>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={staffForm.medical_visit_document}
              key={staffForm.medical_visit_document}
              userToken={userToken}
            />
          </Worker>
          <div className="vehiculeButton">
            <Tooltip title="Ajouter un document">
              <IconButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  document.getElementById('medical_visit_document-input').click();
                }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
            {staffForm.medical_visit_document && (
              <Tooltip title="Télécharger le document">
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchSignedUrlForDownloading(staffForm.medical_visit_document);
                  }}
                >
                  <CloudDownloadIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <input
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          id="medical_visit_document-input"
          type="file"
          onChange={(e) => handleStaffFileChange(e, 'medical_visit_document')}
        />
      </div>

      <div className="sevenLine">
        <Button
          sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }}
          variant="contained"
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? 'Loading...' : 'Modifier le personnel'}
        </Button>
      </div>
    </Box>
  );
}

export default React.memo(UpdateStaffModal);
