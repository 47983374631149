/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';

// Petite fonction pour détecter le préfixe (DEPART, ARRIV, BOTH) dans incident.incident
function parseIncidentText(incidentText) {
  if (!incidentText) return { facilityPrefix: '', description: '' };

  let facilityPrefix = '';
  let description = incidentText;

  if (incidentText.startsWith('[DEPART]')) {
    facilityPrefix = 'Établissement de départ';
    description = incidentText.replace('[DEPART]', '').trim();
  }
  else if (incidentText.startsWith('[ARRIV]')) {
    facilityPrefix = 'Établissement d’arrivée';
    description = incidentText.replace('[ARRIV]', '').trim();
  }
  else if (incidentText.startsWith('[BOTH]')) {
    facilityPrefix = 'Établissement de départ et arrivée';
    description = incidentText.replace('[BOTH]', '').trim();
  }

  return { facilityPrefix, description };
}

function FacilityIncidents({
  establishments,
  selectedEstablishment,
  handleEstablishmentChange,
  currentIncidents,
  paginate,
  currentPage,
  incidentsByEstablishment,
  establishmentIncidentData,
  incidentColors,
  pastelColors,
  handleChartClick
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const barChartRef = useRef(null);

  // ------------------ Filtre par type d’incident (facultatif) ------------------
  const allTypes = useMemo(() => {
    const setOfTypes = new Set();
    currentIncidents.forEach((inc) => {
      if (inc.incident_type) setOfTypes.add(inc.incident_type);
    });
    return Array.from(setOfTypes);
  }, [currentIncidents]);

  const [selectedIncidentType, setSelectedIncidentType] = useState('');
  const handleIncidentTypeChange = (e) => {
    setSelectedIncidentType(e.target.value);
  };

  // Filtrer `currentIncidents` selon `selectedIncidentType`
  const filteredByTypeIncidents = useMemo(() => {
    if (!selectedIncidentType) return currentIncidents;
    return currentIncidents.filter(
      (incident) => incident.incident_type === selectedIncidentType
    );
  }, [currentIncidents, selectedIncidentType]);

  // Pagination : on pagine le résultat filtré
  const startIndex = (currentPage - 1) * 5;
  const endIndex = startIndex + 5;
  const incidentsPaginated = filteredByTypeIncidents.slice(startIndex, endIndex);
  const realTotalPages = Math.ceil(filteredByTypeIncidents.length / 5);

  // ------------------ Modal de réponse OSIRIS ------------------
  const [showOsirisModal, setShowOsirisModal] = useState(false);
  const [osirisReponse, setOsirisReponse] = useState('');

  const handleOpenOsirisModal = (reponse) => {
    setOsirisReponse(reponse || '');
    setShowOsirisModal(true);
  };
  const handleCloseOsirisModal = () => {
    setShowOsirisModal(false);
    setOsirisReponse('');
  };

  // ------------------ Tableau d’incidents par type (global) ------------------
  const incidentCountByType = useMemo(() => {
    const counts = {};
    currentIncidents.forEach((inc) => {
      const t = inc.incident_type || 'Inconnu';
      counts[t] = (counts[t] || 0) + 1;
    });
    return counts;
  }, [currentIncidents]);

  // ----------- Graphique existant (incidents par établissement) -----------
  const incidentsPerEstablishment = Object.entries(incidentsByEstablishment)
    .sort((a, b) => b[1] - a[1]); // Tri décroissant

  const barChartDataEstablishments = {
    labels: incidentsPerEstablishment.map(([establishmentName]) => establishmentName),
    datasets: [
      {
        label: "Nombre d'incidents par établissement",
        data: incidentsPerEstablishment.map(([, count]) => count),
        backgroundColor: pastelColors.slice(0, incidentsPerEstablishment.length),
      },
    ],
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* -------------- CARTe FILTRES -------------- */}
      <Card sx={{ mb: 3 }}>
        <CardHeader title="Filtres" sx={{ backgroundColor: muiTheme.palette.action.hover }} />
        <CardContent>
          <Grid container spacing={2}>
            {/* Filtre d’établissement */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="establishment-select-label">Établissement</InputLabel>
                <Select
                  labelId="establishment-select-label"
                  value={selectedEstablishment}
                  label="Établissement"
                  onChange={handleEstablishmentChange}
                >
                  <MenuItem value="">
                    <em>Tous les établissements</em>
                  </MenuItem>
                  {establishments.map((est) => (
                    <MenuItem key={est.id} value={est.name}>
                      {est.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filtre par type d'incident */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="incident-type-filter-label">Type d'incident</InputLabel>
                <Select
                  labelId="incident-type-filter-label"
                  value={selectedIncidentType}
                  label="Type d'incident"
                  onChange={handleIncidentTypeChange}
                >
                  <MenuItem value="">
                    <em>Tous les types</em>
                  </MenuItem>
                  {allTypes.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* -------------- TABLEAU DES INCIDENTS (PAGINÉS) -------------- */}
      <Typography variant="h6" sx={{ mb: 1 }}>
        Liste des incidents
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table aria-label="Incidents des établissements" size="small">
          <TableHead sx={{ backgroundColor: muiTheme.palette.action.hover }}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Heure</TableCell>
              <TableCell>Établissement</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Raison</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {incidentsPaginated.map((incident, idx) => {
              const { facilityPrefix, description } = parseIncidentText(incident.incident);

              let establishmentName = 'N/A';
              if (facilityPrefix === 'Établissement de départ') {
                establishmentName = incident.departure_label || 'Inconnu';
              }
              else if (facilityPrefix === 'Établissement d’arrivée') {
                establishmentName = incident.arrival_label || 'Inconnu';
              }
              else if (facilityPrefix === 'Établissement de départ et arrivée') {
                const dep = incident.departure_label || 'Inconnu';
                const arr = incident.arrival_label || 'Inconnu';
                establishmentName = `${dep} / ${arr}`;
              }
              else {
                establishmentName = incident.departure_label || 'N/A';
              }

              const rowBackground = idx % 2 === 0
                ? muiTheme.palette.action.hover
                : 'transparent';

              return (
                <TableRow key={incident.id} sx={{ backgroundColor: rowBackground }}>
                  <TableCell>
                    {new Date(incident.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {incident.last_name
                      ? `${incident.last_name.substring(0, 3)}...`
                      : ''}
                  </TableCell>
                  <TableCell>
                    {incident.departure_hour}
                  </TableCell>
                  <TableCell>
                    {establishmentName}
                  </TableCell>
                  <TableCell>
                    {incident.service}
                  </TableCell>
                  <TableCell>
                    {incident.reason}
                  </TableCell>
                  <TableCell>
                    {incident.incident_type}
                  </TableCell>
                  <TableCell>
                    {facilityPrefix && (
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {facilityPrefix}
                      </Typography>
                    )}
                    {description || ''}
                  </TableCell>
                  <TableCell>
                    {incident.incident_type === 'Osiris' && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => handleOpenOsirisModal(incident.incident_osiris_reponse)}
                      >
                        Réponse
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Pagination
          count={realTotalPages}
          page={currentPage}
          onChange={(event, page) => paginate(page)}
          color="primary"
        />
      </Box>

      {/* -------------- MODAL OSIRIS -------------- */}
      <Dialog open={showOsirisModal} onClose={handleCloseOsirisModal}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>Réponse à l’incident Osiris</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {osirisReponse || "Aucune réponse n'a été fournie."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOsirisModal} color="primary" variant="outlined">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      {/* -------------- GRAPH : Incidents par établissement -------------- */}
      <Box sx={{ mt: 6 }}>
        {selectedEstablishment && establishmentIncidentData[selectedEstablishment] ? (
          <Card>
            <CardHeader
              title={`Répartition des incidents pour ${selectedEstablishment}`}
              sx={{ backgroundColor: muiTheme.palette.action.hover }}
            />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 400 }}>
                <Doughnut
                  data={{
                    labels: Object.keys(establishmentIncidentData[selectedEstablishment]),
                    datasets: [{
                      data: Object.values(establishmentIncidentData[selectedEstablishment]),
                      backgroundColor: Object.keys(establishmentIncidentData[selectedEstablishment]).map(
                        (type) => incidentColors[type] || '#000000'
                      ),
                    }],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardHeader
              title="Nombre d'incidents par établissement"
              sx={{ backgroundColor: muiTheme.palette.action.hover }}
            />
            <CardContent>
              <Box sx={{ height: isSmDown ? 300 : 500 }}>
                <Bar
                  ref={barChartRef}
                  data={barChartDataEstablishments}
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        beginAtZero: true,
                      },
                      y: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    onClick: (event) => handleChartClick(event, barChartRef.current),
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>

      {/* -------------- TABLEAU "Incidents par type" (global) -------------- */}
      <Box sx={{ marginTop: 6 }}>
        <Card>
          <CardHeader
            title="Nombre d’incidents par type (global)"
            sx={{ backgroundColor: muiTheme.palette.action.hover }}
          />
          <CardContent>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead sx={{ backgroundColor: muiTheme.palette.action.hover }}>
                  <TableRow>
                    <TableCell>Type d’incident</TableCell>
                    <TableCell>Nombre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(incidentCountByType).map(([type, count], idx) => {
                    const rowBg = idx % 2 === 0
                      ? muiTheme.palette.action.hover
                      : 'transparent';
                    return (
                      <TableRow key={type} sx={{ backgroundColor: rowBg }}>
                        <TableCell>{type}</TableCell>
                        <TableCell>{count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default React.memo(FacilityIncidents);
